import {
  Box,
  ButtonGroup,
  Flex,
  Text,
  useMediaQuery,
  SimpleGrid,
} from "@chakra-ui/react";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import "../../../../../assets/styles/App.css";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTostMessage,
  PosDatePicker,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import {
  commonDateFormate,
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay,
} from "./../../../../../helpers/utils/Utils";
import { getVoid } from "./VoidService";
import moment from "moment";

const Void = (props) => {
  const myContext = useContext(AppContext);
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const [searchQuery, setSearchQuery] = useState("");
  const [totalcount, setTotalCount] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [loading, setLoading] = useState(true);
  const effectRun = useRef(true);
  const [isMobile] = useMediaQuery("(max-width: 767px)");
  const [isExtraLarges] = useMediaQuery("(min-width: 1365px)");
  const [isLarge] = useMediaQuery("(min-width: 1315px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1260px)");
  const [isMeduim2] = useMediaQuery("(min-width: 905px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1175px)");
  const { error } = PosErrorHook();
  const searchInputHideRef = useRef(false);
  const [locations, setLocations] = useState([]);
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const { addToast } = PosTostMessage();
  const submit = useSubmit();
  const [buttonDisable, setButtonDisable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [voidData, setVoidData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const sortRef = useRef(sortModel);
  const [
    listOrderOlColumnVisibilityModel,
    setListOrderOlColumnVisibilityModel,
  ] = React.useState({
    id: false,
  });
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumns = isMobile ? { width: 180 } : { flex: 1.5 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  const [isVisible, setIsVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const Operation = [
    {
      label: "Cash Drawer Opened",
      value: "CDOP",
    },
    {
      label: "Item Deleted",
      value: "IDEL",
    },
    {
      label: "Order Cleared",
      value: "ORCL",
    },
    {
      label: "Ticket Deleted",
      value: "TDEL",
    },
  ];
  const columnNames = [
    { field: "id", headerName: "Id", sortable: true, width: 90 },
    {
      field: "date_time",
      headerName: "Date Time",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "location_id",
      headerName: "Location Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "pos_station_id",
      headerName: "Station Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "user_id",
      headerName: "User",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "operation_type",
      headerName: "Operation Type",
      sortable: true,
      ...actionColumns,
    },
    {
      field: "item_name",
      headerName: "Item Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "barcode",
      headerName: "Barcode",
      sortable: true,
      ...actionColumns,
    },
  ];
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0]);
            formik.setFieldValue(
              "selectedLoaction",
              loaderResponse?.data?.data?.location[0].name
            );
            setListOrderOlColumnVisibilityModel((prev) => ({
              ...prev,
              location_id: false,
            }));
          }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      selectedLoactions: "",
      selectedOperations: [],
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      // selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      // fetchData();
    }
  }, [
    paginationModel,
    sortModel,
    formik.values.selectedLoactions,
    formik.values.fromDate,
    formik.values.selectedOperations,
    formik.values.toDate,
  ]);
  const handleSearchList = () => {
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    effectRun.current = true;
  };

  const modifiedData = voidData.map((data) => ({
    id: data.id,
    date_time: commonDateFormate(data.date_time),
    location_id: data.location_name,
    pos_station_id: data.pos_station_name,
    user_id: data.user_name,
    operation_type:
      data.operation_type === "CDOP"
        ? "Cash Drawer Opened"
        : data.operation_type === "IDEL"
        ? "Item Deleted"
        : data.operation_type === "ORCL"
        ? "Order Cleared"
        : data.operation_type === "TDEL"
        ? "Ticket Deleted"
        : data.operation_type,
    item_name: data.item_name || "N.A",
    barcode: data.barcode || "N.A",
  }));

  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const handleClear = () => {
    // clear input value and get all data
    effectRun.current = true;
    setSearchQuery("");
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    props.navigate(Constants.VOID_REPORT_ROUTE);
  };
  const apply = () => {
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };
  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      effectRun.current = true;
      apply();
    }, 250);
    setTimeoutId(newTimeoutId);
  };
  const handleOptionSelect = (e) => {
    effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
    }
  };
  const handleExportReport = () => {
    if (
      formik.values.selectedLoactions == "" &&
      locations.length > 1 &&
      ((formik.values.fromDate != null && formik.values.fromDate != "") ||
        (formik.values.toDate != "" && formik.values.toDate != null))
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.LOCATION_IS_REQUIRED,
      });
    } else {
    myContext.handleLoading(true);
    let data = {
      // exportReport: true,
      Operations: JSON.stringify(formik.values.selectedOperations),
        dateFrom:
          formik.values.fromDate != null && formik.values.fromDate != ""
            ? reportDateFormate(formik.values.fromDate)
            : "",
        dateTo:
          formik.values.toDate != null && formik.values.toDate != ""
            ? reportDateFormate(formik.values.toDate)
            : "",
      location: JSON.stringify({
        // label: selectedLocation.name,
        value: {
          id: selectedLocation.id,
          // name: selectedLocation.name,
        },
      }),
      // zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      query: JSON.stringify({
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
      }),
    };

    submit(data, {
      method: Constants.POST,
      path: Constants.VOID_REPORT_ROUTE,
    });
  }
  };

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message === "Void Report successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(Constants.DOWNLOAD, "export_void_report.xlsx");
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.data);
          setTotalCount(actionResponse.data.data.totalItems);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);

  const clearFilterDAta = () => {
    effectRun.current = true;
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    if (searchQuery.length > 0) {
      setSearchQuery("");
    }
    if (locations.length > 1) {
      setSelectedLocation([]);
      formik.setFieldValue("selectedLoactions", "");
    }
    formik.setFieldValue("fromDate", "");
    formik.setFieldValue("toDate", "");
    formik.setFieldValue("selectedOperations", []); 

  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    effectRun.current = true;
    if (locations.length > 1) {
      setSelectedLocation([]);
      formik.setFieldValue("selectedLoactions", "");
      formik.setFieldTouched("selectedLoactions", false);
    }
    if (formik.values.selectedOperations.length > 0) {
      formik.setFieldValue("selectedOperations", []);
    }
    formik.setFieldValue("fromDate", "");
    formik.setFieldTouched("fromDate", false);
    formik.setFieldValue("toDate", "");
    formik.setFieldTouched("toDate", false);
  };
  useEffect(() => {
    if (effectRun.current === true) {
      fetchData();
      effectRun.current = false;
    }
  }, [
    paginationModel,
    // searchQuery,
    sortModel,
    formik.values.selectedLoactions,
    formik.values.fromDate,
    formik.values.selectedOperations,
    formik.values.toDate,
    loading,
    searchQuery,
  ]);
  const fetchData = async () => {
    if (
      formik.values.selectedLoactions == "" &&
      locations.length > 1 &&
      ((formik.values.fromDate != null && formik.values.fromDate != "") ||
        (formik.values.toDate != "" && formik.values.toDate != null))
    ) {
      effectRun.current = false;
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.LOCATION_IS_REQUIRED,
      });
    } else {
      setLoading(true);
      let data = {
        Operations: formik.values.selectedOperations,
        dateFrom:
          formik.values.fromDate != null && formik.values.fromDate != ""
            ? reportDateFormate(formik.values.fromDate)
            : "",
        dateTo:
          formik.values.toDate != null && formik.values.toDate != ""
            ? reportDateFormate(formik.values.toDate)
            : "",
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
      };
      if (Object.keys(selectedLocation).length > 0) {
        data.location = {
          label: selectedLocation.name,
          value: {
            id: selectedLocation.id,
            name: selectedLocation.name,
          },
        };
      } else {
        data.location = "";
      }
      try {
        getVoid(data)
          .then((response) => {
            if (
              undefined !== response.data.flag &&
              null !== response.data.flag &&
              response.data.flag == true
            ) {
              setVoidData(response.data.data.data);
              setTotalCount(response.data.data.totalItems);
              setLoading(false);
              myContext.handleLoading(false);
            } else {
              setVoidData([]);
              setTotalCount(0);
              setLoading(false);
              let actionData = response;
              error({ actionData });
              myContext.handleLoading(false);
            }
          })
          .catch((err) => {
            if (
              !searchInputHideRef.current &&
              err?.response?.data?.data?.data === 0
            ) {
              searchInputHideRef.current = true;
            }
            setVoidData([]);
            setTotalCount(0);
            setLoading(false);
            let actionData = err;
            error({ actionData });
            myContext.handleLoading(false);
          });
      } catch (error) {
        setVoidData([]);
        setTotalCount(0);
        setLoading(false);
        myContext.handleLoading(false);
      }
    }
  };
  const handleOperationsSelect = (e) => {
    effectRun.current = true;
    if (undefined !== e.value) {
      formik.setFieldValue("selectedOperations", e.value);
    }
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
  };

  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "")){
          effectRun.current = true;
        }
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "")){
          effectRun.current = true;
        }
        setPaginationModel({
          page: 0,
          pageSize: 25,
        });
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "") ){
        effectRun.current = true;
      }
      setPaginationModel({
        page: 0,
        pageSize: 25,
      });
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const renderReportLayout = () => {
    return (
      <>
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 2, lg: 2, xl: 5 }}
          ml={4}
          mb = {4}
          mr={4}
          spacing={{
            base: "1rem",
            sm: "1rem",
            md: "1rem",
            lg: "1rem",
            xl: "1rem",
          }}
        >
          {locations.length > 1 && (
            <Box>
              <PosDropDown
                id="selectedLoactions"
                options={locations}
                value={selectedLocation}
                resetFilterOnHide={true}
                onChange={handleOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLoactions");
                  formik.handleBlur(e);
                }}
                optionLabel="name"
                placeholder="Select Location"
                width={"100%"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                  <span>{formik.errors.selectedLoactions}</span>
                ) : null}
              </Text>
            </Box>
          )}
          <Box>
            <PosDropDown
              id="selectedOperations"
              options={Operation}
              value={formik.values.selectedOperations}
              resetFilterOnHide={true}
              multiSelect={true}
              onChange={handleOperationsSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedOperations");
                formik.handleBlur(e);
              }}
              optionLabel="label"
              placeholder="Select Operations"
              width={"100%"}
              height={"2.5rem"}
              className="w-full md:w-20rem"
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedOperations &&
              formik.errors.selectedOperations ? (
                <span>{formik.errors.selectedOperations}</span>
              ) : null}
            </Text>
          </Box>
          <Box>
            <PosDatePicker
              id={"fromDate"}
              ref={calendarRef}
              readOnlyInput={true}
              value={formik.values.fromDate}
              label={"From Date"}
              width={"100%"}
              onFocus={() => {
                if (
                  null !== toDateCalendarRef.current &&
                  toDateCalendarRef.current
                ) {
                  toDateCalendarRef.current.hide();
                }
                setToDateFocus(false);
                setTimeout(() => {
                  setFromDateFocus(true);
                }, 1000);
                formik.setFieldTouched("fromDate", true);
              }}
              handleSelectData={hadleFromDateSelect}
              yearRange={"2000:2030"}
              maxDate={dateTimeValue}
              viewDate={fromDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.fromDate && formik.errors.fromDate ? (
                <span>{formik.errors.fromDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box>
            <PosDatePicker
              id={"toDate"}
              ref={toDateCalendarRef}
              readOnlyInput={true}
              value={formik.values.toDate}
              label={"To Date"}
              width={"100%"}
              onFocus={() => {
                if (null !== calendarRef.current && calendarRef.current) {
                  calendarRef.current.hide();
                }
                setFromDateFocus(false);
                setTimeout(() => {
                  setToDateFocus(true);
                }, 1000);
                formik.setFieldTouched("toDate", true);
              }}
              handleSelectData={(e) => {
                effectRun.current = setPaginationModel({
                  page: 0,
                  pageSize: 25,
                });
                formik.setFieldValue("toDate", e.value);
                setToDateView(e.value);
                if(e.value != null && e.value != "" ||  (formik.values.selectedLoactions != null && formik.values.selectedLoactions != "") ){
                  effectRun.current = true;
                }
              }}
              yearRange={"2000:2030"}
              minDate={
                null !== formik.values.fromDate && "" !== formik.values.fromDate
                  ? formik.values.fromDate
                  : undefined
              }
              maxDate={dateTimeValue}
              viewDate={toDateView}
              showButtonBar={true}
              showTime={true}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.toDate && formik.errors.toDate ? (
                <span>{formik.errors.toDate}</span>
              ) : null}
            </Text>
          </Box>
          <Box alignContent={"end"} mt={{ base: 2, md: 0 }}>
            <ButtonGroup>
              <PosFormButton
                isDisabled={
                  searchQuery.length > 0 ||
                  formik.values.selectedLoactions !== "" ||
                  formik.values.selectedOperations.length > 0 ||
                  (formik.values.fromDate !== "" && formik.values.fromDate !== null) ||
                  (formik.values.toDate !== "" && formik.values.toDate !== null)
                    ? false
                    : true
                }
                onClick={() => {
                  clearFilterDAta();
                }}
                buttonText={"Clear"}
                CancelButton={true}
              />
            </ButtonGroup>
          </Box>
        </SimpleGrid>
      </>
    );
  };
  return (
    <Box
      padding={{ base: 4, sm: 6, md: 10, lg: 14 }}
      bg={Colors.loginAuthBackground}
    >
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Void"]}
          breadCrumTitle={"Void"}
        />
        <ButtonGroup gap="2" alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        pt={1}
        borderRadius="0.63rem"
        bg="white"
        boxShadow="0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)"
      >
        {!searchInputHideRef.current ? (
          <Flex
            direction={{ base: "column", md: "row" }}
            alignItems={{ base: "stretch", md: "center" }}
            mt={6}
            mb={3}
            ml={4}
            mr={4}
          >
            <PosSearch
              value={searchQuery}
              onChange={onChange}
              // onChange={(e) => setSearchQuery(e.target.value)}
              onSearch={handleSearchList}
              handleClear={handleClear}
            />
            {locations.length > 1 && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                w={"3rem"}
                ml={{ base: 0, md: "1rem" }}
                mt={{ base: "1rem", md: 0 }}
              >
                <IconButton
                  onClick={toggleVisibility}
                  style={{
                    border: "1px solid #ccc",
                    boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                    color: Colors.posTextInfo,
                    borderColor: Colors.dividerColor,
                    borderRadius: "5px",
                    padding: 5,
                  }}
                >
                  <FilterListIcon style={{ color: Colors.posTextInfo }} />
                </IconButton>
              </Box>
            )}
          </Flex>
        ) : null}

        {/* {isVisible && (
          <Flex
            flexDirection={isMeduim ? "column" : "row"}
            ml={{ base: 4, md: 4 }}
            gap={4}
          >
            <Box>
              <PosDropDown
                id="selectedLoactions"
                options={locations}
                value={selectedLocation}
                resetFilterOnHide={true}
                onChange={handleOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLoactions");
                  formik.handleBlur(e);
                }}
                optionLabel="name"
                placeholder="Location"
                width={
                  isExtraLarges
                    ? "20rem"
                    : isLarge
                    ? "17rem"
                    : isMeduim1
                    ? "13.32rem"
                    : "100%"
                }
                height="2.5rem"
                className="w-full md:w-20rem"
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                  <span>{formik.errors.selectedLoactions}</span>
                ) : null}
              </Text>
            </Box>
            <Box>
              <PosFormButton
                isDisabled={
                  searchQuery.length > 0 ||
                  formik.values.selectedLoactions !== ""
                    ? false
                    : true
                }
                onClick={clearFilterDAta}
                buttonText={"Clear"}
                CancelButton={true}
              />
            </Box>
          </Flex>
        )} */}
        {isVisible && <>{renderReportLayout()}</>}
        {/* <Flex
          flexDirection={isMobile ? "column" : isMeduim ? "column" : "row"}
          mt={6}
          mr={4}
        >
        </Flex> */}
        {loading ? (
          <PosProgress />
        ) : totalcount > 0 ? (
          <Box padding={"1rem"}>
            <PosDataGridTable
              columns={columnNames}
              rows={modifiedData}
              totalcount={totalcount}
              columnVisibilityModel={listOrderOlColumnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setListOrderOlColumnVisibilityModel(newModel)
              }
              paginationModel={paginationModel}
              paginationMode="server"
              sortingMode="server"
              onPaginationModelChange={(newPaginationModel) => {
                effectRun.current = true;
                setPaginationModel(newPaginationModel);
              }}
              sortModel={sortModel}
              onSortModelChange={(newSortModel) => {
                effectRun.current = true;
                sortRef.current = sortModel;
                setSortModel(newSortModel);
              }}
              slots={{
                toolbar: GridToolbar,
              }}
              fileName={Constants.VOID_CSV}
            />
          </Box>
        ) : (
          <Box padding={"1rem"}>
            <PosNoDataFound title={Constants.VOID_NO_DATA} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default WithRouter(Void);
