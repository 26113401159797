import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import voriLogo from "../../../assets/images/Black_LOGO_with_Name copy.png";
import octoposLogo from "../../../assets/images/cropped_logo.png";

import {
  AppContext,
  PosFormButton,
  PosMainProgress,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";

const PublicRewardCardTermsServices = (props) => {
  const myContext = useContext(AppContext);
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const [locationData, setLocationData] = useState([]);
  const [businessNameNotFound, setBusinessNameNotFound] = useState(1);
  const [locImgNotFound, setLocImgNotFound] = useState(1);
  const [locPhone, setLocPhone] = useState("");
  const [businessImage, setBusinessImage] = useState("");

  const effectRun = useRef(true);

  useEffect(() => {
    const subdomain = window.location.host.split(".");
    if (subdomain[1] !== Constants.SANE_POS_DOMAIN) {
      setBusinessImage(octoposLogo);
    } else {
      setBusinessImage(octoposLogo);
    }
  }, []);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLocationData(loaderResponse.data?.data);

          if (
            null != loaderResponse.data?.data?.businessLogo &&
            "" != loaderResponse.data?.data?.businessLogo
          ) {
            setLocImgNotFound(0);
          }
          if (
            null != loaderResponse.data?.data?.businessName &&
            "" != loaderResponse.data?.data?.businessName
          ) {
            setBusinessNameNotFound(0);
          }
          let newVal = loaderResponse.data?.data?.location_data.phone
            .toString()
            .replace(/\D/g, "");
          if (
            loaderResponse.data?.data?.location_data.phone.toString().length ===
            0
          ) {
            newVal = "";
          } else if (
            loaderResponse.data?.data?.location_data.phone.toString().length <=
            3
          ) {
            newVal = newVal.replace(/^(\d{0,3})/, "($1)");
          } else if (
            loaderResponse.data?.data?.location_data.phone.toString().length <=
            6
          ) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "($1) $2");
          } else if (
            loaderResponse.data?.data?.location_data.phone.toString().length <=
            10
          ) {
            newVal = newVal.replace(
              /^(\d{0,3})(\d{0,3})(\d{0,4})/,
              "($1) $2-$3"
            );
          } else {
            newVal = newVal.substring(0, 10);
            newVal = newVal.replace(
              /^(\d{0,3})(\d{0,3})(\d{0,4})/,
              "($1) $2-$3"
            );
          }
          setLocPhone(newVal);
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        }
      }
      // return () => {
      effectRun.current = false;
      // };
    }
  }, [loaderResponse]);

  const handleClose = () => {
    // window.location.reload(); // Reload the current page
    window.open(window.location.href, "_self").close(); // Attempt to close the current tab
  };

  return (
    <Flex
      bg={Colors.loginAuthBackground}
      minHeight={"100vh"}
      flexDirection={"column"}
    >
      <Box
        w={"100%"}
        sx={{
          position: "-webkit-sticky",
          /* Safari */ position: "sticky",
          top: "0",
          zIndex: 999,
        }}
      >
        <Box
          w={"100%"}
          sx={{
            /* Safari */ position: "absolute",
            top: "0",
            zIndex: 99999999,
          }}
        >
          {myContext.loading ? <PosMainProgress /> : null}
        </Box>
        <Flex
          // h={"100vh"}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
        >
          <Box
            bg={Colors.loginAuthBackground}
            style={{
              width: "100rem",
              // borderRadius: "0.63rem",
              // border: "0.13rem solid #FFF",
              // boxShadow: "0rem 1.25rem 3.13rem 0rem #59595926",
              paddingBottom: "2.81rem",
              // boxSizing: "border-box",
            }}
          >
            <Box
              display="flex"
              mt={"2rem"}
              justifyContent="center"
              alignItems="center"
            >
              {locationData.businessLogo != null &&
              "" != locationData.businessLogo ? (
                <Image
                  style={{
                    height: "8.125rem",
                  }}
                  src={locationData.businessLogo}
                  alt="Octopos"
                />
              ) : (
                null
                // <Image
                //   style={{
                //     width: "15.31rem",
                //   }}
                //   src={businessImage}
                //   alt="Octopos"
                // />
              )}
            </Box>
            {/* <Box
              color={Colors.posTextInfo}
              mt={"0.625rem"}
              style={{
                fontSize: "1.5rem",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {businessNameNotFound == 0 && 0 === locImgNotFound && (
                <Text
                  style={{
                    fontSize: "1.13rem",
                    fontStyle: "1.9rem",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "-0.02rem",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {locationData.business_name}
                </Text>
              )}
              {businessNameNotFound == 0 && 1 == locImgNotFound && (
                <Text
                  style={{
                    fontSize: "1.13rem",
                    fontStyle: "1.9rem",
                    fontWeight: "600",
                    lineHeight: "normal",
                    letterSpacing: "-0.02rem",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {locationData.business_name}
                </Text>
              )}
            </Box> */}
            {/* <Box
              m={"0.625rem"}
              color={Colors.posTextInfo}
              style={{
                marginTop: "0.31rem",
                fontSize: "1.13rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {null != loaderResponse.data?.data?.location_data.address1 &&
              "" != loaderResponse.data?.data?.location_data.address1
                ? loaderResponse.data?.data?.location_data.address1 + ", "
                : ""}
              {null != loaderResponse.data?.data?.location_data.city &&
              "" != loaderResponse.data?.data?.location_data.city
                ? loaderResponse.data?.data?.location_data.city + ", "
                : ""}
              {null != loaderResponse.data?.data?.location_data.state &&
              "" != loaderResponse.data?.data?.location_data.state
                ? loaderResponse.data?.data?.location_data.state + " "
                : ""}
              {null != loaderResponse.data?.data?.location_data?.zipcode &&
              "" != loaderResponse.data?.data?.location_data?.zipcode
                ? locationData.location_data?.zipcode + ", "
                : ""}
              {null != loaderResponse.data?.data?.location_data.country &&
              "" != loaderResponse.data?.data?.location_data.country
                ? loaderResponse.data?.data?.location_data.country
                : ""}
              .
            </Box>
            <Box
              color={Colors.posTextInfo}
              style={{
                marginTop: "0.31rem",
                fontSize: "1.13rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
                letterSpacing: "-0.02rem",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              Ph- {null != locPhone && "" != locPhone ? locPhone : "N.A."}
            </Box> */}
            <Box mx={{ base: 4, md: 156 }}>
              <Stack mt={"3rem"} spacing={2}>
                <Box textAlign="left">
                  <Text
                    color={Colors.posTextInfo}
                    fontSize="1.5rem"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                    letterSpacing="-0.01rem"
                  >
                    TERMS & SERVICES
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    as="i"
                    // color={'#002A409E'}
                    // fontSize={"1.1rem"}
                    // fontWeight={"400"}
                    // lineHeight={"1.5rem"}
                    // letterSpacing={"0.05rem"}
                  >
                    By consenting to 
                    {locationData.business_name != null &&
                    "" != locationData.business_name
                      ? locationData.business_name + "’s"
                      : "Octopos’"}{" "}
                    SMS marketing or subscribing via our subscription tools, you
                    agree to receive recurring text notifications (for your
                    order, including abandoned checkout reminders), text
                    marketing offers, and transactional texts, including
                    requests for reviews from us, even if your mobile number is
                    registered on any state or federal do-not-call list. Message
                    frequency varies. Consent is not a condition of purchase.
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    as="i"
                    // color={'#002A409E'}
                    // fontSize={"1.1rem"}
                    // fontWeight={"400"}
                    // lineHeight={"1.5rem"}
                    // letterSpacing={"0.05rem"}
                  >
                    If you wish to unsubscribe from receiving text marketing
                    messages and notifications, reply with STOP to any mobile
                    message sent from us or use the unsubscribe link we provided
                    you within any of our messages. You understand and agree
                    that alternative methods of opting out, such as using
                    alternative words or requests, will not be considered a
                    reasonable means of opting out. We do not charge for the
                    service, but you are responsible for all charges and fees
                    associated with text messaging imposed by your wireless
                    provider. Message and data rates may apply.
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    as="i"
                    // color={'#002A409E'}
                    // fontSize={"1.1rem"}
                    // fontWeight={"400"}
                    // lineHeight={"1.5rem"}
                    // letterSpacing={"0.05rem"}
                  >
                    We have the right to modify any telephone number or short
                    code we use to operate the service at any time. You will be
                    notified on such occasions. You agree that any messages you
                    send to a telephone number or short code we have changed,
                    including any STOP or HELP requests, may not be received,
                    and we will not be liable for honoring requests made in such
                    messages.
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    as="i"
                    // color={'#002A409E'}
                    // fontSize={"1.1rem"}
                    // fontWeight={"400"}
                    // lineHeight={"1.5rem"}
                    // letterSpacing={"0.05rem"}
                  >
                    To the extent permitted by applicable law, you agree that we
                    will not be liable for failed, delayed, or misdirected
                    delivery of any information sent through the service, any
                    errors in such information, and/or any action you may or may
                    not take in reliance on the information or Service.
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    as="i"
                    // color={'#002A409E'}
                    // fontSize={"1.1rem"}
                    // fontWeight={"400"}
                    // lineHeight={"1.5rem"}
                    // letterSpacing={"0.05rem"}
                  >
                    Your right to privacy is important to us. You can see our{" "}
                    <a
                      href="https://octopos.com/privacy-policy/"
                      target="_blank"
                      style={{
                        textDecoration: "underline",
                        color: "blue", // typical link color
                        cursor: "pointer",
                      }}
                    >
                      Privacy Policy
                    </a>{" "}
                    to determine how we collect and use your personal
                    information.
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    color={Colors.posTextInfo}
                    fontSize="1.5rem"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                    letterSpacing="-0.01rem"
                  >
                    COLLECTION OF PHONE NUMBERS
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    // color={'#002A409E'}
                    // fontSize={"1.1rem"}
                    // fontWeight={"400"}
                    // lineHeight={"1.5rem"}
                    // letterSpacing={"0.05rem"}
                  >
                    We collect your phone numbers only when you voluntarily
                    provide them to us, for example, during transactions,
                    inquiries, or when you sign up for our promotional messages.
                    You can opt in to receive these SMS messages either by
                    clicking on  on the welcome pop-up (which shows to new
                    users) or in the checkout by checking your SMS sign-up and
                    providing your phone number.
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    color={Colors.posTextInfo}
                    fontSize="1.5rem"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                    letterSpacing="-0.01rem"
                  >
                    USE OF PHONE NUMBERS FOR SMS
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    // color={'#002A409E'}
                    // fontSize={"1.1rem"}
                    // fontWeight={"400"}
                    // lineHeight={"1.5rem"}
                    // letterSpacing={"0.05rem"}
                  >
                    Your phone numbers are primarily used to provide you with
                    personalized services, updates, and promotional offers.
                    These text messages may relate to our products, services,
                    discounts, or any updates that we think may interest you. 
                    We may also provide special discounts or offers through SMS
                    messages. SMS messaging charges may be applied by your
                    carrier. We will only share your phone number with our SMS
                    provider, subject to their privacy policy.
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    color={Colors.posTextInfo}
                    fontSize="1.5rem"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                    letterSpacing="-0.01rem"
                  >
                    OPTING OUT OF MARKETING MESSAGES
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    // color={'#002A409E'}
                    // fontSize={"1.1rem"}
                    // fontWeight={"400"}
                    // lineHeight={"1.5rem"}
                    // letterSpacing={"0.05rem"}
                  >
                    If at any time you wish to stop receiving marketing SMS from
                    us, you can opt out by
                    </Text>
                    <ul style={{ marginLeft: "0.2rem" }} color={Colors.posTextInfo}>
                      <li
                        style={{
                          marginTop: "0.9rem",
                          listStyleType: "disc",
                          marginLeft: "1.1rem",
                        }}
                      >
                        <Text
                          // color="#737588"
                          fontSize="1rem"
                          fontStyle="normal"
                          fontWeight="400"
                          lineHeight="normal"
                          letterSpacing="-0.01rem"
                        >Texting STOP to opt-out
                        </Text>
                      </li>
                      <li
                        style={{
                          marginTop: "0.5rem",
                          listStyleType: "disc",
                          marginLeft: "1.1rem",
                        }}
                      >
                        <Text
                          // color="#737588"
                          fontSize="1rem"
                          fontStyle="normal"
                          fontWeight="400"
                          lineHeight="normal"
                          letterSpacing="-0.01rem"
                        >
                        Texting UNSUBSCRIBE to opt-out
                        </Text>
                      </li>
                      <li
                        style={{
                          marginTop: "0.5rem",
                          listStyleType: "disc",
                          marginLeft: "1.1rem",
                        }}
                      >
                        <Text
                          // color="#737588"
                          fontSize="1rem"
                          fontStyle="normal"
                          fontWeight="400"
                          lineHeight="normal"
                          letterSpacing="-0.01rem"
                        >
                        Texting CANCEL to opt-out
                        </Text>
                      </li>
                      <li
                        style={{
                          marginTop: "0.5rem",
                          listStyleType: "disc",
                          marginLeft: "1.1rem",
                        }}
                      >
                        <Text
                          // color="#737588"
                          fontSize="1rem"
                          fontStyle="normal"
                          fontWeight="400"
                          lineHeight="normal"
                          letterSpacing="-0.01rem"
                        >
                        Texting QUIT to opt-out
                        </Text>
                      </li>
                    </ul>
                  {/* </Text> */}
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    // color={'#002A409E'}
                    // fontSize={"1.1rem"}
                    // fontWeight={"400"}
                    // lineHeight={"1.5rem"}
                    // letterSpacing={"0.05rem"}
                  >
                    Please note that the opt-out process may take up to 10
                    business days to become effective. During this period, you
                    may still receive some messages from us.
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    color={Colors.posTextInfo}
                    fontSize="1.5rem"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                    letterSpacing="-0.01rem"
                  >
                    PRIVACY OF PHONE NUMBERS
                  </Text>
                </Box>
                <Box mt={"0.5rem"} textAlign="left">
                  <Text
                    // color={'#002A409E'}
                    // fontSize={"1.1rem"}
                    // fontWeight={"400"}
                    // lineHeight={"1.5rem"}
                    // letterSpacing={"0.05rem"}
                  >
                    Once you have opted out, we will not send you any more SMS
                    messages, nor will we sell or transfer your phone number to
                    another party.
                  </Text>
                </Box>
                <Flex
                  flexDirection={"flex-end"}
                  alignContent={"end"}
                  alignSelf={"end"}
                  mt={"0.9rem"}
                >
                  <PosFormButton
                    buttonText={"Close"}
                    CancelButton={true}
                    onClick={handleClose}
                  />
                </Flex>
              </Stack>
            </Box>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default RouteWithLayout(PublicRewardCardTermsServices);
