import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useMediaQuery,
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  commonDateFormate,
  startOfTodaysDay,
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";

const InventoryTrail = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isSmall] = useMediaQuery("(max-width: 1032px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const actionColumn = isMobile ? { width: 135 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 120 } : { flex: 0.9 };
  const actionColumnFlexDouble = isMobile ? { width: 170 } : { flex: 1.2 };
  const [applyClicked, setApplyClicked] = useState(false);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [categoryes, setCategoryes] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "created_at",
      sort: "desc",
    },
  ]);
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const pageRef = useRef(false);
  const searchQueryRef = useRef(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    barcode: false,
    category_names: false,
    location_name: false,
    // total_tip_refunded: false,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const searchInputHideRef = useRef(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const operationType = [
    {
      label: "Sales",
      value: "sales",
    },
    {
      label: "Receiving",
      value: "receiving",
    },
    {
      label: "Shrink",
      value: "subtract",
    },
    {
      label: "Recount",
      value: "recount",
    },
    {
      label: "Refund",
      value: "refund",
    },
    {
      label: "Manual Adjustment",
      value: "manual_adjustment",
    },
    // {
    //   label: "Transfer",
    //   value: "transfer",
    // },
  ];
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0]);
            // setOlColumnVisibilityModel((prev) => ({
            //   ...prev,
            //   location_name: false,
            // }));
            formik.setFieldValue(
              "selectedLoactions",
              loaderResponse?.data?.data?.location[0].name
            );
          }
          setLocations(loaderResponse?.data?.data?.location);
          setCategoryes(loaderResponse?.data?.data?.category);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (pageRef.current) {
      getGetInventoryTrailData();
    }
  }, [paginationModel, sortModel]);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        if (!searchInputHideRef.current && actionResponse.error.data === 0) {
          searchInputHideRef.current = true;
        }
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Inventory Trail Report Exported successfully generated."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.INVENTORY_TRAIL_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          searchInputHideRef.current = true;
          setTableData(actionResponse.data.data.data);
          setTotalCount(actionResponse.data.data.totalItems);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);

  const formik = useFormik({
    initialValues: {
      // selectedLoactions:
      //   undefined !== loaderResponse?.data?.data?.location &&
      //   null !== loaderResponse?.data?.data?.location &&
      //   loaderResponse?.data?.data?.location?.length === 1
      //     ? loaderResponse?.data?.data?.location
      //     : [],
      selectedLoactions: "",
      selectCategory: [],
      selectedOperation: [],
      fromDate: "",
      toDate: "",
    },
    validationSchema: Yup.object({
      // selectCategory: Yup.array().min(1, Constants.PRODUCT_CATEGORY_REQUIRED),
      // selectedOperation: Yup.array().min(1, Constants.OPERATION_TYPE_REQUIRED),
      // selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED), 
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });

  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const handleOptionSelect = (e) => {
    // formik.setFieldValue("selectedLoactions", e.value);
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
      //   formik.setFieldValue("selectedLoactions", e.value);
    }
  };
  const handleCategorySelect = (e) => {
    formik.setFieldValue("selectCategory", e.value);
  };
  const handleOperationOptionSelect = (e) => {
    formik.setFieldValue("selectedOperation", e.value);
  };
  const renderReportLayout = () => {
    return (
      <>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isSmall ? "column" : "row"}
          spacing={4}
          width={isSmall ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Box width={isSmall ? "100% !important" : "33% !important"}>
            <PosDropDown
              id="selectedLoactions"
              options={locations}
              value={selectedLocation}
              onChange={handleOptionSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedLoactions");
                formik.handleBlur(e);
              }}
              // multiSelect={true}
              optionLabel="name"
              placeholder="Select Location*"
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              height={"2.5rem"}
              className="w-full md:w-20rem"
              // maxSelectedLabels={2}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedLoactions &&
              formik.errors.selectedLoactions ? (
                <span>{formik.errors.selectedLoactions}</span>
              ) : null}
            </Text>
          </Box>
          <Stack
            width={isSmall ? "100% !important" : "67% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                value={formik.values.fromDate}
                label={"From Date*"}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                    ? "17rem"
                    : isMeduim1
                    ? "13.32rem"
                    : "100%"
                }
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
            </Box>

            <Box width={"100% !important"}>
              <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                value={formik.values.toDate}
                label={"To Date*"}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                    ? "17rem"
                    : isMeduim1
                    ? "13.32rem"
                    : "100%"
                }
                minDate={
                  null !== formik.values.fromDate &&
                  "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
        </Stack>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          width={"100% !important"}
        >
          <Box width={isMeduim ? "100% !important" : "auto"}>
            <PosDropDown
              options={categoryes}
              value={formik.values.selectCategory}
              onChange={handleCategorySelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectCategory");
                formik.handleBlur(e);
              }}
              multiSelect={true}
              optionLabel="name"
              placeholder="Select Categories"
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              height={"2.5rem"}
              className="w-full md:w-20rem"
              maxSelectedLabels={2}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectCategory && formik.errors.selectCategory ? (
                <span>{formik.errors.selectCategory}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={isMeduim ? "100% !important" : "auto"}>
            <PosDropDown
              options={operationType}
              value={formik.values.selectedOperation}
              onChange={handleOperationOptionSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedOperation");
                formik.handleBlur(e);
              }}
              multiSelect={true}
              optionLabel="label"
              placeholder="Select Operation"
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              height={"2.5rem"}
              className="w-full md:w-20rem"
              maxSelectedLabels={2}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedOperation &&
              formik.errors.selectedOperation ? (
                <span>{formik.errors.selectedOperation}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    searchInputHideRef.current = false;
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getGetInventoryTrailData = () => {
    if (null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          setButtonDisable(true);
          setLoading(true);
          setShowTable(false);
          setApplyClicked(true);
          let data = {
            getreport: true,
            category: JSON.stringify(formik.values.selectCategory),
            location: JSON.stringify(selectedLocation),
            operation: JSON.stringify(formik.values.selectedOperation),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            query: JSON.stringify({
              limit: paginationModel.pageSize,
              page: paginationModel.page + 1,
              filter: searchQuery,
              order:
                sortModel.length > 0
                  ? sortModel[0].field
                  : sortRef.current[0].field,
              order_type:
                sortModel.length > 0
                  ? sortModel[0].sort
                  : sortRef.current[0].sort,
            }),
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.INVENTORY_TRAIL_REPORT_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };
  const handleExportReport = () => {
    if (null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions) {
      if (null != formik.values.fromDate && "" != formik.values.fromDate) {
        if (null != formik.values.toDate && "" != formik.values.toDate) {
          myContext.handleLoading(true);
          let data = {
            getreport: false,
            type: "xls",
            category: JSON.stringify(formik.values.selectCategory),
            location: JSON.stringify(selectedLocation),
            operation: JSON.stringify(formik.values.selectedOperation),
            dateFrom: reportDateFormate(formik.values.fromDate),
            dateTo: reportDateFormate(formik.values.toDate),
            zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            query: JSON.stringify({
              limit: paginationModel.pageSize,
              page: paginationModel.page + 1,
              filter: searchQuery,
              order:
                sortModel.length > 0
                  ? sortModel[0].field
                  : sortRef.current[0].field,
              order_type:
                sortModel.length > 0
                  ? sortModel[0].sort
                  : sortRef.current[0].sort,
            }),
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.INVENTORY_TRAIL_REPORT_ROUTE,
          });
        } else {
          clearTableData(Constants.TO_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.FORM_DATE_IS_REQUIRED);
      }
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };
  const columnNames = [
    {
      field: "product_name",
      headerName: "Product Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "barcode",
      headerName: "Barcode",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "category_names",
      headerName: "Category",
      sortable: false,
      ...actionColumnXS,
    },
    {
      field: "type",
      headerName: "Type",
      sortable: false,
      ...actionColumnXS,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      sortable: false,
      ...actionColumnXS,
    },
    {
      field: "user_name",
      headerName: "User",
      sortable: false,
      ...actionColumnXS,
    },
    {
      field: "created_at",
      headerName: "Date",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "location_name",
      headerName: "Location",
      sortable: true,
      ...actionColumnXS,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      ...actionColumnFlexDouble,
    },
  ];

  const modifiedData = tableData.map((data, i) => ({
    id: i + 1,
    created_at: commonDateFormate(data.created_at),
    barcode: data.barcode,
    category_names: data.category_names,
    location_id: data.location_id,
    location_name: data.location_name,
    narration: data.narration,
    quantity: data.quantity,
    description:
      data.narration == "ORD"
        ? "Sales made through Octopos."
        : data.narration == "PRO_CRE"
        ? "Product created through the back office."
        : data.narration == "PRO_IMPO_CRE"
        ? "Product imported through the back office."
        : data.narration == "PRO_API_CRE"
        ? "Product created through the mobile app."
        : data.narration == "PRO_API_ADD"
        ? "Inventory received through the mobile app."
        : data.narration == "PRO_API_V2_ADD"
        ? "Inventory received through API."
        : data.narration == "PRO_API_V2_CRE"
        ? "Product created through API."
        : data.narration == "PRO_API_SUB"
        ? "Inventory shrank through the mobile app."
        : data.narration == "PRO_API_V2_SUB"
        ? "Inventory shrank through API."
        : data.narration == "PRO_API_RECO"
        ? "Inventory recounted through the mobile app."
        : data.narration == "PRO_API_V2_RECO"
        ? "Inventory recounted through API."
        : data.narration == "RFN_RTN"
        ? "Item refunded through Octopos."
        : data.narration == "PRO_RECO"
        ? "Manual adjustment made through the back office."
        : data.narration == "PRO_IMPO_RECO"
        ? "Manual adjustment made through the back office import."
        : data.narration == "PRO_API_V2_EDIT"
        ? "Manual adjustment made through API."
        : "N.A.",
    product_name: data.product_name,
    user_name: data.user_name,
    type:
      data.narration == "ORD"
        ? "Sales"
        : data.narration == "PRO_CRE" ||
          data.narration == "PRO_IMPO_CRE" ||
          data.narration == "PRO_API_CRE" ||
          data.narration == "PRO_API_ADD" ||
          data.narration == "PRO_API_V2_ADD" ||
          data.narration == "PRO_API_V2_CRE"
        ? "Receiving"
        : data.narration == "PRO_API_SUB" || data.narration == "PRO_API_V2_SUB"
        ? "Shrink"
        : data.narration == "PRO_API_RECO" ||
          data.narration == "PRO_API_V2_RECO"
        ? "Recount"
        : data.narration == "RFN_RTN"
        ? "Refund"
        : data.narration == "PRO_RECO" ||
          data.narration == "PRO_IMPO_RECO" ||
          data.narration == "PRO_API_V2_EDIT"
        ? "Manual Adjustment"
        : "N.A.",
  }));
  const handleResetAllData = () => {
    pageRef.current = false;
    searchQueryRef.current = false;
    searchInputHideRef.current = false;
    setSortModel([
      {
        field: "created_at",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setSelectedLocation([]);
    setSearchQuery("");
    setTableData([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    setApplyClicked(false);
    formik.resetForm();
  };
  const handleSearchList = () => {
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    searchQueryRef.current = true;
  };
  const handleClear = () => {
    searchQueryRef.current = true;
    setSearchQuery("");
  };
  const apply = () => {
    searchQueryRef.current = true;
  };
  useEffect(() => {
    if (searchQueryRef.current) {
      getGetInventoryTrailData();
    }
  }, [searchQuery]);
  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    searchQueryRef.current = true;
    setSearchQuery(value);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      apply();
    }, 500);
    setTimeoutId(newTimeoutId);
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Inventory Trail"]}
          breadCrumTitle={"Inventory Trail"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack
            gap={4}
            flex={1}
            flexShrink={"0"}
            direction={"column"}
            m={4}
            alignItems={"flex-start"}
          >
            {renderReportLayout()}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isLarge ? 4 : 4}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              isDisabled={applyClicked ? false : true}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLoactions?.length === 0 ||
                formik.values.fromDate === "" ||
                formik.values.toDate === "" ||
                formik.errors.selectedLoactions ||
                formik.errors.fromDate ||
                formik.errors.toDate ||
                buttonDisable ||
                myContext.loading
              }
              onClick={getGetInventoryTrailData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {searchInputHideRef.current ? (
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems={{ base: "stretch", md: "center" }}
              gap={{ base: 2, md: 0 }}
              mb={3}
            >
              <PosSearch
                value={searchQuery}
                // onChange={(e) => {
                //   let value =
                //     searchQuery.length > 0
                //       ? e.target.value
                //       : e.target.value.trim();
                //   setSearchQuery(value);
                // }}
                onChange={onChange}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
              <Spacer />
            </Flex>
          ) : null}
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.TOTAL_SALE_BY_DAY_CSV}
              />
            </Flex>
          ) : (
            <PosNoDataFound title={"There are no Inventory Trail."} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(InventoryTrail);
