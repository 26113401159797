import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLoaderData, useLocation } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

const PosSettingsView = (props) => {
  const myContext = useContext(AppContext);
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  const [allValue, setSettings] = useState([]);
  const [tipsArray, setTipsData] = useState(true);
  const stateData = useLocation(); // get param data

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          myContext.handleLoading(false);
          const tipsArray = [];
          Object.keys(loaderResponse.data.data?.settings).map((key) => {
            loaderResponse.data.data?.settings[key].map((f) => {
              f.setting_key = f.key;
              if (f.key == "tip_perc_1" && ("" == f.value || null == f.value)) {
                f.value = "15";
              }
              if (f.key == "tip_perc_2" && ("" == f.value || null == f.value)) {
                f.value = "20";
              }
              if (f.key == "tip_perc_3" && ("" == f.value || null == f.value)) {
                f.value = "25";
              }
              if (
                f.key == "tip_perc_1" ||
                f.key == "tip_perc_2" ||
                f.key == "tip_perc_3"
              ) {
                tipsArray.push({
                  tip: f.value,
                });
              }
            });
          });
          setTipsData(tipsArray);
          const arraySettings = Object.keys(
            loaderResponse.data.data?.settings
          ).map((key) => ({
            category: key,
            category_data: loaderResponse.data.data?.settings[key],
          }));
          setSettings(arraySettings);
        }
      }
      // return () => {
      effectRun.current = false;
      // };
    }
  }, [loaderResponse]);
  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1">
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          // /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "3.5rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              props.navigate(Constants.POS_LIST, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["POS Stations", "View Settings"]}
            breadCrumTitle={"View Settings"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                props.navigate(Constants.POS_LIST, {
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
          p="8"
        >
          <Text
            as="u"
            fontSize="1.5rem"
            color={Colors.taxShow}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Basic Details
          </Text>
          <Stack direction={["row"]} spacing={3} mt={2}>
            <Text
              fontSize={"1.1rem"}
              fontStyle={"normal"}
              fontWeight={"500"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              mt={2}
              color={Colors.posTextInfo}
            >
              POS Name:
            </Text>
            <Text
              fontSize={"1.1rem"}
              fontStyle={"normal"}
              fontWeight={"300"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              mt={2}
              color={Colors.posTextInfo}
            >
              {undefined != loaderResponse?.data?.data?.posStation 
              && ""!=loaderResponse?.data?.data?.posStation
              && null != loaderResponse?.data?.data?.posStation ? loaderResponse?.data?.data?.posStation : "N.A."}
            </Text>
          </Stack>
          <Stack direction={["row"]} spacing={3} mt="0.31rem">
            <Text
              fontSize={"1.1rem"}
              fontStyle={"normal"}
              fontWeight={"500"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              mt={2}
              color={Colors.posTextInfo}
            >
              Location:
            </Text>
            <Text
              fontSize={"1.1rem"}
              fontStyle={"normal"}
              fontWeight={"300"}
              lineHeight={"normal"}
              letterSpacing={"-0.01rem"}
              mt={2}
              color={Colors.posTextInfo}
            >
              {undefined != loaderResponse?.data?.data?.location
              && null != loaderResponse?.data?.data?.location
              && '' != loaderResponse?.data?.data?.location ?  loaderResponse?.data?.data?.location : "N.A."}
            </Text>
          </Stack>
        </Box>

        {allValue.map((data, index1) => (
          <Box
            key={index1}
            mt={{ base: 4, lg: 12 }}
            ml={{ base: 4, lg: 14 }}
            mr={{ base: 4, lg: 14 }}
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
            p="8"
          >
            <Text
              as="u"
              fontSize="1.5rem"
              color={Colors.taxShow}
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.27px",
              }}
            >
              {data.category}
            </Text>
            {data.category_data.map((fl, index) => (
              <Stack direction={["row"]} key={fl.id} spacing={3} mt="0.31rem">
                <Text
                  fontSize={"1.1rem"}
                  fontStyle={"normal"}
                  fontWeight={"500"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.01rem"}
                  mt={2}
                  color={Colors.posTextInfo}
                >
                  {fl.setting_display_name}
                </Text>
                <Text
                  fontSize={"1.1rem"}
                  fontStyle={"normal"}
                  fontWeight={"300"}
                  lineHeight={"normal"}
                  letterSpacing={"-0.01rem"}
                  mt={2}
                  color={Colors.posTextInfo}
                >
                  {fl.value !== null && fl.value !== "" ? fl.value : "N.A."}
                </Text>
              </Stack>
            ))}
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

export default WithRouter(PosSettingsView);
