import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr, useMediaQuery
} from "@chakra-ui/react";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDatePicker,
  PosDropDown,
  PosFormButton, PosNoDataFound,
  PosProgress,
  PosTostMessage
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
const TotalSalesByPaymentType = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [totalPaymentsData, setTotalPaymentsData] = useState("");
  const [totalPaymentsMethodsData, setTotalPaymentsMethodsData] = useState("");
  const [totalPaymentsMethods, setTotalPaymentsMethods] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [locations, setLocations] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [buttonDisable, setButtonDisable] = useState(false);

  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const payment_type = [
    {
      label: "CASH",
      value: "CASH",
    },
    {
      label: "CARD",
      value: "CARD",
    },
    {
      label: "OTHER",
      value: "OTHER",
    },
    {
      label: "EBT",
      value: "EBT",
    },
  ];
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isSmall] = useMediaQuery("(max-width: 1032px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const formik = useFormik({
    initialValues: {
      selectedEmployees: [],
      selectedPaymentType: [],
      selectedLoactions: "",
      fromDate: "",
      toDate: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      // selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if(undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1){
              setSelectedLocation(loaderResponse?.data?.data?.location[0])
              formik.setFieldValue("selectedLoactions", loaderResponse?.data?.data?.location[0].name);
            }
          setLocations(loaderResponse?.data?.data?.location);
          let employees = loaderResponse?.data?.data?.employee;
          let mtEmployees = employees.map((employee) => ({
            id: employee.id,
            name: employee.name,
          }));
          setEmployees(mtEmployees);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    getGetTotalSalesByPaymentData();
  }, []);

  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data.data.employeeData !== null &&
          actionResponse.data.data.employeeData !== "" &&
          actionResponse.data.data.employeeData !== undefined
        ) {
          setEmployeeData(actionResponse.data.data.employeeData);
        }
        setTotalPaymentsData(actionResponse.data.data.total_payment);
        setPaymentTypes(actionResponse.data.data.paymentType);
        const total_payment = actionResponse.data.data.payment_methods;
        if (total_payment.length > 0) {
          setTotalPaymentsMethods(total_payment);
          var total_payments_method =
            Number(actionResponse.data.data.payment_methods[0].CARD) +
            Number(actionResponse.data.data.payment_methods[0].CASH) +
            Number(actionResponse.data.data.payment_methods[0].EBT) +
            Number(actionResponse.data.data.payment_methods[0].OTHER);
          setTotalPaymentsMethodsData(total_payments_method.toFixed(2));
        }
        setLoading(false);
        setButtonDisable(false);
        // }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  //   const handleExportReport = () => {
  //     if (
  //       //   selectedLocation.length > 0 &&
  //       null != formik.values.selectedLoactions &&
  //       "" != formik.values.selectedLoactions &&
  //       null != formik.values.fromDate &&
  //       "" != formik.values.fromDate &&
  //       null != formik.values.toDate &&
  //       "" != formik.values.toDate
  //     ) {
  //       let data = {
  //         getreport: false,
  //         location: JSON.stringify(selectedLocation),
  //         dateFrom: reportDateFormate(formik.values.fromDate),
  //         dateTo: reportDateFormate(formik.values.toDate),
  //         zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  //         type: "xls",
  //       };
  //       submit(data, {
  //         method: Constants.POST,
  //         path: Constants.TOTAL_DAILY_SALE,
  //       });
  //     }
  //   };

  const getGetTotalSalesByPaymentData = () => {
    if (
      //   selectedLocation.length > 0 &&
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions &&
      null != formik.values.fromDate &&
      "" != formik.values.fromDate &&
      null != formik.values.toDate &&
      "" != formik.values.toDate
    ) {
      setButtonDisable(true);
      setLoading(true);
      setEmployeeData([]);
      setShowTable(false);
      let locationData = {
        label: selectedLocation.name,
        value: { id: selectedLocation.id },
      };
      let data = {
        location: JSON.stringify(locationData),
        employee: JSON.stringify(formik.values.selectedEmployees),
        paymentTypes: JSON.stringify(formik.values.selectedPaymentType),
        dateFrom: reportDateFormate(formik.values.fromDate),
        dateTo: reportDateFormate(formik.values.toDate),
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.TOTAL_SALES_BY_PAYMENT_TYPE_REPORT_ROUTE,
      });
    }
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
    setSelectedLocation(e.value);
    formik.setFieldValue("selectedLoactions", e.value.name);
    }
    //   formik.setFieldValue("selectedLoactions", e.value);
  };
  const handleEmpOptionSelect = (e) => {
    formik.setFieldValue("selectedEmployees", e.value);
  };
  const handlePaymentOptionSelect = (e) => {
    formik.setFieldValue("selectedPaymentType", e.value);
  };
  const handleResetAllData = () => {
    setSelectedLocation([]);
    setShowTable(true);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const renderReportLayout = () => {
    return (
      <>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isSmall ? "column" : "row"}
          spacing={4}
          width={isSmall ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Box width={isSmall ? "100% !important" : "33% !important"}>
            <PosDropDown
              id="selectedLoactions"
              options={locations}
              value={selectedLocation}
              onChange={handleOptionSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedLoactions");
                formik.handleBlur(e);
              }}
              // multiSelect={true}
              optionLabel="name"
              placeholder="Select Locations*"
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              height={"2.5rem"}
              className="w-full md:w-20rem"
              // maxSelectedLabels={2}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedLoactions &&
              formik.errors.selectedLoactions ? (
                <span>{formik.errors.selectedLoactions}</span>
              ) : null}
            </Text>
          </Box>
          <Stack
            width={isSmall ? "100% !important" : "67% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                value={formik.values.fromDate}
                label={"From Date*"}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                    ? "17rem"
                    : isMeduim1
                    ? "13.32rem"
                    : "100%"
                }
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                value={formik.values.toDate}
                label={"To Date*"}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                    ? "17rem"
                    : isMeduim1
                    ? "13.32rem"
                    : "100%"
                }
                minDate={
                  null !== formik.values.fromDate &&
                  "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
        </Stack>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          width={"100% !important"}
        >
          <Box width={isMeduim ? "100% !important" : "auto"}>
            <PosDropDown
              options={employees}
              value={formik.values.selectedEmployees}
              onChange={handleEmpOptionSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedEmployees");
                formik.handleBlur(e);
              }}
              multiSelect={true}
              optionLabel="name"
              placeholder="Select Employees"
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              height={"2.5rem"}
              maxSelectedLabels={2}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedEmployees &&
              formik.errors.selectedEmployees ? (
                <span>{formik.errors.selectedEmployees}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={isMeduim ? "100% !important" : "auto"}>
            <PosDropDown
              options={payment_type}
              value={formik.values.selectedPaymentType}
              onChange={handlePaymentOptionSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedPaymentType");
                formik.handleBlur(e);
              }}
              multiSelect={true}
              optionLabel="label"
              placeholder="Select Payment Types"
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                  ? "17rem"
                  : isMeduim1
                  ? "13.32rem"
                  : "100%"
              }
              height={"2.5rem"}
              maxSelectedLabels={2}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedPaymentType &&
              formik.errors.selectedPaymentType ? (
                <span>{formik.errors.selectedPaymentType}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Total Daily Sales by Payment Type"]}
          breadCrumTitle={"Total Daily Sales By Payment Type"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack
            gap={4}
            flex={1}
            flexShrink={"0"}
            direction={"column"}
            m={4}
            alignItems={"flex-start"}
          >
            {renderReportLayout()}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isLarge ? 4 : 4}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLoactions?.length === 0 ||
                formik.values.fromDate === "" ||
                formik.values.toDate === "" ||
                formik.errors.selectedLoactions ||
                formik.errors.fromDate ||
                formik.errors.toDate ||
                buttonDisable
              }
              onClick={getGetTotalSalesByPaymentData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalPaymentsData == "" ||
            totalPaymentsData == null ||
            totalPaymentsData == undefined ? (
            <>
              {employeeData.length > 0 &&
                employeeData.map((data, i) => (
                  <Box
                    key={i}
                    mt={8}
                    p={2.5}
                    borderRadius={"0.63rem"}
                    border={"1px solid rgba(183, 183, 183, 0.12)"}
                    bg={Colors.posviewbgcolor}
                  >
                    {/* <PosListHeader
              key={`header-${i}`}
              header={`Employee Name: ${data.name}`}
            /> */}
                    <Text
                      mb={2}
                      ml={2}
                      key={`header-${i}`}
                      color={"#615E83"}
                      fontSize={{
                        base: "1.5rem",
                        sm: "1.5rem",
                        md: "1.5rem",
                        lg: "1.3rem",
                        xl: "1.5rem",
                      }}
                      fontWeight={"semibold"}
                    >
                      {`Employee Name: ${data.name}`}
                    </Text>
                    <Divider key={`divider-${i}`} />
                    <TableContainer key={`tablecontainer-${i}`}>
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            {paymentTypes.includes("CARD") ||
                            paymentTypes.length === 0 ? (
                              <Th
                                key={`card-header-${i}`}
                                textAlign={"center"}
                                color={"#010048"}
                                fontSize={{
                                  base: "1rem",
                                  sm: "1.5rem",
                                  md: "1.5rem",
                                  lg: "1.3rem",
                                  xl: "1.5rem",
                                }}
                                fontWeight={"semibold"}
                              >
                                CARD
                              </Th>
                            ) : null}
                            {paymentTypes.includes("EBT") ||
                            paymentTypes.length === 0 ? (
                              <Th
                                key={`card-header-${i}`}
                                textAlign={"center"}
                                color={"#010048"}
                                fontSize={{
                                  base: "1rem",
                                  sm: "1.5rem",
                                  md: "1.5rem",
                                  lg: "1.3rem",
                                  xl: "1.5rem",
                                }}
                                fontWeight={"semibold"}
                              >
                                EBT
                              </Th>
                            ) : null}
                            {paymentTypes.includes("CASH") ||
                            paymentTypes.length === 0 ? (
                              <Th
                                key={`cash-header-${i}`}
                                textAlign={"center"}
                                color={"#010048"}
                                fontSize={{
                                  base: "1rem",
                                  sm: "1.5rem",
                                  md: "1.5rem",
                                  lg: "1.3rem",
                                  xl: "1.5rem",
                                }}
                                fontWeight={"semibold"}
                              >
                                CASH
                              </Th>
                            ) : null}
                            {paymentTypes.includes("OTHER") ||
                            paymentTypes.length === 0 ? (
                              <Th
                                key={`other-header-${i}`}
                                textAlign={"center"}
                                color={"#010048"}
                                fontSize={{
                                  base: "1rem",
                                  sm: "1.5rem",
                                  md: "1.5rem",
                                  lg: "1.3rem",
                                  xl: "1.5rem",
                                }}
                                fontWeight={"semibold"}
                              >
                                OTHER
                              </Th>
                            ) : null}
                            {paymentTypes.length !== 1 ? (
                              <Th
                                key={`total-header-${i}`}
                                textAlign={"center"}
                                color={"#010048"}
                                fontSize={{
                                  base: "1rem",
                                  sm: "1.5rem",
                                  md: "1.5rem",
                                  lg: "1.3rem",
                                  xl: "1.5rem",
                                }}
                                fontWeight={"semibold"}
                              >
                                TOTAL
                              </Th>
                            ) : null}
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr key={`row-${i}`}>
                            {paymentTypes.includes("CARD") ||
                            paymentTypes.length === 0 ? (
                              <Td
                                key={`card-data-${i}`}
                                textAlign={"center"}
                                color={"rgba(1, 0, 72, 0.85)"}
                                fontSize={"0.93rem"}
                                fontWeight={"medium"}
                              >
                                $
                                {parseFloat(data.CARD)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </Td>
                            ) : null}
                            {paymentTypes.includes("EBT") ||
                            paymentTypes.length === 0 ? (
                              <Td
                                key={`card-data-${i}`}
                                textAlign={"center"}
                                color={"rgba(1, 0, 72, 0.85)"}
                                fontSize={"0.93rem"}
                                fontWeight={"medium"}
                              >
                                $
                                {parseFloat(data.EBT)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </Td>
                            ) : null}
                            {paymentTypes.includes("CASH") ||
                            paymentTypes.length === 0 ? (
                              <Td
                                key={`cash-data-${i}`}
                                textAlign={"center"}
                                color={"rgba(1, 0, 72, 0.85)"}
                                fontSize={"0.93rem"}
                                fontWeight={"medium"}
                              >
                                $
                                {parseFloat(data.CASH)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </Td>
                            ) : null}
                            {paymentTypes.includes("OTHER") ||
                            paymentTypes.length === 0 ? (
                              <Td
                                key={`other-data-${i}`}
                                textAlign={"center"}
                                color={"rgba(1, 0, 72, 0.85)"}
                                fontSize={"0.93rem"}
                                fontWeight={"medium"}
                              >
                                $
                                {parseFloat(data.OTHER)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </Td>
                            ) : null}
                            {paymentTypes.length !== 1 ? (
                              <Td
                                key={`total-data-${i}`}
                                color={"rgba(1, 0, 72, 0.85)"}
                                fontSize={"0.93rem"}
                                fontWeight={"medium"}
                                textAlign={"center"}
                              >
                                $
                                {parseFloat(
                                  Number(data.CARD) +
                                    Number(data.CASH) +
                                    Number(data.OTHER)
                                )
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </Td>
                            ) : null}
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                ))}
              <Box
                mt={8}
                p={2.5}
                borderRadius={"0.63rem"}
                border={"1px solid rgba(183, 183, 183, 0.12)"}
                bg={Colors.posviewbgcolor}
              >
                {/* <PosListHeader header={"Total Sales by Payment Type"} /> */}
                <Text
                  mb={2}
                  ml={2}
                  color={"#615E83"}
                  fontSize={{
                    base: "1.5rem",
                    sm: "1.5rem",
                    md: "1.5rem",
                    lg: "1.3rem",
                    xl: "1.5rem",
                  }}
                  fontWeight={"semibold"}
                >
                  Summary
                </Text>
                <Divider />
                <TableContainer>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        {paymentTypes.includes("CARD") ||
                        paymentTypes.length === 0 ? (
                          <Th
                            textAlign={"center"}
                            color={"#010048"}
                            fontSize={{
                              base: "1rem",
                              sm: "1.5rem",
                              md: "1.5rem",
                              lg: "1.3rem",
                              xl: "1.5rem",
                            }}
                            fontWeight={"semibold"}
                          >
                            CARD({totalPaymentsMethods[0].CARDPERACTUAL}%)
                          </Th>
                        ) : null}
                        {paymentTypes.includes("EBT") ||
                        paymentTypes.length === 0 ? (
                          <Th
                            textAlign={"center"}
                            color={"#010048"}
                            fontSize={{
                              base: "1rem",
                              sm: "1.5rem",
                              md: "1.5rem",
                              lg: "1.3rem",
                              xl: "1.5rem",
                            }}
                            fontWeight={"semibold"}
                          >
                            EBT({totalPaymentsMethods[0].EBTPERACTUAL}%)
                          </Th>
                        ) : null}
                        {paymentTypes.includes("CASH") ||
                        paymentTypes.length === 0 ? (
                          <Th
                            textAlign={"center"}
                            color={"#010048"}
                            fontSize={{
                              base: "1rem",
                              sm: "1.5rem",
                              md: "1.5rem",
                              lg: "1.3rem",
                              xl: "1.5rem",
                            }}
                            fontWeight={"semibold"}
                          >
                            CASH({totalPaymentsMethods[0].CASHPERACTUAL}%)
                          </Th>
                        ) : null}
                        {paymentTypes.includes("OTHER") ||
                        paymentTypes.length === 0 ? (
                          <Th
                            textAlign={"center"}
                            color={"#010048"}
                            fontSize={{
                              base: "1rem",
                              sm: "1.5rem",
                              md: "1.5rem",
                              lg: "1.3rem",
                              xl: "1.5rem",
                            }}
                            fontWeight={"semibold"}
                          >
                            OTHER({totalPaymentsMethods[0].OTHERPERACTUAL}%)
                          </Th>
                        ) : null}
                        {paymentTypes.length !== 1 ? (
                          <Th
                            textAlign={"center"}
                            color={"#010048"}
                            fontSize={{
                              base: "1rem",
                              sm: "1.5rem",
                              md: "1.5rem",
                              lg: "1.3rem",
                              xl: "1.5rem",
                            }}
                            fontWeight={"semibold"}
                          >
                            TOTAL
                          </Th>
                        ) : null}
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        {paymentTypes.includes("CARD") ||
                        paymentTypes.length === 0 ? (
                          <Td
                            textAlign={"center"}
                            color={"rgba(1, 0, 72, 0.85)"}
                            fontSize={"0.93rem"}
                            fontWeight={"medium"}
                          >
                            $
                            {parseFloat(totalPaymentsMethods[0].CARD)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </Td>
                        ) : null}
                        {paymentTypes.includes("EBT") ||
                        paymentTypes.length === 0 ? (
                          <Td
                            textAlign={"center"}
                            color={"rgba(1, 0, 72, 0.85)"}
                            fontSize={"0.93rem"}
                            fontWeight={"medium"}
                          >
                            $
                            {parseFloat(totalPaymentsMethods[0].EBT)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </Td>
                        ) : null}
                        {paymentTypes.includes("CASH") ||
                        paymentTypes.length === 0 ? (
                          <Td
                            textAlign={"center"}
                            color={"rgba(1, 0, 72, 0.85)"}
                            fontSize={"0.93rem"}
                            fontWeight={"medium"}
                          >
                            $
                            {parseFloat(totalPaymentsMethods[0].CASH)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </Td>
                        ) : null}
                        {paymentTypes.includes("OTHER") ||
                        paymentTypes.length === 0 ? (
                          <Td
                            textAlign={"center"}
                            color={"rgba(1, 0, 72, 0.85)"}
                            fontSize={"0.93rem"}
                            fontWeight={"medium"}
                          >
                            $
                            {parseFloat(totalPaymentsMethods[0].OTHER)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </Td>
                        ) : null}
                        {paymentTypes.length !== 1 ? (
                          <Td
                            textAlign={"center"}
                            color={"rgba(1, 0, 72, 0.85)"}
                            fontSize={"0.93rem"}
                            fontWeight={"medium"}
                          >
                            $
                            {parseFloat(totalPaymentsMethodsData)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </Td>
                        ) : null}
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </>
          ) : (
            <PosNoDataFound
              title={"There are no Total Sales By Payment Type."}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(TotalSalesByPaymentType);
