export const DB_NAME = "octopos.db";

export const FAILED_TO_LOGIN = "Sorry, failed to login. Please try again.";

export const USER_EMAIL_REQUIRED = "Email is required.";

export const USER_EMAIL_NOT_VALID = "Your email must be valid.";

export const USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER =
  "Your email not be greater than 191 characters long.";

export const PASSWORD_FIELD_REQUIRED = "Password is required.";

export const CONFIRM_PASSWORD_FIELD_REQUIRED = "Confirm Password is required.";

export const SAME_PASSWORD =
  "The password confirmation does not match the password.";

export const PASSWORD_MUST_BE_8_CHARACHTER =
  "Password must be 8 characters long.";

export const PASSWORD = "password";

export const YOU_ENTERED_THE_WRONG_PASSWORD = "You entered the wrong password.";

export const VALID_PASSWORD = "Please Enter Valid password.";

export const SAME_NEW_PASSWORD =
  "The password confirmation does not match the newpassword.";

// COMMON

export const CONTANT_TYPE = "application/json";

export const SANE_POS = 'Octopos';

export const SANE_POS_URL = "https://www.octopos.com/";

export const VORI = 'Vori';

export const SORTING_AND_PAGINATION_MODE = "server";

export const VORI_FOOTER = 'Vori, Inc';

export const VORI_URL = "https://www.vori.com/";

export const SANE_POS_MASTER = 'Octopos Master Admin';

export const SANE_POS_DOMAIN = 'octoretail';

export const VORI_MASTER = 'Vori Master Admin';

export const UNAUTHORIZE_PAGE = "/401";

export const FORBIDDEN_ERROR_PAGE = "/403";

export const LOGOUT_PAGE = "/logout";

export const TOAST_POSITION = "top-right";

export const TOAST_TYPE_SUCESS = "success";

export const TOAST_TYPE_WARNING = "warning";

export const TOAST_TYPE_ERROR = "error";

export const FIELD_IS_REQUIRED = "Field is reqired.";

// export const TOKEN_EXPIRTED = "Token Expired";
export const TOKEN_EXPIRTED = "Session Timed Out";

export const IMAGE_TYPE_PNG = "image/png";

export const IMAGE_TYPE_JPG = "image/jpeg";

export const IMAGE_TYPE_WEBP = "image/webp";

export const UPLOAD_IMAGE_SIZE = "2000000";

export const SUBMIT_TIME_FORM_NOT_VALID = "Make sure all required fields are filled in.";

export const SUBMIT_TIME_FORM_NOT_VALID_TITLE = "Field Required";

export const IMAGE_TYPE_VALIDATION =
  "Allows only .png or .jpg or .jpeg or .webp image.";

export const IMAGE_SIZE_VALIDATION = "Allows only upto 2MB image size.";

export const API_ID = ":id";

export const TOAST_HEADING_SUCCESS = "Success";

export const TOAST_HEADING_WARNING = "Warning";

export const TOAST_HEADING_ERROR = "Error";

export const REACT_APP_API_URL =
  process.env.REACT_APP_DOMAIN == "devlopment"
    ? window.location.protocol + "//" + window.location.hostname + ":8000/"
    : window.location.origin + "/";

//     export const REACT_APP_API_URL =
// //   process.env.REACT_APP_DOMAIN == "devlopment"
// //     ? // ? window.location.protocol + "//" + window.location.hostname + ":8000/"
//       window.location.protocol + "//" + "postesting.octoretail.com/"
//     // : window.location.origin + "/";

export const DEACTIVE = "deactive";

export const REACTIVE = "reactive";

export const DEACTIVE_SUCCESS = "deactivated successfully.";

export const REACTIVE_SUCCESS = "deactivated successfully.";

export const PRINT_BUTTON = "Print";

export const VIEW_BUTTON = "View";

export const EXPORT_BUTTON = "Export";

export const EXPORT_PDF_BUTTON = "Export PDF";

export const GLOBLE_ROUTE_CHECK_PERMISSION = "global-route-check-permission";

export const NETWORK_ERROR = "ERR_NETWORK";

// export const SERVER_IS_NOT_REACHABLE = "SERVER IS NOT REACHABLE";
export const SERVER_IS_NOT_REACHABLE = "Unable to complete the operation.";

export const TOAST_HEADING_SYSTEM_ERROR = "System Error";

// Api Code

export const UNAUTHORIZE_CODE = 401;

export const FORBIDDEN_ERROR = 403;

export const REQUEST_DENIED_CODE = 999;

export const CODE = "code";

export const FLAGE = "flag";

export const POST = "post";

export const PUT = "put";

export const GET = "get";

export const DELETE = "delete";

//Routing
export const SIGNIN_PATH = "/";

export const PUBLIC_GIFT_CARD_PAGE_PATH = "/giftCardSms";

export const LOGIN_PATH =  "/login";

export const PUBLIC_REWARD_CARD_MOBILE_SMS_PRIVACY_POLICY_PATH =  "/#/privacy-policy";
export const PUBLIC_REWARD_CARD_MOBILE_SMS_PRIVACY_POLICY =  "/privacy-policy";

export const PUBLIC_REWARD_CARD_TERMS_SERVICES_PATH =  "/#/terms-services";
export const PUBLIC_REWARD_CARD_TERMS_SERVICES =  "/terms-services";

export const PUBLIC_REWARD_CARD_CREATE_PAGE_PATH = "/create-reward-card";

export const PUBLIC_REWARD_CARD_UPDATE_PAGE_PATH = "/update-reward-card";

export const PUBLIC_GET_REWARD_CARD_CREATE_PAGE_DATA_API = 'get-reward-card-form-data-create';

export const PUBLIC_REWARD_CARD_CREATE_PAGE_PATH_CREATE_API = 'create-reward-card'

export const PUBLIC_GET_REWARD_CARD_UPDATE_PAGE_DATA_API = 'get-reward-card-form-data';

export const PUBLIC_REWARD_CARD_UPDATE_PAGE_PATH_UPDATE_API = 'reward-cards'

export const PUBLIC_SMS_RECEIPT = "/sms";

export const PUBLIC_SMS_RECEIPT_DATA_API = "get-sms-data";

export const PUBLIC_DOWNLOAD_DAILY_LOW_INVENTORY_PRODUCTS_ROUTE = '/dailyLowInventoryProductsReport';

export const PUBLIC_DOWNLOAD_DAILY_LOW_INVENTORY_PRODUCTS_EXPORT = 'daily_low_inventory_report.xlsx';

export const PUBLIC_DOWNLOAD_DAILY_LOW_INVENTORY_PRODUCTS_API = 'get-daily-low-inventory-products';

export const PUBLIC_DOWNLOAD_LOW_INVENTORY_PRODUCTS_ROUTE = '/lowInventoryProductsReport';

export const PUBLIC_DOWNLOAD_LOW_INVENTORY_PRODUCTS_EXPORT = 'low_inventory_report.xlsx';

export const PUBLIC_DOWNLOAD_LOW_INVENTORY_PRODUCTS_API = 'get-low-inventory-products';

export const CAMPAIGN_UNSUBSCRIBE_PUBLIC_PAGE =  "/campaignEmailUnsubscribe";

export const CHECK_CAMPAIGN_UNSUB_KEY_API = 'check-url-key-for-unsubscribe';

export const PUBLIC_CAMPAIGN_UNSUB_API = "unsubscribe";

export const FORGOT_PASSWORD_PATH = "/forgot-password";

export const RESET_PASSWORD_PATH = "/reset";

export const RESET_PASSWORD_API_ROUTE = "reset-password";

export const DASHBOARD_TITLE = "DASHBOARD";

export const DASHBOARD_PATH = "/dashboard";

export const WELCOME_PATH = "/welcome";

export const HR_TITLE = "HR";

export const HR_ROLE_TITLE = "Roles";

export const HR_ROLE_PATH = "/roles/list";

export const HR_EMPLOYEE_TITLE = "Employees";

export const HR_EMPLOYEE_PATH = "/employees/list";

export const ESTABLISHMENT_TITLE = "ESTABLISHMENT";

export const ESTABLISHMENT_LOCATION_TITLE = "Locations";

// export const ESTABLISHMENT_LOCATION_PATH = "/locations/view-all";
export const ESTABLISHMENT_LOCATION_PATH = "/locations/list";

export const ESTABLISHMENT_POS_STATION_TITLE = "Pos Stations";

export const ESTABLISHMENT_POS_STATION_PATH = "/pos-stations/list";

export const PRODUCT_TITLE = "PRODUCTS";

export const PRODUCT_LIST_TITLE = "Products";

// export const PRODUCT_LIST_PATH = "/products/view-all";
export const PRODUCT_LIST_PATH = "/products/list";

export const PRODUCT_IMPORT_EXPORT_PATH = "/products/import-export";

export const PRODUCT_EDIT = "/product/edit/";

export const PRODUCT_CATEGORY_TITLE = "Categories";

export const CATEGORY_LIST = "/category/list";

export const CATEGORY_CREATE = "/category/create";

export const CATEGORY_EDIT = "/category/edit/";

export const PRODUCT_CATEGORY_PATH = "/categories/view-all";

export const PRODUCT_MODIFIER_TITLE = "Modifiers";

export const PRODUCT_MODIFIER_PATH = "/modifier-set/list";

export const PRODUCT_TAX_TITLE = "Taxes";

export const TAX_LIST = "/tax/list";

export const PRODUCT_PROMOTION_TITLE = "Promotions";

export const PRODUCT_TARE_TITLE = "Tare Containers";

export const PRODUCT_TARE_PATH = "/tare-containers/list";

export const REPORT_TITLE = "REPORTS";

export const REPORT_PATH = "/reports";

// export const REPORT_TITLE = "REPORTS"

export const ORDER_LIST_PATH = "/orders/view-all";

export const ORDER_VIEW_PATH = "/order/view/";

export const PURCHASING_TITLE = "PURCHASING";

export const PURCHASING_VENDOR_TITLE = "Vendors";

export const PURCHASING_VENDOR_PATH = "/vendors/view-all";

export const PURCHASING_ORDER_TITLE = "Purchase Orders";

export const PURCHASING_ORDER_PATH = "/purchase-order/list";

export const MARKETING_TITLE = "MARKETING";

export const MARKETING_DASHBOARD_TITLE = "Dashboard";

export const MARKETING_DASHBOARD_PATH = "/mktg-dashboard";

export const MARKETING_COUPONS_TITLE = "Coupons";

export const MARKETING_COUPONS_PATH = "/coupon/view-all";

export const MARKETING_SEGMENT_TITLE = "Segments";

// export const MARKETING_SEGMENT_PATH = "/segments/view-all";
export const MARKETING_SEGMENT_PATH = "/segments/list";

export const MARKETING_VERIFY_EMAIL_TITLE = "Verify Email";

export const MARKETING_VERIFY_EMAIL_PATH = "/verify/email";

export const MARKETING_CAMPAIGN_TITLE = "Campaigns";

export const MARKETING_CAMPAIGN_PATH = "/campaign/list";

export const CAMPAIGN_CREATE = "/campaign/create";

export const CAMPAIGN_VIEW = "/campaign/view/";

export const CAMPAIGN_EDIT = "/campaign/edit/"

export const REWARDS_TITLE = "REWARDS & LOYALTY";

export const REWARDS_CARD_TITLE = "Reward Customers";

export const REWARDS_CARD_PATH = "/reward-cards/list";

export const REWARDS_LIST_TITLE = "Rewards";

export const REWARDS_LIST_PATH = "/reward/list";

export const REWARDS_GIFT_CARD_TITLE = "Gift Cards";

export const REWARD_TITLE = "REWARD";

export const REWARD_PATH = "/reward/list";

export const REWARD_EDIT = "/reward/edit/";

export const REWARD_CREATE = "/reward/create";

export const REWARDS_GIFT_CARD_PATH = "/gift-card/list";

export const CUSTOMERS_TITLE = "CUSTOMERS";

export const CUSTOMERS_PATH = "/customer/list";

export const ADDCREDIT = "Success";

export const MODIFIER_SET_CREATE = "/modifier-set/create";

export const MODIFIER_SET_LIST = "/modifier-set/list";

export const MODIFIER_SET_EDIT = "/modifier-set/edit/";

export const GET_ALL_USER = "get-all-users";

export const REACTIVES_SUCCESS = "activated successfully.";

export const CUSTOMER_CREADIT_SUCCESS = "Customer credit added successfully.";

export const CUSTOMERS_CREATE = "/customer/create";

export const CUSTOMERS_EDIT = "/customer/edit/";

export const CUSTOMERS_LIST_CREADIT_API_PATH = "add-customer-credit/";

export const INTEGRATION_TITLE = "INTEGRATIONS";

export const INTEGRATION_LIST_TITLE = "Integrations";

export const INTEGRATION_LIST_PATH = "/integration/list";

export const INTEGRATION_WEBHOOK_TITLE = "Webhooks";

export const INTEGRATION_WEBHOOK_PATH = "/webhook/list";

export const INTEGRATION_API_TITLE = "API";

export const INTEGRATION_API_PATH = "/user-api-auth-detail";

export const KDS_API_TITLE = "Kitchen Display Screens";

export const KDS_API_PATH = "/kds/list";

export const CDS_API_TITLE = "Customer Display Screens";

export const CDS_API_PATH = "/cds/list";

export const CAMPAIGN_PUBLIC_PAGE_PATH = "/campaign";
// =============Global Route Check Permission Route ===========//

export const GLOBAL_ROUTE_CHECK_PERMISSION_API_ROUTE = "global-route-check-permission";

/***************Individual Module Constant ***********************/

//TAX Module

export const TAX_MODULE = "Tax";

export const TAX_EDIT = "/tax/edit/";

export const TAX_CREATE = "/tax/create";

export const TAX_CREATE_API_ROUTE = "tax_create";

export const TAX_CREATE_API_PERMISSION = "tax-create";

export const TAX_LIST_API_ROUTE = "viewAllTax";

export const TAX_LIST_API_PERMISSION = "Tax-list";

export const TAX_EDIT_API_ROUTE = "editTax";

export const TAX_EDIT_API_PERMISSION = "Tax-edit";

export const TAX_VIEW_API_ROUTE = "showTaxForView";

export const TAX_VIEW_API_PERMISSION = "Tax-view";

export const TAX_DEACTIVE_API_ROUTE = "deactive_tax";

export const TAX_DEACTIVE_API_PERMISSION = "Tax-deactivate";

export const TAX_DEACTIVE_LIST_API_ROUTE = "deactive_tax_list";

export const TAX_DEACTIVE_LIST_API_PERMISSION = "Tax-deactivate";

export const TAX_REACTIVE_API_ROUTE = "active_tax";

export const TAX_REACTIVE_API_PERMISSION = "Tax-reactivate";

export const TAX_NAME_REQUIRED = "Please enter a name.";

export const TAX_NAME_LENGTH_GREATE_THEN_191 =
  "Your name should not be greater than 191 characters long.";

export const TAX_TYPE_REQUIRED = "Please select a type.";

export const TAX_RATE_REQUIRED = "Please enter a tax rate value.";

export const TAX_RATE_NOT_VALID = "Please enter a valid tax rate.";

export const TAX_RATE_MIN_VALUE = "Your tax rate must be at least 0.01.";

export const TAX_RATE_MAX_VALUE = "Your tax rate cannot exceed 999999.99.";

export const TAX_TYPE_AMOUNT = "Amount";

export const TAX_TYPE_PERCENTAGE = "Percentage";

export const TAX_PER_MORE_THEN_100 = "Your tax rate cannot exceed 100.";

export const TAX_REACTIVE_SUCCESS = "Tax Reactivated Succesfully.";

export const TAX_DEACTIVE_SUCCESS = "Tax Deactivated Succesfully.";

export const TAX_NOT_FOUND = "There are no active Taxes.";

export const DEACTIVE_TAX_NOT_FOUND = "There are no inactive Taxes.";

export const ACTIVE_TAX_EXPORT_FILENAME = "activeTax";

export const DEACTIVE_TAX_EXPORT_FILENAME = "deactiveTax";

export const DEACTIVE_TITLE = "Deactivate Tax";

export const DEACTIVE_DISCRIPTION = "Deactive tax will lead to remove tax from product which are associated. Do you really want to deactive the tax ?";

export const TAX_DISCRIPTION = "Manage taxes for your products";

export const TAX_TYPE_TIPPY = "It can either be in percentage form or amount form. amount form stays constant irrespective of the product price, and the percentage varies according to the Price.";

export const TAX_EBT_EXEMPT_TIPPY = "If clicked on, then exempt this tax on EBT/SNAP payments.";

export const TAX_EBT_EXEMPT_DISCRIPTION = "Turn on, to exempt this tax on EBT/SNAP payments";

//   USER LOGIN

export const LOGIN_API_ROUTE = "authenticate";

export const FORGOT_PASSWORD_API_ROUTE = "forgot-password";

export const GET_IMAGE_API_ROUTE = "get-image";

//ROLE Module

export const ROLE_MODULE = "Role";

export const ROLE_CREATE = "/roles/create";

export const ROLE_CREATE_API_ROUTE = "create-role-with-permission";

export const ROLE_CREATE_API_PERMISSION = "role-create";

export const ROLE_GET_ALL_PERMISSION = "get-all-permissions";

export const ROLE_LIST = "/roles/list";

export const ROLE_LIST_API_ROUTE = "roles-list";

export const ROLE_LIST_API_PERMISSION = "role-list";

export const ROLE_EDIT = "/roles/edit/";

export const ROLE_EDIT_API_ROUTE = "role-edit";

export const ROLE_EDIT_API_PERMISSION = "role-edit";

export const ROLE_UPDATE_API_ROUTE = "role-update";

export const ROLE_DEACTIVE_LIST_API_ROUTE = "roles-deactive-list-paginate";

export const ROLE_DEACTIVE_LIST_API_PERMISSION = "role-deactivate";

export const ROLE_DEACTIVE_API_ROUTE = "role-deactivate";

export const ROLE_REACTIVE_API_PERMISSION = "role-reactivate";

export const ROLE_REACTIVE_API_ROUTE = "role-reactive";

export const ROLE_NAME_REQUIRED = "Please enter a role name.";

export const ROLE_NAME_LENGTH_GREATE_THEN_191 =
  "Your name not be greater than 191 characters long.";

export const DISPLAY_NAME_REQUIRED = "Please enter a display name.";

export const DISPLAY_NAME_LENGTH_GREATE_THEN_191 =
  "Your display name not be greater than 191 characters long.";

export const DESCRIPTION_LENGTH_GREATE_THEN_5000 =
  "Your description not be greater than 5000 characters long.";

export const ROLE_PERMISSION_EMPTY = "Permission is required.";

export const ROLE_REACTIVE_SUCCESS = "Role Reactivated Successfully.";

export const ROLE_DEACTIVE_SUCCESS = "Role Deactivated Successfully.";

export const ACTIVE_ROLE_NOT_FOUND = "There are no active Roles.";

export const DEACTIVE_ROLE_NOT_FOUND = "There are no inactive Roles.";

export const ACTIVE_ROLES_EXPORT_FILENAME = "Active Roles";

export const DEACTIVE_ROLES_EXPORT_FILENAME = "Inactive Roles";

//MODIFIER SET MODULE

export const MODIFIER_SET = "Modifier set";

export const MODIFIER_SET_REACTIVE_SUCCESS =
  "modifier set Reactivated Succesfully.";

export const MODIFIER_SET_NOT_FOUND = "There are no active Modifiers.";

export const DEACTIVE_MODIFIER_SET_NOT_FOUND = "There are no inactive Modifiers.";

export const MODIFIER_NAME_LENGTH_GREATER_THAN_191 =
  "Your modifier name not be greater than 191 characters long.";

export const MODIFIER_ALREADY_EXISTS_IN_THIS_MODIFIER_SET =
  "Modifier already exists in this modifier set.";

export const MODIFIER_SET_LENGTH_GREATER_THAN_191 =
  "Your modifier set sort can not be less than 1.";

export const MODIFIER_VALID_MODIFIER_PRICE =
  "Please enter a valid modifier price.";

export const MODIFIER_DATA_IS_REQUIRED = "Modifier data is required.";

export const MODIFIER_NAME_MAX_VALUE =
  "Your modifier price cannot exceed 999999.99.";

export const MODIFIER_SET_MIN_VALUE =
  "Your modifier set sort can not be less than 1.";

export const MODIFIER_SET_INTEGER_VALUE =
  "The modifier set sort must be an integer.";

export const MODIFIER_SET_NAME_REQUIRED = "Please enter a modifier set name.";

export const MODIFIER_PRICE_MUST_INTEGER = "The Price must be a integer";

export const MODIFIER_SET_SORT_MUST_INTEGER = "The modifier set sort must be a integer";

export const MODIFIER_SET_NAME_LENGTH_GREATER_THAN_191 =
  "Your modifier set name not be greater than 191 characters long.";

export const MODIFIER_NAME_REQUIRED = "Please enter a modifier name.";

export const MODIFIER_SET_CREATE_API_ROUTE = "modifierSet";

export const MODIFIER_SET_CREATE_API_PERMISSION = "modifier-set-create";

export const MODIFIER_SET_LIST_API_ROUTE = "modifier-set-list";

export const MODIFIER_SET_DEACTIVE_LIST_API_ROUTE =
  "modifier-set-deactive-list-paginate";

export const MODIFIER_SET_DEACTIVE_API_PERMISSION = "modifier-set-deactivate";

export const MODIFIER_SET_DEACTIVE_API_ROUTE = "modifier-set-deactivate";

export const MODIFIER_SET_DEACTIVE_SUCCESS =
  "Modifier set deactivated successfully.";

export const MODIFIER_SET_REACTIVE_API_ROUTE = "modifier-set-reactive";

export const MODIFIER_SET_REACTIVE_API_PERMISSION = "modifier-set-reactivate";

export const MODIFIER_SET_LIST_API_PERMISSION = "modifier-set-list";

export const MODIFIER_SET_EDIT_API_ROUTE = "modifierSet";

export const MODIFIER_SET_EDIT_API_PERMISSION = "modifier-set-edit";

export const MODIFIER_SET_SORT_LENGTH_GREATE_THEN_999 =
  "Your modifier set sort can not be greater than 999.";

export const MODIFIER_SET_EDITS_API_ROUTE = "modifier-set/edit/";

export const ACTIVE_MODIFIER_SET_EXPORT_FILENAME = "Active Modifier set";

export const DEACTIVE_MODIFIER_SET_EXPORT_FILENAME = "Deactive Modifier set";

//CUSTOMER MODULE

export const CUSTOMER_ADDRESS_LENGTH_GREATE_THEN_5000 =
  "Your address not be greater than 5000 characters long.";

export const CUSTOMER_PLEASE_ENTER_A_CITY = "Please enter a city.";

export const CUSTOMER_CITY_LENGTH_GREATE_THEN_191 =
  "Your city not be greater than 191 characters long.";

export const CUSTOMER_PLEASE_ENTER_A_PHONE_NUMBER =
  "Please enter a phone number.";

export const CUSTOMER_PHONE_NUMBER_MUST_BE_LESS_THAN_15_DIGITS_LONG =
  "Your phone number must be less than 15 digits long.";

export const CUSTOMER_PHONE_NUMBER_MUST_BE_GREATER_THAN_2_DIGITS_LONG =
  "Your phone number must be greater than 2 digits long.";

export const THIS_IS_NOT_A_VALID_PHONE_NUMBER =
  "This is not a valid phone number.";

export const CUSTOMER_EMAIL_LENGTH_GREATE_THEN_191 =
  "Your email can not exceed 191 characters.";

export const CUSTOMER_NOTES_LENGTH_GREATE_THEN_191 =
  "Your notes not be greater than 191 characters long.";

export const CUSTOMER_PLEASE_ENTER_A_VALID_CREDIT_LIMIT =
  "Please enter a valid credit limit.";

export const CUSTOMER_CREDIT_LIMIT_MAX_ERROR =
  "Your credit limit cannot exceed 999999.99.";

export const CUSTOMER_CREDIT_LIMIT_MIN_ERROR =
  "Your credit limit must be at least 0.01.";

export const CUSTOMER_PLEASE_SELECT_A_COUNTRY = "Please select a country.";

export const CUSTOMER_PLEASE_SELECT_A_STATE = "Please select a state.";

export const CUSTOMER_PLEASE_SELECT_A_TAX_EXEMPT_OPTION =
  "Please select a tax exempt option.";

export const CUSTOMER_PLEASE_SELECT_A_REWARD_CALCULATION_OPTION =
  "Please select a exclude from reward calculation option.";

export const CUSTOMER_MODULE = "Customer";

export const CUSTOMER_DEACTIVE_SUCCESS = " deactivated successfully.";

export const CUSTOMER_REACTIVE_API_ROUTE = "customer-reactive";

export const CUSTOMER_REACTIVE_SUCCESS = " activated successfully.";

export const CUSTOMER_NAME_REQUIRED = "Please enter a name.";

export const CUSTOMER_ADDRESS_NAME_REQUIRED = "Please enter an address.";

export const CUSTOMER_NAME_LENGTH_GREATER_THAN_191 =
  "Your name not be greater than 191 characters long.";

export const CUSTOMER_NOT_FOUND = "There are no active Customers.";

export const DEACTIVE_CUSTOMER_NOT_FOUND = "There are no inactive Customers.";

export const CUSTOMER_CREATE_API_PERMISSION = "customer-create";

export const CUSTOMER_CREDIT_ADD_API_PERMISSION = "customer-credit-add";

export const CUSTOMER_CREATE_API_ROUTE = "create-customer";

export const CUSTOMER_VIEW_PATH = "/customer/view/";

export const CUSTOMER_GET_DATA_API_ROUTE = "customers-view/";

export const MODIFIER_SET_NAME_HAS_ALREADY_BEEN_TAKEN =
  "Modifier Set Name has already been taken.";

export const CUSTOMER_EXTERNAL_REFERENCE_LENGTH_GREATE_THEN_191 =
  "Your external reference not be greater than 191 characters long.";

export const CUSTOMER_PLEASE_ENTER_A_AMOUNT = "Please enter a amount.";

export const CUSTOMER_AMOUNT_MAX_VALUE = "Your amount cannot exceed 999999.99.";

export const CUSTOMER_LIST_API_ROUTE = "customers-list";

export const CUSTOMER_GET_EDIT_API_ROUTE = "get-customer-edit-details/";

export const CUSTOMER_DEACTIVATE_API_ROUTE = "customer-deactivate";

export const CUSTOMER_DEACTIVATE_LIST_API_ROUTE = "deactive-customer-list";

export const CUSTOMER_UPDATE_API_ROUTE = "customer-update/";

export const CUSTOMER_LIST_API_PERMISSION = "customer-list";

export const CUSTOMER_VIEW_API_PERMISSION = "customer-view";

export const CUSTOMER_REACTIVE_API_PERMISSION = "customer-reactivate";

export const ACTIVE_CUSTOMER_EXPORT_FILENAME = "Active Customer";

export const DEACTIVE_CUSTOMER_EXPORT_FILENAME = "Deactive Customer";

//Gift CARD MODULE

export const GIFT_CARD_VIEWS = "gift-card-view";

export const GIFT_CARD_EDIT_API_PERMISSION = "gift-card-edit";

export const GIFT_CARD_DEACTIVATE_API_PERMISSION = "gift-card-deactivate";

export const GIFT_CARD_REACTIVATE_API_PERMISSION = "gift-card-reactivate";

export const GIFT_CARD_DEACTIVE_SUCCESS = "Gift card deactivated successfully.";

export const GIFT_CARD_REACTIVE_SUCCESS = "Gift card activated successfully.";

export const GIFT_CARD_NOT_FOUND = "There are no active Gift Cards.";

export const DEACTIVE_GIFT_CARD_NOT_FOUND = "There are no inactive Gift Cards.";

export const GIFT_CARD_TITLE = "Giftcards";

export const GIFT_CARD_LIST = "/gift-card/list";

export const GIFT_CARD_VIEW = "/gift-card/view/";

export const GIFT_CARD_EXTERNAL_REFERENCE_LENGTH_GREATE_THEN_191 =
  "Your external reference not be greater than 191 characters long.";

export const GIFT_CARD_PLEASE_ENTER_A_AMOUNT = "Please enter a amount.";

export const GIFT_CARD_AMOUNT_MAX_VALUE =
  "Your amount cannot exceed 500.";

export const GIFT_CARD_CREDIT_LIMIT_MIN_ERROR =
  "Your credit limit must be at least 0.01.";

export const GIFT_CARD_ADD_CREDIT_API_ROUTE = "add-gift-card-balance/";

export const GIFT_CARD_REACTIVE_API_ROUTE = "gift-card-reactive";

export const GIFT_CARD_DEACTIVE_API_ROUTE = "gift-card-deactivate";

export const GIFT_CARD_AUDIT_TRAILS_API_ROUTE =
  "gift-card-audit-trails-paginate";

export const GIFT_CARD_DEACTIVE_CARD_LIST_API_ROUTE = "deactive-gift-card-list";

export const GIFT_CARD_LIST_API_ROUTE = "gift-cards-list";

export const GIFT_CARD_LIST_PERMISSION = "gift-card-list";

export const ACTIVE_GIFT_CARD_EXPORT_FILENAME = "Active GiftCards";

export const DEACTIVE_GIFT_CARD_EXPORT_FILENAME = "Deactive GiftCards";

//REWARD MODULE

export const REWARD_MODULE = "Reward";

export const REWARD_REACTIVE_SUCCESS = " activated successfully.";

export const REWARD_DEACTIVE_SUCCESS = " deactivated successfully.";

export const DEACTIVE_REWARD_NOT_FOUND = "There are no inactive Rewards.";

export const REWARD_NOT_FOUND = "There are no active Rewards.";

export const REWARD_LIST_API_ROUTE = "getAllRewards";

export const REWARD_DEACTIVE_LIST_API_ROUTE = "deactive-reward-list";

export const REWARD_DEACTIVE_API_ROUTE = "reward-deactivate";

export const REWARD_REACTIVE_API_ROUTE = "reward-reactive";

export const REWARD_POINT_CALCULATION_API_ROUTE =
  "reward-point-calculation-type";

export const REWARD_CREATE_API_PERMISSION = "reward-create";

export const REWARD_CREATE_API_ROUTE = "reward-create";

export const REWARD_GET_DATA_API_ROUTE = "reward-edit";

export const REWARD_UPDATE_API_ROUTE = "reward-update/";

export const REWARD_GET_DATA_ALL_API_ROUTE = "reward-coupon-paginate";

export const REWARD_GET_UPDATE_ALL_API_ROUTE = "reward-coupon-update-paginate";

export const REWARD_NAME_REQUIRED = "Please enter a name.";

export const REWARD_NAME_LENGTH_GREATER_THAN_255 =
  "Your name not be greater than 255 characters long.";

export const REWARD_NAME_LENGTH_GREATER_THAN_191 =
  "Your name not be greater than 255 characters long.";

export const REWARD_REQUIRED_REWARD_POINT =
  "Please enter a required reward points.";

export const REWARD_REQUIRED_REWARD_POINT_VISIT =
  "Your required reward points has to be greater than 0 for points earned on visit.";

export const REWARD_MINIMUM_ORDER_AMOUNT =
  "Please enter a minimum order amount.";

export const REWARD_POINT_CALCULATION_TYPE =
  "You must select a reward point calculation type.";

export const REWARD_VALID_REQUIRED_REWARD_POINTS =
  "Please enter a valid required reward points.";

export const REWARD_REQUIRED_REWARD_POINTS_MAX_VALUE =
  "Your required reward points cannot exceed 2147483647.";

export const REWARD_ORDER_AMOUNT_MIN_VALUE =
  "Your minimum order amount must be at least 0.01.";

export const REWARD_ORDER_AMOUNT_MAX_VALUE =
  "Your minimum order amount cannot exceed 999999.99.";

export const REWARD_VALID_AMOUNT_ORDER =
  "Please enter a valid minimum order amount.";

export const REWARD_LIST_API_PERMISSION = "reward-list";

export const REWARD_VIEW_API_PERMISSION = "reward-view";

export const REWARD_EDIT_API_PERMISSION = "reward-edit";

export const REWARD_DEACTIVATE_API_PERMISSION = "reward-deactivate";

export const REWARD_REACTIVATE_API_PERMISSION = "reward-reactivate";

export const ACTIVE_REWARD_EXPORT_FILENAME = "Active Reward";

export const DEACTIVE_REWARD_EXPORT_FILENAME = "Deactive Reward";

export const WebAppTitleDtls = [
  {
    title: "Dashboard",
    subtitle: "Grant full access to view the OctoRetail back office dashboard",
  },
  {
    title: "Role",
    subtitle:
      "Grant full access to manage, create, delete, and edit roles option",
  },
  {
    title: "Profile",
    subtitle: "Grant full access to edit profiles",
  },
  {
    title: "Employee",
    subtitle: "Grant full access to edit employees profiles",
  },
  {
    title: "Location",
    subtitle: "Grant full access to manage store locations",
  },
  {
    title: "Category",
    subtitle: "Grant full access to manage categories",
  },
  {
    title: "Product",
    subtitle: "Grant full access to manage products",
  },
  {
    title: "Pos-Station",
    subtitle: "Grant full access to manage POS station",
  },
  {
    title: "Pay-In",
    subtitle: "Grant full access to manage Pay In tasks",
  },
  {
    title: "Pay-Out",
    subtitle: "Grant full access to manage Pay out tasks",
  },
  {
    title: "Reward-Card",
    subtitle: "Grant full access to manage Reward customers",
  },
  {
    title: "Order",
    subtitle: "Grant full access to manage Order",
  },
  {
    title: "Tills",
    subtitle: "Grant full access to manage Tills",
  },
  {
    title: "Reports",
    subtitle: "Grant full access to manage Reports",
  },
  {
    title: "User auth api detail",
    subtitle: "Grant full access to manage User Auth Api Detail",
  },
  {
    title: "Settings",
    subtitle: "--------------------",
  },
  {
    title: "Global-Settings",
    subtitle: "Grant full access to manage Global Settings",
  },
  {
    title: "Modifier-Set",
    subtitle: "Grant full access to manage Modifier Set",
  },
  {
    title: "Labels",
    subtitle: "Grant full access to manage Label",
  },
  {
    title: "Coupons",
    subtitle: "Grant full access to manage Coupons",
  },
  {
    title: "Campaigns",
    subtitle: "Grant full access to manage Campaign",
  },
  {
    title: "Product-Barcode",
    subtitle: "Grant full access to manage Product-Barcode",
  },
  {
    title: "Vendor",
    subtitle: "Grant full access to manage Vendor",
  },
  {
    title: "Tax",
    subtitle: "Grant full access to manage Tax",
  },
  {
    title: "Purchase Order",
    subtitle: "Grant full access to manage Purchase Order",
  },
  {
    title: "Reward",
    subtitle: "Grant full access to manage Reward",
  },
  {
    title: "Verify Email",
    subtitle: "Grant full access to manage Verify Email",
  },
  {
    title: "Segment",
    subtitle: "Grant full access to manage Segment",
  },
  {
    title: "Integration",
    subtitle: "Grant full access to manage Integration",
  },
  {
    title: "Promotion",
    subtitle: "Grant full access to manage Promotion",
  },
  {
    title: "Billing",
    subtitle: "Grant full access to manage Billing",
  },
  {
    title: "Marketing Dashboard",
    subtitle: "Grant full access to manage Marketing-Dashboard",
  },
  {
    title: "Webhook",
    subtitle: "Grant full access to manage Webhook",
  },
  {
    title: "Tare Container",
    subtitle: "Grant full access to manage Tare-Container",
  },
  {
    title: "Customer",
    subtitle: "Grant full access to manage Customer",
  },
  {
    title: "Gift Card",
    subtitle: "Grant full access to manage Gift-Card",
  },
];

export const MobileAppTitleDtls = [
  {
    title: "Print Label",
    subtitle: "--------------------",
  },
  {
    title: "Product Detail",
    subtitle: "--------------------",
  },
  {
    title: "Dashboard",
    subtitle: "--------------------",
  },
  {
    title: "Recount Inventory",
    subtitle: "--------------------",
  },
  {
    title: "Subtract Inventory",
    subtitle: "--------------------",
  },
  {
    title: "Receive Inventory",
    subtitle: "--------------------",
  },
  {
    title: "Change Price",
    subtitle: "--------------------",
  },
  {
    title: "Bulk Label Print",
    subtitle: "--------------------",
  },
];

export const PosAppTitleDtls = [
  {
    title: "Order Discount",
    subtitle: "--------------------",
  },
  {
    title: "Item Discount",
    subtitle: "--------------------",
  },
  {
    title: "Refund",
    subtitle: "--------------------",
  },
  {
    title: "End of Day Report",
    subtitle: "--------------------",
  },
  {
    title: "Age Verification Bypass",
    subtitle: "--------------------",
  },
  {
    title: "Extra Item Bypass",
    subtitle: "--------------------",
  },
  {
    title: "Pay In",
    subtitle: "--------------------",
  },
  {
    title: "Pay Out",
    subtitle: "--------------------",
  },
  {
    title: "Bank Drop",
    subtitle: "--------------------",
  },
  {
    title: "Open Cash Drawer",
    subtitle: "--------------------",
  },
  {
    title: "Set Till",
    subtitle: "--------------------",
  },
  {
    title: "Close Till",
    subtitle: "--------------------",
  },
  {
    title: "Update Settings",
    subtitle: "--------------------",
  },
];

/* VORI ristricted permissions */
export const VORI_RESTRICTED_PERMISSIONS = [
  // 'Category',
  // 'category-create',
  // 'category-edit',
  // 'category-list',
  // 'category-deactivate',
  // 'category-reactivate',

  // 'Product',
  // 'product-create',
  // 'product-list',
  // 'product-edit',
  // 'product-deactivate',
  // 'product-reactivate',
  // 'product-import',
  // 'product-duplicate',

  // 'Reward-Card',
  // 'reward-card-create',
  // 'reward-card-edit',
  // 'reward-card-list',
  // 'reward-card-deactivate',
  // 'reward-card-reactivate',

  "User auth api detail",
  "user-auth-api-key",

  "Modifier-Set",
  "modifier-set-create",
  "modifier-set-list",
  "modifier-set-edit",
  "modifier-set-view",
  "modifier-set-deactivate",
  "modifier-set-reactivate",

  "Labels",
  "label-generate",

  "Product-Barcode",
  "add-barcode",

  "Vendor",
  "vendor-create",
  "vendor-list",
  "vendor-edit",
  "vendor-deactivate",
  "vendor-reactivate",
  "vendor-view",

  // 'Tax',
  // 'tax-create',
  // 'Tax-list',
  // 'Tax-edit',
  // 'Tax-deactivate',
  // 'Tax-reactivate',
  // 'Tax-reactivate',
  // 'Tax-view',

  "Purchase Order",
  "purchase-order-create",
  "purchase-order-list",
  "purchase-order-edit",
  "purchase-order-deactivate",
  "purchase-order-reactivate",
  "purchase-order-view",

  // 'Reward',
  // 'reward-create',
  // 'reward-list',
  // 'reward-edit',
  // 'reward-deactivate',
  // 'reward-reactivate',
  // 'reward-view',

  "Integration",
  "integration-create",
  "integration-view",
  "integration-list",
  "integration-delete",

  // 'webhook-create',
  // 'webhook-list'
];

export const POS_HIDDEN_PERMISSIONS = [
  "Settings",
  "setting-create",
  "setting-list",
  "setting-edit",
  "setting-view",
  "setting-deactivate",
  "setting-reactivate",
];

// Employees module
export const EMPLOYEES_LIST_API_ROUTE = "users";

export const EMPLOYEES_CLOCK_IN_OUT_API_ROUTE = "update-clock-in-out-status";

export const EMPLOYEES_LIST_API_PERMISSION = "employee-list";

export const EMPLOYEES_DEACTIVE_API_ROUTE = "users";

export const EMPLOYEES_DEACTIVE_API_PERMISSION = "employee-deactivate";

export const EMPLOYEES_DEACTIVE_LIST_API_ROUTE = "deactive-employees";

export const EMPLOYEES_DEACTIVE_LIST_API_PERMISSION = "employee-reactivate";

export const EMPLOYEES_REACTIVE_API_ROUTE = "active-employee";

export const EMPLOYEES_REACTIVE_API_PERMISSION = "employee-reactivate";

export const EMPLOYEES_REACTIVE_SUCCESS = "Employees Reactivated Succesfully.";

export const EMPLOYEES_DEACTIVE_SUCCESS = "Employees Deactivated Succesfully.";

export const EMPOYEES_LIST_ROUTE = "/employees/list";

export const EMPLOYEES_NAME_REQUIRED = "Name  is required";

export const EMPLOYEES_NAME_LESS_THEN_OR_EQUAL_255_CHARACTER =
  "Your name not be greater than 191 characters long.";

export const EMPLOYYEES_PIN_REQUIRED = "Pin is required.";

export const EMPLOYEES_PIN_INVALID = "Pin is Invalid.";

export const EMPLOYEES_PIN_MUST_BE_SIX_DIGIT =
  "Your pin must be valid.";

export const ROLES_FOR_EMPLOYEES_CREATE_API_ROUTE = "get-role";

export const ROLES_PERMISSION_FOR_EMPLOYEES_CREATE = "employee-create";

export const EMPLOYEES_CREATE_API_ROUTE = "register";

export const EMPLOYEES_CREATE_API_PERMISSION = "employee-create";

export const EMPLOYEES_VIEW_API_ROUTE = "users";

export const EMPLOYEES_VIEW_API_PERMISSION = "employee-edit";

export const EMPLOYEES_UPDATE_API_PERMISSION = "employee-edit";

export const EMPLOYEES_CLOCK_IN_OUT_API_PERMISSION = "employee-list";

export const EMPLOYEES_UPDATE_API_ROUTE = "users";

export const EMPLOYEES_MODULE = "Employees";

export const ACTIVE_EMP_EXPORT_FILENAME = "activeEmployees";

export const DEACTIVE_EMP_EXPORT_FILENAME = "deactiveEmployees";

export const EMPLOYEES_NOT_FOUND = "There are no active Employees.";

export const DEACTIVE_EMPLOYEES_NOT_FOUND = "There are no inactive Employees.";

export const WAGE_PER_HOUR_NOT_VALID =
  "Please enter a valid wage per hour rate.";

export const WAGE_PER_HOUR_MIN_VALUE = "wage per hour must be at least 0.01.";

export const WAGE_PER_HOUR_MAX_VALUE = "wage per hour cannot exceed 999999.99.";

export const EMPLOYEES_VIEW = "/employees/view/";

export const EMPLOYEES_EDIT = "/employees/edit/";

export const EMPLOYEES_CREATE = "/employees/create";

export const EMPLOYEES_ROLE_REQUIRED = "Please select a role of employee.";


// =========Tare-Container Module Constant ==========

export const TARE_CONTAINER_MODULE = "Tare Containers";

export const TARE_CONTAINER_LIST_API_ROUTE = "tare-container-list";

export const TARE_CONTAINER_LIST_API_PERMISSION = "tare-container-list";

export const TARE_CONTAINER_DEACTIVE_API_ROUTE = "tare-container-deactivate";

export const TARE_CONTAINER_DEACTIVE_API_PERMISSION =
  "tare-container-deactivate";

export const TARE_CONTAINER_REACTIVE_API_ROUTE =
  "tare-container-deactivate-active";

export const TARE_CONTAINER_REACTIVE_API_PERMISSION =
  "tare-container-reactivate";

export const TARE_CONTAINER_REACTIVE_SUCCESS =
  "Tare Container Reactivated Succesfully.";

export const TARE_CONTAINER_DEACTIVE_SUCCESS =
  "Tare Container Deactivated Succesfully.";

export const TARE_CONTAINER_DEACTIVE_LIST_API_ROUTE =
  "tare-container-deactivate-list";

export const TARE_CONTAINER_DEACTIVE_LIST_API_PERMISSION =
  "tare-container-deactivate";

export const TARE_CONTAINER_LIST = "/tare-containers/list";

export const TARE_CONTAINER_CREATE = "/tare-containers/create";

export const TARE_CONTAINER_EDIT = "/tare-containers/edit/";

export const TARE_CONTAINER_WEIGHT_REQUIRED = "Please enter a weight.";

export const TARE_CONTAINER_WEIGHT_NOT_VALID = "Please enter a valid tare container weight.";

export const TARE_CONTAINER_WEIGHT_MIN_VALUE = "Weight must be at least 0.01.";

export const TARE_CONTAINER_WEIGHT_MAX_VALUE =
  "Weight cannot exceed 999999.99.";

export const TARE_CONTAINER_CREATE_API_ROUTE = "tare-container-create";

export const TARE_CONTAINER_CREATE_API_PERMISSION = "tare-container-create";

export const TARE_CONTAINER_EDIT_API_ROUTE = "tare-container-edit";

export const TARE_CONTAINER_EDIT_API_PERMISSION = "tare-container-edit";

export const TARE_CONTAINER_VIEW_API_ROUTE = "tare-container-view";

export const TARE_CONTAINER_VIEW_API_PERMISSION = "tare-container-view";

export const TARE_CONTAINER_NOT_FOUND = "There are no active Tare Containers.";

export const DEACTIVE_TARE_CONTAINER_NOT_FOUND =
  "There are no inactive Tare Containers.";

  export const DEACTIVE_TARE_EXPORT_FILENAME = "deactiveTareContainer";

  export const ACTIVE_TARE_EXPORT_FILENAME = "activeTareContainer";
// =================RewardCard module Constant===========================
export const REWARD_CARD_MODULE = "Reward Customer";

export const REWARD_CARDS_VIEW = "/reward-cards/view/";

export const REWARD_CARDS_LIST = "/reward-cards/list";

export const REWARD_CARDS_CREATE = "/reward-cards/create";

export const REWARD_CARDS_EDIT = "/reward-cards/edit/";

export const REWARD_CARD_CREATE_PRICE_BOOKS_DATA = "reward-cards/price_books";

export const DEACTIVE_REWARD_CARDS_NOT_FOUND =
  "There are no inactive Reward Customers.";

export const REWARD_CARDS_NOT_FOUND = "There are no active Reward Customers.";

export const REWARD_CARD_LIST_API_ROUTE = "admin_reward_cards";

export const REWARD_CARD_LIST_API_PERMISSION = "reward-card-list";

export const REWARD_CARD_DEACTIVE_LIST_API_ROUTE = "deactive_reward_cards_list";

export const REWARD_CARD_DEACTIVE_LIST_API_PERMISSION =
  "reward-card-reactivate";

export const REWARD_CARD_DEACTIVE_API_ROUTE = "deactive_reward_cards";

export const REWARD_CARD_DEACTIVE_API_PERMISSION = "reward-card-deactivate";

export const REWARD_CARD_REACTIVE_API_ROUTE = "active_reward_cards";

export const REWARD_CARD_REACTIVE_API_PERMISSION = "reward-card-reactivate";

export const REWARD_CARD_REACTIVE_SUCCESS =
  "Reward-card Reactivated Succesfully.";

export const REWARD_CARD_DEACTIVE_SUCCESS =
  "Reward-card Deactivated Succesfully.";

export const REWARD_CARD_CREATE_API_ROUTE = "admin_reward_cards_create";

export const REWARD_CARD_CREATE_API_PERMISSION = "reward-card-create";

export const REWARD_CARD_FIRST_NAME_LENGTH_GREATE_THEN_255 =
  "Your first name not be greater than 255 characters long.";

export const REWARD_CARD_LAST_NAME_LENGTH_GREATE_THEN_255 =
  "Your last name not be greater than 255 characters long.";

export const PHONE_NOT_VALID = "Please enter a valid phone number.  ";

export const PHONE_IS_REQUIRED = "Please enter a phone number.";

export const PHONE_MIN_VALUE =
  "Your phone number must be greater than 2 digits long.";

export const PHONE_MAX_VALUE =
  "Your phone number must be less than 11 digits long.";

export const REWARD_CARD_NOTES_LENGTH_GREATE_THEN_255 =
  "Notes can not be greater than 255 characters long.";

export const REWARD_CARD_EDIT_API_ROUTE = "admin_reward_cards";

export const REWARD_CARD_EDIT_API_PERMISSION = "reward-card-edit";

export const REWARD_CARD_VIEW_FOR_EDIT_API_ROUTE = "admin_reward_cards/edit";

export const REWARD_CARD_VIEW_FOR_EDIT_API_PERMISSION = "reward-card-edit";

export const SMS_SUBSCRIBTION_TYPE_YES = "YES";

export const SMS_SUBSCRIBTION_TYPE_NO = "No";

export const SMS_SUB_TYPE_REQUIRED = "Please select a sms subscription.";

export const EMAIL_SUB_TYPE_REQUIRED = "Please select a email subscription.";

export const REWARD_CARD_VIEW_FOR_VIEW_API_ROUTE = "admin_reward_cards";

export const REWARD_CARD_ORDER_API_ROUTE = "reward-card-order-paginate";

export const REWARD_CARD_ORDER_API_PERMISSION = "reward-card-list";

export const REWARD_CARD_EXPORT_API_ROUTE = "reward_card/export";

export const DEACTIVE_RC_EXPORT_FILENAME = "deactiveRewardCards";

export const ACTIVE_RC_EXPORT_FILENAME = "activeRewardCards";

export const RC_ORDERS_EXPORT_FILENAME = "RewardCardOrders";

export const REWARD_CSV = "Coupons";

export const REWARD_PRODUCT_CSV = "Products";

export const NO_REWARD_CARD_ORDERS = "There are no Orders.";

export const REWARD_FILTER_OPTIONS = [
  {
    name: 'Reward Customers',
    value: "REWARD_CUST", 
  },
  {
    name: "Credit Account Customers",
    value:'CREDIT_ACC', 
  },
]

// ===========================Api Token Module Constant ===================

export const USER_AUTH_API_KEY_GET_API_DATA_PERMISSION = "user-auth-api-key";

export const USER_AUTH_API_KEY_GET_API_DATA_ROUTE = "admin-api-auth-detail";

export const USER_AUTH_API_KEY_REVOKE_TOKEN_API_ROUTE = "admin-api-auth-detail";

export const USER_AUTH_API_KEY_GENERATE_TOKEN_API_ROUTE =
  "add-admin-api-auth-detail";

export const USER_AUTH_API_KEY_REVOKE_TOKEN_API_PERMISSION =
  "user-auth-api-key";

// =================verify email module Constant ======
export const VERIFY_EMAIL_GET_API_ROUTE = "get-verified-email";

export const VERIFY_EMAIL_GET_API_ROUTE_PERMISSION = "verify-email";

export const VERIFY_EMAIL_API_PERMISSION = "verify-email";

export const VERIFY_EMAIL_API_ROUTE = "verify-email";

// =========Category Module Constants===========

export const CATEGORY_MODULE = "Category";

export const CATEGORY_LIST_API_ROUTE = "admin_categories";

export const CATEGORY_LIST_API_PERMISSION = "category-list";

export const CATEGORY_CREATE_API_ROUTE = "admin_category_create";

export const CATEGORY_CREATE_API_PERMISSION = "category-create";

export const CATEGORY_DEACTIVE_LIST_API_ROUTE = "deactive_category_list";

export const CATEGORY_DEACTIVE_LIST_API_PERMISSION = "category-reactivate";

export const CATEGORY_DEACTIVATE_API_ROUTE = "deactive_category";

export const CATEGORY_DEACTIVATE_API_PERMISSION = "category-deactivate";

export const CATEGORY_REACTIVATE_API_ROUTE = "active_category";

export const CATEGORY_GET_DATA_API_ROUTE = "admin_categories";

export const CATEGORY_UPDATE_API_ROUTE = "admin_categories";

export const CATEGORY_EDIT_API_PERMISSION = "category-edit";

export const NAME_REQUIRED = "Please enter a name.";

export const NAME_LENGTH_GREATER_THEN_191 =
  "Your name not be greater than 191 characters long.";

export const CATEGORY_SORT_NOT_INTEGER_ERROR =
  "The category sort must be an integer.";

export const CATEGORY_SORT_MIN_ERROR =
  "Your category sort may not be less than 1.";

export const CATEGORY_SORT_MAX_ERROR =
  "Your category sort may not be greater than 999.";

export const CATEGORY_SORT_INVALID_ERROR =
  "Please enter a valid category sort.";

export const CATEGORY_SORT_INTEGER_ERROR =
  'The category sort must be a integer';

export const ONLINE_ORDERING_SORT_NOT_INTEGER_ERROR =
  "The online ordering sort must be an integer.";

export const ONLINE_ORDERING_SORT_MIN_ERROR =
  "Your online ordering sort may not be less than 1.";

export const ONLINE_ORDERING_SORT_MAX_ERROR =
  "Your online ordering sort may not be greater than 999.";

export const ONLINE_ORDERING_SORT_INVALID_ERROR =
  "Please enter a valid online ordering sort.";

export const ONLINE_ORDERING_SORT_INTEGER_ERROR =
  "The online ordering sort must be a integer.";

export const CATEGORY_DEACTIVE_SUCCESS = "Category Deactivated Successfully.";

export const CATEGORY_REACTIVE_SUCCESS = "Category Reactivated Successfully.";

export const CATEGORY_NOT_FOUND = "There are no active Categories.";

export const DEACTIVE_CATEGORY_NOT_FOUND = "There are no inactive Categories.";

export const ACTIVE_CATEGORY_EXPORT_FILENAME = "Active Categories";

export const DEACTIVE_CATEGORY_EXPORT_FILENAME = "Deactive Categories";

// =========Order Module Constants===========

export const ORDER_LIST_API_ROUTE = "order-paginate";

export const ORDER_LIST_API_PERMISSION = "order-list";

export const ORDER_GET_DATA_API_ROUTE = "orders"; 

export const ORDER_PDF_API_ROUTE = "order-pdf"; 

export const ORDER_VIEW_API_PERMISSION = "order-view";

export const PICKUP_CODE = "PIC";

export const ORDER_CSV = "Order";

export const ORDER_LINE_CSV = "OrderLine";

export const ORDER_PAYMENT_CSV = "PaymentDetails";

export const ORDER_REFUNDS_CSV = "OrderRefunds";

export const ORDER_ITEM_WISE_REFUND_CSV = "ItemWiseRefunds";

export const ORDER_FAILED_PAX_CSV = "OrderFailedPaxResponse";

export const DELIVERY = "Delivery";

export const PICKUP = "Pickup";

export const DISCOUNT_TYPE_AMOUNT = "amou";

export const AMOUNT = "Amount";

export const PERCENTAGE = "Percentage";

export const AUTHORIZE_DOT_NET_CODE = "AUTHNET";

export const PAYMENT_GATEWAY_AUTHORIZE_DOT_NET = "Authorize.net";

export const ORDER_NOT_FOUND = "There are no Orders.";

export const ORDER_PAYMENT_NOT_FOUND = "There are no Payment details.";

export const ORDER_REFUND_NOT_FOUND = "There are no Refund details.";

export const ORDER_REWARD_NOT_FOUND = "There are no Reward details.";



//PRODUCT Module

export const PRODUCT_MODULE = "Product";

// export const PRODUCT_LIST_API_ROUTE = "viewAllTax";
export const PRODUCT_LIST_API_ROUTE = "product-paginate";

export const PRODUCT_LIST_API_PERMISSION = "product-list";

export const PRODUCT_LOCATION_AND_CATEGORY_GET_API_ROUTE =
  "get-locations-and-categories";

export const PRODUCT_CREATE = "/products/create";

// export const PRODUCT_CREATE_API_ROUTE = "create-role-with-permission";
export const PRODUCT_CREATE_API_ROUTE = "products";

export const PRODUCT_CREATE_API_PERMISSION = "product-create";

export const PRODUCT_LOCATION_AND_CATEGORY_AND_TAX_GET_API_ROUTE =
  "get-locations-categories-and-taxs";

export const LOCATION_NOT_FOUND = "Location not exist in the system.";

export const PRODUCT_CATEGORY_NOT_FOUND = "Category not exist in the system.";

export const PRODUCT_VENDOR_LIST_GET_API_ROUTE = "get-vendor-list";

export const PRODUCT_EDIT_API_ROUTE = "products";

export const PRODUCT_EDIT_API_PERMISSION = "product-edit";

export const PRODUCT_NAME_REQUIRED = "Please enter a name.";

export const PRODUCT_NAME_LENGTH_GREATE_THEN_191 =
  "Your name not be greater than 191 characters long.";

export const PRODUCT_DESCRIPTION_LENGTH_GREATE_THEN_5000 =
  "Your description not be greater than 5000 characters long.";

export const PRODUCT_SKU_LENGTH_GREATE_THEN_191 =
  "Your sku not be greater than 191 characters long.";

export const PRODUCT_BARCODE_REQUIRED = "Please enter a primary barcode.";

export const PRODUCT_BARCODE_LENGTH_GREATE_THEN_191 =
  "Your primary barcode not be greater than 191 characters long.";

export const PRODUCT_ADDITIONAL_BARCODE_LENGTH_GREATE_THEN_191 =
  "Your barcode not be greater than 191 characters long.";  

export const PRODUCT_LOCATION_REQUIRED = "Please select a location.";

export const PRODUCT_CATEGORY_REQUIRED = "Please select a category.";

export const PRODUCT_IN_STOCK_QTY_REQUIRED =
  "Please enter an in stock quantity.";

export const PRODUCT_IN_STOCK_QTY_NOT_VALID_ERROR =
  "Please enter a valid in stock quantity.";

export const PRODUCT_IN_STOCK_QTY_MIN_ERROR =
  "Your in stock quantity must be at least -999999.99";

export const PRODUCT_IN_STOCK_QTY_MAX_ERROR =
  "Your in stock quantity cannot exceed 999999.99";

export const PRODUCT_SALE_PRICE_REQUIRED = "Please enter a sale price.";

export const PRODUCT_AMOUNT_NOT_VALID_ERROR =
  "Please enter a valid amount.";

export const PRODUCT_AMOUNT_MIN_ERROR =
  "Your amount must be at least 0.01";

export const PRODUCT_AMOUNT_MAX_ERROR =
  "Your amount cannot exceed 999999.99";

export const PRODUCT_SALE_PRICE_NOT_VALID_ERROR =
  "Please enter a valid sale price.";

export const PRODUCT_SALE_PRICE_MIN_ERROR =
  "Your sale price must be at least 0.01";

export const PRODUCT_SALE_PRICE_MAX_ERROR =
  "Your sale price cannot exceed 999999.99";

export const PRODUCT_SALE_PRICE_MIN_MAX_ERROR =
  "Your sale price must be at least 0.01 and cannot exceed 999999.99";

export const PRODUCT_CASH_PRICE_REQUIRED = "Please enter a cash price.";

export const PRODUCT_CASH_PRICE_NOT_VALID_ERROR =
  "Please enter a valid cash price.";

export const PRODUCT_CASH_PRICE_MIN_ERROR =
  "Your cash price must be at least 0.01";

export const PRODUCT_CASH_PRICE_MAX_ERROR =
  "Your cash price cannot exceed 999999.99";

export const PRODUCT_CASH_PRICE_MIN_MAX_ERROR =
  "Your cash price must be at least 0.01 and cannot exceed 999999.99";

export const PRODUCT_RETAIL_PRICE_REQUIRED = "Please enter a retail price.";

export const PRODUCT_RETAIL_PRICE_NOT_VALID_ERROR =
  "Please enter a valid retail price.";

export const PRODUCT_RETAIL_PRICE_MIN_ERROR =
  "Your retail price must be at least 0.01";

export const PRODUCT_RETAIL_PRICE_MAX_ERROR =
  "Your retail price cannot exceed 999999.99";

export const PRODUCT_RETAIL_PRICE_MIN_MAX_ERROR =
  "Your retail price must be at least 0.01 and cannot exceed 999999.99";

export const PRODUCT_ONLINE_ORDERING_PRICE_NOT_VALID_ERROR =
  "Please enter a valid online ordering price.";

export const PRODUCT_ONLINE_ORDERING_PRICE_MIN_ERROR =
  "Your online ordering price must be at least 0.01";

export const PRODUCT_ONLINE_ORDERING_PRICE_MAX_ERROR =
  "Your online ordering price cannot exceed 999999.99";

export const PRODUCT_COST_PER_CASE_PRICE_NOT_VALID_ERROR =
  "Please enter a valid cost per case.";

export const PRODUCT_COST_PER_CASE_PRICE_MIN_ERROR =
  "Your cost per case must be at least 0.01";

export const PRODUCT_COST_PER_CASE_PRICE_MAX_ERROR =
  "Your cost per case cannot exceed 999999.99";

export const PRODUCT_ADDITIONAL_COST_PER_CASE_PRICE_NOT_VALID_ERROR =
  "Please enter a valid additional cost.";

export const PRODUCT_ADDITIONAL_COST_PRICE_MIN_ERROR =
  "Your additional cost must be at least 0.01";

export const PRODUCT_ADDITIONAL_COST_PRICE_MAX_ERROR =
  "Your additional cost cannot exceed 999999.99";

export const PRODUCT_NO_OF_SELLABLE_UNITS_NOT_VALID_ERROR =
  "Please enter a valid no. of sellable units in a case.";  

export const PRODUCT_NO_OF_SELLABLE_UNITS_MIN_ERROR =
  "Your no. of sellable units in a case must be at least 0.01";

export const PRODUCT_NO_OF_SELLABLE_UNITS_MAX_ERROR =
  "Your no. of sellable units in a case cannot exceed 999999.99";

export const PRODUCT_CUSTOM_MARGIN_NOT_VALID_ERROR =
  "Please enter a valid custom margin.";  

export const PRODUCT_CUSTOM_MARGIN_MIN_ERROR =
  "Your custom margin must be at least 1";

export const PRODUCT_CUSTOM_MARGIN_MAX_ERROR =
  "Your custom margin cannot exceed 100";

export const PRODUCT_THRESHOLD_NOT_VALID_ERROR =
  "Please enter a valid threshold.";

export const PRODUCT_THRESHOLD_MIN_ERROR =
  "Your threshold must be at least 0.00";

export const PRODUCT_THRESHOLD_MAX_ERROR =
  "Your threshold cannot exceed 999999.99";

export const PRODUCT_REWARD_POINTS_MIN_ERROR =
  "your min value 0";

export const PRODUCT_REWARD_POINTS_MAX_ERROR =
  "Your reward points cannot exceed 2147483647";

export const PRODUCT_REWARD_POINTS_REGEX_ERROR =
  "Please enter a valid reward points.";

export const PRODUCT_REWARD_POINTS_REQUIRED = "Please enter a reward points.";

export const PRODUCT_MIN_AGE_VERIFICATION_REQUIRED =
  "Please enter a minimum age.";

export const PRODUCT_MIN_AGE_VERIFICATION_ERROR =
  "Please enter a valid minimum age.";

export const PRODUCT_MIN_AGE_VERIFICATION_MIN_ERROR =
  "Your minimum age must be at least 1.";

export const PRODUCT_MIN_AGE_VERIFICATION_MAX_ERROR =
  "Your minimum age cannot exceed 100.";

export const PRODUCT_VENDOR_ALREADY_EXISTS = "Vendor already exists.";

export const PRODUCT_VENDOR_ITEM_CODE_MAX_ERROR =
  "Your Item Code not be greater than 255 characters long.";

export const PRODUCT_DEACTIVE_LIST_API_ROUTE = "deactive-products";

export const PRODUCT_DEACTIVE_LIST_API_PERMISSION = "product-deactivate";

export const PRODUCT_DEACTIVE_API_ROUTE = "products";

export const PRODUCT_DEACTIVE_API_PERMISSION = "product-deactivate";

export const PRODUCT_REACTIVE_API_ROUTE = "active_product";

export const PRODUCT_REACTIVE_API_PERMISSION = "product-reactivate";

export const PRODUCT_DEACTIVE_SUCCESS = "Product Deactivated Successfully.";

export const PRODUCT_REACTIVE_SUCCESS = "Product Reactivated Successfully.";

export const PRODUCT_NOT_FOUND = "There are no active Products.";

export const DEACTIVE_PRODUCT_NOT_FOUND = "There are no inactive Products.";

export const PRODUCT_GET_BULK_UPLOAD_PRODUCT_API_ROUTE =
  "get-bulk-upload-products";

export const PRODUCT_SALE_PRICE_CHANGE_API_ROUTE = "product-change-price";

export const PRODUCT_SALE_PRICE_CHANGE_API_PERMISSION = "product-edit";

export const PRODUCT_VIEW_API_ROUTE = "products";

export const PRODUCT_VIEW_API_PERMISSION = "product-list";

export const PRODUCT_GET_QUICK_EDIT_API_ROUTE = "products-paginateQuick";

export const PRODUCT_QUICK_EDIT_API_ROUTE = "quick-editProducts";

export const PRODUCT_QUICK_EDIT_API_PERMISSION = "product-edit";

export const PRODUCT_DUPLICATE_API_ROUTE = "products";

export const PRODUCT_DUPLICATE_API_PERMISSION = "product-duplicate";

export const PRODUCT_PRINT_LARGE_SIGN_API_ROUTE = "print_large_sign";

export const PRODUCT_PRINT_LARGE_SIGN_API_PERMISSION = "print-large-sign";

export const PRODUCT_IMPORT_API_ROUTE = "products";

export const PRODUCT_IMPORT_API_PERMISSION = "product-import";

export const PRODUCT_IMPORT_CLICK_API_ROUTE = "products/import";

export const PRODUCT_GET_LOCATION_API_ROUTE = "get-locations";

export const PRODUCT_GET_UPLOADED_FILE_DTLS_API_ROUTE =
  "get-uploaded-file-details";

export const PRODUCT_EXPORT_SUCCESS_CSV_FILE_API_ROUTE =
  "products/export-success-csv";

export const PRODUCT_DELETE_FILE_API_ROUTE =
  "products/delete-file";  

export const PRODUCT_EXPORT_ERROR_EXCEL_FILE_API_ROUTE =
  "products/export-error-excel";

export const PRODUCT_EXPORT_API_PERMISSION = "products/export";

export const DEFAULT_BACKGROUND_COLOR = "#5881FE";
export const DEFAULT_BORDER_COLOR = "";
export const DEFAULT_TEXT_COLOR = "#F4F4F4";

export const ACTIVE_PRODUCT_EXPORT_FILENAME = "Active Products";

export const DEACTIVE_PRODUCT_EXPORT_FILENAME = "Deactive Products";

export const IMPORTED_PRODUCT_EXPORT_FILENAME = "Import Files";

export const PRODUCT_PRICE_NOT_FOUND = "There are no Price Book details.";

// ======== Vendor Module Constants ========

export const VENDOR_MODULE = "Vendor";

export const VENDOR_LIST = "/vendor/list";

export const VENDOR_LIST_API_ROUTE = "vendor_locations";

export const COUNTRY_STATE = "showCountriesAndState";

export const GET_COUNTRY_STATE = "get-state-list-for-user/";

export const VENDOR_LIST_API_PERMISSION = "vendor-list";

export const VENDOR_CREATE = "/vendor/create";

export const VENDOR_CREATE_API_PERMISSION = "vendor-create";

export const VENDOR_NAME_REQUIRED = "Name  is required";

export const VENDOR_NAME_LESS_THEN_OR_EQUAL_255_CHARACTER =
  "Vendor name can not exceed 191 characters.";

export const VENDOR_EMAIL_REQUIRED = "Email is required.";

export const VENDOR_EMAIL_NOT_VALID = "Please enter a valid email.";

export const VENDOR_ADDRESS_LESS_THEN_OR_EQUAL_5000_CHARACTER =
  "Vendor address can not exceed 5000 characters.";

export const VENDOR_CONTACT_LESS_THEN_OR_EQUAL_255_CHARACTER =
  "Vendor contact person can not exceed 191 characters.";

export const VENDOR_CITY_LESS_THEN_OR_EQUAL_191_CHARACTER =
  "Vendor city can not exceed 191 characters.";

export const VENDOR_PHONE_MUST_BE_5_DIGITS =
  "Vendor phone must be at least 5 digits.";
export const VENDOR_PHONE_LESS_THAN_15_DIGITS =
  "Vendor phone must can not exceed 15 digits.";

export const VENDOR_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER =
  "Your email can not exceed 191 characters.";

export const VENDOR_PHONE_NOT_VALID = "Please enter a valid phone number.";

export const VENDOR_CREATE_API_ROUTE = "vendor_create";

export const VENDOR_EDIT = "/vendor/edit/";

export const VENDOR_VIEW = "/vendor/view/";

export const VENDOR_EDIT_API_PERMISSION = "vendor-edit";

export const VENDOR_VIEW_API_ROUTE = "show_vendor";

export const VENDOR_EDIT_API_ROUTE = "edit_vendor";

export const VENDOR_VIEW_API_PERMISSION = "vendor-view";

export const VENDOR_REACTIVE_SUCCESS = "Vendor Reactivated Succesfully.";

export const VENDOR_DEACTIVE_SUCCESS = "Vendor Deactivated Succesfully.";

export const VENDOR_NOT_FOUND = "There are no active Vendors.";

export const DEACTIVE_VENDOR_NOT_FOUND = "There are no inactive Vendors.";

export const VENDOR_DEACTIVE_API_ROUTE = "deactive_vendor";

export const VENDOR_DEACTIVE_API_PERMISSION = "vendor-deactivate";

export const VENDOR_DEACTIVE_LIST_API_ROUTE = "deactive_vendor_list";

export const VENDOR_DEACTIVE_LIST_API_PERMISSION = "vendor-deactivate";

export const VENDOR_REACTIVE_API_ROUTE = "active_vendor";

export const VENDOR_REACTIVE_API_PERMISSION = "vendor-reactivate";

export const ACTIVE_VENDORS_EXPORT_FILENAME = "Active Vendors";

export const DEACTIVE_VENDORS_EXPORT_FILENAME = "Inactive Vendors";

// PURCHASING_ORDER

export const PURCHASING_ORDER_MODULE = "Purchase Order";

export const PURCHASING_ORDER_LIST = "purchase-order/list";

export const PURCHASING_ORDER_EDIT = "/purchase-order/edit/";

export const PURCHASING_ORDER_CREATE = "/purchase-order/create";

export const PURCHASING_ORDER_REACTIVE_API_ROUTE = "active-purchase-order/";

export const PURCHASING_ORDER_DEACTIVE_API_ROUTE = "deactive-purchase-order/";

export const PURCHASING_ORDER_GET_DEACTIVE_API_ROUTE = "get-purchase-order-deactive-list";

export const PURCHASING_ORDER_LIST_API_ROUTE = "purchase-order-list";

export const PURCHASING_ORDER_REACTIVE_SUCCESS = "Purchase Order Reactivated Succesfully.";

export const PURCHASING_ORDER_DEACTIVE_SUCCESS = "Purchase Order Deactivated Succesfully.";

export const PURCHASING_ORDER_SELECT_VENDOR = "Please select a vendor.";

export const PURCHASING_ORDER_SELECT_LOCATION = "Please select a location.";

export const PURCHASING_ORDER_SELECT_QUANTITY = "Please enter a quantity.";

export const PURCHASING_ORDER_QUANTITY_MAX_VALUE = "Your quantity not be greater than 999999.99.";

export const PURCHASING_ORDER_QUANTITY_MIN_VALUE = "Your quantity must be at least 1.";

export const PURCHASING_ORDER_MIN_VALUE = "Your cost must be at least 0.00.";

export const PURCHASING_UNIT_CASE_MIN_VALUE = "Your unit/case must be at least 0.01.";

export const PURCHASING_UNIT_CASE_MAX_VALUE = "Your unit/case cannot exceed 999999.99.";

export const PURCHASING_ORDER_VALID_COST = "Please enter a valid cost.";

export const PURCHASING_ORDER_MAX_VALUES = "Your cost cannot exceed 999999.99.";

export const PURCHASING_ORDER_LIST_ROUTE = "purchase-order-list"

export const PURCHASING_ORDER_PAGINATE_ROUTE = "product-paginate";

export const PURCHASING_ORDER_GET_VENDORS_ROUTE = "get-vendors-for-filter"

export const PURCHASING_ORDER_VENDOR_LOCATION_ROUTE = "vendor-location-list";

export const PURCHASING_ORDER_MANUAL_PRODUCT_LIST_ROUTE = "get-manual-product-list";

export const PURCHASING_ORDER_CREATE_ROUTE = "create-purchase";

export const PURCHASING_ORDER_PRODUCT_LIST_ROUTE = "get-product-list";

export const PURCHASING_ORDER_ROUTE = "get-purchase/";

export const PURCHASING_ORDER_EDIT_ROUTE = "edit-purchase-order-list/";

export const PURCHASE_ORDER_EXCEL_ROUTE = "export-excel-for-purchase-order/";

export const PURCHASE_ORDER_PDF_ROUTE = "generate-pdf-for-purchase-order/";

export const PURCHASE_ORDER_CREATE_PERMISSION  = "purchase-order-create";

export const PURCHASE_ORDER_VIEW_ALL_PERMISSION = "purchase-order-list";

export const PURCHASE_ORDER_VIEW_PERMISSION = "purchase-order-view";

export const PURCHASE_ORDER_EDIT_PERMISSION = "purchase-order-edit";

export const PURCHASE_ORDER_DEACTIVATE_PERMISSION = "purchase-order-deactivate";

export const PURCHASE_ORDER_REACTIVATE_PERMISSION = "purchase-order-reactivate";

export const PURCHASING_ORDER_MANUAL_PRODUCT_EDIT_ROUTE = "edit-manual-product-list";

export const PURCHASING_ORDER_PRODUCT_EDIT_ROUTE = "edit-product-list";

export const PURCHASE_ORDER_EDIT_API_ROUTE = "edit-purchase-order";

export const DEACTIVE_PURCHASE_ORDER_NOT_FOUND = "There are no inactive Purchase Orders.";

export const PURCHASE_ORDER_NOT_FOUND = "There are no active Purchase Orders.";

export const PURCHASE_ORDER_GET_PRODUCT_ROUTE = "get-product";

export const PURCHASE_ORDER_GET_PRODUCT_EDIT_ROUTE = "get-product-for-edit";

export const ACTIVE_PURCHASE_ORDER_EXPORT_FILENAME = "Active Purchase order";

export const DEACTIVE_PURCHASE_ORDER_EXPORT_FILENAME = "Deactive Purchase order";

// Online Gift Card Sale Report

export const ONLINE_GIFT_CARD_SALE = "/reports/online-gift-card-sales";

export const ONLINE_GIFT_CARD_PERMISSION_API_ROUTE = "global-route-check-permission";

export const ONLINE_GIFT_CARD_DATA_API_ROUTE = "get-online-gift-card-sales-data";

export const ONLINE_GIFT_CARD_SALES_DATA_API_ROUTE = "export-online-gift-card-sales-data";

export const ONLINE_GIFT_CARD_SALES_PDF_API_ROUTE = "generate-online-gift-card-sales-pdf";

export const REPORT_ONLINE_GIFT_CARD_SALES_PERMISSION = "report-online-gift-card-sales";

export const ONLINE_GIFT_CARD_REPORT_CSV = "OnlineGiftCardReport";

export const ONLINE_GIFT_CARD_NO_DATA = "There are no Online Gift Card Sales.";

// Top Items By Sales Report and Top Items by Volume

export const TOP_SALE_REPORT = "/reports/top-sale-report";

export const TOP_VOLUME_REPORT = "/reports/top-volume-report";

export const REPORT_ITEMS_BY_SALES_PERMISSION = "report-items-by-sales";

export const REPORT_ITEMS_BY_VOLUME_PERMISSION = "report-items-by-volume";

export const REPORT_ITEMS_BY_SALES_NO_DATA = "There are no Items By Sales Data.";

export const REPORT_ITEMS_BY_VOLUME_NO_DATA = "There are no Items By Volume Data.";


// product not found Report

export const PRODUCT_NOT_FOUND_BARCODE = "/reports/product-not-found-barcodes";

export const PRODUCT_NOT_FOUND_BARCODE_DATA = "product-not-found-barcodes";

export const PRODUCT_NOT_FOUND_BARCODE_DATA_ROUTE = "get-product-not-found-barcode-data";

export const PRODUCT_NOT_FOUND_BARCODE_EXPORT_DATA_ROUTE = "export-product-not-found-barcode-data";

export const PRODUCT_NOT_FOUND_BARCODE_DELETE_DATA = "delete-product-not-found-barcode-data";

export const REPORT_PRODUCT_NOT_FOUND_BARCODES_PERMISSION = "report-product-not-found-barcodes";

export const PRODUCT_NOT_FOUND_BARCODES_REPORT_CSV = "ProductNotFoundBarcodesReport";

export const PRODUCT_NOT_FOUND_BARCODES_NO_DATA = "There are no Product Not Found Barcodes.";

// Bottle Deposit Refund Report

export const BOTTLE_DEPOSIT_REFUND = "/reports/bottle-deposit-refund";

export const BOTTLE_DEPOSIT_REFUND_DATA_ROUTE = "get-bottle-deposit-refund-data";

export const BOTTLE_DEPOSIT_REFUND_EXPORT_ROUTE = "export-bottle-deposit-refund-data";

export const REPORT_BOTTLE_DEPOSIT_REFUND_PERMISSION = "report-bottle-deposit-refund";

export const BOTTLE_DEPOSIT_REFUND_REPORT_CSV = "BottleDepositRefundReport";

export const BOTTLE_DEPOSIT_REFUND_NO_DATA = "There are no Bottle Deposit Refund.";

// Refund Without Order Report

export const REFUND_WITHOUT_ORDER = "/reports/refund-without-order";

export const REFUND_WITHOUT_ORDER_ROUTE = "get-refund-without-order-data";

export const REFUND_WITHOUT_ORDER_EXPORT_DATA = "export-refund-without-order-data";

export const REFUND_WITHOUT_ORDER_DETAILS_ROUTE = "view-refund-without-order-details/";

export const REPORT_REFUND_WITHOUT_ORDER_PERMISSION = "report-refund-without-order";

export const REFUND_WITHOUT_ORDER_SELECT_CUSTOMER = "Please select a customer.";

export const REFUND_WITHOUT_ORDER_REPORT_CSV = "RefundWithoutOrderReport";

export const REFUND_WITHOUT_ORDER_NO_DATA = "There are no Refund Without Order.";

// Order Discount Report

// export const ORDER_DISCOUNT_REPORT_ROUTE = "/reports/order-discount";
export const DISCOUNT_REPORT_ROUTE = "/reports/discount-report";

export const ORDER_DISCOUNT_REPORT_API_ROUTE = "get-order-discount";

export const ORDER_DISCOUNT_REPORT_API_PERMISSION = "report-order-discount";

export const ORDER_DISCOUNT_REPORT_EXPORT_API_ROUTE = "export-order-discount";

export const ORDER_DISCOUNT_REPORT_EXPORT_API_PERMISSION = "report-order-discount";

export const ORDER_DISCOUNT_REPORT_EXPORT_SUCCESS = "Discount Report successfully generated.";

export const ORDER_DISCOUNT_REPORT_NOT_FOUND = "There are no Discount.";

export const ORDER_DISCOUNT_REPORT_EXPORT_FILENAME = "Discount Report";

// Customer Unused Credit Report

export const CUSTOMER_UNUSED_CREDIT_ROUTE = "/reports/customer-unused-credit";

export const CUSTOMER_UNUSED_CREDIT_DATA = "get-customer-unused-credit-data";

export const CUSTOMER_UNUSED_CREDIT_EXPORT_DATA = "export-customer-unused-credit-data";

export const CUSTOMER_UNUSED_CREDIT_PDF = "generate-customer-unused-credit-pdf";

export const REPORT_CUSTOMER_UNUSED_CREDIT_PERMISSION = "report-customer-unused-credit";

export const CUSTOMER_UNUSED_SELECT_CUSTOMER = "Please select a customer.";

export const CUSTOMER_UNUSED_CREDIT_REPORT_CSV = "CustomerUnusedCreditReport";

export const CUSTOMER_UNUSED_CREDIT_NO_DATA = "There are no Customer Unused Credit.";

// Customer Credit Ledger Report

export const CUSTOMER_CREDIT_LEDGER_ROUTE = "/reports/customer-credit-ledger";

export const CUSTOMER_CREDIT_LEDGER_DATA = "get-customer-credit-ledger-data";

export const CUSTOMER_CREDIT_LEDGER_EXPORT_DATA = "export-customer-credit-ledger-data";

export const CUSTOMER_CREDIT_LEDGER_PDF = "generate-customer-credit-ledger-pdf";

export const REPORT_CUSTOMER_CREDIT_LEDGER_PERMISSION = "report-customer-credit-ledger";

export const CUSTOMER_CREDIT_SELECT_CUSTOMER = "Please select a customer.";

export const CUSTOMER_CREDIT_LEDGER_REPORT_CSV = "CustomerCreditLedgerReport";

export const CUSTOMER_CREDIT_LEDGER_NO_DATA = "There are no Customer Credit Ledger.";

// Employee Tips Amount By Day Report

export const EMPLOYEE_TIPS_AMOUNT_ROUTE = "/reports/employees-tips-amount";

export const LOCATION_REPORT_DATA = "get-locations-report-data";

export const REPORT_TOTAL_SALES_PERMISSION = "report-total-sales";

export const REPORT_EMPLOYEE_DATA_PERMISSION = "report-employees-workedtime";

export const EMPLOYEE_TIPS_REPORT_PERMISSION = "employees-tips-report-export";

export const EMPLOYEE_TIPS_REPORT_PERMISSIONS = "get-employee-report";

export const REPORT_EMPLOYEE_TIP_AMOUNT_PERMISSION = "report-employees-tipamount";

export const EMPLOYEE_TIPS_REPORT_CSV = "EmployeeTipsbyDayReport";

export const EMPLOYEE_TIPS_AMOUNT_BY_DAY_NO_DATA = "There are no Total Employee Tips by Day.";

// Total Daily Sales Report

export const TOTAL_DAILY_SALE = "/reports/total-daily-sales";

export const TOTAL_DAILY_SALE_CSV = "TotalDailySales";

export const TOTAL_DAILY_SALE_API_ROUTE = "get-total-daily-sales-report";

export const TOTAL_DAILY_SALE_API_PERMISSION = "report-total-daily-sales";

export const GET_ALL_LOCATIONS_API_ROUTE = "get-all-locations";

export const GET_ALL_LOCATIONS_API_PERMISSION = "report-total-daily-sales";

export const TOTAL_DAILY_SALE_EXPORT_API_ROUTE = "total-daily-sales-report-export";

// Total Sales By Day Report

export const TOTAL_SALE_BY_DAY = "/reports/sales";

export const TOTAL_SALE_BY_DAY_CSV = "TotalSalesByDay";

export const TOTAL_SALE_BY_DAY_API_ROUTE = "get-sales-report";

export const TOTAL_SALE_BY_DAY_API_PERMISSION = "report-total-sales";

export const GET_ALL_LOCATIONS_FOR_TOTAL_SALE_BY_DAY_API_ROUTE = "get-all-locations";

export const GET_ALL_LOCATIONS_FOR_TOTAL_SALE_BY_DAY_API_PERMISSION = "report-total-sales";

export const TOTAL_SALE_BY_DAY_EXPORT_API_ROUTE = "sales-report-export";

export const REPORT_MODULE = "Report";

export const NO_DATA_FOUND = "No data found.";

// ================= Recount Report Constant==================================
export const RECOUNT_REPORT_PERMISSION = "report-inventary-recount";

export const RECOUNT_REPORT_ROUTE = "/reports/recount";

export const RECOUNT_LIST_API_ROUTE = "get-recount-data";

export const RECOUNT_EXPORT_DATA_ROUTE = "export-recount-data";

export const RECOUNT_NOT_FOUND = "There are no Recount.";

export const RECOUNT_CSV = "Recount";

// ================= Void Report Constant==================================
export const VOID_REPORT_PERMISSION = "report-operation-void";

export const VOID_REPORT_ROUTE = "/reports/void";

export const VOID_LIST_API_ROUTE = "get-void-data";

export const VOID_EXPORT_DATA_ROUTE = "export-void-data";

export const VOID_NO_DATA = "There are no Void.";

export const VOID_CSV = "Void";

// ================= Dountime Detector Constant==================================

export const PUBLIC_DOUNTIME_DETECTOR_PAGE_PATH = "/test";

export const PUBLIC_DOUNTIME_DETECTOR_URL = "test";

// Tills Report 

export const TILLS_TITLE = "Tills";

export const TILL_REPORT_ROUTE = "/tills/list";

export const TILL_CREATE = "/tills/create";

export const TILL_VIEW = "/tills/view/";

export const TILL_EDIT = "/tills/edit/";

export const TILL_PDF_ROUTE = "generate-till-details-pdf";

export const TILL_REACTIVE_SUCCESS = "Till activated successfully.";

export const TILL_DEACTIVE_SUCCESS = "Till deactivated successfully.";

export const TILL_ALL_LOCATION_ROUTE = "get-all-locations";

export const TILL_EDIT_ALL_LIST_ROUTE = "tills-edit-all-list";

export const TILL_DEACTIVE_LIST_ROUTE = "tills-deactive-list-paginate";

export const TILL_GET_CREATE_PAGE_DATA_ROUTE = "tills-get-create-page-data";

export const TILL_ROUTE = "tills";

export const TILL_EDIT_ROUTE = "tills/edit/";

export const TILLS_ROUTE = "tills/";

export const TILLS_SHOW_ROUTE = "tills/show/";

export const TILLS_DEACTIVATE_ROUTE = "tills-deactivate/";

export const TILLS_REACTIVE_ROUTE = "tills-reactive/";

export const TILLS_EDIT_ALL_LIST_ROUTE = "tills-edit-all-list";

export const PERMISSION_TILLS_CREATE = "till-create";

export const PERMISSION_TILLS_VIEW = "till-list";

export const PERMISSION_TILLS_EDIT = "till-edit";

export const PERMISSION_TILLS_DEACTIVATE = "till-deactivate";

export const PERMISSION_TILLS_REACTIVATE = "till-reactivate";

export const TILLS_SELECT_POS_STATION = "You must select a pos station.";

export const TILLS_SELECT_OPEN_BY = "You must select a opened by.";

export const TILLS_ENTER_STARING_AMOUNT = "Please enter a starting amount.";

export const TILLS_SELCT_CLOSED_BY = "You must select a closed by.";

export const TILLS_ENTER_CLOSING_AMOUNT = "Please enter a closing amount.";

export const TILLS_STARTING_AMOUNT_MIN_VALUE = "Your starting amount must be at least 0.01.";

export const TILLS_STARTING_AMOUNT_MAX_VALUE = "Your starting amount cannot exceed 999999.99.";

export const TILLS_ENTER_CLOSED_AMOUNT = "Please enter a closing amount.";

export const TILLS_ENTER_CLOSED_AMOUNT_MIN_VALUE = "Your closing amount must be at least -999999.99.";

export const TILLS_ENTER_CLOSED_AMOUNT_MAX_VALUE = "Your closing amount cannot exceed 999999.99.";

export const TILLS_ENTER_VALID_CLOSED_AMOUNT = "Please enter a valid closing amount.";

export const ACTIVE_TILLS_EXPORT_FILENAME = "Active Tills";

export const DEACTIVE_TILLS_EXPORT_FILENAME = "Deactive Tills";

export const TILLS_NOT_FOUND = "There are no active Tills.";

export const DEACTIVE_TILLS_NOT_FOUND = "There are no inactive Tills.";

// Pay In Report 

export const PAYIN_TITLE = "PAY In";

export const PAY_IN_REPORT_ROUTE = "/pay-in/list";

export const PAY_IN_CREATE_ROUTE = "/pay-in/create";

export const PAY_IN_EDIT_ROUTE = "/pay-in/edit/";

export const PAY_IN_REACTIVE_SUCCESS = "Pay in activated successfully.";

export const PAY_IN_DEACTIVE_SUCCESS = "Pay in  deactivated successfully.";

export const PAY_IN_TILL_IS_OPENED = "Sorry, could not edit pay in as it's till is opened.";

export const PAY_IN_LIST_REPORT_ROUTE = "pay-in-list";

export const PAY_IN_DEACTIVE_LIST_REPORT_ROUTE = "pay-in-deactive-list-paginate";

export const PAY_IN_DEACTIVATE_REPORT_ROUTE = "pay-in-deactivate/";

export const PAY_IN_REACTIVATE_REPORT_ROUTE = "pay-in-reactive/";

export const PAY_IN_GET_ALL_LOCATION_ROUTE = "get-all-locations";

export const PAY_IN_GET_DATA_ROUTE = "pay-in-get-data";

export const PAY_IN_ROUTE = "pay-in";

export const PAY_IN_REPORTS_ROUTE = "pay-in/";

export const PAY_IN_EDIT_REPORT_ROUTE = "/edit";

export const PAY_IN_CREATE = "pay-in-create";

export const PAY_IN_LIST = "pay-in-list";

export const PAY_IN_EDIT = "pay-in-edit";

export const PAY_IN_DEACTIVATE = "pay-in-deactivate";

export const PAY_IN_REACTIVATE = "pay-in-reactivate";

export const PAY_IN_SELECT_POS_STATION = "You must select a pos station.";

export const PAY_IN_SELECT_USER = "You must select a user.";

export const PAY_IN_ENTER_AMOUNT = "Please enter an amount.";

export const PAY_IN_MIN_VALUE = "Your amount must be at least 0.01.";

export const PAY_IN_MAX_VALUE = "Your amount cannot exceed 999999.99.";

export const PAY_IN_VALID_AMOUNT = "Please enter a valid amount.";

export const PAY_IN_ENTER_REASON = "Please enter a reason.";

export const PAY_IN_REASON_GREATER_VALUE = "Your reason not be greater than 5000 characters long.";

export const ACTIVE_PAY_IN_EXPORT_FILENAME = "Active PayIn";

export const DEACTIVE_PAY_IN_EXPORT_FILENAME = "Deactive PayIn";

export const PAY_IN_NOT_FOUND = "There are no active Pay In.";

export const DEACTIVE_PAY_IN_NOT_FOUND = "There are no inactive Pay In.";

// Pay Out Report 

export const PAYOUT_TITLE = "Payout";

export const PAYOUT_DEACTIVE_SUCCESS = "Payout deactivated successfully.";

export const PAYOUT_REACTIVE_SUCCESS = "Payout activated successfully.";

export const PAY_OUT_REPORT_ROUTE = "/pay-out/list";

export const PAYOUT_CREATE = "/pay-out/create";

export const PAYOUT_VIEW = "/payout/view/";

export const PAYOUTLIST_API_ROUTE = "pay-out-list";

export const PAYOUT_DEACTIVE_LIST_API_ROUTE = "pay-out-deactive-list-paginate";

export const PAYOUT_GETDATA_API_ROUTE = "get-all-locations";

export const PAYOUT_DEACTIVE_PAYOUT_API_ROUTE = "pay-out-deactivate/";

export const PAYOUT_REACTIVE_PAYOUT_API_ROUTE = "pay-out-reactive/";

export const PAYOUT_EDIT_ALL_LIST_ROUTE = "pay-out-edit-all-list";

export const PAYOUT_GET_POSDATA_API_ROUTE = "pay-out-get-data";

export const PAYOUT_ROUTE = "pay-out";

export const PAYOUT_SHOW_ROUTE = "pay-out-view/";

export const PAYOUT_EDIT_ROUTE = "/pay-out/edit/";

export const PAY_OUT_EDIT_ROUTE = "/edit";

export const PAY_OUT_UPDATE_EDIT_ROUTE = "pay-out/";

export const PAYOUT_UPDATE_API_PERMISSION = "pay-out-edit";

export const PAY_OUT_EXPORT_ROUTE = "export-payout-report-data";

export const AMOUNT_REQUIRED = "Please enter an amount.";

export const REASON_REQUIRED = "Please enter a reason.";

export const POS_STATION_REQUIRED = "You must select pos station";

export const EMPLOYEE_REQUIRED = "You must select user";

export const EXPORT_TITLE = "Export";

export const PAYOUT_PLEASE_ENTER_A_VALID_AMOUNT =
  "Please enter a amount";

export const PAYOUT_AMOUNT_MAX_ERROR =
  "Your amount cannot exceed 999999.99.";

export const PAYOUT_AMOUNT_MIN_ERROR =
  "Your amount must be at least 0.01.";

  export const ACTIVE_PAYOUT_EXPORT_FILENAME = "Active Payout";

export const DEACTIVE_PAYOUT_EXPORT_FILENAME = "Deactive Payout";

export const TILL_IS_OPEN = "Sorry, could not deactivate pay out as it's till is opened.";

export const PAYOUT_NOT_FOUND = "There are no active Pay Out.";

export const DEACTIVE_PAYOUT_NOT_FOUND = "There are no inactive Pay Out.";  

// ======== POS Module Constants ========

export const POS_MODULE = "POS Stations";

export const POS_LIST = "/pos-stations/list";

export const POS_LIST_API_PERMISSION = "pos-station-list";

export const POS_LIST_API_ROUTE = "pos-stations";

export const POS_CREATE = "/pos-stations/create";

export const POS_CREATE_API_ROUTE = "pos-station"; // post method

export const POS_NAME_REQUIRED = "Name  is required";

export const POS_NAME_LESS_OR_EQUAL_191_CHARACTER =
  "POS Station name can not exceed 191 characters.";

export const POS_LOCATION_REQUIRED = "Location is required.";

export const POS_CREATE_API_PERMISSION = "pos-station-create";

export const POS_GET_LOCATIONS_API_ROUTE = "pos-station-get-all-location";

export const POS_DEACTIVE_LIST_API_ROUTE =
  "pos-stations-deactive-list-paginate";

export const POS_DEACTIVE_API_ROUTE = "pos-station-deactivate";

export const POS_DEACTIVE_API_PERMISSION = "pos-station-deactivate";

export const POS_REACTIVE_API_ROUTE = "pos-station-reactive";

export const POS_REACTIVE_API_PERMISSION = "pos-station-reactivate";

export const POS_EDIT_GET_DATA_API_ROUTE = "pos-station"; //get method

export const POS_EDIT = "/pos-stations/edit/";

export const POS_EDIT_API_PERMISSION = "pos-station-edit";

export const POS_EDIT_API_ROUTE = "pos-station"; // put method

export const POS_EDIT_SETTING = "/pos-stations/settings/edit/";

export const POS_EDIT_API_ROUTE_SETTING = "setting/editSetting";

export const POS_UPDATE_SETTING_API_ROUTE_SETTING = "setting";

export const POS_EDIT_SETTINGS_API_PERMISSION = "change-settings";

export const IP_REGEX =
  /^tcp:\/\/+(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i;

export const POS_NOT_FOUND = "There are no active POS Stations.";

export const DEACTIVE_POS_NOT_FOUND = "There are no inactive POS Stations.";

export const POS_VIEW_SETTING = "/pos-stations/settings/view/";

export const POS_SETTING_VIEW_API_ROUTE_SETTING = "setting/viewSetting";

export const POS_SETTING_VIEW_API_PERMISSION = "setting-view";

export const POS_DEACTIVATE_API_ROUTE = "pos-station-deactivate";

export const POS_DEACTIVATE_PERMISSION = "pos-station-deactivate";

export const POS_DEACTIVATE_LIST_API_ROUTE =
  "pos-stations-deactive-list-paginate";

export const POS_REACTIVATE_API_ROUTE = "pos-station-reactive";

export const POS_REACTIVATE_PERMISSION = "pos-station-reactivate";

export const POS_REACTIVE_SUCCESS = "Pos Station Reactivated Succesfully.";

export const POS_DEACTIVE_SUCCESS = "Pos Station Reactivated Succesfully.";

export const POS_SETTING_DUPLICATE = "/pos-stations/settings/duplicate/";

export const POS_SETTING_DUPLICATE_API_ROUTE = "pos-stations/duplicate";

export const POS_SETTING_DUPLICATE_PERMISSION = "pos-station-duplicate";

//  total daily sales report

export const LOCATION_IS_REQUIRED = "Please select a location.";

export const CUSTOMER_IS_REQUIRED = "Please select a customer.";

export const FORM_DATE_IS_REQUIRED = "Please select a from date.";

export const TO_DATE_IS_REQUIRED = "Please select a to date.";

// =========Coupon Module Constants==============
export const COUPON_MODULE = "Coupon";

export const COUPON_VIEW = "/coupon/view/";

export const COUPON_LIST = "/coupon/list";

export const COUPON_CREATE = "/coupon/create";

export const COUPON_EDIT = "/coupon/edit/";

export const COUPON_CREATE_PERMISSION = "coupon-create";

export const COUPON_CREATE_API_ROUTE = "coupon-create";

export const COUPON_VIEW_ALL_PERMISSION = "coupon-list";

export const COUPON_VIEW_PERMISSION = "coupon-view";

export const COUPON_EDIT_PERMISSION = "coupon-edit";

export const COUPON_DEACTIVATE_PERMISSION = "coupon-deactivate";

export const COUPON_REACTIVATE_PERMISSION = "coupon-reactivate";

export const COUPON_PRODUCT_CREATE_VIEW_API_ROUTE = "coupon-product-paginate";

export const DISCOUNT_VALUE_REQUIRED = "Please enter a discount value.";

export const DISCOUNT_VALUE_NOT_VALID = "Please enter a valid discount value.";

export const PRODUCT_CSV_EXPORT = "Products";

export const DISCOUNT_VALUE_MIN_VALUE =
  "Your discount value must be at least 0.01.";

export const DISCOUNT_VALUE_MAX_VALUE =
  "Your discount value cannot exceed 999999.99.";

export const DISCOUNT_VALUE_MORE_THEN_100 =
  "Your discount value cannot exceed 100.";

export const DISCOUNT_TYPE_REQUIRED = "Please select a discount type.";

export const AUDIENCE_TYPE__REQUIRED = "Please select an audience type.";

export const COUPON_TYPE_REQUIRED = "Please select a coupon type.";

export const MIN_MAX_QTY_PER_COP = "Min value should be 1";

export const MAX_MAX_QTY_PER_COP = "Max value should be 999999";
export const MAX_QTY_PER_COP_IS_INVALID = "Please enter a valid Maximum Quantity Per Coupon.";
export const MAX_QTY_PER_COP_IS_INT =
  "Max Quantity Per Coupon must be an integer";

export const MAX_QTY_PER_COP_REQ = "Max Quantity Per Coupon is required";

export const MIN_AMOUNT_REQ = "Minimum amount is required";

export const MIN_AMOUNT_MUST_BE_NUMBER = "Minimum amount must be a number";

export const MAX_VSL_OF_MIN_AMOU = "Maximum value is 999999.99";

export const MAX_VAL_OF_MIN_AMOU = "Your minimum amount cannot exceed 999999.99.";

export const MIN_VAL_OF_MIN_AMOU = "Your minimum amount must be at least 0.01.";

export const MIN_MAX_VAL_TOAST = "Discount value must be less or equal to minimum amount.";

export const DISC_VAL_GREATER_THAN_PROD_SALE_PRICE_TOAST = "Coupon's Discount value must be less or equal to product's sale price.";

export const COUPON_VIEW_ALL_API_ROUTE = "coupon-view-paginate";

export const COUPON_PDF_FOR_BARCODE_API_ROUTE =
  "generate-pdf-for-coupon-barcode";

export const COUPON_DOWN_IMAGE_BARCODE_API_ROUTE = "download-coupon-barcode";

export const COUPON_DEACTIVE_LIST_API_ROUTE = "coupons-deactive-list-paginate";

export const COUPON_DEACTIVE_API_ROUTE = "coupons-deactivate";

export const COUPON_REACTIVE_API_ROUTE = "coupons-reactive";

export const COUPON_REACTIVE_SUCCESS = "Coupon Reactivated Succesfully.";

export const Coupon_DEACTIVE_SUCCESS = "Coupon Deactivated Succesfully.";

export const COUPON_VIEW_API_ROUTE = "coupons-view";

export const COUPON_EDIT_API_ROUTE = "coupons/edit";

export const COUPON_UPDATE_API_ROUTE = "coupon-update";

export const S3_BUCKET_COUPON_IMAGE =
  "https://s3-us-west-1.amazonaws.com/snapstics-staging-file-storage/images/coupon_logo/";

  export const DEACTIVE_COUPON_EXPORT_FILENAME = "deactiveCoupon";

  export const ACTIVE_COUPON_EXPORT_FILENAME = "activeCoupon";  

  export const COUPON_NOT_FOUND = "There are no active Coupons.";

export const DEACTIVE_COUPON_NOT_FOUND = "There are no inactive Coupons.";

export const COUPON_PRODUCT_NOT_FOUND = "There are no Products.";
// ======== WebHooks Module constant ============================

export const WEBHOOKS_VIEW_ALL_API_ROUTE = "webhook_list";

export const WEBHOOKS_VIEW_ALL_PERMISSION = "webhook-list";

export const WEBHOOKS_DELETE_API_ROUTE = "webhook_delete";

export const WEBHOOKS_DELETE_SUCCESS = "Webhook Delete Succesfully.";

export const WEBHOOK_MODULE = "Webhook";

export const WEBHOOKS_DELETE_PERMISSION = "webhook-list";

export const WEBLOGS_VIEW_ALL_API_ROUTE = "webhook_logs_list";

export const WEBLOGS_VIEW_ALL_PERMISSION = "webhook-list";

export const LOCATION_FOR_CREATE_API_ROUTE = "webhook-get-all-location";

export const LOCATION_FOR_CREATE_PERMISSION = "webhook-create";

export const WEBHOOK_DELETED_SUCCESS = "Webhook deleted successfully.";

export const WEBHOOK_URL_PATTERN =
  "^(https?|ftp|file)://((localhost)|([a-zA-Z0-9]+(.[a-zA-Z0-9]+)+))(:(d+))?([/?#].*)?$";

export const WEBHOOK_URL_NOT_VALID = "Your url must be valid.";

export const TOKEN_VALUE_REQUIRED = "Please enter a token.";

export const API_NAME_VALUE_REQUIRED = "Please enter a api key name.";

export const API_KEY_VALUE_REQUIRED = "Please enter a api key value.";

export const WEBHOOK_TYPE_REQUIRED = "Please Select a  webhook type.";

export const WEBHOOK_URL_REQUIRED = "Please enter a webhook url.";

export const WEBHOOK_LOCATION_REQUIRED = "Please select a location.";

export const WEBHOOK_ADD = "webhook";

export const WEBHOOK_CREATE_API_PERM = "webhook-create";

export const WH_EXPORT_FILENAME = "webhook";

  export const WL_EXPORT_FILENAME = "webhookLogs";

  export const WEBHOOK_NOT_FOUND = "There are no any Webhooks.";

export const WL_NOT_FOUND =
  "There are no Webhook logs.";
// ======== Campaign Module Constants ========

export const CAMPAIGN_MODULE = "Campaign";

export const TARGETED = "Targeted";

export const GENERIC = "Generic";

export const EMAIL = "Email";

export const SMS = "SMS";

export const SMS_EMAIL_BOTH = "sms_email_both";

export const SCHEDULED_NOW = "scheduled_now";

export const SCHEDULED_LATER = "scheduled_later";

export const COUPON_TYPE_PRODUCT = "prod";

export const PRODUCT = "Product";

export const ORDER = "Order";

export const SELECT_SEGMENT = 'select_segment';

export const SELECT_ALL = 'select_all';

export const GET_CAMPAIGN_RELATED_DATE_ROUTE = "get-coupon-list";

export const SEND_TEST_EMAIL_ROUTE = "mktg-campaign-send-test-email";

export const GET_SMS_PLAN_DETAILS_ROUTE = "get-sms-plan-details";

export const CHECK_CAMPAIGN_SMS_AVAILABILITY_ROUTE = "check-campaign-sms-availability";

export const UPDATE_SMS_PLAN_SUBSCRIPTION_ROUTE = "camp-update-sms-plan";

export const CREATE_CAMPAIGN_ROUTE = "mktg-campaign-create";

export const GET_CAMPAIGN_LIST_ROUTE = "getAllCampaigns";

export const CAMPAIGN_DEACTIVATE_ROUTE = "mktg-campaign-deactivate";

export const GET_DEACTIVE_CAMPAIGN_LIST_ROUTE = "deactive-campaign-list";

export const GET_CAMPAIGN_EDIT_DATA_ROUTE = "mktg-campaign-edit";

export const CAMPAIGN_REACTIVATE_ROUTE = "mktg-campaign-reactive";

export const CHECK_CAMPAIGN_EDIT_SMS_AVAILABILITY_ROUTE = "check-edit-campaign-sms-availability";

export const CAMPAIGN_UPDATE_ROUTE = "mktg-campaign-update";

export const CAMPAIGN_PUBLIC_PAGE_ROUTE = "get-mktg-campaign-data";

export const CAMPAIGN_CREATE_API_PERMISSION = "campaign-create";

export const CAMPAIGN_LIST_PERMISSION = 'campaign-list';

export const CAMPAIGN_DEACTIVATE_PERMISSION = 'campaign-deactivate';

export const CAMPAIGN_REACTIVATE_PERMISSION = 'campaign-reactivate';

export const CAMPAIGN_EDIT_PERMISSION = 'campaign-edit';

export const START_DATE_REQUIRED = "Please select a start date.";

export const END_DATE_REQUIRED = "Please select an end date.";

export const SCHEDULED_SEND_DATE_TIME_REQUIRED = "Please select a send date & time.";

export const MAX_USAGE_REQUIRED = "Please enter a max usage per reward customer.";

export const MAX_USAGE_INVALID_ERROR = "Your max usage per reward customer must be at least 1 and cannot exceed 999999";

export const MAX_USAGE_MIN_ERROR = "Your max usage per reward customer must be at least 1.";

export const MAX_USAGE_MAX_ERROR = "Your max usage per reward customer cannot exceed 999999.";

export const COUPON_ALREADY_ADDED_ERROR = "This coupon is already added in campaign.";

export const COUPON_ORDER_TYPE_ERROR = 'You can add only one order type coupon in the campaign.';

export const SUBJECT_REQUIRED = "Please enter a subject.";

export const SUBJECT_LENGTH_GREATER_THEN_191 = "Your subject not be greater than 191 characters long.";

export const PREDEFINED_BUSINESS_LOGO = 'https://sanepos-prod-file-storage.s3-us-west-1.amazonaws.com/images/email_template_images/predefine_template/company_logo.png';

export const PREDEFINED_BUSINESS_NAME = 'Company Name';

export const AUDIENCE_TYPE_REQUIRED = "Please select an audience type.";

export const SEGMENT_IS_REQUIRED = "Please select a segment.";

export const MARKETING_TYPE_SCREEN = "MARKETING_TYPE_SCREEN";

export const CAMPAIGN_TYPE_SCREEN = "CAMPAIGN_TYPE_SCREEN";

export const BASIC_DETAILS_SCREEN = "BASIC_DETAILS_SCREEN";

export const EMAIL_TEMPLATE_SELECTION_SCREEN = "EMAIL_TEMPLATE_SELECTION_SCREEN";

export const EMAIL_DESIGN_SCREEN = "EMAIL_DESIGN_SCREEN";

export const SMS_DESIGN_SCREEN = "SMS_DESIGN_SCREEN";

export const AUDIENCE_SCREEN = "AUDIENCE_SCREEN";

export const SMS_DETAILS_REQUIRED = "Please enter a sms text.";

export const CARD_DETAILS_GET_ERROR = "Sorry, could not get card details.";

export const UPGRADE_PLAN = "upgrade_plan";

export const CAMPAIGN_CREATE_FOR_NEXT_MONTH_WITH_CANCEL_PLAN_ERROR = 'You can not set schedule date as next month as you unsubscribe SMS/Email plan.';

export const PLAN_SUBSCRIPTION_REQUIRED = 'Please select an SMS/Email plan to continue.';

export const FORM_FILLING_ERROR = 'To continue, Please fill all required details.';

export const CAMPAIGN_REACTIVE_SUCCESS = "Campaign Reactivated Succesfully.";

export const CAMPAIGN_DEACTIVE_SUCCESS = "Campaign Deactivated Succesfully.";

export const USER_WITH_MS_CUST_ID_GET_ERROR = 'To continue with this process, ask system administrator to add your data to the service.';

export const CAMPAIGN_REACTIVE_FOR_NEXT_MONTH_WITH_CANCEL_PLAN_ERROR = 'You can not reactivate this campaign, as you are unsubscribe SMS/Email plan.';

export const MS_CARD_DETAILS_GET_ERROR = 'Sorry, could not get customer card details from microservice.';

export const MICROSERVICE_RESPONSE_GET_ERROR = "Sorry, could not get customer plan subscription and card details from microservice.";
export const CAMPAIGN_DASHBOARD = "/campaign/dashboard/";
export const CHECK_CAMAPIGN_EXIST = "check-campaign-exist";
export const CAMPAIGN_DASHBOARD_VIEW_PERMISSION = 'campaign-dashboard-view';
export const NO_COUPONS_ATTACHED = "No coupons are attached with campaign.";
export const CAMPAIGN_VIEW_API = 'mktg-campaign-view';
export const CAMPAIGN_DASHBOARD_SMS_API = 'get-camp-db-sms-sent-data';
export const CAMPAIGN_DASHBOARD_EMAIL_API = 'get-camp-db-email-sent-data';
export const CAMPAIGN_DASHBOARD_UNSUBSCRIBED_API = 'get-camp-db-unsubscribed-email-sms-data';
export const CAMPAIGN_DASHBOARD_REVENUE_API = 'get-camp-db-total-revenue-data';
export const CAMPAIGN_DASHBOARD_COUPON_REEDEM_API = 'get-camp-db-coup-redeem-data';
export const CAMPAIGN_VIEW_USER_API = 'get-user-list';
export const CAMPAIGN_USER_TIME_USED = 'get-coupon-time-used-details';
export const NO_CAMPAIGNS_FOUND = "There are no active Campaigns.";
export const DEACTIVE_CAMPAIGNS_NOT_FOUND = "There are no inactive Campaigns.";
export const CAMPAIGNS_USER_NOT_FOUND = "There are no users attached with this campaign.";
export const CAMPAIGN_USER__CSV = "Campaign User";
export const ACTIVE_CAMPAIGN_EXPORT_FILENAME = "Active Campaigns";
export const DEACTIVE_CAMPAIGN_EXPORT_FILENAME = "Inactive Campaigns";

export const BLANK_TEMPLATE_RE_HTML = '<re-html><re-head><re-title>Message</re-title></re-head><re-body><re-header></re-header></re-body></re-html>';
export const EMAIL_TEMPLATE_EMPTY = "There is no email template available.";
export const MARK_DASHBOARD_PERMISSION = "marketing-dashboard-view";
export const MARK_DASHBOARD_TOTAL_COUPON_REEDEMED_API = "get-total-coupons_redeem-details";
export const MARK_DASHBOARD_TOTAL_REVENUE_API = "get-total-revenue-details";
export const MARK_DASHBOARD_TOTAL_EMAIL_SENT_API = 'get-total-email-sent-details';
export const MARK_DASHBOARD_TOTAL_SMS_SENT_API = 'get-total-sms-sent-details';
export const MARK_DASHBOARD_TOTAL_UNSUB_EMAIL_API = 'get-total-email-unsubscribed-details';
export const MARK_DASHBOARD_TOTAL_UNSUB_SMS_API = 'get-total-sms-unsubscribed-details';
export const MARK_DASHBOARD_TOP_PERFORMING_COUPON_API = 'get-top-performing-coupon-details';

// ======== Location Module Constants ========

export const LOCATION_MODULE = "Location";

export const LOCATION_LIST_API_PERMISSION = "location-list";

export const LOCATION_DATA_FOR_LIST_API_ROUTE = "admin_locations";

export const LOCATION_CREATE = "/locations/create";

export const LOCATION_DATA_API_ROUTE = "admin_locations";

export const LOCATION_CREATE_PERMISSION = "location-create";

export const LOCATION_NAME_REQUIRED = "Location Name  is required";

export const LOCATION_NAME_LESS_THEN_OR_EQUAL_255_CHARACTER =
  "Location name can not exceed 255 characters.";

export const LOCATION_ADDRESS_REQUIRED = "Address is required";
export const LOCATION_COUNTRY_REQUIRED = "Country is required";
export const LOCATION_STATE_REQUIRED = "State is required";
export const LOCATION_TIMEZONE_REQUIRED = "Timezone is required";
export const LOCATION_TYPE_REQUIRED = "Location Type is required";

export const LOCATION_ZIP_REQUIRED = "Zipcode is required";

export const LOCATION_ZIP_VALID = "Please enter valid zipcode";

export const LOCATION_ADDRESS_LESS_THEN_OR_EQUAL_5000_CHARACTER =
  "Location address can not exceed 5000 characters.";

export const LOCATION_CONTACT_LESS_THEN_OR_EQUAL_255_CHARACTER =
  "Location contact person can not exceed 255 characters.";

export const LOCATION_CITY_LESS_THEN_OR_EQUAL_191_CHARACTER =
  "City can not exceed 191 characters.";

export const LOCATION_ZIP_MUST_BE_2_DIGITS =
  "Zipcode must be at least 2 digit.";

export const LOCATION_ZIP_MUST_BE_10_DIGITS =
  "Zipcode must be at least 10 digit.";

export const LOCATION_CITY_REQUIRED = "City is required.";

export const PHONE_REQUIRED = "Phone Number is required.";

export const LOCATION_PHONE_MUST_BE_5_DIGITS =
  "Location phone must be at least 5 digit.";

export const LOCATION_PHONE_LESS_THAN_15_DIGITS =
  "Location phone must can not exceed 15 digit.";

export const LOCATION_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER =
  "Your email can not exceed 255 characters.";

export const LOCATION_PHONE_NOT_VALID = "Please enter a valid phone number.";

export const LOCATION_CREATE_API_ROUTE = "admin_location_create_basic";

export const LOCATION_SETTING_OPTIONS_PAGE = "/location_setting_options/";

export const IDEAL_TIME_REQUIRED = "Please enter idle time.";
export const IDEAL_TIME_VALID = "Your idle time must be integer.";
export const IDEAL_TIME_MIN_2 = "Your idle time must be at least 2 minutes.";
export const IDEAL_TIME_MAX_60 = "Your idle time can not exceed 60 minutes.";

export const EXTRA_ITEM_THRESHOLD_REQUIRED =
  "Please enter extra item permission threshold.";

  export const EXTRA_ITEM_THRESHOLD_VALID =
  "Please enter valid extra item threshold.";

  export const MIN_DELIVERY_AMOUNT_VALID_ERROR =
  "Please enter valid minimum order amount.";

export const EXTRA_ITEM_THRESHOLD_MAX =
  "Your extra item permission threshold cannot exceed 999999.99.";

export const EXTRA_ITEM_THRESHOLD_MIN =
  "Your extra item permission threshold must be at least 0.";

export const LOCATION_ACCOUNT_SETTINGS_CREATE_API_ROUTE =
  "admin_location_create_account_settings";

export const LOCATION_CREATE_REWARD = "/#/create-reward-card";

export const PROD_TEMPLATE_OPTIONS = [
  {
    image: "assets/images/bwb.png",
    name: "Bordered",
    description: "",
    value: "BWB",
  },
  {
    image: "assets/images/bwob.png",
    name: "Without Border",
    description: "",
    value: "BWOB",
  },
  {
    image: "assets/images/rwb.png",
    name: "With Reward + Border",
    description: "",
    value: "RWB",
  },
  {
    image: "assets/images/rwob.png",
    name: "With Reward",
    description: "",
    value: "RWOB",
  },
];

export const LOCATION_PRICING_TEMPLATE_CREATE_API_ROUTE =
  "admin_location_create_pricing_template";

export const LOCATION_REWARD_SETTING_CREATE_API_ROUTE =
  "admin_location_create_reward_setting";

export const REWARD_FEILD_TYPES = [
  {
    name: "Text",
    value: "text",
  },
  {
    name: "Date",
    value: "date",
  },
  {
    name: "Number",
    value: "number",
  },
];
export const LOCATION_ACCOUNT_SETTINGS = "Location Account Setting";

export const FIELD_NAME_REQUIRED = "Please enter a field name.";
export const FIELD_TYPE_REQUIRED = "Please select a field type.";
export const FIELD_NAME_LENGTH_GREATER_THAN_191 =
  "Your field name not be greater than 191 characters long.";
export const RECEIPT_HEADER_1_REQUIRED = "Please enter a receipt header 1.";
export const RECEIPT_HEADER_2_REQUIRED = "Please enter a receipt header 2.";
export const RECEIPT_FOOTER_1_REQUIRED = "Please enter a receipt footer 1.";
export const RECEIPT_FOOTER_2_REQUIRED = "Please enter a receipt footer 2.";

export const RECEIPT_HEADER_1_MAX_999 =
  "Receipt Header 1 can not exceed 999 characters.";
export const RECEIPT_HEADER_2_MAX_999 =
  "Receipt Header 2 can not exceed 999 characters.";
export const RECEIPT_FOOTER_1_MAX_999 =
  "Receipt Footer 1 can not exceed 999 characters.";
export const RECEIPT_FOOTER_2_MAX_999 =
  "Receipt Footer 2 can not exceed 999 characters.";

export const REPORTING_EMAIL_REQUIRED = "Email Address is required";
export const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export const LOCATION_EDIT = "/location/edit/";

export const LOCATION_UPDATE_PERMISSION = "location-edit";

export const LOCATION_DEACTIVATE_API = "deactive_location";
export const LOCATION_DEACTIVATE_PERMISSION = "location-deactivate";

export const LOCATION_REACTIVATE_API = "active_location";
export const LOCATION_REACTIVATE_PERMISSION = "location-reactivate";

export const LOCATION_DEACTIVATED_LOCATION_LIST_API = "deactive_location_list";

export const LOCATION_REACTIVE_SUCCESS = "Location Reactivated Succesfully.";
export const LOCATION_DEACTIVE_SUCCESS = "Location Deactivated Succesfully.";

export const NO_LOCATION_NOT_FOUND = "There are no active Locations.";

export const DEACTIVE_LOCATION_NOT_FOUND = "There are no inactive Locations.";


export const MONDAY = 'MON';
export const TUESDAY = 'TUE';
export const WEDNESDAY = 'WED';
export const THURSDAY = 'THU';
export const FRIDAY = 'FRI';
export const SATURDAY = 'SAT';
export const SUNDAY = 'SUN';

export const CLOSE_HOUR_ERROR = "Please enter close hour.";
export const OPEN_HOUR_ERROR = "Please enter open hour.";

export const SUNDAY_MIN_ERROR = 'Sunday opening hours can not be less than or equal to closing hours.';
export const MONDAY_MIN_ERROR = 'Monday opening hours can not be less than or equal to closing hours.';
export const TUESDAY_MIN_ERROR = 'Tuesday opening hours can not be less than or equal to closing hours.';
export const WEDNESDAY_MIN_ERROR = 'Wednesday opening hours can not be less than or equal to closing hours.';
export const THURSDAY_MIN_ERROR = 'Thursday opening hours can not be less than or equal to closing hours.';
export const FRIDAY_MIN_ERROR = 'Friday opening hours can not be less than or equal to closing hours.';
export const SATURDAY_MIN_ERROR = 'Saturday opening hours can not be less than or equal to closing hours.';

export const SUNDAY_TIME_ERROR = 'Looks like you have already added these operation hours for sunday. Enter different hours.';
export const MONDAY_TIME_ERROR = 'Looks like you have already added these operation hours for monday. Enter different hours.';
export const TUESDAY_TIME_ERROR = 'Looks like you have already added these operation hours for tuesday. Enter different hours.';
export const WEDNESDAY_TIME_ERROR = 'Looks like you have already added these operation hours for wednesday. Enter different hours.';
export const THURSDAY_TIME_ERROR = 'Looks like you have already added these operation hours for thursday. Enter different hours.';
export const FRIDAY_TIME_ERROR = 'Looks like you have already added these operation hours for friday. Enter different hours.';
export const SATURDAY_TIME_ERROR = 'Looks like you have already added these operation hours for saturday. Enter different hours.';

export const MIN_DELIVERY_AMOUNT_REQUIRED = "Please enter a minimum order amount.";
export const MIN_DELIVERY_AMOUNT_MIN_ERROR =
  "Your minimum order amount must be at least 0.01";
export const MIN_DELIVERY_AMOUNT_MAX_ERROR =
  "Your minimum order amount cannot exceed 999999.99";
export const MIN_DELIVERY_AMOUNT_MIN_MAX_ERROR =
  "Your minimum order amount must be at least 0.01 and cannot exceed 999999.99";

export const DELIVERY_LEAD_REQUIRED = "Please select a delivery lead time.";
export const PICKUP_LEAD_REQUIRED = "Please select a pickup lead time.";

export const SLOT_INTERVAL_REQUIRED = "Please select slot interval.";
export const DAYS_REQUIRED = "Please select day(s).";

// Promotions Module
export const PROMOTION_MODULE = "Promotions";

export const PRODUCT_PROMOTION_PATH = "/promotions/list";

export const PROMOTION_LIST_PERMISSION = "promotion-list";

export const BX1 = "BX1";
export const SXPY = "SXPY";
export const BXPY = "BXPY";

export const PROMTION_LOCATIONS_API = 'get-all-locations-for-promotions';

export const PROMOTION_CREATE = "/promotion/create";

export const PROMOTION_BX1_QTY_REQUIRED = "Please enter a quantity.";

export const PROMOTION_BX1_QTY_NOT_VALID = "Please enter a valid quantity.";

export const PROMOTION_BX1_QTY_MIN_VALUE = "Your quantity must be at least 1.";

export const PROMOTION_BX1_QTY_MAX_VALUE = "Your tax rate cannot exceed 999999.";

export const PROMOTION_CREATE_API_ROUTE = "promotion_create";

export const PROMOTION_CREATE_ADD_PRODUCT_API_ROUTE = 'promotion-valid-until-create';

export const PROMOTION_LIST_API_ROUTE = "viewAllPromotions";

export const PROMOTION_PRODUCT_CREATE_LIST = "get-location-products";

export const BUY_QTY_ERROR = "Please enter quantity.";
export const PRICE_ERROR = "Please enter sale price.";
export const BXPY_ERROR = 'Sorry, you can not add same quantity and promotion price as in combined.';

export const DEACTIVATED_PROMOTION_LIST_PERMISSION = "deactive_promotion_list";

export const PROMOTION_REACTIVE_SUCCESS = "Promotion Reactivated Succesfully.";

export const PROMOTION_DEACTIVE_SUCCESS = "Promotion Deactivated Succesfully.";

export const PROMOTION_NOT_FOUND = "There are no active Promotions.";

export const DEACTIVE_PROMOTION_NOT_FOUND = "There are no inactive Promotions.";

export const PROMOTION_CREATE_PERMISSION = "promotion-create";

export const PROMO_LOCATIONS_ARE_REQUIRED = "Please select location(s).";

export const PROMOTION_EDIT_PERMISSION = "promotion-edit";

export const PROMOTION_DEACTIVE_PERMISSION = "promotion-deactivate";

export const PROMOTION_REACTIVE_PERMISSION = "promotion-reactivate";

export const PROMOTION_DEACTIVATE_API = "deactive_promotion";
export const PROMOTION_REACTIVATE_API = 'active_promotion';

export const PROMOTION_GET_ALL_LOCATION = 'get-all-locations-for-promotions';

export const PROMOTION_EDIT = "/promotion/edit/";

export const PROMOTION_EDIT_API_ROUTE = 'showPromotionForView';

export const PROMOTION_UPDATE_API_ROUTE = 'editPromotion';

export const PROMO_LOCATION_PRO_EDIT = "get-location-products-edit";

export const PROMOTION_EDIT_ADD_PRODUCT_API_ROUTE = 'promotion-valid-until';

export const PROMOTION_ASSIGNED_PRODUCT_EDIT = "promotion-assigned-products"

export const PROMOTION_PRODUCT_ATTACHED_ERROR = 'This product is currently attached to the promotion id: ';

export const PROMOTION_PRODUCT_SOLD_BY_WEIGHT_ERROR = 'This product cannot be added in the promotion as it is sold by weight.';

export const ACTIVE_PROMO_EXPORT_FILENAME = "Active Promotions";

export const DEACTIVE_PROMO_EXPORT_FILENAME = "Inactive Promotions";

export const PROMO_PROD_EXPORT_FILENAME = "Promotions Products";

// ======== Profile Module Constants ========

export const PROFILE_IMAGE_API = 'admin-profile-pic';

export const PROFILE_MODULE = "Profile";

export const PROFILE_EDIT = "/profile";

export const PROFILE_DETAIL_API_ROUTE = "admin-profile";

export const PROFILE_DETAIL_API_PERMISSION = "profile-edit";

export const USER_NAME_REQUIRED = "Please enter a name.";

export const USER_NAME_LENGTH_GREATER_THAN_191 =
  "Your name not be greater than 191 characters long.";

export const USER_ADDRESS_NAME_REQUIRED = "Please enter an address line1.";

export const USER_ADDRESS_LENGTH_GREATE_THEN_5000 =
  "Your address not be greater than 5000 characters long.";

export const USER_PLEASE_ENTER_A_CITY = "Please enter a city.";

export const USER_CITY_LENGTH_GREATE_THEN_191 =
  "Your city not be greater than 191 characters long.";

export const USER_PLEASE_ENTER_A_PHONE_NUMBER = "Please enter a phone number.";

export const USER_PHONE_NUMBER_MUST_BE_LESS_THAN_15_DIGITS_LONG =
  "Your phone number must be less than 15 digits long.";

export const USER_PHONE_NUMBER_MUST_BE_GREATER_THAN_2_DIGITS_LONG =
  "Your phone number must be greater than 2 digits long.";

export const USER_IS_NOT_A_VALID_PHONE_NUMBER =
  "This is not a valid phone number.";

export const USER_EMAIL_LENGTH_GREATE_THEN_191 =
  "Your email can not exceed 191 characters.";

export const USER_BUSINESS_NAME_REQUIRED = "Please enter a business name.";

export const USER_BUSINESS_NAME_LENGTH_GREATER_THAN_191 =
  "Your business name not be greater than 191 characters long.";

export const USER_ZIP_CODE_MUST_BE_LESS_THAN_11_DIGITS_LONG =
  "Your zip code must be less than 11 digits long.";

export const USER_ZIP_CODE_MUST_BE_GREATER_THAN_2_DIGITS_LONG =
  "Your zip code must be greater than 2 digits long.";

export const USER_ZIP_CODE__REQUIRED = "please enter a zip.";

export const USER_COUNTRY_CODE__REQUIRED = "Your Country code is required";

export const USER_API_ID = "/id";

export const THIS_IS_NOT_A_VALID_COUNTRY_CODE = "This is not valid country code";

export const COUNTRY_CODE_MUST_BE_LESS_THAN_4_DIGITS_LONG = " Your country code must be less than 4 characters long";

export const THIS_IS_NOT_A_VALID_ZIPCODE =
  "This is not a valid zipcode.";

//  Total Category Sales

export const TOTAL_CATEGORY_SALE = "/reports/category-sales";

export const GET_ALL_LOCATION_AND_CATEGORY_ROUTE = "get-locations-and-category";

export const TOTAL_CATEGORY_SALE_PERMISSION = "report-total-category-sales";

export const TOTAL_CATEGORY_SALE_ROUTE = "get-category-report";

export const TOTAL_CATEGORY_SALE_EXPORT_API_ROUTE =
  "category-sales-report-export";

export const DOWNLOAD = "download";

export const XLS_FILE_NAME = "report.xls";

export const TOTAL_CATEGORY_SALE_CSV = "TotalDailyCategorySales";

// Total sales by POS Stations

export const TOTAL_SALE_BY_POS_STATION = "/reports/sales-by-pos";

export const TOTAL_SALE_BY_POS_STATION_PERMISSION = "report-sales-by-pos";

export const TOTAL_SALE_BY_POS_STATION_API_ROUTE = "get-sales-by-pos-data";

export const TOTAL_SALE_BY_POS_STATION_API_PERMISSION = "report-sales-by-pos";

export const TOTAL_SALE_BY_POS_STATION_EXPORT_API_ROUTE =
  "export-sales-by-pos-data";

  export const TOTAL_SALE_BY_POS_CSV = "TotalDailySalesByPosStation";
// ==============================Outstanding Gift Card Liability Report===========================

export const OUTSTATING_GIFT_CARD_LIABILITY =
  "/reports/outstanding-gift-card-liability";

export const OUTSTATING_GIFT_CARD_LIABILITY_PERMISSION =
  "report-outstanding-gift-card-liability";

export const OUTSTATING_GIFT_CARD_LIABILITY_PERMISSION_API_ROUTE =
  "global-route-check-permission";

export const OUTSTATING_GIFT_CARD_LIABILITY_API_ROUTE =
  "get-outstanding-gift-card-data";

export const OUTSTATING_GIFT_CARD_LIABILITY_EXPORT_API_ROUTE =
  "export-outstanding-gift-card-data";

export const OUTSTATING_GIFT_CARD_LIABILITY_PDF_API_ROUTE =
  "generate-outstanding-gift-card-pdf";

export const PDF_FILE_NAME = "report.pdf";

export const OUTSTATING_GIFT_CARD_REPORT_CSV = "outstandingGiftCardReport";

export const OUTSTATING_GIFT_CARD_NO_DATA = "There are no Outstanding Gift Card Liability.";

// ===========Extra Item Report Constant=================================

export const EXTRA_ITEM_ROUTE = "/reports/extra-item";

export const EXTRA_ITEM_REPORT_API_ROUTE = "get-extra-item-data";

export const EXTRA_ITEM_REPORT_API_PERMISSION = "report-extra-item";

export const EXTRA_ITEM_REPORT_EXPORT_API_ROUTE = "export-extra-item-data";

export const EXTRA_ITEM_REPORT_EXPORT_API_PERMISSION = "report-extra-item";

export const EXTRA_ITEM_CSV = "ExtraItemReport";

// ===========VARIABLE Item Report Constant=================================

export const VARIABLE_ITEM_ROUTE = "/reports/variable-item";

export const VARIABLE_ITEM_REPORT_API_ROUTE = "get-variable-item-data";

export const VARIABLE_ITEM_REPORT_API_PERMISSION = "report-variable-item";

export const VARIABLE_ITEM_REPORT_EXPORT_API_ROUTE = "export-variable-item-data";

export const VARIABLE_ITEM_REPORT_EXPORT_API_PERMISSION = "report-variable-item";

export const VARIABLE_ITEM_CSV = "VariableItemReport";
// ===========sales summary for all location Report Constant=================================

export const SALES_SUMMARY_FOR_ALL_LOCATIONS_ROUTE = "/reports/sales-summary-for-all-locations";

export const SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_API_ROUTE = "get-sales-summary-for-all-locations-data";

export const SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_API_PERMISSION = "report-sales-summary-for-all-locations";

export const SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_EXPORT_API_ROUTE = "export-sales-summary-for-all-locations-data";

export const SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_EXPORT_API_PERMISSION = "report-sales-summary-for-all-locations";

export const SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_PDF_API_PERMISSION = "report-sales-summary-for-all-locations";

export const SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_PDF_API_ROUTE = "generate-sales-summary-for-all-locations-pdf";

export const SALES_SUMMARY_FOR_ALL_LOCATIONS_REPORT_CSV = "SalesSummaryForAllLocation";
// ===========sales summary for single location Report Constant=================================

export const SALES_SUMMARY_FOR_SINGLE_LOCATIONS_ROUTE = "/reports/sales-summary-for-single-locations";

export const SALES_SUMMARY_FOR_SINGLE_LOCATIONS_REPORT_API_ROUTE = "get-sales-summary-for-single-location-data";

export const SALES_SUMMARY_FOR_SINGLE_LOCATIONS_REPORT_API_PERMISSION = "report-sales-summary-for-single-location";

export const SALES_SUMMARY_FOR_SINGLE_LOCATIONS_REPORT_EXPORT_API_ROUTE = "export-sales-summary-for-single-location-data";

export const SALES_SUMMARY_FOR_SINGLE_LOCATIONS_REPORT_EXPORT_API_PERMISSION = "report-sales-summary-for-single-location";

export const SALES_SUMMARY_FOR_SINGLE_LOCATIONS_REPORT_PDF_API_PERMISSION = "report-sales-summary-for-single-location";

export const SALES_SUMMARY_FOR_SINGLE_LOCATIONS_REPORT_PDF_API_ROUTE = "generate-sales-summary-for-single-location-pdf";

//  ===========Refund With Order Report Constant=================================
export const REFUND_WITH_ORDER_ROUTE = "/reports/refund-with-orders";

export const REFUND_WITH_ORDER_REPORT_API_ROUTE = "get-refund-report";

export const REFUND_WITH_ORDER_REPORT_API_PERMISSION = "report-refund";

export const REFUND_WITH_ORDER_REPORT_EXPORT_API_ROUTE = "export-refund-data";

export const REFUND_WITH_ORDER_REPORT_EXPORT_API_PERMISSION = "report-refund";

export const REFUND_WITH_ORDER_LINE_REPORT_API_ROUTE = "get-refund-line-details";

export const REFUND_WITH_ORDER_LINE_REPORT_API_PERMISSION = "report-refund";

export const REFUND_WITH_ORDER_CSV = "RefundOrders";
//  ===========Refund With Order Report Constant=================================
export const CUSTOMER_CREDIT_ACCOUNT_ROUTE = "/reports/customer-credit-account";

export const CUSTOMER_CREDIT_ACCOUNT_REPORT_API_ROUTE = "get-customer-credit-account-data";

export const CUSTOMER_CREDIT_ACCOUNT_REPORT_API_PERMISSION = "report-customer-credit-account";

export const CUSTOMER_CREDIT_ACCOUNT_REPORT_EXPORT_API_ROUTE = "export-customer-credit-account-data";

export const CUSTOMER_CREDIT_ACCOUNT_EXPORT_API_PERMISSION = "report-customer-credit-account";

export const GET_CUSTOMER_AND_LOCATION_API_ROUTE = "get-common-data";

export const GET_CUSTOMER_AND_LOCATION_API_PERMISSION = "report-customer-unused-credit";

export const CUSTOMER_CREDIT_ACCOUNT_REPORT_PDF_API_PERMISSION = "report-customer-credit-account";

export const ORDER_ALREADY_PAID = "This order is already paid.";

export const INVOICE_EMAIL_SEND_SUCCESSFULLY = "Invoice email sent successfully.";

export const CUSTOMER_NOT_HAVE_BALANCE = "Customer does not have credit balance to pay this order.";

export const CUSTOMER_CREDIT_ACCOUNT_REPORT_PDF_API_ROUTE = "generate-customer-credit-account-pdf";

export const GET_CUSTOMER_LATEST_BALANCE_API_ROUTE = "customers-view";

export const GET_CUSTOMER_LATEST_BALANCE_API_PERMISSION = "report-customer-credit-account";

export const EXT_REF_LENGTH_GREATE_THEN_191 =
  "Your external reference not be greater than 191 characters long.";

export const PAYMENT_AMOUNT_REQUIRED = "Please enter a payment amount.";

export const PAYMENT_AMOUNT_NOT_VALID = "Please enter a valid payment amount.";

export const PAYMENT_AMOUNT_MIN_VALUE = "Your payment amount must be at least 0.01.";

export const PAYMENT_AMOUNT_MAX_VALUE =
  "Your payment amount cannot exceed 999999.99.";

export const PAY_ORDER_UNPAID_AMOUNT_API_ROUTE = "pay-order-unpaid-amount";

export const PAY_ORDER_UNPAID_AMOUNT_API_PERMISSION = "report-customer-credit-account";

export const SEND_INVOICE_EMAIL_API_ROUTE = "send-invoice-email";

export const SEND_INVOICE_EMAIL_API_PERMISSION = "report-customer-credit-account";

export const CUSTOMER_CREDIT_ACCOUNT_REPORT_CSV = "CustomerCreditAccountReport";

export const CUSTOMER_CREDIT_ACCOUNT_REPORT_FROM_DATE_REQUIRED = "Please Select a From Date";
// export const CUSTOMER_NOT_HAVE_BALANCE = "Customer does not have credit balance to pay this order.";

// ========================= Taxes  Report Constant=================================

export const TAXES_REPORT_ROUTE = "/reports/taxes";

export const TAXES_REPORT_API_ROUTE = "get-taxes-report";

export const TAXES_REPORT_API_PERMISSION = "report-taxes";

export const TAXES_REPORT_EXPORT_API_ROUTE = "export-taxes-report";

export const TAXES_REPORT_EXPORT_API_PERMISSION = "report-taxes";

export const TAX_REPORT_CSV = "TaxReport";
// ========================= Cashback  Report Constant=================================

export const CASHBACK_REPORT_ROUTE = "/reports/cashback";

export const CASHBACK_REPORT_API_ROUTE = "get-cashback-data";

export const CASHBACK_REPORT_API_PERMISSION = "report-cashback";

export const CASHBACK_REPORT_EXPORT_API_ROUTE = "export-cashback-data";

export const CASHBACK_REPORT_EXPORT_API_PERMISSION = "report-cashback";

export const CASHBACK_CSV = "Cashback";
// ========================= Inventory Movement By Day Report Constant=================================

export const INVENTORY_MOVEMENT_BY_DAY_REPORT_ROUTE = "/reports/inventory";

export const INVENTORY_MOVEMENT_BY_DAY_REPORT_API_ROUTE = "get-inventory-report-data";

export const INVENTORY_MOVEMENT_BY_DAY_REPORT_API_PERMISSION = "report-inventary";

export const INVENTORY_MOVEMENT_BY_DAY_REPORT_EXPORT_API_ROUTE = "export-inventory-report-data";

export const INVENTORY_MOVEMENT_BY_DAY_REPORT_EXPORT_API_PERMISSION = "report-inventary";

export const MIN_QUA_NOT_VALID = "Please enter valid min quantity.";

export const MAX_QUA_NOT_VALID = "Please enter valid max quantity.";

export const LAST_RECEIVED_NOT_VALID = "Please enter valid last received day(s).";

export const PROD_GAP_NOT_VALID = "Please enter valid product gap day(s).";

export const MIN_QUA_GREATER_THAN_MAX_QUA = "Your Min quantity can not exceed Max quantity.";

export const LAST_RECEIVED_MUST_BE_AT_LEAST_1 = "Your last received day(s) must be at least 1.";

export const PRODUCT_GAP_MUST_BE_AT_LEAST_1 = "Your product gap day(s) must be at least 1.";

export const MAX_QUA_EMPTY = "Please enter maximum quantity.";

export const MIN_QUA_EMPTY = "Please enter minimum quantity.";

export const DAILY_INVENTORY_MOVEMENT_CSV = "DailyInventoryMovement";

// ========================= Inventory Summary Report Constant=================================

export const INVENTORY_SUMMARY_REPORT_CSV = "InventorySummary";

export const INVENTORY_SUMMARY_REPORT_ROUTE = "/reports/inventory-summary";

export const INVENTORY_SUMMARY_REPORT_API_ROUTE = "get-inventory-summary";

export const INVENTORY_SUMMARY_REPORT_API_PERMISSION = "report-inventary-summary";

export const ALL_LOCATION_AND_VENDOR_API_ROUTE = "get-loc-for-inventory-summary";

export const ALL_LOCATION_AND_VENDOR_API_PERMISSION = "report-inventary";

export const INVENTORY_SUMMARY_REPORT_EXPORT_API_ROUTE = "export-inventory-summary";

export const INVENTORY_SUMMARY_REPORT_EXPORT_API_PERMISSION = "report-inventary";

export const MIN_IN_STOCK_QUA_NOT_VALID = "Please enter valid min in stock.";
export const MIN_IN_STOCK_QUA_GREATER_THAN_NOT_VALID = "Your min in stock cannot be greater than 999999.99.";
export const MAX_IN_STOCK_QUA_GREATER_THAN_NOT_VALID = "Your max in stock cannot be greater than 999999.99.";

export const MAX_IN_STOCK_QUA_NOT_VALID = "Please enter valid max in stock.";

export const MIN_IN_STOCK_QUA_GREATER_THAN_MAX_QUA = "Your min in stock must be less than or equal to the max in stock.";

// ================= Inventory Trail Report Constant==================================
export const INVENTORY_TRAIL_REPORT_PERMISSION = "report-inventary-trail";

export const INVENTORY_TRAIL_REPORT_ROUTE = "/reports/inventory-trail";

export const INVENTORY_TRAIL_REPORT_API_ROUTE = "get-inventory-trail-data";

export const EXPORT_INVENTORY_TRAIL_REPORT_API_ROUTE = "export-inventory-trail-data";

export const OPERATION_TYPE_REQUIRED = "Please select a operation.";

export const INVENTORY_TRAIL_EXCEL_FILENAME = "export_inventory_trail_report.xlsx";

// ================= Shrinkage Report Constant==================================
export const SHRINKAGE_REPORT_PERMISSION = "report-inventary-shrinkage";

export const SHRINKAGE_REPORT_ROUTE = "/reports/shrinkage";

export const SHRINKAGE_REPORT_API_ROUTE = "get-shrinkage-data";

export const EXPORT_SHRINKAGE_REPORT_API_ROUTE = "export-shrinkage-data";

// export const OPERATION_TYPE_REQUIRED = "Please select a operation.";

export const SHRINKAGE_EXCEL_FILENAME = "shrinkage_report.xlsx";

export const SHRINKAGE_NOT_FOUND = "There are no Shrinkage.";

// ================= Total Sales By Payment Type Report Constant=================================

export const TOTAL_SALES_BY_PAYMENT_TYPE_REPORT_ROUTE = "/reports/sales-by-payment-type";

export const TOTAL_SALES_BY_PAYMENT_TYPE_REPORT_API_ROUTE = "get-sales-by-payment-type-data";

export const TOTAL_SALES_BY_PAYMENT_TYPE_REPORT_API_PERMISSION = "report-sales-by-payment-type";

export const ALL_LOCATION_AND_EMP_API_ROUTE = "get-locations-report-data";

export const ALL_LOCATION_AND_EMP_API_PERMISSION = "report-sales-by-payment-type";

// =================== Employees Work-time  Report Constant=================================

export const EMPLOYEES_WORK_TIME_REPORT_ROUTE = "/reports/employeesWorktime";

export const EMPLOYEES_WORK_TIME_REPORT_API_ROUTE = "get-employees-report-data";

export const UPDATE_EMPLOYEES_WORK_TIME_API_ROUTE = "update-employees-work-time";

export const EMPLOYEES_WORK_TIME_REPORT_API_PERMISSION = "report-employees-workedtime";

export const EMPLOYEES_WORK_TIME_REPORT_EXPORT_API_ROUTE = "employees-work-time-report-export";

export const EMPLOYEES_WORK_TIME_REPORT_EXPORT_API_PERMISSION = "report-employees-workedtime";

export const EMPLOYEES_WORK_TIME_REPORT_EXPORT_CSV = "EmployeesWorkTime";
//   ====================== master Admin Routing ======================

export const MASTER_ADMIN_WELCOME_TITLE = " WELCOME";

export const MASTER_ADMIN_WELCOME_PATH = "/master-admin-home-page";

export const MASTER_ADMIN_ROLES_TITLE = " ROLES";

export const MASTER_ADMIN_ROLES_PATH = "/master-admin-roles";

export const MASTER_ADMIN_LOCATIONS_TITLE = "LOCATIONS";

export const MASTER_ADMIN_LOCATIONS_PATH = "/master-admin-locations";

export const MASTER_ADMIN_POS_STATIONS_TITLE = "POS STATIONS";

export const MASTER_ADMIN_GLOBLE_SETTINGS_TITLE = "GLOBAL SETTINGS";
export const MASTER_ADMIN_LOCATION_CREATE = "/master-admin-location/create";
export const MASTER_ADMIN_LOCATION_EDIT = "/master-admin-location/edit/";
export const MASTER_ADMIN_LOCATION_SETTING_OPTIONS_PAGE = "/master_admin_location_setting_options/";
export const DUAL_CASH_DISCOUNT_REQUIRED = "Please enter a cash discount percentage.";
export const DUAL_CASH_DISCOUNT_NOT_VALID = "Please enter a cash discount percentage.";
export const DUAL_CASH_DISCOUNT_MIN_VALUE = "Your cash discount percentage must be at least 0.01.";
export const DUAL_CASH_DISCOUNT_MAX_VALUE = "Your cash discount percentage cannot exceed 100.";

// ========= Master Admin POS ============ //
export const MASTER_ADMIN_GLOBAL_MODULE = "Global Settings";
export const MASTER_ADMIN_POS_STATIONS_PATH = "/master-admin-pos-stations";
export const MASTER_ADMIN_POS_CREATE = "/master-admin-pos-station/create";

export const MASTER_ADMIN_POS_EDIT = "/master-admin-pos-stations/edit/";
export const MASTER_ADMIN_POS_VIEW_SETTING = "/master-admin-pos-stations/settings/view/";
export const MASTER_ADMIN_POS_SETTING_DUPLICATE = "/master-admin-pos-stations/settings/duplicate/";

// ========= Master Admin Roles ============ //
export const MASTER_ADMIN_ROLE_CREATE = "/master-admin-roles/create";
export const MASTER_ADMIN_ROLE_EDIT = "/master-admin-roles/edit/";

// ========= Master Admin Global Settings ============ //
export const MASTER_ADMIN_GLOBLE_SETTINGS_PATH = "/master-admin-global-settings";
export const MASTER_ADMIN_GLOBLE_SETTINGS_CREATE_PATH = "/master-admin-global-setting/create";
export const MASTER_ADMIN_GLOBLE_SETTINGS_EDIT_PATH = "/master-admin-global-setting/edit/";

export const GLOBAL_SETTING_CREATE_PERMISSION = 'global-setting-create';

export const GLOBAL_SETTING_FIELD_SORT_REQUIRED = "Please enter a field sort.";
export const GLOBAL_SETTING_FIELD_SORT_NOT_VALID = "Please enter a valid field sort.";
export const GLOBAL_SETTING_FIELD_SORT_MIN_VALUE = "Your field sort must be at least 1.";
export const GLOBAL_SETTING_FIELD_SORT_MAX_VALUE = "Your field sort cannot exceed 100.";
export const GLOBAL_SETTING_KEY_REQUIRED = "Please enter a setting key.";
export const GLOBAL_SETTING_KEY_LENGTH_GREATE_THEN_191 =
  "Your setting key should not be greater than 191 characters long.";
export const GLOBAL_SETTING_CATEGORY_REQUIRED = "Please select a category.";


export const GLOBAL_SETTING_EDIT_PERISSION = 'global-setting-edit';
export const GLOBAL_SETTING_LIST_PERISSION = 'global-setting-list';
export const GLOBAL_SETTING_REACTIVATE_PERISSION = 'global-setting-reactivate';
export const GLOBAL_SETTING_VIEW_PERISSION = 'global-setting-view';
export const GLOBAL_SETTING_DEACTIVATE_PERISSION = 'global-setting-deactivate';

export const VIEW_GLOBAL_SETTING = 'global-settings/view/';
export const VIEW_ALL_GLOBAL_SETTING = 'global-settings/view-all';
export const EDIT_GLOBAL_SETTING = 'global-settings/edit/';
export const REACTIVATE_GLOBAL_SETTING = 'global-settings/reactivate';

export const GLOBAL_SETTING_LIST_API = "global-setting-list";
export const GLOBAL_SETTING_CREATE_API = "global-setting";
export const GLOBAL_SETTING_CATEGORY_GET_API = "global-setting-category";
export const GLOBAL_SETTING_EDIT_API = "global-setting/edit/";
export const GLOBAL_SETTING_UPDATE_API = "global-setting";
export const GLOBAL_SETTING_DEACTIVATE_API = "global-setting-deactivate";
export const GLOBAL_SETTING_DEACTIVATE_LIST_API = "global-setting-deactive-list-paginate";
export const GLOBAL_SETTING_DEACTIVATE_REACTIVE_API = "global-setting-reactive";
export const ADD_GLOBAL_SETTING_CATEGORY_API = "global-setting-category-add";

export const ACTIVE_GLOBAL_SETTINGS_NOT_FOUND = "There are no active Global Settings.";
export const DEACTIVE_GLOBAL_SETTINGS_NOT_FOUND = "There are no inactive Global Settings.";

export const GS_REACTIVE_SUCCESS = "Global Setting reactivated successfully.";
export const GS_DEACTIVE_SUCCESS = "Global Setting deactivated successfully.";

export const OPTION_ERROR = "Please enter option label.";

export const ACTIVE_GS_EXPORT_FILENAME = "Active Global Settings";

export const DEACTIVE_GS_EXPORT_FILENAME = "Inactive Global Settings";

// ======== Segment Module Constants ========

export const SEGMENT_MODULE = "Segment";

export const SEGMENT_LIST = "/segments/list";

export const SEGMENT_LIST_API_ROUTE = "view-all-segment";

export const SEGMENT_LIST_API_PERMISSION = "segment-list";

export const SEGMENT_TYPE_GET_API_ROUTE = "get-segment-type";

export const SEGMENT_CREATE = "/segments/create";

export const SEGMENT_CREATE_API_ROUTE = "create-segment-reward-card";

export const SEGMENT_CREATE_API_PERMISSION = "segment-create";

export const SEGMENT_EDIT = "/segments/edit/";

export const SEGMENT_FETCH_API_ROUTE = "get-segment-for-edit";

export const SEGMENT_EDIT_API_PERMISSION = "segment-edit";

export const SEGMENT_EDIT_API_ROUTE = "editSegment";

export const SEGMENT_VIEW = "/segments/view/";

export const SEGMENT_VIEW_API_PERMISSION = "segment-view";

export const SEGMENT_DEACTIVE_LIST_API_ROUTE = "deactive_segments_list";

export const SEGMENT_DEACTIVE_LIST_API_PERMISSION = "segment-reactivate";

export const SEGMENT_DEACTIVATED_API_ROUTE = "deactive_segment";

export const SEGMENT_DEACTIVE_API_PERMISSION = "segment-deactivate";

export const SEGMENT_REACTIVATED_API_ROUTE = "active_segments";

export const SEGMENT_REACTIVE_API_PERMISSION = "segment-reactivate";

export const SEGMENT_REACTIVE_SUCCESS = "Segment Reactivated Succesfully.";

export const SEGMENT_DEACTIVE_SUCCESS = "Segment Deactivated Succesfully.";

export const SEGMENT_NOT_FOUND = "There are no active Segments.";

export const DEACTIVE_SEGMENT_NOT_FOUND = "There are no inactive Segments.";

export const SEGMENT_REWARD_CARD_LIST_GET_API_ROUTE = "get-reward-card-list";

export const SEGMENT_SMART_GROUP_FILTER_LIST_GET_API_ROUTE = "get-filter-list";

export const SEGMENT_SMART_GROUP_FILTER_SHOW_USER_API_ROUTE = "get-reward-card-list-for-display";

export const SEGMENT_MANUAL_SHOW_USER_API_ROUTE = "get-Segment-Reward-Card";

export const SEGMENT_NAME_FIELD_REQUIRED = "Please enter a name.";

export const SEGMENT_NAME_MAX_VALUE = "Your name not be greater than 191 characters long.";

export const SEGMENT_RANGE_NUMBER_FIELD_REQUIRED = "Please enter a number.";

export const SEGMENT_RANGE_VALID_NUMBER = "Please enter a valid number.";

export const SEGMENT_RANGE_NUMBER_MIN_ERROR = "Your number must be at least 1";

export const SEGMENT_RANGE_NUMBER_MAX_ERROR = "Your number cannot exceed 999999";

export const SEGMENT_MIN_VISIT_NUMBER_ERROR = "You must enter minimum visit.";

export const SEGMENT_MIN_VISIT_NOT_VALID_NUMBER_ERROR = "You must enter valid minimum visit.";

export const SEGMENT_MIN_VISIT_NUMBER_MIN_ERROR = "Your minimum visit must be at least 1.";

export const SEGMENT_MIN_VISIT_NUMBER_MAX_ERROR = "Your minimum visit cannot exceed 999999.";

export const SEGMENT_LAT_VISIT_NUMBER_FIELD_REQUIRED = "You must enter last visit.";

export const SEGMENT_LAT_VISIT_NOT_VALID_NUMBER = "You must enter valid last visit.";

export const SEGMENT_LAT_VISIT_NUMBER_MIN_ERROR = "Your last visit must be at least 1.";

export const SEGMENT_LAT_VISIT_NUMBER_MAX_ERROR = "Your last visit cannot exceed 999999.";

export const SEGMENT_SPENT_AMOUNT_FIELD_REQUIRED = "Please enter an amount.";

export const SEGMENT_SPENT_NOT_VALID_AMOUNT = "Please enter a valid amount.";

export const SEGMENT_SPENT_AMOUNT_MIN_ERROR = "Your amount must be at least 0.01";

export const SEGMENT_SPENT_AMOUNT_MAX_ERROR = "Your amount cannot exceed 999999.99";

export const SEGMENT_LOCATION_FIELD_REQUIRED = "Please select a location.";

export const SEGMENT_FILTER_TYPE_FIELD_REQUIRED = "Please select filter type.";

export const SEGMENT_CUSTOM_FILTER_FIELD_REQUIRED = "Please select custom filter.";

export const SEGMENT_PREDEFINED_FILTER_FIELD_REQUIRED = "Please select predefined filter.";

export const SEGMENT_FILTER_FIELD_REQUIRED = "You must select a filter.";

export const SEGMENT_CATEGORIES_REQUIRED = "You must select a category.";

export const SEGMENT_MANUAL_USERS_REQUIRED = "Please add users.";

export const SEGMENT_PREDEFINED_FILTER_REQUIRED = "Please select any predefined filter.";

export const SEGMENT_CAT_FILTER_REQUIRED = "Please select category filter.";

export const SEGMENT_SELECT_CATEGORY_EMPTY = "Please select any one category.";

export const SEGMENT_ENTER_RANGE_NUMBER_AND_SELECT_DURATION = "Please Enter Number and Select Duration.";

export const SEGMENT_ON_DATE_REQUIRED = "Please Select on date.";

export const SEGMENT_FROM_DATE_AND_TO_DATE_REQUIRED = "Please Select from date and to date.";

export const SEGMENT_DATE_TYPE_FILTER_REQUIRED = "Please select Date Type Filter.";

export const SEGMENT_ITEM_FILTER_REQUIRED = "Please select item filter.";

export const SEGMENT_SELECT_ITEM_EMPTY = "Please select any one item.";

export const SEGMENT_DURATION_FILTER_REQUIRED = "Please select duration.";

export const SEGMENT_MINIMUM_VIST_AND_LAST_VISIT_REQUIRED = "Please enter minimum visit and last visit.";

export const SEGMENT_MIN_AMOUNT_AND_MAX_AMOUNT_REQUIRED = "Please enter minimum amount and maximum amount.";

export const SEGMENT_YOUR_ENDING_AMOUNT_CAN_NOT_EXCEED_STARTING_AMOUNT= "Your Minimum Amount cannot be more than Maximum Amount.";

export const SEGMENT_SPENT_FILTER_REQUIRED = "Please select any spent filter.";

export const SEGMENT_CUSTOM_FILTER_REQUIRED = "Please select any custom filter.";

export const SEGMENT_SELECT_FILTER_FIELD_REQUIRED = "Please select filter.";

export const SEGMENT_SELECT_FILTER_TYPE_REQUIRED = "Please select any filter type.";

export const SEGMENT_SELECT_SEGMENT_TYPE_REQUIRED = "Please select any segment type.";

export const SEGMENT_REWARD_CARD_NOT_FOUND = "No more reward customer found.";

export const ACTIVE_SEGMENT_EXPORT_FILENAME = "Active Segments";

export const DEACTIVE_SEGMENT_EXPORT_FILENAME = "Deactive Segments";

export const SEGMENT_USER_EXPORT_FILENAME = "Segment Users";

// KDS Module

export const KDS_MODULE = "Kitchen Display Screen";
export const KDS_LIST = "/kds/list";
export const KDS_LIST_API_ROUTE = "kds";
export const KDS_LIST_API_PERMISSION = "kds-list";
export const KDS_CREATE_API_PERMISSION = "kds-create";
export const SEND_TYPE_REQUIRED = "Please select any one option.";
export const KDS_PHONE_REQUIRED = "Please enter phone number.";
export const KDS_CREATE = "/kds/create";
export const KDS_NOT_FOUND = "There are no active Kitchen Display Screens.";
export const KDS_PHONE_MUST_BE_5_DIGITS =
  "Phone must be at least 5 digit.";
export const KDS_PHONE_LESS_THAN_15_DIGITS =
  "Phone must can not exceed 15 digit.";
export const KDS_COPIED = "KDS Code Copied successfully.";
export const KDS_CREATE_API_ROUTE = "kds-resource";
export const ACTIVE_KDS_EXPORT_FILENAME = "Active KDS";

// CDS Module
export const CDS_MODULE = "Customer Display Screen";
export const POS_GET_CDS_LOCATIONS_API_ROUTE = "cds-get-all-location";
export const CDS_LIST = "/cds/list";
export const CDS_LIST_API_ROUTE = "cds";
export const CDS_LIST_API_PERMISSION = "cds-list";
export const CDS_CREATE_API_PERMISSION = "cds-create";
export const CDS_PHONE_REQUIRED = "Please enter phone number.";
export const CDS_CREATE = "/cds/create";
export const CDS_NOT_FOUND = "There are no active Customer Display Screens.";
export const CDS_PHONE_MUST_BE_5_DIGITS =
  "Phone must be at least 5 digit.";
export const CDS_PHONE_LESS_THAN_15_DIGITS =
  "Phone must can not exceed 15 digit.";
export const CDS_COPIED = "CDS Code Copied successfully.";
export const CDS_CREATE_API_ROUTE = "cds-resource";
export const ACTIVE_CDS_EXPORT_FILENAME = "Active CDS";

// ======== Billing Module Constants ========

export const BILLING_MODULE = "Billing";

export const BILLINGS_VIEW_DETAILS = "/billing/list";

export const BILLING_DETAIL_API_ROUTE = "get-billing-details";

export const BILLING_LIST_API_PERMISSION = "billing-list";

export const BILLING_VIEW_API_PERMISSION = "billing-view";

export const GET_MS_USER_DETAILS = "get-ms-user-details";

export const BILLING_VIEW_DETAIL_API_ROUTE = "get-billing-view-details";

export const GENERATE_BILLING_DETAILS_PDF = "generate-billing-details-pdf";

export const BILLING_VIEW = "billing-view";

export const VIEW = "/billing/view/";

export const UPDATE_BILLING_SETTINGS = "update-billing-settings";

export const UPDATE_SMS_PLAN = "update-sms-plan";

export const CREATE_NEW_CARD_API_ROUTE = "create-stripe-customer-card";

export const DELETE_CUSTOMER_CARD_API_ROUTE = "delete_card";

export const MAKE_CARD_AS_DEFAULT_API_ROUTE = "make_card_as_default";

export const STRIPE_PAYMENT_CARD_HOLDER_NAME_NOT_FOUND = "Please enter card holder name.";

export const STRIPE_PAYMENT_EMAIL_ADDRESS_NOT_FOUND = "Please enter email address.";

export const CARD_DETAILS_NOT_FOUND = "To select an SMS / Email plan, first you need to add a payment method.";

export const UPDATE_SMS_PLAN_API_ROUTE = "update-sms-plan";

export const CHANGE_PLAN_SUBSCRIPTION_API_ROUTE = "change-plan-subscription";

export const CARD_BRAND_VISA = "Visa";

export const CARD_BRAND_MASTERCARD = "MasterCard";

export const CARD_BRAND_AMERICAN_EXPRESS = "American Express";

export const CARD_BRAND_DISCOVER = "Discover";

export const CARD_BRAND_DINERS_CLUB = "Diners Club";

export const CARD_BRAND_UNION_PAY = "UnionPay";

export const CARD_BRAND_JCB = "JCB";

export const CARD_LOGO_VISA_PATH = "./assets/images/visa.png";

export const CARD_LOGO_MASTERCARD_PATH = "./assets/images/masterCard.png";

export const CARD_LOGO_AMERICAN_EXPRESS_PATH = "./assets/images/americanExpress.png";

export const CARD_LOGO_DISCOVER_PATH = "./assets/images/discover.png";

export const CARD_LOGO_DINERS_CLUB_PATH = "./assets/images/dinersClub.png";

export const CARD_LOGO_JCB_PATH = "./assets/images/jcb.png";

export const CARD_LOGO_UNION_PAY_PATH = "./assets/images/unionPay.png";

export const CARD_LOGO_DEFAULT_CARD_PATH = "./assets/images/defaultCard.png";

export const BILLING_LIST_DATA_NOT_FOUND = "There are no Billing Details.";

export const BILLING_HISTORY_NOT_FOUND = "There are no Billing History.";

export const BILLING_HISTORY_EXPORT_FILENAME = "Billing History";

// export const BILLING_VIEW_DETAILS = "billing/list";

// ======== Dashboard Module Constants ========

export const DASHBOARD_GET_LOCATION_API_ROUTE = "get-location";

export const DASHBOARD_VIEW_API_PERMISSION = "dashboard-view";

export const DASHBOARD_GET_TODAY_DASHBOARD_DETAILS_API_ROUTE = "get-today-dashboard-dtls/";

export const DASHBOARD_GET_PAYMENT_DTLS_API_ROUTE = "get-payment-details/";

export const DASHBOARD_GET_PAYMENT_METHOD_API_ROUTE = "get-payment-method/";

export const DASHBOARD_GET_REWARD_CUSTOMER_API_ROUTE = "reward-customers/";

export const DASHBOARD_GET_AVERAGE_BILL_PAYMENT_API_ROUTE = "average-bill-payment/";

export const DASHBOARD_GET_ITEM_BY_VOLUMN_API_ROUTE = "item-by-volumn/";

export const DASHBOARD_GET_ITEM_BY_SALES_API_ROUTE = "item-by-sales/";

export const DASHBOARD_GET_ONLINE_GIFT_CARD_SALES_DTLS_API_ROUTE = "get-online-gift-card-sales-details/";

export const DASHBOARD_GET_ITEM_BY_SALES_REPORT_API_ROUTE = "item-by-sales-report";

export const DASHBOARD_TOP_ITEM_SALES_EXPORT_FILENAME = "Items By Sales";

export const DASHBOARD_TOP_ITEM_VOLUME_EXPORT_FILENAME = "Items by Volume";

export const DASHBOARD_GET_ITEM_BY_VOLUME_REPORT_API_ROUTE = "item-by-volume-report";

export const DASHBOARD_LOCATION_REQUIRED = "Please select location.";

// Integration Module
export const INTEGRATION_MODULE = "Integration";
export const INTEGRATION_LIST_API_ROUTE = "integration_list";
export const INTEGRATION_LIST_PERMISSION = "integration-list";
export const INTEGRATION_CREATE = "/integration/create";
export const INTEGRATION_CREATE_API_PERMISSION = "integration-create";
export const INTEGRATION_CREATE_API_ROUTE = "integration";
export const INTEGRATIONS_NOT_FOUND = "There are no integrations found.";

//Public Page Gift Card 

export const GIFT_CARD_PUBLIC_DATA = "get-gift-card-data";

// data grid media query for responsive
export const MEDIA_QUERY_DATA_GRID_TABLE_MAX_WIDTH = "(max-width: 768px)";

export const SIDEBAR_CLOSE_ROUTE = ['/profile','/billing/list'];

export const RC_REWARD_EXPORT_FILENAME = "RewardsDetails";

//================ Report Excel And Pdf Filename Constant ================//

export const CATEGORY_SALES_EXCEL_FILENAME = "export_category_sale_report.xlsx";
export const DAILY_SALES_EXCEL_FILENAME = "sale-report.xlsx";
export const TOTAL_DAILY_SALES_EXCEL_FILENAME = "export_total_daily_sales_report.xlsx";
export const POS_SALES_EXCEL_FILENAME = "export_sales_by_pos_report.xlsx";
export const ORDER_DISCOUNT_EXCEL_FILENAME = "export_order_discount_report.xlsx";
export const TAXES_REPORT_EXCEL_FILENAME = "export_taxes_report.xlsx";
export const SALES_SUMMARY_ALL_LOC_EXCEL_FILENAME = "export_sales_summary_for_all_locations_report.xlsx";
export const SALES_SUMMARY_SINGLE_LOC_EXCEL_FILENAME = "export_sales_summary_for_single_location_report.xlsx"; 
export const DAILY_INVENTORY_MOVEMENT_EXCEL_FILENAME = "export_inventory_report.xlsx";
export const INVENTORY_SUMMARY_EXCEL_FILENAME = "export_inventory_summary_report.xlsx";
export const CUST_CREDIT_AC_EXCEL_FILENAME = "export_customer_credit_account_report.xlsx";
export const EMP_WORK_TIME_EXCEL_FILENAME = "export_employee_work_time_report.xlsx"
export const CASHBACK_EXCEL_FILENAME = "export_cashback_report.xlsx"
export const EXTRA_ITEM_EXCEL_FILENAME = "export_extra_item_report.xlsx"
export const VARIABLE_ITEM_EXCEL_FILENAME = "export_variable_item_report.xlsx"
export const REFUND_WITH_ORDER_EXCEL_FILENAME = "export_refund_report.xlsx"
export const RC_EXCEL_FILENAME = "export_reward_cards.xlsx"

// Price Book Module
export const PRICE_BOOK_MODULE = "Price Book";

export const PRICE_BOOK_TITLE = "PRICE BOOK";

export const PRICE_BOOK_LIST_TITLE = "Price Books";

export const PRICE_BOOK_LIST_PATH = "/price-books/list";

export const PRICE_BOOK_LOCATIONS_API = 'get-all-locations-for-price-books';

export const PRICE_BOOK_GET_PRODUCT_LIST = "get-location-products-for-price-book";

export const PRICE_BOOK_CREATE = "/price-books/create";

export const PRICE_BOOK_EDIT = "/price-books/edit/";

export const PRICE_BOOK_GET_API_ROUTE = 'get-all-details-for-price-book';

export const PRICE_BOOK_CREATE_PERMISSION = "price-book-create";

export const PRICE_BOOK_CREATE_API_ROUTE = "create-price-book";

export const PRICE_BOOK_NAME_REQUIRED = "Please enter a name.";

export const PRICE_BOOK_NAME_LENGTH_GREATE_THEN_191 =
  "Your name should not be greater than 191 characters long.";

export const PRICE_BOOK_LOCATIONS_ARE_REQUIRED = "Please select location(s).";

export const PRICE_BOOK_MANUAL_PRODUCT_LIST_ROUTE = "get-manual-product-list-for-price-book";

export const PRICE_BOOK_SALE_PRICE_REQUIRED = "Please enter price book amount.";

export const PRICE_BOOK_SALE_PRICE_MIN_VALUE = "Your price book amount must be at least 0.01.";

export const PPRICE_BOOK_PLEASE_ENTER_A_VALID_SALE_PRICE = "Please enter a valid price book amount.";

export const PRICE_BOOK_SALE_PRICE_MAX_VALUES = "Your price book amount cannot exceed 999999.99.";

export const PRICE_BOOK_EDIT_PERMISSION = "price-book-edit";

export const PRICE_BOOK_EDIT_API_ROUTE = "edit-price-book";

export const PRICE_BOOK_LIST_PERMISSION = "price-book-list";

export const PRICE_BOOK_DEACTIVATE_PERMISSION = "price-book-deactivate";
 
export const PRICE_BOOK_REACTIVATE_PERMISSION = "price-book-reactivate";

export const PRICE_BOOK_LIST_API_ROUTE = "getAllPricebooks";

export const PRICE_BOOK_NOT_FOUND = "There are no active Price Books.";

export const DEACTIVE_PRICE_BOOK_NOT_FOUND = "There are no inactive Price Books.";

export const ACTIVE_PRICE_BOOK_EXPORT_FILENAME = "Active Price Books";

export const DEACTIVE_PRICE_BOOK_EXPORT_FILENAME = "Deactive Price Books";

export const PRICE_BOOK_REACTIVE_SUCCESS = " activated successfully.";

export const PRICE_BOOK_DEACTIVE_SUCCESS = " deactivated successfully.";

export const PRICE_BOOK_DEACTIVE_LIST_API_ROUTE = "deactive-price-book-list";

export const PRICE_BOOK_DEACTIVE_API_ROUTE = "price-book-deactive";

export const PRICE_BOOK_REACTIVE_API_ROUTE = "price-book-reactive";

export const DEACTIVE_TITLES = "Deactivate Price Book";

export const DEACTIVE_DISCRIPTIONS = "Do you really want to deactive the Price Book ?";

export const PRICE_BOOK_PRODUCT_NOT_FOUND = "No more products found.";

export const PRICE_BOOK_PROD_EXPORT_FILENAME = "Price Book Products";