import { DeleteIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ButtonGroup,
  ChakraProvider,
  Divider,
  Flex,
  IconButton,
  Input,
  Spacer,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { Checkbox } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { endOfTodaysDay, startOfTodaysDay } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { Mtheme, theme } from "../../../theme/index";
import {
  getManualProductList,
  getProductsForSelectedLocation,
} from "./PriceBookService";

const PriceBookEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  const [locations, setLocations] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isMobileDialog] = useMediaQuery("(max-width: 992px)");
  const [type, setSelectedType] = useState("");
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [loading, setLoading] = useState(true);
  const [allProduct, setAllProduct] = useState([]);
  const [totalcount, setTotalCount] = useState(0);
  const [viewSelectedProducts, setViewSelectedProducts] = React.useState([]);
  const [page, setPage] = useState(0);
  const [assignedBarcodes, setAssignedBarcodes] = useState([]);
  const [locationAllProducts, setLocationAllProduct] = useState([]);
  const selectedProductRef = useRef([]);
  const [autoSelectedProducts, setAutoSelectedProducts] = useState("");
  const [inputValueAuto, setInputValueAuto] = useState("");
  const [productData, setProductData] = useState([]);
  const [prodData, setProdData] = useState([]);
  const [sortType, setSortType] = useState("desc");
  const [open, setOpen] = useState(false);
  const [modalOpen, setModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const actionRun = useRef(false);
  const selectedProdQtyRef = useRef([]);
  const effectRunData = useRef(true);
  const fetchRef = useRef(false);
  const oldselectedRef = useRef([]);
  const selectedProductDataRef = useRef([]);
  const [selectedProductData, setSelectedProductData] = useState([]);

  const [inputs, setInputs] = useState([]);
  const [Tiers, setTiers] = useState([]);
  const checkedItemsRef = useRef(false);
  const autoCompleteActive = useRef(true);
  const [timeoutId, setTimeoutId] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  // const [promoTier, setPromoTier] = useState([]);
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [options, setOptions] = useState([]);
  const inputRefs = useRef([]);
  const loading1 = open && options.length === 0;

  const [checkedItemsProducts, setCheckedItemsProducts] = React.useState([]);
  // const [promotionSalePrice,setPromotionSalePrice] = useState([]);
  const [value, setValue] = useState(options[0]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "id",
      sort: "desc",
    },
  ]);
  const sortRef = useRef(sortModel);

  const {
    isOpen: isProductModalOpen,
    onOpen: onProductModalOpen,
    onClose: onProductModalClose,
  } = useDisclosure();
  const [buttonClicked, setButtonClicked] = useState(false);
  //   const [locationProductFLag, setLocationProductFLag] = useState("");

  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.8 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };
  // var selectAllChecked = modifiedData.filter(x => checkedItems.includes(x.id))
  const [showTable, setShowTable] = useState(true);

  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState(
    {}
  );

  const sleep = (duration) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  };

  useEffect(() => {
    // let active = true;
    autoCompleteActive.current = true;

    if (!loading1) {
      return undefined;
    }

    (async () => {
      await sleep(450); // For demo purposes.
      if (autoCompleteActive.current) {
        search();
      }
    })();

    return () => {
      // active = false;
      autoCompleteActive.current = false;
    };
  }, [loading1]);
  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const search = () => {
    let data = {
      // vendors: "3",
      locations: formik.values.selectedLocations,
      filter: inputValueAuto,
      meilisearch: true,
      query: {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: inputValueAuto,
        allProdFlag: 0,
      },
    };
    setLoading(true);
    try {
      getManualProductList(data)
        .then((response) => {
          if (response.data.flag === true) {
            //   setProducts(response.data.data.data);
            setProductData(response.data.data.data);
            setOptions(response.data.data.data);
            // setTimeout(() => {
            //   let _filteredProducts;
            //   if (!event.query.trim().length) {
            //     _filteredProducts = [...response.data.data.data];
            //   } else {
            //     _filteredProducts = response.data.data.data.filter((product) => {
            //       return (
            //         (product.name.toLowerCase().includes(event.query.toLowerCase()) ||
            //         product.barcode.toLowerCase().startsWith(event.query.toLowerCase()))
            //       );
            //     });
            //   }
            //   setFilteredProducts(_filteredProducts);
            // }, 350);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            //   setProducts([]);
            setTotalCount(0);
            setLoading(false);
            setOptions([]);
            setOpen(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          // setProducts([]);
          setTotalCount(0);
          setOptions([]);
          setOpen(false);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setProductData([]);
      setTotalCount(0);
      setLoading(false);
      setOptions([]);
      setOpen(false);
      myContext.handleLoading(false);
    }
  };

  const autoCompleteSelectedProducts = (pro) => {
    if (undefined != pro && null != pro && "" != pro) {
      if (!checkedItems.includes(pro.barcode)) {
        var proAutoData = {
          id: pro.barcode,
          name: pro.name,
          barcode: pro.barcode,
          //   sku: pro.sku || "N.A.",
          //   in_stock_qty: pro.in_stock_qty,
          sale_price: pro.sale_price,
          sale_price_edit: pro.sale_price,
          //   location: pro.location.name,
          //   threshold: pro.threshold || "N.A.",
          //   unit_case: pro.no_of_eaches || "",
          autoCompleteProduct: true,
        };
        handleCheckboxChange({ row: proAutoData });
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      } else {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.PRICE_BOOK_MODULE,
          alertDescription: "Product already exists.",
        });
        setAutoSelectedProducts("");
        setInputValueAuto("");
      }
      // setAutoSelectedProducts('');
    }
  };

  const handleColumn = (coulmn) => {
    if (coulmn.sortName === columnProductViewNames) {
      if (sortType === "asc") {
        // handleSortColumn(coulmn, "desc");
      } else {
        // handleSortColumn(coulmn, "asc");
      }
    } else {
      // handleSortColumn(coulmn, "asc");
    }
  };

  function handleCheckboxChange(params) {
    const rowId = params.row.id;
    let dataIndex = selectedProductData.findIndex((x) => x.id == rowId);
    if (dataIndex > -1) {
      let array = [
        ...selectedProductData.slice(0, dataIndex),
        ...selectedProductData.slice(dataIndex + 1),
      ];
      setSelectedProductData(array);
    } else {
      let prodData = [...selectedProductData, params.row];
      setSelectedProductData(prodData);
    }
    const productIndex = selectedProductRef.current.findIndex(
      (x) => x.id == rowId
    );
    if (checkedItems.includes(rowId)) {
      let index = checkedItems.findIndex((x) => x === rowId);
      let prodIndex = prodData.findIndex((x) => x.id === rowId);
      if (index > -1) {
        let data = [
          ...checkedItems.slice(0, index),
          ...checkedItems.slice(index + 1),
        ];
        let array = [
          ...prodData.slice(0, prodIndex),
          ...prodData.slice(prodIndex + 1),
        ];
        setCheckedItems(data);
        setProdData(array);
      }
    } else {
      let index = selectedProdQtyRef.current.findIndex((x) => x.id == rowId);
      let obj = {
        id: rowId,
        sale_price_edit: "",
        salePriceErrors:
          index > -1 ? selectedProdQtyRef.current[index].salePriceErrors : "",
      };
      let array = [...prodData, obj];
      setProdData(array);

      let data = [...checkedItems, rowId];
      setCheckedItems(data);
    }
    if (params.row.autoCompleteProduct) {
      checkedItemsRef.current = true;
      effectRunData.current = true;
    }
  }

  useEffect(() => {
    if (checkedItemsRef.current) {
      handleSelectedViewProduct();
      checkedItemsRef.current = false;
    }
  }, [checkedItems]);

  const handleSelectedViewProduct = () => {
    setVisible(false);
    selectedProductDataRef.current = selectedProductData;
    let removeItems = viewSelectedProducts.filter(
      (product) =>
        undefined !== product?.id && checkedItems.includes(product?.id)
    );
    let selectedProducts = [...removeItems];
    checkedItems.map((id) => {
      let index = selectedProducts.findIndex(
        (x) => undefined !== x?.id && x?.id === id
      );
      if (index <= -1) {
        let item = selectedProductData.filter(
          (product) => undefined !== product?.id && product?.id === id
        );
        if (undefined !== item[0] && null !== item[0]) {
          // selectedProducts.push(item[0]);
          let searchViewExist = selectedProducts.findIndex(
            (x) => x.additionalRow == true
          );
          if (searchViewExist >= 0) {
            selectedProducts.splice(selectedProducts.length - 1, 0, item[0]);
          } else {
            selectedProducts.splice(selectedProducts.length, 0, item[0]);
          }
        }
      }
    });
    // setModal(false);
    onProductModalClose();
    setSelectedCategories([]);
    setViewSelectedProducts(selectedProducts);
    setSearchQuery("");
    setIsChecked(false);
    effectRunData.current = true;
    focusInputByIndex();
  };

  const focusInputByIndex = () => {
    if (inputRefs.current[modifiedViewDatas.length - 1]) {
      inputRefs.current[modifiedViewDatas.length - 1].focus();
    }
  };

  useEffect(() => {
    let searchExist = modifiedViewDatas.findIndex(
      (x) => x.additionalRow == true
    );
    let searchViewExist = viewSelectedProducts.findIndex(
      (x) => x.additionalRow == true
    );
    if (
      modifiedViewDatas.length > 0 &&
      effectRunData.current &&
      searchExist == -1 &&
      searchViewExist == -1
    ) {
      let array = [];
      var addInput = {
        id: "",
        name: "",
        // categories: '',
        barcode: "",
        // item_code: '',
        sale_price: "",
        sale_price_edit: "",
        additionalRow: true,
      };
      array = [...viewSelectedProducts, addInput];
      setViewSelectedProducts(array);
      effectRunData.current = false;
    }
  }, [viewSelectedProducts]);

  const handleInputChange = (event, id, field_name) => {
    event.preventDefault();
    const { value } = event.target;
    let index = prodData.findIndex((x) => x.id === id);
    if (index > -1) {
      let obj = { ...prodData[index] };
      obj[field_name] = value;
      switch (field_name) {
        case "sale_price_edit":
          const parsedSalePrice = parseFloat(value);
          obj.salePriceErrors =
            value.length === 0
              ? Constants.PRICE_BOOK_SALE_PRICE_REQUIRED
              : parseFloat(value) <= 0.0
              ? Constants.PRICE_BOOK_SALE_PRICE_MIN_VALUE
              : isNaN(parsedSalePrice) || !/^\d*\.?\d*$/.test(value)
              ? Constants.PPRICE_BOOK_PLEASE_ENTER_A_VALID_SALE_PRICE
              : parseFloat(value) > 1000000
              ? Constants.PRICE_BOOK_SALE_PRICE_MAX_VALUES
              : "";
          break;
        default:
          break;
      }
      let array = [
        ...prodData.slice(0, index),
        obj,
        ...prodData.slice(index + 1),
      ];
      // array.map((obj, i) => {
      //   if (
      //     (undefined == obj.salePriceErrors || "" == obj.salePriceErrors) &&
      //     obj.sale_price_edit > 0
      //   ) {
      //     //   var total =
      //     //     parseFloat(obj.unit_case).toFixed(2) * parseInt(obj.quantity);
      //     obj.sale_price_edit = parseFloat(obj.sale_price_edit);
      //     // } else {
      //     //   obj.sale_price_edit = "";
      //   } else {
      //     if (obj.sale_price_edit < 1) {
      //       obj.sale_price_edit = "";
      //     }
      //   }
      // });
      setProdData(array);
    }
  };

  const handleSingleDelete = (id) => {
    if (checkedItems.includes(id)) {
      let dataIndex = selectedProductData.findIndex((x) => x.id == id);
      if (dataIndex > -1) {
        let array = [
          ...selectedProductData.slice(0, dataIndex),
          ...selectedProductData.slice(dataIndex + 1),
        ];
        setSelectedProductData(array);
        selectedProductDataRef.current = array;
      }

      const updatedCheckedItems = checkedItems.filter(
        (itemId) => itemId !== id
      );
      setCheckedItems(updatedCheckedItems);
      const updatedSelectedProducts = viewSelectedProducts.filter(
        (product) => product.id !== id
      );
      setViewSelectedProducts(updatedSelectedProducts);
      const updatedProductData = prodData.filter((item) => item.id !== id);
      setProdData(updatedProductData);
      selectedProductRef.current = selectedProductRef.current.filter(
        (product) => product.id !== id
      );
      // fetchData();
      // setModal(false);
      // setSearchQuery("");
    }
  };
  const fetchData = async () => {
    let data = {
      //   vendors: selectedVendorData.id,
      locations: formik.values?.selectedLocations,
      query: {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
        // allProdFlag: locationProductFLag === 1 ? 1 : 0,
      },
    };
    setLoading(true);
    try {
      getManualProductList(data)
        .then((response) => {
          if (response.data.flag === true) {
            setProductData(response.data.data.data);
            // setLocationProductFLag(response.data.data.allLocationProductFLag);
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            setProductData([]);
            setTotalCount(0);
            setLoading(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setProductData([]);
          setTotalCount(0);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setProductData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const modalClose = () => {
    setVisible(false);
    setSelectedProductData(selectedProductDataRef.current);
    const selectedProductIds = viewSelectedProducts.map(
      (product) => product.id
    );
    setCheckedItems(selectedProductIds);
    selectedProductRef.current = oldselectedRef.current;
    setProdData(selectedProdQtyRef.current);

    setSearchQuery("");
    onProductModalClose();
    setSelectedCategories([]);
  };

  const columnProductViewNames = [
    {
      columnNames: "Product Name",
      sortName: "",
      width: "",
      textAlign: "start",
    },
    {
      columnNames: "Primary Barcode",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Sale Price",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Price Book Amount($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Delete",
      sortName: "",
      width: "",
    },
  ];
  const modifiedViewDatas = viewSelectedProducts.map((item) => ({
    id: item.id,
    name: item.name,
    barcode: item.barcode,
    sale_price: item.sale_price,
    sale_price_edit: undefined !== item?.sale_price ? item.sale_price : null,
  }));

  const handleKeyDown = (event) => {
    let flag =
      formik.values.name.length <= 0 ||
      formik.errors.name ||
      formik.values.selectedLocations?.length <= 0 ||
      viewSelectedProducts.length - 1 <= 0 ||
      prodData.some(
        (item) =>
          item.sale_price_edit === undefined ||
          item.sale_price_edit === "" ||
          parseFloat(item.sale_price_edit) < 0 ||
          item.salePriceErrors
      ) ||
      buttonDisable
        ? true
        : false;
    if (event.key === "Enter" && !flag) {
      formik.handleSubmit();
    }
  };

  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedLocations", e.value);
    setViewSelectedProducts([]);
    // setCheckedItemsProducts([]);
    setInputs([]);

    setSelectedProductData([]);
    setCheckedItems([]);
    setProdData([]);
    selectedProductDataRef.current = [];
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLocations(loaderResponse?.data?.data?.allLocation);
          if (
            undefined != loaderResponse?.data?.data?.locations &&
            null != loaderResponse?.data?.data?.locations &&
            0 < loaderResponse?.data?.data?.locations.length
          ) {
            formik.values.selectedLocations =
              loaderResponse?.data?.data?.locations;
          }
          let array = [];
          let arrayIds = [];
          loaderResponse.data.data.products.map((item_obj) => {
            function barcodeExists(pBarcode, array) {
              return array.some(function(e) {
                return e.barcode == pBarcode;
              });
            }

            if (false == barcodeExists(item_obj.products.barcode, array)) {
              let obj = {
                // Pass Barcode in Id Because of pass backend data
                id: item_obj.products.barcode,
                name: item_obj.products.name,
                barcode: item_obj.products.barcode,
                sale_price: item_obj.products.sale_price,
                sale_price_edit: item_obj.price_book_sale_price,
              };
              // array.push(obj);
              array.splice(array.length, 0, obj);
              arrayIds.splice(arrayIds.length, 0, item_obj.products.barcode);
            }
          });
          if (array.length > 0) {
            setViewSelectedProducts(array);
            setProdData(array);
            setCheckedItems(arrayIds);
          }
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      // return () => {
      effectRun.current = false;
      // };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.PRICE_BOOK_LIST_PATH, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.PRICE_BOOK_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
      /* else {
              let actionData = actionResponse;
              error({ actionData });
            } */
    }
  }, [actionResponse]);

  const clickIfapplyCat = (cat) => {
    setSelectedCategories(cat);
    // if (
    //   cat.length == 0 &&
    //   selectedVendors.length == 0 &&
    //   selectedLocations?.length == 0
    // ) {
    // setPage(0);
    paginationModel.page = 0;
    apply();
    // }
  };

  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    // setPage(0);
    paginationModel.page = 0;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      apply();
    }, 250);
    setTimeoutId(newTimeoutId);
  };

  const apply = () => {
    effectRun.current = true;
    setLoading(true);
  };

  useEffect(() => {
    if (effectRun.current === true) {
      //   if (!isChecked) {
      handleProductDetails();
      setAllChecked(false);
      //   } else {
      //     fetchDatathreshold();
      //   }
      effectRun.current = false;
    }
  }, [searchQuery, paginationModel, sortModel, loading, isChecked, modalOpen]);

  const onCheckboxSelectionChange = (e) => {
    const isChecked = e.target.checked; // This will be true if the checkbox is being checked, and false if it's being unchecked.
    setAllChecked(isChecked);
    selectAllProduct(isChecked);
  };

  const selectAllProduct = (isChecked) => {
    const pageData = modifiedData;
    const selectedIds = pageData.map((data) => data.id);
    let updatedCheckedItems, updatedSelectedProductData, updatedProdData;
    if (isChecked) {
      // If the checkbox is checked, select all items on the current page
      updatedCheckedItems = [
        ...checkedItems,
        ...selectedIds.filter((id) => !checkedItems.includes(id)),
      ];
      updatedSelectedProductData = [
        ...selectedProductData,
        ...pageData.filter(
          (data) => !selectedProductData.some((item) => item.id === data.id)
        ),
      ];
      const newPageData = pageData
        .filter(
          (pageItem) =>
            !prodData.some((prodItem) => prodItem.id === pageItem.id)
        )
        .map((data) => ({
          id: data.id,
          sale_price_edit: "",
          salePriceErrors: "",
        }));
      updatedProdData = [...prodData, ...newPageData];
    } else {
      // If the checkbox is unchecked, unselect all items on the current page
      updatedCheckedItems = checkedItems.filter(
        (id) => !selectedIds.includes(id)
      );
      updatedSelectedProductData = selectedProductData.filter(
        (item) => !selectedIds.includes(item.id)
      );
      updatedProdData = prodData.filter(
        (item) => !selectedIds.includes(item.id)
      );
    }
    setCheckedItems(updatedCheckedItems);
    setSelectedProductData(updatedSelectedProductData);
    setProdData(updatedProdData);
  };

  const openDialog = (index) => {
    var CheckedProducts = [];
    if (viewSelectedProducts.length > 0) {
      viewSelectedProducts.map((dt) => {
        CheckedProducts.push(dt.id);
      });
    }
    setCheckedItems(CheckedProducts);
    selectedProdQtyRef.current = prodData;
    const updateData = prodData.map((item) => ({
      ...item,
    }));
    setProdData(updateData);
    oldselectedRef.current = selectedProductRef.current;
    setIsDialogOpen(true);
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
    effectRun.current = true;
    setSortModel([
      {
        field: "id",
        sort: "desc",
      },
    ]);
    onProductModalOpen();
  };

  const handleSearchList = () => {
    paginationModel.page = 0;
    handleProductDetails();
  };
  const handleClear = () => {
    // clear input value and get all data
    setSearchQuery("");
    effectRun.current = true;
  };
  const modifiedData = productData?.map((data) => ({
    id: data.id,
    name: data.name,
    barcode: data.barcode,
    sale_price: data.sale_price,
    sale_price_edit: data.sale_price,
    category_name: data?.category_name?.replace(/,/g, ", ") || "",
    // checkBoxDisabled: data.checkBoxDisabled,
    // soldByWeight: data.sold_by_weight,
  }));

  const handleProductDetails = async () => {
    setLoading(true);
    let data = {
      locations: formik.values.selectedLocations,
      categories: selectedCategories,
      query: {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
      },
    };
    try {
      getProductsForSelectedLocation(data)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            setLoading(false);
            setAssignedBarcodes(response.data.data.attached_product_barcodes);
            var selectProductFiltersName = [];
            selectProductFiltersName = response.data.data.data.data;
            setProductData(response.data.data.data.data);
            setTotalCount(response.data.data.totalCount);
            const cat = response.data.data?.category;
            const categoriesDropDownData = cat?.map((cat) => ({
              label: cat.name,
              value: { name: cat.name, id: cat.id },
            }));
            setCategories(categoriesDropDownData);

            myContext.handleLoading(false);
          } else {
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          setTotalCount(0);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setTotalCount(0);
      myContext.handleLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name:
        undefined !== loaderResponse?.data?.data?.price_book?.name &&
        null != loaderResponse?.data?.data?.price_book?.name
          ? loaderResponse?.data?.data?.price_book?.name
          : "",
      selectedLocations: [loaderResponse?.data?.data?.locations],
    },
    validationSchema: Yup.object({
      selectedLocations: Yup.array().min(
        1,
        Constants.PRICE_BOOK_LOCATIONS_ARE_REQUIRED
      ),
      name: Yup.string()
        .trim()
        .required(Constants.PRICE_BOOK_NAME_REQUIRED)
        .max(191, Constants.PRICE_BOOK_NAME_LENGTH_GREATE_THEN_191),
    }),
    onSubmit: async (values) => {
      setVisible(false);
      myContext.handleLoading(true);
      setButtonDisable(true);
      var removeAdditionalField = viewSelectedProducts.filter((pr) => {
        return undefined == pr.additionalRow || pr.additionalRow != true;
      });
      let obj = {};
      let prodArray = [];
      removeAdditionalField.map((product) => {
        const matchedProduct = prodData.find((p) => p.id === product.id);
        obj = {
          ...product,
          sale_price_edit: parseFloat(matchedProduct.sale_price_edit),
        };
        prodArray.push(obj);
      });
      let payload = {
        ...values,
        locations:
          values.selectedLocations.length > 0
            ? JSON.stringify(values.selectedLocations)
            : [],
        products: JSON.stringify(prodArray),
      };
      actionRun.current = true;
      submit(payload, {
        method: Constants.PUT,
        // path: Constants.PRICE_BOOK_EDIT_API_ROUTE,
      });
    },
  });

  const onHideDialog = () => {
    formik.resetForm();
    setSelectedCategories([]);
    setVisible(false);
  };

  const productColumnNames = [
    {
      field: "selection",
      // headerName: "",
      hideable: false,
      headerName: (
        // <Checkbox
        //   checked={selectAll || selectAllChecked.length === modifiedData.length}
        //   onChange={handleSelectAll}
        //   indeterminate={
        //     checkedItems.length > 0 && checkedItems.length < modifiedData.length
        //   }
        // />
        <Checkbox onChange={onCheckboxSelectionChange} checked={allChecked} />
      ),
      sortable: false,
      resizable: false,
      ...actionColumnXS,
      renderCell: (params) => (
        <IconButton aria-label="select" sx={{ color: "#5881FE" }}>
          <Checkbox
            checked={checkedItems.includes(params.row.id)}
            onChange={() => {
              handleCheckboxChange(params);
            }}
          />
        </IconButton>
      ),
    },
    // { field: "id", headerName: "Id", sortable: true, flex: 1 },
    {
      field: "name",
      headerName: "Product name",
      sortable: false,
      ...actionColumnFlexDouble,
    },
    {
      field: "barcode",
      headerName: "Primary Barcode",
      sortable: false,
      ...actionColumnXL,
    },
    {
      field: "sale_price",
      headerName: "Sale Price ($)",
      sortable: false,
      resizable: false,
      ...actionColumnLG,
    },
    {
      field: "category_name",
      headerName: "Categories",
      sortable: false,
      resizable: false,
      ...actionColumnLG,
    },
  ];

  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" w={isMobile ? "100vw" : "calc(100vw - 263.2px)"}>
        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          /* pl={"1.88rem"} */
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              // pass param data in tax list page
              props.navigate(Constants.PRICE_BOOK_LIST_PATH, {
                state: stateData.state,
              });
            }}
            breadCrumNames={["Price Books", "Edit Price Book"]}
            breadCrumTitle={"Edit Price Book"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                // pass param data in tax list page
                props.navigate(Constants.PRICE_BOOK_LIST_PATH, {
                  state: stateData.state,
                });
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              isDisabled={
                formik.values.name?.length <= 0 ||
                formik.errors.name ||
                formik.values.selectedLocations?.length <= 0 ||
                viewSelectedProducts.length - 1 <= 0 ||
                prodData.some(
                  (item) =>
                    item.sale_price_edit === undefined ||
                    item.sale_price_edit === "" ||
                    parseFloat(item.sale_price_edit) < 0 ||
                    item.salePriceErrors
                ) ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
          p="8"
        >
          <Text
            fontSize="1.125rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Basic Details
          </Text>
          <Stack spacing={1}>
            <PosLable fontWeight={"500"} requiredLabel={true} name={"Name"} />
            <PosInput
              id="name"
              placeholder={"Enter Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.name}
              handleBlur={(e) => {
                formik.setFieldValue("name", formik.values.name.trim());
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInput={true}
              inputType={"text"}
              inputError={formik.touched.name && formik.errors.name}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.name && formik.errors.name ? (
                <span>{formik.errors.name}</span>
              ) : null}
            </Text>
          </Stack>
          <PosLable
            fontWeight={"500"}
            requiredLabel={true}
            name="Select Locations"
          />
          <PosDropDown
            options={locations}
            value={formik.values.selectedLocations}
            onChange={handleOptionSelect}
            onBlur={(e) => {
              formik.setFieldTouched("selectedLocations");
              formik.handleBlur(e);
            }}
            onHide={() => {
              formik.setFieldTouched("selectedLocations");
            }}
            multiSelect={true}
            optionLabel="name"
            placeholder="Select Locations"
            width={{ base: "100%", md: "60%" }}
            height={"2.5rem"}
            className="w-full md:w-20rem"
            maxSelectedLabels={2}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.selectedLocations &&
            formik.errors.selectedLocations ? (
              <span>{formik.errors.selectedLocations}</span>
            ) : null}
          </Text>
          <Box pt={"2rem"}>
            <Divider borderWidth={"0.8px"} borderColor="#E6E6E6" mb={2} />
          </Box>
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-end" }}
            flexDirection={{ base: "column", md: "row" }}
            // pt={{ base: "0.8rem", md: "0.8rem" }}
            pb={{ base: "1.5rem", md: "1.5rem" }}
            // pl={{ base: "1rem", lg: "1.5rem" }}
            // pr={{ base: "0.5rem", md: "1.56rem" }}
            // gap={1}
          >
            <Spacer />
            <Stack mt={"2rem"}>
              <Button
                bg={Colors.posNavbarLink}
                size={{ base: "sm", md: "md" }}
                style={{
                  display: "flex",
                  padding: "0.63rem",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "0.63rem",
                  borderRadius: "0.31rem",
                  border: "1px solid #E3E3E3",
                  boxShadow: "0px 0.25rem 0.25rem 0px #e0e0e01a",
                  backgroundColor: Colors.posNavbarLink,
                }}
                // onClick={() => openDialog()}
                onClick={() => {
                  setVisible(true);
                  openDialog();
                }}
                isDisabled={
                  formik.values.name?.length <= 0 ||
                  formik.errors.name ||
                  formik.values.selectedLocations?.length <= 0
                    ? // || buttonDisableProducts
                      true
                    : false
                }
              >
                Add Products
              </Button>
            </Stack>
          </Flex>

          {viewSelectedProducts.length > 0 && (
            <>
              <Text
                fontSize="1.125rem"
                w="100%"
                mb={3}
                color={Colors.posImageIconColor}
                style={{
                  fontStyle: "normal",
                  fontWeight: 300,
                  lineHeight: "normal",
                  letterSpacing: "-0.27px",
                }}
              >
                Location(s):{" "}
                {formik.values.selectedLocations.map((loc, index) => (
                  <React.Fragment key={loc.id}>
                    {loc.name}
                    {index !== formik.values.selectedLocations.length - 1
                      ? ", "
                      : ""}
                  </React.Fragment>
                ))}
              </Text>
            </>
          )}
          <Dialog
            header={<div style={{ padding: 0, margin: 0 }}> </div>}
            visible={visible}
            onHide={onHideDialog}
            style={{ width: isMobileDialog ? "80%" : "70%" }}
            closable={false}
            closeOnEscape={false}
          >
            <ChakraProvider theme={theme}>
              <Box
                position="sticky"
                top="0"
                zIndex="1000"
                backgroundColor="white"
                pb={2}
              >
                <Flex
                  minWidth="max-content"
                  gap="2"
                  mt={4}
                  mb={4}
                  alignItems="center"
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Flex
                    alignSelf="flex-start"
                    fontSize="1.5rem"
                    fontWeight={600}
                    color="rgb(1, 0, 72)"
                  >
                    Add Products
                  </Flex>
                  <Spacer />
                  <ButtonGroup alignSelf="flex-end">
                    <PosFormButton
                      onClick={modalClose}
                      buttonText="Cancel"
                      CancelButton={true}
                      isDisabled={buttonDisable}
                    />
                    <PosFormButton
                      isDisabled={
                        checkedItems.length <= 0 ||
                        (checkedItems.length === 1 && checkedItems[0] === "") ||
                        (isChecked && prodData.length === 0) ||
                        buttonDisable
                      }
                      buttonsubmit="Add Products"
                      SubmitButton={true}
                      onClick={handleSelectedViewProduct}
                    />
                  </ButtonGroup>
                </Flex>
                <Flex
                  // minWidth="max-content"
                  gap="2"
                  mt={4}
                  mb={4}
                  // alignItems="center"
                  flexDirection={{ base: "column", sm: "column", md: "row" }}
                >
                  <PosSearch
                    value={searchQuery}
                    // onChange={(e) => {
                    //   let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
                    //   setSearchQuery(value);
                    // }}
                    onChange={onChange}
                    onSearch={handleSearchList}
                    handleClear={handleClear}
                  />
                  {/* <Spacer /> */}
                  <PosDropDown
                    options={categories}
                    value={selectedCategories}
                    onChange={(e) => {
                      clickIfapplyCat(e.value);
                    }}
                    // onChange={(e) => setSelectedCategories(e.value)}
                    multiSelect={true}
                    optionLabel="label"
                    placeholder="Categories"
                    lableAvailable={true}
                    width="100%"
                    height="2.5rem"
                    className="w-full md:w-20rem"
                    maxSelectedLabels={2}
                  />
                </Flex>
                {/* {totalcount > 0 ?
                    <ButtonGroup>
                      <PrimeReactCheckbox onChange={onCheckboxSelectionChange} checked={allChecked}></PrimeReactCheckbox>
                      <label htmlFor="ingredient1" className="ml-2">{allChecked ? 'Unselect All' : 'Select All'}</label>
                    </ButtonGroup> : null} */}
              </Box>
            </ChakraProvider>
            {loading ? (
              <PosProgress />
            ) : totalcount > 0 ? (
              <Box maxHeight={"32rem"} overflow={"auto"}>
                <PosDataGridTable
                  columns={productColumnNames}
                  rows={modifiedData}
                  totalcount={totalcount}
                  columnVisibilityModel={olColumnVisibilityModel}
                  onColumnVisibilityModelChange={(newModel) =>
                    setOlColumnVisibilityModel(newModel)
                  }
                  paginationModel={paginationModel}
                  paginationMode="server"
                  onPaginationModelChange={(newPaginationModel) => {
                    effectRun.current = true;
                    setPaginationModel(newPaginationModel);
                  }}
                  sortModel={sortModel}
                  onSortModelChange={(newSortModel) => {
                    sortRef.current = sortModel;
                    setSortModel(newSortModel);
                  }}
                  slots={{
                    toolbar: GridToolbar,
                  }}
                  fileName={Constants.PRICE_BOOK_PROD_EXPORT_FILENAME}
                  // onRowSelectionModelChange={(newRowSelectionModel) => {
                  //   setRowSelectionModel(newRowSelectionModel);
                  // }}
                  // checkboxSelection={true}
                  // rowSelectionModel={rowSelectionModel}
                  // keepNonExistentRowsSelected={true}
                />
              </Box>
            ) : (
              <PosNoDataFound title={Constants.PRICE_BOOK_PRODUCT_NOT_FOUND} />
            )}
          </Dialog>
          {viewSelectedProducts && viewSelectedProducts.length > 0 && (
            <Box
              borderRadius="0.63rem"
              mt={3}
              bg={Colors.posPageDataBackground}
              boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflowX: "auto",
              }}
            >
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead
                    alignitems="start"
                    // hidden={headerHide}
                    sx={{ backgroundColor: Colors.modifierTable }}
                  >
                    <TableRow>
                      {columnProductViewNames.map((column, index) => (
                        <TableCell
                          key={index}
                          // color={Colors.primeposTextInfo}
                          sx={{
                            fontWeight: 500,
                            fontSize: "0.94rem",
                            lineHeight: "1.18rem",
                            letterSpacing: "-0.01rem",
                            fontStyle: "normal",
                            textAlign: column.textAlign || "center",
                            maxHeight: "42rem",
                            color: "#010048",
                            // pl:"3.56rem",
                            // width:
                            //   column.width == "" ? undefined : column.width,
                          }}
                        >
                          <Flex
                            flexDirection={"row"}
                            style={{
                              justifyContent:
                                column?.columnNames != "Product Name" &&
                                "center",
                            }}
                          >
                            {column.columnNames}
                          </Flex>
                          {column.sortName.length > 0 ? (
                            <TableSortLabel
                              active={
                                column.sortName === columnProductViewNames
                              }
                              direction={
                                column.sortName === columnProductViewNames
                                  ? sortType
                                  : "asc"
                              }
                              onClick={() => handleColumn(column)}
                            />
                          ) : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modifiedViewDatas.map((row, i) => {
                      let index = prodData.findIndex((x) => x.id === row.id);
                      return (
                        <TableRow
                          key={i}
                          // sx={{
                          //   "&:last-child td, &:last-child th": { border: 0 },
                          // }}
                        >
                          <TableCell>
                            {index === -1 ? (
                              <Flex flexDirection={"row"}>
                                <Search2Icon mt={3} mr={3} />
                                <ThemeProvider theme={Mtheme}>
                                  <Autocomplete
                                    size="small"
                                    filterOptions={(x) => x}
                                    id="autoselect"
                                    //   disabled={
                                    //     !formik.values.locations ||
                                    //     formik.errors.locations ||
                                    //     buttonDisable
                                    //       ? true
                                    //       : false
                                    //   }
                                    sx={{ width: 300 }}
                                    open={open}
                                    onOpen={() => {
                                      setOpen(true);
                                    }}
                                    onClose={() => {
                                      setOpen(false);
                                    }}
                                    value={autoSelectedProducts}
                                    onChange={(event, newValue) => {
                                      // setAutoSelectedProducts(newValue);
                                      autoCompleteSelectedProducts(newValue);
                                      // setInputValueAuto(newValue);
                                    }}
                                    inputValue={inputValueAuto}
                                    onInputChange={(event, newInputValue) => {
                                      setOptions([]);
                                      setInputValueAuto(newInputValue);
                                    }}
                                    // isOptionEqualToValue={(option, value) => option.barcode === value?.barcode}
                                    getOptionLabel={(option) =>
                                      option?.name || ""
                                    }
                                    options={options}
                                    loading={loading1}
                                    renderOption={(props, option) => (
                                      <li
                                        {...props}
                                        key={
                                          option?.id ||
                                          option?.barcode ||
                                          option?.name
                                        }
                                      >
                                        {option?.name}
                                      </li>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Search"
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <React.Fragment>
                                              {loading1 ? (
                                                <CircularProgress
                                                  color="inherit"
                                                  size={20}
                                                />
                                              ) : null}
                                              {params.InputProps.endAdornment}
                                            </React.Fragment>
                                          ),
                                        }}
                                      />
                                    )}
                                  />
                                </ThemeProvider>
                              </Flex>
                            ) : (
                              <VStack align="left">
                                <Box
                                  style={{
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: 500,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                    color: "#010048",
                                  }}
                                >
                                  {row.name}
                                </Box>
                              </VStack>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              textAlign: "center",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {row.barcode || "N.A."}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              textAlign: "center",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {null != row.sale_price && "" != row.sale_price
                              ? "$" + row.sale_price
                              : "N.A."}
                          </TableCell>

                          <TableCell align="center">
                            <Input
                              id="sale_price_edit"
                              htmlSize={4}
                              ref={(el) => (inputRefs.current[i] = el)}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "sale_price_edit")
                              }
                              // disabled={modifiedViewDatas.length - 1 == i}
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                textAlign: "center",
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3", // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "sale_price_edit");
                              }}
                              value={
                                (index > -1
                                  ? prodData[index].sale_price_edit
                                  : row.sale_price_edit) || ""
                              }
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              type="number"
                              isInvalid={
                                prodData[index]?.salePriceErrors?.length > 0
                              }
                            />
                            <Text color={Colors.errorColor}>
                              {prodData[index]?.salePriceErrors?.length > 0 ? (
                                <span>{prodData[index]?.salePriceErrors}</span>
                              ) : null}
                            </Text>
                          </TableCell>

                          <TableCell align="center">
                            {index == -1 ? null : (
                              <DeleteIcon
                                color={Colors.posCancleButtonMuiRed}
                                style={{
                                  width: "1.13rem",
                                  height: "1.13rem",
                                  flexShrink: 0,
                                  cursor: "pointer",
                                }}
                                onClick={() => handleSingleDelete(row.barcode)}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
        <Box pb={16}></Box>
      </Box>
    </Flex>
  );
};

export default WithRouter(PriceBookEdit);
