import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useRadioGroup,
  useMediaQuery
} from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosLable,
  PosSwitchButton,
  PosTable,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { addGlobalSettingCategory } from "./MasterAdminGlobalSettingService";

const MasterAdminGlobalSettingEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isMobile] = useMediaQuery(Constants.MEDIA_QUERY_DATA_GRID_TABLE_MAX_WIDTH);
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const effectRun = useRef(true);
  const [options, setOptions] = useState([]);
  const [validationErrors, setValidationErrors] = useState(
    "Please enter dropdown options."
  );
  const [categories, setGlobalSettingsCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [globalSettingOptions, setGlobalSettingOptions] = useState([]);
  const [globalData, setGlobalData] = useState([]);

  // const cancelRef = React.useRef();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {
    isOpen: isRewardModalOpen,
    onOpen: onRewardModalOpen,
    onClose: onRewardModalClose,
  } = useDisclosure();
  const [paramData, setParamData] = useState(stateData.state); 

  const [columnProductViewNames, setColumnProductViewNames] = useState([
    {
      columnNames: "Id",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Code",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Option",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Add",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Delete",
      sortName: "",
      width: "",
    },
  ]);

  useEffect(() => {
    if (props.isRewardOpen) {
      myContext.handleLoading(true);
    }
    return () => {};
  }, [props.isRewardOpen]);

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined != loaderResponse.data?.data?.allCategory &&
            null != loaderResponse.data?.data?.allCategory &&
            "" != loaderResponse.data?.data?.allCategory &&
            loaderResponse.data?.data?.allCategory.length > 0
          ) {
            setGlobalSettingsCategories(loaderResponse.data?.data?.allCategory);
          }
          if (
            undefined != loaderResponse.data?.data &&
            null != loaderResponse.data?.data &&
            "" != loaderResponse.data?.data
          ) {
            formik.setFieldValue(
              "input_type",
              loaderResponse.data?.data?.input_type
            );
            formik.setFieldValue(
              "setting_display_name",
              loaderResponse.data?.data?.setting_display_name
            );
            formik.setFieldValue(
              "required",
              loaderResponse.data?.data?.required
            );
            formik.setFieldValue(
              "setting_key",
              loaderResponse.data?.data?.setting_key
            );
            formik.setFieldValue(
              "field_sort",
              loaderResponse.data?.data?.field_sort
            );
            formik.setFieldValue(
              "selectedCategory",
              loaderResponse.data?.data?.global_settings_category?.id
            );
            setSelectedCategory(
              loaderResponse.data?.data?.global_settings_category
            );
            setGlobalData(loaderResponse.data?.data);
          }
          if (loaderResponse.data?.data?.input_type == 1) {
            if (
              undefined != loaderResponse.data?.data?.global_setting_options &&
              loaderResponse.data?.data?.global_setting_options.length > 0
            ) {
              loaderResponse.data?.data?.global_setting_options.map((dt) => {
                dt.notMatched = false;
                dt.optionError = "";
              });
              setGlobalSettingOptions(
                loaderResponse.data?.data?.global_setting_options
              );
            }
          }
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      // return () => {
        effectRun.current = false;
      // };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(
          "/" +
            process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
            Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH, {
              state: paramData,
            }
        );
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.MASTER_ADMIN_GLOBAL_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);
  const handleInputTypeChange = (value) => {
    formik.setFieldValue("input_type", value);
    setOptions([]);
    setValidationErrors("Please enter dropdown options.");
    if (1 == value) {
      if (globalSettingOptions.length <= 0) {
        setGlobalSettingOptions([
          ...globalSettingOptions,
          {
            options: "-",
            option_label: "",
            notMatched: true,
            optionError: Constants.OPTION_ERROR,
          },
        ]);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      setting_display_name:
        undefined != globalData.setting_display_name
          ? globalData.setting_display_name
          : "",
      setting_key:
        undefined != globalData.setting_key ? globalData.setting_key : "",
      selectedCategory:
        undefined != globalData.global_settings_category_id
          ? globalData.global_settings_category_id
          : "",
      field_sort:
        undefined != globalData.field_sort ? globalData.field_sort : "",
      input_type:
        undefined != globalData.input_type ? globalData.input_type : 1,
      required:
        undefined != globalData.required && 0 == globalData.required
          ? false
          : true,
    },
    // initialValues: {
    //   setting_display_name: "",
    //   setting_key: "",
    //   selectedCategory: "",
    //   field_sort: "",
    //   input_type: 1,
    //   required: false,
    // },
    enableReinitialize: true,
    validationSchema: Yup.object({
      setting_display_name: Yup.string()
        .trim()
        .required(Constants.TAX_NAME_REQUIRED)
        .max(191, Constants.TAX_NAME_LENGTH_GREATE_THEN_191),
      setting_key: Yup.string()
        .trim()
        .required(Constants.GLOBAL_SETTING_KEY_REQUIRED)
        .max(191, Constants.GLOBAL_SETTING_KEY_LENGTH_GREATE_THEN_191),
      selectedCategory: Yup.string().required(
        Constants.GLOBAL_SETTING_CATEGORY_REQUIRED
      ),
      input_type: Yup.string().required(Constants.TAX_TYPE_REQUIRED),
      field_sort: Yup.number()
        .required(Constants.GLOBAL_SETTING_FIELD_SORT_REQUIRED)
        .integer(Constants.GLOBAL_SETTING_FIELD_SORT_NOT_VALID)
        .typeError(Constants.GLOBAL_SETTING_FIELD_SORT_NOT_VALID)
        .min(1, Constants.GLOBAL_SETTING_FIELD_SORT_MIN_VALUE)
        .max(100, Constants.GLOBAL_SETTING_FIELD_SORT_MAX_VALUE),
    }),
    onSubmit: async (values) => {
      const modCategory = {
        value: { id: selectedCategory.id },
      };
      if (
        undefined != values.input_type &&
        null != values.input_type &&
        "" != values.input_type &&
        1 == values.input_type &&
        globalSettingOptions.length > 0
      ) {
        values.options = JSON.stringify(globalSettingOptions);
      }
      let data = {
        ...values,
        category: JSON.stringify(modCategory),
      };
      myContext.handleLoading(true);
      setButtonDisable(true);
      submit(data, {
        method: Constants.POST,
        path: Constants.GLOBAL_SETTING_EDIT_API,
      });
    },
  });

  const {
    getRootProps: getInputTypeRootProps,
    getRadioProps: getInputTypeRadioProps,
  } = useRadioGroup({
    name: "value",
    defaultValue: "",
    onChange: handleInputTypeChange,
  });

  const CardRadioSlot = ({ children, isChecked, ...props }) => {
    return (
      <Box as="label">
        <input type="radio" {...props} style={{ display: "none" }} />
        <Box
          cursor="pointer"
          borderWidth="2px"
          borderRadius="0.625rem"
          // h={"3.25rem"}
          // w={"7rem"}
          w={"8rem"}
          boxShadow="md"
          // bg={isChecked ? "teal.600" : "white"}
          color={isChecked ? "white" : "black"}
          borderColor={isChecked ? "messenger.300" : "gray.300"}
          _hover={{
            bg: isChecked ? "messenger" : "gray.100",
          }}
          _checked={{
            bg: "teal.600",
            color: "white",
            borderColor: "#5881FE",
          }}
          px={5}
          py={3}
        >
          {children}
        </Box>
      </Box>
    );
  };

  const handleClose = () => {
    formikModal.resetForm();
    if (props.onRewardClose) {
      props.onRewardClose();
    }
  };

  const formikModal = useFormik({
    initialValues: {
      name: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(Constants.TAX_NAME_REQUIRED)
        .max(191, Constants.TAX_NAME_LENGTH_GREATE_THEN_191),
    }),
    onSubmit: async (values) => {
      setButtonDisable(true);
      myContext.handleLoading(true);
      addGlobalSettingCategory(values)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            setGlobalSettingsCategories(response.data?.data?.allCategory);
            let cat = response.data?.data?.allCategory.filter(
              (chip) => chip.id == response.data?.data?.id
            );
            setSelectedCategory(cat[0]);
            formik.setFieldValue("selectedCategory", response.data?.data?.id);
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.MASTER_ADMIN_GLOBAL_MODULE,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
            onRewardModalClose();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err?.response?.data;
          error({ actionData });
        });
    },
  });

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.setting_display_name.length <= 0 ||
      formik.errors.setting_display_name ||
      formik.errors.input_type ||
      formik.errors.field_sort ||
      (undefined != formik.values.input_type &&
        null != formik.values.input_type &&
        "" != formik.values.input_type &&
        1 == formik.values.input_type &&
        globalSettingOptions.length <= 0) ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };

  const inputTypeOptions = [
    {
      title: "Dropdown",
      value: 1,
    },
    {
      title: "Text Field",
      value: 0,
    },
  ];

  const handleOptionSelect = (e) => {
    if(undefined != e.value &&  null != e.value && '' != e.value){
      setSelectedCategory(e.value);
      formik.setFieldValue("selectedCategory", e.value.id);  
    }
  };

  const openDialog = (index) => {
    setIsDialogOpen(true);
    onRewardModalOpen();
  };

  const handleCloseDialog = () => {
    formikModal.resetForm();
    setIsDialogOpen(false);
    onRewardModalClose();
  };

  const handleOptionChange = (e, i) => {
    let { id, value } = e.target;
    let onChangeValue = [...globalSettingOptions];
    onChangeValue[i][id] = value;
    let go = [];
    globalSettingOptions.map((dt, index) => {
      if (index == i) {
        if (undefined == dt.id || null == dt.id || "" == dt.id) {
          if (
            undefined != e.target.value &&
            null != e.target.value &&
            "" != e.target.value
          ) {
            dt.optionError = "";
            dt.options = e.target.value.replaceAll(" ", "").toUpperCase();
            dt.option_label = e.target.value;
          } else {
            dt.options = "-";
            dt.optionError = Constants.OPTION_ERROR;
          }
        } else {
          if (
            undefined != e.target.value &&
            null != e.target.value &&
            "" != e.target.value
          ) {
            dt.optionError = "";
            dt.option_label = e.target.value;
          } else {
            dt.optionError = Constants.OPTION_ERROR;
          }
          // dt.optionError="";
          // dt.option_label = e.target.value;
        }
      }
      go.push(dt);
    });
    setGlobalSettingOptions(go);
  };

  const handleAddInput = () => {
    setGlobalSettingOptions([
      ...globalSettingOptions,
      {
        options: "-",
        option_label: "",
        notMatched: true,
        optionError: Constants.OPTION_ERROR,
      },
    ]);
  };

  const findAndReturnValue = (array, criteria, valueToReturn) => {
    const foundObject = array.find((item, k) => k == criteria);
    if (foundObject) {
      return foundObject[valueToReturn];
    } else {
      return null;
    }
  };

  const handleSingleDelete = (i) => {
    const foundObject = findAndReturnValue(globalSettingOptions, i-1, "id");
    if (foundObject) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.MASTER_ADMIN_GLOBAL_MODULE,
        alertDescription: "You can not delete existing option.",
      });
    } else {
      let latestGlobalSettingOptions = [];
      globalSettingOptions.map((dt, ind) => {
        if ((i-1) != ind) {
          latestGlobalSettingOptions.push(dt);
        }
      });
      if(latestGlobalSettingOptions.length <= 0){
        formik.setFieldValue('input_type',0);
      }
      setGlobalSettingOptions(latestGlobalSettingOptions);
    }
  };

  const modifiedViewData = globalSettingOptions.map((dt, index) => ({
    id: index+1,
    code: dt.options,
    option: (
      <>
        <PosInput
          id="option_label"
          posInput={true}
          placeholder={"Enter Opion Label"}
          inputValue={dt.option_label}
          handleInputChange={(e) => {
            handleOptionChange(e, index);
          }}
          // handleBlur={(e) => {
          //   handleOptionBlur(e, index);
          // }}
          inputType={"text"}
          inputError={formik.touched.optionError && formik.errors.optionError}
        />
        <Text color={Colors.errorColor}>
          {undefined != dt?.optionError &&
          null != dt?.optionError &&
          "" != dt?.optionError ? (
            <span>{dt?.optionError}</span>
          ) : null}
        </Text>
      </>
    ),
    add:
      index == globalSettingOptions.length - 1 ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          w={"3rem"}
          // ml={"1rem"}
        >
          <IconButton
            onClick={() => handleAddInput()}
            isRound={true}
            isDisabled={
              undefined == dt.option_label ||
              null == dt.option_label ||
              "" == dt.option_label ||
              (undefined != dt?.optionError &&
                null != dt?.optionError &&
                "" != dt?.optionError)
                ? true
                : false
            }
          >
            <AddIcon />
          </IconButton>
        </Box>
      ) : (
        "-"
      ),
  }));

  let inputvalid = globalSettingOptions.findIndex((x) => {
    return x.optionError.length > 0 || x.optionError.length > 0;
  });

  return (
    <Flex bg={Colors.loginAuthBackground}>
      <Box flex="1" w={isMobile ? "100vw" : 'calc(100vw - 263.2px)'}>
      <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={"flex-start"}
          pt={{ base: "1.5rem", md: "3.06rem" }}
          pb={{ base: "1.5rem", md: "0" }}
          pl={{ base: "1rem", lg: "1.88rem" }}
          pr={{ base: "0.5rem", md: "1.88rem" }}
          gap={2}
        >
          <PosBreadCrumb
            handleClick={(i) => {
              // pass param data in tax list page
              props.navigate(
                "/" +
                  process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
                  Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH,
                {
                  state: stateData.state,
                }
              );
            }}
            breadCrumNames={["Global Settings", "Edit a Global Setting"]}
            breadCrumTitle={"Edit a Global Setting"}
          />
          <Spacer />
          <Stack direction={"row"} alignSelf={"flex-end"}>
            <PosFormButton
              onClick={() => {
                // pass param data in tax list page
                props.navigate(
                  "/" +
                    process.env.REACT_APP_MASTER_ADMIN_LOGIN_ROUTE +
                    Constants.MASTER_ADMIN_GLOBLE_SETTINGS_PATH,
                  {
                    state: stateData.state,
                  }
                );
              }}
              buttonText={"Cancel"}
              CancelButton={true}
              isDisabled={buttonDisable}
            />
            <PosFormButton
              isDisabled={
                formik.values.setting_display_name.length <= 0 ||
                formik.errors.setting_display_name ||
                formik.errors.input_type ||
                formik.errors.field_sort ||
                (undefined != formik.values.input_type &&
                  null != formik.values.input_type &&
                  "" != formik.values.input_type &&
                  1 == formik.values.input_type &&
                  (globalSettingOptions.length <= 0 || inputvalid > -1)) ||
                buttonDisable
                  ? true
                  : false
              }
              buttonsubmit={"Submit"}
              SubmitButton={true}
              onClick={formik.handleSubmit}
            />
          </Stack>
        </Flex>
        <Box
          mt={{ base: 4, lg: 12 }}
          ml={{ base: 4, lg: 14 }}
          mr={{ base: 4, lg: 14 }}
          borderRadius="0.63rem"
          bg={Colors.posPageDataBackground}
          boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
          p="8"
        >
          <Text
            fontSize="1.125rem"
            w="10.5rem"
            color={Colors.posTextInfo}
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              letterSpacing: "-0.27px",
            }}
          >
            Basic Details
          </Text>
          <Stack spacing={3}>
            <PosLable
              fontWeight={"500"}
              requiredLabel={true}
              name={"Display Name: (Ex: Pax IP)"}
            />
            <PosInput
              id="setting_display_name"
              placeholder={"Enter Display Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.setting_display_name}
              handleBlur={(e) => {
                formik.setFieldValue(
                  "setting_display_name",
                  formik.values.setting_display_name.trim()
                );
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInput={true}
              inputType={"text"}
              inputError={
                formik.touched.setting_display_name &&
                formik.errors.setting_display_name
              }
            />
            <Text color={Colors.errorColor}>
              {formik.touched.setting_display_name &&
              formik.errors.setting_display_name ? (
                <span>{formik.errors.setting_display_name}</span>
              ) : null}
            </Text>
          </Stack>
          <PosLable
            fontWeight={"500"}
            requiredLabel={true}
            name={"Key: (Ex: pax_ip)"}
          />
          <PosInput
            id="setting_key"
            placeholder={"Enter Key"}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.setting_key}
            handleBlur={(e) => {
              formik.setFieldValue(
                "setting_key",
                formik.values.setting_key.trim()
              );
              formik.handleBlur(e);
            }}
            onKeyDown={handleKeyDown}
            posInput={true}
            inputType={"text"}
            inputError={formik.touched.setting_key && formik.errors.setting_key}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.setting_key && formik.errors.setting_key ? (
              <span>{formik.errors.setting_key}</span>
            ) : null}
          </Text>
          <PosLable
            fontWeight={"500"}
            requiredLabel={true}
            name="Value: (Select Input Type)"
          />
          <Stack mt={2} direction={{ base:"column", md: "row" }}>
            {inputTypeOptions.map((option, index) => {
              const radio = getInputTypeRadioProps({
                value: option.value,
              });
              return (
                <CardRadioSlot
                  key={index}
                  {...radio}
                  isChecked={formik.values.input_type == option.value}
                >
                  <Flex flexDirection="row" alignItems="center">
                    <Text
                      fontWeight="bold"
                      color={Colors.posTextInfo}
                      textAlign="center"
                    >
                      {option.title}
                    </Text>
                  </Flex>
                </CardRadioSlot>
              );
            })}
          </Stack>
          <Text color={Colors.errorColor}>
            {formik.touched.type && formik.errors.type ? (
              <span>{formik.errors.type}</span>
            ) : null}
          </Text>
          {formik.values.input_type == 1 && globalSettingOptions.length > 0 && (
            <Box
              borderRadius="0.63rem"
              mt={"0.88rem"}
              bg={Colors.posPageDataBackground}
              boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
            >
              <PosTable
                columnNames={columnProductViewNames}
                rawdata={modifiedViewData}
                footerHide={true}
                singleDeleteButton={true}
                goSingleDelete={(id) => handleSingleDelete(id)}
              />
            </Box>
          )}
          <Stack spacing={3} mt="1rem">
            <Box display={"flex"}>
              <Box w={{ base: "100%", md: "100%" }}>
                <PosLable
                  name={"Category"}
                  label={true}
                  requiredLabel={true}
                  fontWeight={500}
                ></PosLable>
                <PosDropDown
                  width={"100%"}
                  value={selectedCategory}
                  onChange={handleOptionSelect}
                  onBlur={(e) => {
                    formik.setFieldTouched("selectedCategory");
                    formik.handleBlur(e);
                  }}
                  options={categories}
                  optionLabel={"name"}
                  lableAvailable={true}
                  className="w-full md:w-20rem"
                  style={{ width: "100%" }}
                  placeholder={"Select Category"}
                ></PosDropDown>
                <Text color={Colors.errorColor}>
                  {formik.touched.selectedCategory &&
                  formik.errors.selectedCategory ? (
                    <span>{formik.errors.selectedCategory}</span>
                  ) : null}
                </Text>
              </Box>
              <Box
                display={"flex"}
                alignItems={
                  formik.errors?.selectedCategory?.length > 0
                    ? "center"
                    : "flex-end"
                }
                justifyContent={"center"}
                w={"3rem"}
                ml={"1rem"}
              >
                <IconButton
                  onClick={() => openDialog()}
                  sx={{
                    marginTop:
                      formik.errors?.selectedCategory?.length > 0 ? "2rem" : 0,
                  }}
                  isRound={true}
                >
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
            <PosLable
              fontWeight={"500"}
              requiredLabel={true}
              name={"Field Sort"}
            />
            <PosInput
              id="field_sort"
              placeholder={"Enter Field Sort"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.field_sort}
              handleBlur={(e) => {
                formik.handleBlur(e);
                formik.setFieldValue("field_sort", formik.values.field_sort);
              }}
              onKeyDown={handleKeyDown}
              posNumberInput={true}
              inputType={"number"}
              inputError={formik.touched.field_sort && formik.errors.field_sort}
            />
            <Text color={Colors.errorColor}>
              {formik.touched.field_sort && formik.errors.field_sort ? (
                <span>{formik.errors.field_sort}</span>
              ) : null}
            </Text>
          </Stack>
          <Flex
          flexDirection={"row"} >
          <PosLable
            fontWeight={"500"}
            requiredLabel={true}
            label={true}
            name={"Required"}
          />
            <PosSwitchButton
              id="required"
              switchValue={formik.values.required}
              onChange={formik.handleChange}
              mt={"2rem"}
              ml={"1.5rem"}
            />
            </Flex>
        </Box>
        <>
          <Modal
            isOpen={isRewardModalOpen}
            onClose={onRewardModalClose}
            size={"lg"}
            scrollBehavior={"inside"}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add New Category</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <PosLable
                  fontWeight={500}
                  requiredLabel={true}
                  name={"Name"}
                  label={true}
                />
                <PosInput
                  id="name"
                  placeholder={"Enter Category Name"}
                  color={Colors.posTextInfo}
                  handleInputChange={formikModal.handleChange}
                  inputValue={formikModal.values.name}
                  handleBlur={formikModal.handleBlur}
                  onKeyDown={handleKeyDown}
                  posInput={true}
                  inputType={"text"}
                  inputError={
                    formikModal.touched.name && formikModal.errors.name
                  }
                />
                <Text color={Colors.errorColor}>
                  {formikModal.touched.name && formikModal.errors.name ? (
                    <span>{formikModal.errors.name}</span>
                  ) : null}
                </Text>
              </ModalBody>
              <ModalFooter>
                <Flex mb={"2.69rem"}>
                  <PosFormButton
                    buttonText={"Cancel"}
                    CancelButton={true}
                    // ref={cancelRef}
                    onClick={handleCloseDialog}
                    isDisabled={buttonDisable}
                  />
                  <PosFormButton
                    isDisabled={
                      formikModal.values.name.length <= 0 ||
                      formikModal.errors.name ||
                      buttonDisable
                        ? true
                        : false
                    }
                    buttonsubmit={"Submit"}
                    SubmitButton={true}
                    onClick={formikModal.handleSubmit}
                  />
                  {/* <Button
                    colorScheme="messenger"
                    ml={3}
                    SubmitButton={true}
                    onClick={formikModal.handleSubmit}
                    isDisabled={
                      formikModal.values.name.length <= 0 ||
                      formikModal.errors.name ||
                      buttonDisable
                        ? true
                        : false
                    }
                  >
                    {"Submit"}
                  </Button> */}
                </Flex>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      </Box>
    </Flex>
  );
};

export default WithRouter(MasterAdminGlobalSettingEdit);
