import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
  useMediaQuery,
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosInput,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
import { twofix } from "../../../../../helpers/utils/Utils";

const InventorySummary = (props) => {
  const { addToast } = PosTostMessage();
  const actionRun = useRef(false);
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [tableTotalData, setTableTotalData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isExtraLarge] = useMediaQuery("(min-width: 1710px)");
  const [isLarge] = useMediaQuery("(max-width: 1435px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 150 } : { flex: 0.8 };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "in_stock_qty",
      sort: "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [searchButton, setSearchButton] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    // total_cash_discount: false,
  });
  const columnNames = [
    {
      field: "name",
      headerName: "Product Name",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "barcode",
      headerName: "Primary Barcode",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "in_stock_qty",
      headerName: "In Stock Quantity",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "value_based_on_retail",
      headerName: "Valuation Based on Retail($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "value_based_on_cost",
      headerName: "Valuation Based on Cost($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "location_id",
      headerName: "Location",
      sortable: false,
      ...actionColumnXS,
    },
  ];

  const totalColumnName = [
    {
      field: "total_valuation_retail",
      headerName: "Total Valuation Based on Retail($)",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "total_valuation_cost",
      headerName: "Total Valuation Based on Cost($)",
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  ];
  const [loading, setLoading] = useState(true);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      // selectedLoactions: locations.length > 0 ? [locations[0]] : [],
      // selectedLoactions:  undefined !== loaderResponse?.data?.data?.location &&
      // null !== loaderResponse?.data?.data?.location && loaderResponse?.data?.data?.location?.length === 1
      //   ? loaderResponse?.data?.data?.location : [],
      selectedLoactions: [],
      selectedVendors: [],
      min_in_stock: "",
      max_in_stock: "",
    },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLoactions: Yup.array().min(1, Constants.LOCATION_IS_REQUIRED),
      min_in_stock: Yup.number()
        .typeError(Constants.MIN_QUA_NOT_VALID)
        .max(999999.99, Constants.MIN_IN_STOCK_QUA_GREATER_THAN_NOT_VALID),
      max_in_stock: Yup.number()
        .typeError(Constants.MAX_QUA_NOT_VALID)
        .max(999999.99, Constants.MAX_IN_STOCK_QUA_GREATER_THAN_NOT_VALID),
    }),
    onSubmit: async (values) => {},
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setLocations(loaderResponse?.data?.data?.location);
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setOlColumnVisibilityModel((prev) => ({
              ...prev,
              location_id: false,
            }));
          } else if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length > 1
          ) {
            setOlColumnVisibilityModel((prev) => ({
              ...prev,
              location_id: true,
            }));
          }
          formik.setFieldValue("selectedLoactions", [loaderResponse?.data?.data?.location[0]]);
          setVendors(loaderResponse?.data?.data?.vendor);

          // getInventorySummaryData();

          setButtonDisable(true);
          setLoading(true);
          setShowTable(false);
          const formattedData = {
            location: [loaderResponse?.data?.data?.location[0]],
            vendor: formik.values.selectedVendors,
            max_in_stock: formik.values.max_in_stock,
            min_in_stock: formik.values.min_in_stock,
          };
          let data = {
            getreport: true,
            filter: searchQuery,
            data: JSON.stringify(formattedData),
            query: JSON.stringify({
              limit: paginationModel.pageSize,
              filter: searchQuery,
              page: paginationModel.page + 1,
              order:
                sortModel.length > 0
                  ? sortModel[0].field
                  : sortRef.current[0].field,
              order_type:
                sortModel.length > 0
                  ? sortModel[0].sort
                  : sortRef.current[0].sort,
            }),
          };
          submit(data, {
            method: Constants.POST,
            path: Constants.INVENTORY_SUMMARY_REPORT_ROUTE,
          });

          myContext.handleLoading(false);
        }
      }
      // return () => {
      effectRun.current = false;
      // };
    }
    getInventorySummaryData();
  }, [paginationModel, sortModel]);
  // useEffect(() => {
  //   if (locations.length > 0) {
  //     formik.setFieldValue("selectedLoactions", [locations[0]]);
  //     getInventorySummaryData();
  //   }
  // }, [formik.values.selectedLoactions]);
  useEffect(() => {
    // if (effectRun.current === true) {
    // getInventorySummaryData();
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTableTotalData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Inventory Summary Report successfully exported."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.INVENTORY_SUMMARY_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
          // actionRun.current = false;
        } else {
          setTableData(actionResponse.data.data.data);
          let total = [
            {
              id: 1,
              total_valuation_retail:
                undefined != actionResponse?.data?.data?.total_valuation_retail
                  ? actionResponse?.data?.data?.total_valuation_retail
                  : "N.A.",
              total_valuation_cost:
                undefined != actionResponse?.data?.data?.total_valuation_cost
                  ? actionResponse?.data?.data?.total_valuation_cost
                  : "N.A.",
            },
          ];
          setTableTotalData(total);
          setTotalCount(actionResponse.data.data.totalItems);
          setSearchButton(false);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTableTotalData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
    // }
  }, [actionResponse]);

  const handleExportReport = () => {
    if (formik.values.selectedLoactions.length > 0) {
      myContext.handleLoading(true);
      let data = {
        getreport: false,
        filter: searchQuery,
        location: JSON.stringify(formik.values.selectedLoactions),
        vendor: JSON.stringify(formik.values.selectedVendors),
        max_in_stock: formik.values.max_in_stock,
        min_in_stock: formik.values.min_in_stock,
        type: "xls",
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.INVENTORY_SUMMARY_REPORT_ROUTE,
      });
    } else {
      clearTableData(Constants.LOCATION_IS_REQUIRED);
    }
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTableTotalData([]);
    setShowTable(true);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getInventorySummaryData = () => {
    // actionRun.current = true;
    if (
      formik.values.min_in_stock !== "" &&
      formik.values.min_in_stock !== null &&
      formik.values.max_in_stock !== "" &&
      formik.values.max_in_stock !== null
    ) {
      if (
        Number(formik.values.min_in_stock) > Number(formik.values.max_in_stock)
      ) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.REPORT_MODULE,
          alertDescription: Constants.MIN_IN_STOCK_QUA_GREATER_THAN_MAX_QUA,
        });
      } else if (formik.values.selectedLoactions.length > 0) {
        setButtonDisable(true);
        setLoading(true);
        setShowTable(false);
        const formattedData = {
          location: formik.values.selectedLoactions,
          vendor: formik.values.selectedVendors,
          max_in_stock: formik.values.max_in_stock,
          min_in_stock: formik.values.min_in_stock,
        };
        let data = {
          getreport: true,
          filter: searchQuery,
          data: JSON.stringify(formattedData),
          query: JSON.stringify({
            limit: paginationModel.pageSize,
            filter: searchQuery,
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
          }),
        };
        submit(data, {
          method: Constants.POST,
          path: Constants.INVENTORY_SUMMARY_REPORT_ROUTE,
        });
      }
    } else if (formik.values.selectedLoactions.length > 0) {
      setButtonDisable(true);
      setLoading(true);
      setShowTable(false);
      const formattedData = {
        location: formik.values.selectedLoactions,
        vendor: formik.values.selectedVendors,
        max_in_stock: formik.values.max_in_stock,
        min_in_stock: formik.values.min_in_stock,
      };
      let data = {
        getreport: true,
        filter: searchQuery,
        data: JSON.stringify(formattedData),
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          filter: searchQuery,
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.INVENTORY_SUMMARY_REPORT_ROUTE,
      });
    }
  };
  const getInventorySummaryDataAfterClear = () => {
    // actionRun.current = true;
    if (
      formik.values.min_in_stock !== "" &&
      formik.values.min_in_stock !== null &&
      formik.values.max_in_stock !== "" &&
      formik.values.max_in_stock !== null
    ) {
      if (
        Number(formik.values.min_in_stock) > Number(formik.values.max_in_stock)
      ) {
        addToast({
          alertStatus: Constants.TOAST_TYPE_WARNING,
          alertTitle: Constants.REPORT_MODULE,
          alertDescription: Constants.MIN_IN_STOCK_QUA_GREATER_THAN_MAX_QUA,
        });
      } else if (formik.values.selectedLoactions.length > 0) {
        setButtonDisable(true);
        setLoading(true);
        setShowTable(false);
        const formattedData = {
          location: formik.values.selectedLoactions,
          vendor: formik.values.selectedVendors,
          max_in_stock: formik.values.max_in_stock,
          min_in_stock: formik.values.min_in_stock,
        };
        let data = {
          getreport: true,
          filter: "",
          data: JSON.stringify(formattedData),
          query: JSON.stringify({
            limit: paginationModel.pageSize,
            filter: "",
            page: paginationModel.page + 1,
            order:
              sortModel.length > 0
                ? sortModel[0].field
                : sortRef.current[0].field,
            order_type:
              sortModel.length > 0
                ? sortModel[0].sort
                : sortRef.current[0].sort,
          }),
        };
        submit(data, {
          method: Constants.POST,
          path: Constants.INVENTORY_SUMMARY_REPORT_ROUTE,
        });
      }
    } else if (formik.values.selectedLoactions.length > 0) {
      setButtonDisable(true);
      setLoading(true);
      setShowTable(false);
      const formattedData = {
        location: formik.values.selectedLoactions,
        vendor: formik.values.selectedVendors,
        max_in_stock: formik.values.max_in_stock,
        min_in_stock: formik.values.min_in_stock,
      };
      let data = {
        getreport: true,
        filter: "",
        data: JSON.stringify(formattedData),
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          filter: "",
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.INVENTORY_SUMMARY_REPORT_ROUTE,
      });
    }
  };
  const handleOptionSelect = (e) => {
    formik.setFieldValue("selectedLoactions", e.value);
  };

  const handleVendorOptionSelect = (e) => {
    formik.setFieldValue("selectedVendors", e.value);
  };
  const handleResetAllData = () => {
    setSearchQuery("");
    if (locations.lenght > 1 ){
      formik.setFieldValue("selectedLoactions", []);
    }
    setSortModel([
      {
        field: "in_stock_qty",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setTableTotalData([]);
    setShowTable(true);
    setTotalCount(0);
    formik.resetForm();
  };

  const modifiedData = tableData.map((data) => ({
    id: data.id,
    name: data.name,
    barcode: data.barcode,
    in_stock_qty: data.in_stock_qty,
    value_based_on_retail:
      undefined != data.valuation_based_on_retail &&
      null != data.valuation_based_on_retail &&
      "" != data.valuation_based_on_retail
        ? data.valuation_based_on_retail
        : "N.A.",
    value_based_on_cost:
      undefined != data.valuation_based_on_cost &&
      null != data.valuation_based_on_cost &&
      "" != data.valuation_based_on_cost
        ? data.valuation_based_on_cost
        : "N.A.",
    location_id: data.location_with_trashed.name,
  }));
  const handleSearchList = () => {
    // if (searchQuery.trim.length > 0) {
    getInventorySummaryData();
    // }
  };
  const renderReportLayout = () => {
    return (
      <>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isLarge ? "column" : "row"}
          spacing={4}
          width={isLarge ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDropDown
                options={locations}
                value={formik.values.selectedLoactions}
                onChange={handleOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedLoactions");
                  formik.handleBlur(e);
                }}
                multiSelect={true}
                optionLabel="name"
                placeholder="Select Locations*"
                width={isExtraLarge ? "17rem" : "100%"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
                maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                  <span>{formik.errors.selectedLoactions}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDropDown
                options={vendors}
                value={formik.values.selectedVendors}
                onChange={handleVendorOptionSelect}
                onBlur={(e) => {
                  formik.setFieldTouched("selectedVendors");
                  formik.handleBlur(e);
                }}
                multiSelect={true}
                optionLabel="name"
                placeholder="Select Vendors"
                width={isExtraLarge ? "17rem" : "100%"}
                height={"2.5rem"}
                className="w-full md:w-20rem"
                maxSelectedLabels={2}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.selectedVendors &&
                formik.errors.selectedVendors ? (
                  <span>{formik.errors.selectedVendors}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
          <Stack
            width={"100% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosInput
                id="min_in_stock"
                placeholder={"Enter Min In Stock"}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.min_in_stock}
                handleBlur={(e) => {
                  formik.setFieldValue("min_in_stock", twofix(e.target.value));
                  formik.handleBlur(e);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                // onKeyDown={handleKeyDown}
                posNumberInput={true}
                inputType={"text"}
                darkPlaceholder={true}
                inputError={
                  formik.touched.min_in_stock && formik.errors.min_in_stock
                }
              />
              <Text color={Colors.errorColor}>
                {formik.touched.min_in_stock && formik.errors.min_in_stock ? (
                  <span>{formik.errors.min_in_stock}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosInput
                id="max_in_stock"
                placeholder={"Enter Max In Stock"}
                handleInputChange={formik.handleChange}
                inputValue={formik.values.max_in_stock}
                handleBlur={(e) => {
                  formik.setFieldValue("max_in_stock", twofix(e.target.value));
                  formik.handleBlur(e);
                }}
                width={isExtraLarge ? "17rem" : "100%"}
                // onKeyDown={handleKeyDown}
                posNumberInput={true}
                darkPlaceholder={true}
                inputType={"text"}
                inputError={
                  formik.touched.max_in_stock && formik.errors.max_in_stock
                }
              />
              <Text color={Colors.errorColor}>
                {formik.touched.max_in_stock && formik.errors.max_in_stock ? (
                  <span>{formik.errors.max_in_stock}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
        </Stack>
      </>
    );
  };
  const handleClear = () => {
    setSearchQuery("");
    setSearchButton(true);
    getInventorySummaryDataAfterClear();
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Inventory Summary"]}
          breadCrumTitle={"Inventory Summary"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isLarge ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack
            gap={4}
            flex={1}
            flexShrink={"0"}
            direction={"column"}
            m={4}
            alignItems={"flex-start"}
          >
            {renderReportLayout()}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={isLarge ? "flex-start" : "flex-end"}
            alignItems={"center"}
            pl={isLarge ? 4 : 0}
          >
            <PosFormButton
              onClick={handleResetAllData}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLoactions?.length === 0 ||
                formik.errors.selectedLoactions ||
                formik.errors.min_in_stock ||
                formik.errors.max_in_stock ||
                buttonDisable
              }
              onClick={getInventorySummaryData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : searchQuery.length > 0 ||
            totalcount > 0 ||
            searchButton ? (
            <Flex mb={2} mr={2} flexDirection={{ base: "column", md: "row" }}>
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                  if (e.target.value == null || "" == e.target.value) {
                    getInventorySummaryDataAfterClear();
                  }
                }}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
            </Flex>
          ) : null}
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={setPaginationModel}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.INVENTORY_SUMMARY_REPORT_CSV}
              />
              <PosDataGridTable
                columns={totalColumnName}
                rows={tableTotalData}
                hideFooter={true}
              />
            </Flex>
          ) : (
            <PosNoDataFound title={"There are no Inventory Summary."} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(InventorySummary);
