import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  ButtonGroup,
  ChakraProvider,
  Divider,
  Flex,
  Image,
  Img,
  Input,
  Select,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useMediaQuery,
  VStack,
  WrapItem,
  useDisclosure,
} from "@chakra-ui/react";
import { Checkbox } from "@mui/material";
import { Dialog } from "primereact/dialog";
import { Checkbox as PrimeReactCheckbox} from "primereact/checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { GridToolbar } from "@mui/x-data-grid";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import moment from "moment";
// import { AutoComplete } from "primereact/autocomplete";
import Autocomplete from '@mui/material/Autocomplete';
import { InputAdornment } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Calendar } from "primereact/calendar";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import invoice from "../../../assets/images/invoice.svg";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosInput,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosSwitchButton,
  PosTostMessage
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { endOfTodaysDay, startOfTodaysDay } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import { Mtheme, theme } from "../../../theme/index";
import {
  editManualProductList,
  editPurchaseOrderProductList,
  getProductForEdit,
} from "./PurchaseOrderService";
import { Search2Icon } from "@chakra-ui/icons";

const PurchaseOrderEdit = (props) => {
  const [buttonDisable, setButtonDisable] = useState(false);
  const locationRef = useRef(false);
  const fetchRef = useRef(false);
  const stateData = useLocation();
  const myContext = useContext(AppContext);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const checkedItemsRef = useRef(false)
  const actionRun = useRef(false);
  const effectRun = useRef(true);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [productData, setProductData] = useState([]);
  const [allLocationProductFLag, setAllLocationProductFLag] = useState("");
  const [searchQuery, setSearchQuery] = useState(
    undefined !== stateData.state &&
      null !== stateData.state &&
      "" !== stateData.state
      ? stateData.state.filter
      : ""
  );
  const oldselectedRef = useRef([])
  const [sortType, setSortType] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [checkedItems, setCheckedItems] = React.useState([]);
  const [viewSelectedProducts, setViewSelectedProducts] = React.useState([]);
  const [modalOpen, setModal] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedLocationData, setSelectedLocationData] = useState([]);
  const [locationAllProducts,setLocationAllProduct] = useState([])
  const [locationData, setLocationData] = useState([]);
  const [selectedVendorData, setSelectedVendorData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [purchaseOrderData, setPurchaseOrderData] = useState("");
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [perchesOrderData, setPerchesOrderData] = useState([]);
  const [perchesOrderUserData, setPerchesOrderUserData] = useState([]);
  const [selectedQuantityUnit, setSelectedQuantityUnit] = useState(
    loaderResponse?.data?.data?.PurchaseOrder?.status
  );
  const calendarRef = useRef(Calendar);
  const calendarRef1 = useRef(Calendar);
  const [isChecked, setIsChecked] = useState(false);
  const [addProductClicked, setAddProductClicked] = useState(false);
  const [thresholdProductClicked, setThresholdProductClicked] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    categories: false,
  });
  const selectedProductRef = useRef([])
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: "name",
      sort: "asc",
    },
  ]);
  const selectedProdQtyRef = useRef([]);
  const modalSizes = useBreakpointValue({
    base: "half",
    sm: "2md",
    md: "2lg",
    lg: "md",
    xl: "lg",
  });
  const [products, setProducts] = useState([]);
  const [autoSelectedProducts, setAutoSelectedProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);

  const effectRunData = useRef(true);
  const inputRefs = useRef([]);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading1 = open && options.length === 0;
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isMobileDialog] = useMediaQuery("(max-width: 992px)");
  const [timeoutId, setTimeoutId] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const {
    isOpen: isProductModalOpen,
    onOpen: onProductModalOpen,
    onClose: onProductModalClose,
  } = useDisclosure();
  const selectedProductDataRef = useRef([]);

  const sleep = (duration) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }
  const [value, setValue] = useState(options[0]);
  const [inputValueAuto, setInputValueAuto] = useState('');
  const [isAllProductsChecked, setIsAllProductsChecked] = useState(true);

useEffect(() => {
  let active = true;

  if (!loading1) {
    return undefined;
  }

  (async () => {
    await sleep(450); // For demo purposes.
    if (active) {
      search();
    }
  })();

  return () => {
    active = false;
  };
}, [loading1]);
useEffect(() => {
  if (!open) {
    setOptions([]);
  }
}, [open]);

  const search = () => {
    let data = {
      created_at: purchaseOrderData.created_at,
      deleted_at: purchaseOrderData.deleted_at,
      id: purchaseOrderData.id,
      show_all: isAllProductsChecked == true ? true : false,
      location: selectedLocationData.id,
      location_id: selectedLocationData.id,
      locationCity: purchaseOrderData.locations?.city,
      locationCountry: purchaseOrderData.locations?.country,
      locationState: purchaseOrderData.locations?.state,
      vendors: {
        id: selectedVendorData.id,
      },
      locations: {
        id: selectedLocationData.id,
      },
      vendor: selectedVendorData.id,
      users: {
        id: perchesOrderUserData.id,
      },
      filter: inputValueAuto,
      meilisearch: true,
      query: {
        limit: 1000,
        page: 0,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: inputValueAuto,
        allProdFlag: allLocationProductFLag === 1 ? 1 : 0,
      },
    };
    try {
      editManualProductList(data)
        .then((response) => {
          if (response.data.flag === true) {
            setProducts(response.data.data.data);
            setProductData(response.data.data.data);
            setOptions(response.data.data.data);
            setAllLocationProductFLag(
              response.data.data.allLocationProductFLag
            );
            // setTimeout(() => {
            //   let _filteredProducts;
            //   if (!event.query.trim().length) {
            //     _filteredProducts = [...response.data.data.data];
            //   } else {
            //     _filteredProducts = response.data.data.data.filter((product) => {
            //       return (
            //         (product.name.toLowerCase().includes(event.query.toLowerCase()) ||
            //         product.barcode.toLowerCase().startsWith(event.query.toLowerCase()))
            //       );
            //     });
            //   }
            //   setFilteredProducts(_filteredProducts);
            // }, 350);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            setProducts([]);
            setTotalCount(0);
            setLoading(false);
            setOptions([]);
            setOpen(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setProducts([]);
          setTotalCount(0);
          setOptions([]);
          setOpen(false);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setProductData([]);
      setTotalCount(0);
      setLoading(false);
      setOptions([]);
      setOpen(false);
      myContext.handleLoading(false);
    }
};

const autoCompleteSelectedProducts= (pro) => {
  if(undefined != pro && null != pro && '' != pro){
    if(!checkedItems.includes(pro.id)){
      var proAutoData = {
        id: pro.id,
        name: pro.name,
        barcode: pro.barcode,
        sku: pro.sku || "N.A.",
        in_stock_qty: pro.in_stock_qty,
        sale_price: pro.sale_price,
        location: pro.location.name,
        threshold: pro.threshold || "N.A.",
        unit_case:pro.no_of_eaches || "",
        autoCompleteProduct:true,
        categories: pro.categories,
      }
      handleCheckboxChange({row:proAutoData});
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
    else{
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.PURCHASING_ORDER_MODULE,
        alertDescription:'Product already exists.',
      });
      setAutoSelectedProducts("");
      setInputValueAuto("");
    }
    // setAutoSelectedProducts('');
  }
}

  const unselectProducts = () => {
    if (!isChecked) {
      // setIsChecked(false);
    } else {
      const selectedProductIds = viewSelectedProducts.map(
        (product) => product.product_id || product.id
      );
      setCheckedItems(selectedProductIds);
      setPerchesOrderData(selectedProdQtyRef.current);
    }
    // fetchData();
  };
  const handleSwitchChange = (e) => {
    setSelectedCategories([]);
    const checked = e.target.checked;
    setSearchQuery("");
    fetchRef.current = true;
    setIsChecked(checked);
    if (checked) {
    } else {
      unselectProducts();
    }
  }; 
  const handleAllProductSwitchChange = (e) => {
    setSelectedCategories([]);
    const checked = e.target.checked;
    setSearchQuery("");
    fetchRef.current = true;
    setIsAllProductsChecked(checked);
    const selectedProductIds = viewSelectedProducts.map(
      (product) => product.product_id || product.id
    );
    setCheckedItems(selectedProductIds);
    setPerchesOrderData(selectedProdQtyRef.current);
    if(isChecked){
      setIsChecked(false);
    }
  }; 
  
  const handleClear = () => {
    // clear input value and get all data
    fetchRef.current = true;
    setSearchQuery("");
    // effectRun.current = true;
  };

  const onSelectLocation = (e) => {
    if (undefined !== e.value) {
    locationRef.current = true;
    setSelectedLocationData(e.value);
    setPerchesOrderData([]);
    setViewSelectedProducts([]);
    setCheckedItems([]);
    setIsChecked(false);
    selectedProductRef.current = []
      setSelectAll(false);
    setIsLocationChanged(true);
    formik.setFieldValue("locations", e.value.name);
    setFilteredProducts('');
    setProducts([]);
    // setAutoSelectedProducts('');
  }
  };

  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
    locationRef.current = true;
    setSelectedVendorData(e.value);
    setPerchesOrderData([]);
    setViewSelectedProducts([]);
    setCheckedItems([]);
    setIsChecked(false);
    selectedProductRef.current = []
      setSelectAll(false);
    setIsVendorChanged(true);
    formik.setFieldValue("vendors", e.value.name);
    setFilteredProducts('');
    setProducts([])
    // setAutoSelectedProducts('')
  }
  };

  const handleCloseModal = () => {
    setModal(false);
    setSelectAll(false);
    // const selectedProductIds = viewSelectedProducts.map(
    //   (product) => product.product_id
    // );
    // setCheckedItems(selectedProductIds);
    const selectedProductIds = viewSelectedProducts.map(
      (product) => product.product_id || product.id
    );
    setCheckedItems(selectedProductIds);
    selectedProductRef.current = oldselectedRef.current
    setPerchesOrderData(selectedProdQtyRef.current);
    setSearchQuery("");
    setIsChecked(false);
  };

  function handleCheckboxChange(params) {
    const rowId = params.row.id;
    let sDataIndex = selectedProductData.findIndex((x) => x.id == rowId);
    if (sDataIndex > -1) {
      let array = [
        ...selectedProductData.slice(0, sDataIndex),
        ...selectedProductData.slice(sDataIndex + 1),
      ];
      setSelectedProductData(array);
    } else {
      let prodData = [...selectedProductData, params.row];
      setSelectedProductData(prodData);
    }
    let dataIndex = productData.findIndex((x) => x.id == rowId);
    let prodIndex = selectedProductRef.current.findIndex((x) => x.id == rowId);
    if (prodIndex <= -1 || undefined == params.row.autoCompleteProduct || !params.row.autoCompleteProduct) {
    if(prodIndex > -1) {
      selectedProductRef.current = [
        ...selectedProductRef.current.slice(0, prodIndex),
        ...selectedProductRef.current.slice(prodIndex + 1),
      ];
    } else {
      let getPayload = productData.filter((x) => x.id == rowId)
      selectedProductRef.current = [
        ...selectedProductRef.current,
        ...getPayload,
      ];
    }
    }
    let data = [];
    let array = [];
    if (checkedItems.includes(rowId)) {
      let index = checkedItems.findIndex((x) => x === rowId);
      if (index > -1) {
        data = [
          ...checkedItems.slice(0, index),
          ...checkedItems.slice(index + 1),
        ];
        array = [
          ...perchesOrderData.slice(0, index),
          ...perchesOrderData.slice(index + 1),
        ];
        setCheckedItems(data);
        setPerchesOrderData(array);
      }
    } else {
      let index = selectedProdQtyRef.current.findIndex((x) => x.id == rowId);
      // let prodId = locationAllProducts.findIndex((x) => x.id == rowId);
      let obj = {
        id: rowId,
        quantity: index > -1 ? parseInt(selectedProdQtyRef.current[index].quantity) : "",
        cost:
          index > -1
            ? selectedProdQtyRef.current[index]?.cost
            : null != productData[dataIndex]?.cost
            ? productData[dataIndex]?.cost
            : "",
        unit_case:
          index > -1
            ? selectedProdQtyRef.current[index]?.unit_case
            : null != productData[dataIndex]?.no_of_eaches
            ? productData[dataIndex]?.no_of_eaches
            : "",
        quantityUnit:
          index > -1 ? selectedProdQtyRef.current[index].quantityUnit : "CAS",
          costErrors:
          index > -1 ? selectedProdQtyRef.current[index].costErrors : "",
        quantityErrors:
          index > -1 ? selectedProdQtyRef.current[index].quantityErrors : "",
        unitCaseErrors:
          index > -1 ? selectedProdQtyRef.current[index].unitCaseErrors : "",
      };
      array = [...perchesOrderData, obj];
      data = [...checkedItems, rowId];
      setCheckedItems(data);
      setPerchesOrderData(array);
    }
    if(params.row.autoCompleteProduct){
      checkedItemsRef.current = true;
      effectRunData.current = true;
    }
  }

  useEffect(() => {
    if(checkedItemsRef.current) {
      handleSelectedViewProduct();
      checkedItemsRef.current = false;
    }
  },[checkedItems]);

  const handleSelectedViewProduct = () => {
    setVisible(false);
    setModal(false);
    selectedProductRef.current = selectedProductData;
    let removeItems =  viewSelectedProducts.filter((product) =>
        checkedItems.includes(product?.product_id || product?.id)
      );
    let selectedProducts = [...removeItems];
    checkedItems.map((id) => {
      let index = selectedProducts.findIndex((x) => x?.id === id || x?.product_id === id);
      if (index <= -1) {
        let item = selectedProductRef.current.filter((product) => product?.id === id);
        if(undefined !== item[0] && null !== item[0]){
          // selectedProducts.push(item[0]);
          let searchViewExist = selectedProducts.findIndex((x) => x.additionalRow == true);
          if(searchViewExist >= 0){
            selectedProducts.splice(selectedProducts.length-1, 0, item[0]);
          }
          else{
            selectedProducts.splice(selectedProducts.length, 0, item[0]);
          }
        }
      }
    });
    setViewSelectedProducts(selectedProducts);
    setSearchQuery("");
    setIsChecked(false);
    onProductModalClose();
    effectRunData.current = true;
    focusInputByIndex();
  };
  

  // const handleSingleDelete = (id) => {
  //   if (checkedItems.includes(id)) {
  //     const updatedCheckedItems = checkedItems.filter(
  //       (itemId) => itemId !== id
  //     );
  //     setCheckedItems(updatedCheckedItems);
  //     const updatedSelectedProducts = viewSelectedProducts.filter(
  //       (product) => product.product_id !== id
  //     );
  //     setViewSelectedProducts(updatedSelectedProducts);
  //     const updatedPerchesOrderData = perchesOrderData.filter(
  //       (item) => item.id !== id
  //     );
  //     setPerchesOrderData(updatedPerchesOrderData);
  //     fetchData();
  //     setModal(false);
  //     setSearchQuery("");
  //   }
  // };

  const handleSingleDelete = (id) => {
    if (checkedItems.includes(id)) {
      let dataIndex = selectedProductData.findIndex((x) => x.id == id);
      if (dataIndex > -1) {
        let array = [
          ...selectedProductData.slice(0, dataIndex),
          ...selectedProductData.slice(dataIndex + 1),
        ];
        setSelectedProductData(array);
        selectedProductRef.current = array;
      }
      const updatedCheckedItems = checkedItems.filter(
        (itemId) => itemId !== id
      );
      setCheckedItems(updatedCheckedItems);
      const updatedSelectedProducts = viewSelectedProducts.filter(
        (product) => product.product_id !== id && product.id !== id
      );
      setViewSelectedProducts(updatedSelectedProducts);
      const updatedPerchesOrderData = perchesOrderData.filter(
        (item) => item.product_id !== id && item.id !== id
      );
      setPerchesOrderData(updatedPerchesOrderData);
      selectedProductRef.current = selectedProductRef.current.filter(
        (product) => product.product_id !== id && product.id !== id
      );
      // fetchData();
      // setModal(false);
      // setSearchQuery("");
    }
  };

  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setPurchaseOrderData(loaderResponse.data.data.PurchaseOrder);
          setVendorData(loaderResponse.data.data.vendor);
          const vendors = loaderResponse.data.data.vendor;
          const purchaseOrderVendorId =
            loaderResponse.data.data.PurchaseOrder.vendors.id;
          const matchingVendors = vendors.find(
            (vendor) => vendor.id === purchaseOrderVendorId
          );
          setSelectedVendorData(matchingVendors);
          setLocationData(loaderResponse.data.data.location);
          const locations = loaderResponse.data.data.location;
          const purchaseOrderLocationId =
            loaderResponse.data.data.PurchaseOrder.locations.id;
          const matchingLocation = locations.find(
            (location) => location.id === purchaseOrderLocationId
          );
          setSelectedLocationData(matchingLocation);
          setViewSelectedProducts(
            loaderResponse.data.data.PurchaseOrder.purchas_order_items
          );
          let array = [];
          loaderResponse.data.data.PurchaseOrder.purchas_order_items.map(
            (item_obj) => {
              let obj = {
                id: item_obj.product_id,
                quantity: parseInt(item_obj.quantity),
                cost: item_obj.cost,
                quantityUnit: item_obj.quantity_unit,
                unit_case: item_obj.unit_case || "",
                total_unit: ("N.A." !=  item_obj.total_unit && undefined != item_obj.total_unit) ? parseFloat(item_obj.total_unit).toFixed(2) : "N.A.",
                cost_per_unit: ("N.A." != item_obj.cost_per_unit && undefined != item_obj.cost_per_unit) ? parseFloat(item_obj.cost_per_unit).toFixed(2) : "N.A.",
              };
              // array.push(obj);
              array.splice(array.length, 0, obj);
            }
          );
          if (array.length > 0) {
            setPerchesOrderData(array);
          }
          const productDetails =
            loaderResponse.data.data.PurchaseOrder.purchas_order_items;
          const productIds = productDetails.map(
            (product) => product.product_id
          );
          setCheckedItems(productIds);
          setPerchesOrderUserData(loaderResponse.data.data.PurchaseOrder.users);
          if (buttonClicked) {
            fetchData();
            setModal(true);
            setButtonClicked(false);
          }
          myContext.handleLoading(false);
        } else {
          let actionData = loaderResponse;
          error({ actionData });
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.PURCHASING_ORDER_PATH);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.PURCHASING_ORDER_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse, buttonClicked]);
  const modifiedData = productData.map((data) => ({
    id: data.id,
    name: data.name,
    barcode: data.barcode,
    sku: data.sku || "N.A.",
    in_stock_qty: data.in_stock_qty,
    sale_price: data.sale_price,
    location: data.location.name,
    threshold: data.threshold || "N.A.",
    unit_case:data.no_of_eaches || "",
    category_name: data.categories.map((cat) => cat.name).join(", "),
  }));
  const [selectAll, setSelectAll] = useState(false);
  
  const focusInputByIndex = () => {
  if (inputRefs.current[modifiedViewDatas.length-1]) {
    inputRefs.current[modifiedViewDatas.length-1].focus();
  }
};
  // const handleSelectAll = (event) => {
  //   if (event.target.checked) {
  //     let ids = checkedItems.map((row) => {
  //       if(modifiedData.filter((x) => x.id == row.id).length === 0){
  //         return row
  //       }
  //     })
  //     let allProdIds =  modifiedData.map((row) => row.id)
  //     let checkedIds = [...ids,...allProdIds]
  //     setCheckedItems(checkedIds);
  //     let prod = productData.map((row) => {
  //       let index = selectedProductRef.current.findIndex(x => x.id === row.id)
  //       if(index <= -1){
  //         return row
  //       }
  //     })
  //     selectedProductRef.current=[...selectedProductRef.current,...prod];
  //     let array = []
  //     checkedIds.map((rowId) => {
  //       let dataIndex = locationAllProducts.findIndex((x) => x.id == rowId);
  //       let index = selectedProdQtyRef.current.findIndex((x) => x.id == rowId);
  //       let obj = {
  //         id: rowId,
  //         quantity: index > -1 ? selectedProdQtyRef.current[index].quantity : "",
  //         cost:
  //           index > -1
  //             ? selectedProdQtyRef.current[index].cost
  //             : undefined != locationAllProducts[dataIndex]?.cost &&
  //               null != locationAllProducts[dataIndex]?.cost
  //             ? locationAllProducts[dataIndex]?.cost
  //             : "",
  //         quantityUnit:
  //           index > -1 ? selectedProdQtyRef.current[index].quantityUnit : "CAS",
  //         costErrors:
  //           index > -1 ? selectedProdQtyRef.current[index].costErrors : "",
  //         quantityErrors:
  //           index > -1 ? selectedProdQtyRef.current[index].quantityErrors : "",
  //       };
  //       array.push(obj)
  //     })
  //     setPerchesOrderData(array);
  //     setSelectAll(true);
  //   } else {
  //     selectedProductRef.current = [];
  //     setCheckedItems([]);
  //     setPerchesOrderData([]);
  //     setSelectAll(false);
  //   }
  // };
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnXS = isMobile ? { width: 90 } : { flex: 0.8 };
  const actionColumnSM = isMobile ? { width: 150 } : { flex: 0.9 };
  const actionColumnMD = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnLG = isMobile ? { width: 150 } : { flex: 1.1 };
  const actionColumnXL = isMobile ? { width: 150 } : { flex: 1.35 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 2 };

  const selectAllProduct = (isChecked) => {
    const pageData = modifiedData;
    const selectedIds = pageData.map((data) => data.id);
    let updatedCheckedItems, updatedSelectedProductData, updatedPerchaseData;
    if (isChecked) {
      // If the checkbox is checked, select all items on the current page      
      updatedCheckedItems = [...checkedItems, ...selectedIds.filter((id) => !checkedItems.includes(id))];
      updatedSelectedProductData = [
        ...selectedProductData,
        ...pageData.filter((data) => !selectedProductData.some((item) => item.id === data.id))
      ];
      const newPageData = pageData
        .filter(pageItem => !perchesOrderData.some(prodItem => prodItem.id === pageItem.id))
        .map(data => ({
          id: data.id,
          cost_per_unit: "", 
          quantity: "",
          quantityUnit:"CAS",
          total_unit:"",
          unit_case:"",
          cost:""
        }));
      updatedPerchaseData = [...perchesOrderData, ...newPageData];
    } else {
      // If the checkbox is unchecked, unselect all items on the current page      
      updatedCheckedItems = checkedItems.filter((id) => !selectedIds.includes(id));
      updatedSelectedProductData = selectedProductData.filter(
        (item) => !selectedIds.includes(item.id)
      );
      updatedPerchaseData = perchesOrderData.filter(
        (item) => !selectedIds.includes(item.id)
      );
    }
    setCheckedItems(updatedCheckedItems);
    setSelectedProductData(updatedSelectedProductData);
    setPerchesOrderData(updatedPerchaseData);
  };
  const onCheckboxSelectionChange = (e) => {
    const isChecked = e.target.checked; // This will be true if the checkbox is being checked, and false if it's being unchecked.
    setAllChecked(isChecked);
    selectAllProduct(isChecked);
  };
  const openDialog = (index) => {
    var CheckedProducts = [];
    if (viewSelectedProducts.length > 0) {
      viewSelectedProducts.map((dt) => {
        if(undefined != dt.product_id && null != dt.product_id && '' != dt.product_id){
          CheckedProducts.push(dt.product_id);
        }
        else{
          CheckedProducts.push(dt.id);
        }
      });
    }
    setCheckedItems(CheckedProducts);
    selectedProdQtyRef.current = perchesOrderData;
    const updateData=perchesOrderData.map((item) => ({
      ...item
    }));
    setPerchesOrderData(updateData)
    oldselectedRef.current = selectedProductRef.current;
    setIsDialogOpen(true);
    setPaginationModel({
      page: 0,
      pageSize: 100,
    });
    fetchRef.current = true;
    setSortModel([
      {
        field: "name",
        sort: "asc",
      },
    ]);
    setSelectedCategories([]);
    setIsAllProductsChecked(true);
    onProductModalOpen();
  };
  const modalClose = () => {
    setVisible(false);
    setSelectedProductData(selectedProductRef.current);
    const selectedProductIds = viewSelectedProducts.map(
      (product) => product.id
    );
    setCheckedItems(selectedProductIds);
    selectedProductRef.current = oldselectedRef.current;
    setPerchesOrderData(selectedProdQtyRef.current);

    setSearchQuery("");
    onProductModalClose();
    setSelectedCategories([]);
    setIsChecked(false);
  };
  const onHideDialog = () => {
    formik.resetForm();
    setSelectedCategories([]);
    setVisible(false);
  };
  const clickIfapplyCat = (cat) => {
    setSelectedCategories(cat);
    // if (
    //   cat.length == 0 &&
    //   selectedVendors.length == 0 &&
    //   selectedLocations?.length == 0
    // ) {
    // setPage(0);
    paginationModel.page = 0;
    apply();
    // }
  };
  const apply = () => {
    fetchRef.current = true;
    setLoading(true);
  };
  const onChange = (e) => {
    let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
    setSearchQuery(value);
    // setPage(0);
    paginationModel.page = 0;
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      apply();
    }, 250);
    setTimeoutId(newTimeoutId);
  };
  const productColumnNames = [
    {
      field: "selection",
      // headerName: "",
      hideable: false,
      headerName: (
        // <Checkbox
        //   checked={selectAll || selectAllChecked.length === modifiedData.length}
        //   onChange={handleSelectAll}
        //   indeterminate={
        //     checkedItems.length > 0 && checkedItems.length < modifiedData.length
        //   }
        // />
        <Checkbox onChange={onCheckboxSelectionChange} checked={allChecked}/>
      ),
      sortable: false,
      resizable: false,
      ...actionColumnXS,
      renderCell: (params) => (
        <IconButton aria-label="select" sx={{ color: "#5881FE" }}>
          <Checkbox
            checked={checkedItems.includes(params.row.id)}
            onChange={() => {
              handleCheckboxChange(params);
            }}
          />
        </IconButton>
      ),
    },
    { field: "id", headerName: "Id", sortable: true, ...actionColumnXS },
    {
      field: "name",
      headerName: "Product Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "barcode",
      headerName: "Primary Barcode",
      sortable: true,
      ...actionColumnXL,
    },
    {
      field: "sku",
      headerName: "SKU",
      sortable: true,
      ...actionColumnSM,
    },
    {
      field: "in_stock_qty",
      headerName: "In Stock",
      sortable: true,
      ...actionColumnMD,
    },
    {
      field: "sale_price",
      headerName: "Sale Price",
      sortable: true,
      ...actionColumnLG,
    },
    {
      field: "location",
      headerName: "Location",
      sortable: false,
      ...actionColumnMD,
    },
    {
      field: "threshold",
      headerName: "Threshold",
      sortable: true,
      ...actionColumnLG,
    },
    {
      field: "category_name",
      headerName: "Categories",
      sortable: false,
      resizable: false,
      ...actionColumnLG,
    },
  ];

  const columnProductViewNames = [
    {
      columnNames: "Product Name",
      sortName: "",
      width: "",
      textAlign: "start",
    },
    {
      columnNames: "Primary Barcode",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Sale Price",
      sortName: "",
      width: "",
    },
    {
      columnNames: "In Stock",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Cost/Case($)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Qty(cases)",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Units/Case",
      sortName: "",
      width: "",
    },
    // {
    //   columnNames: "Quantity Unit",
    //   sortName: "",
    //   width: "",
    // },
    {
      columnNames: "Total Units",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Cost/Unit",
      sortName: "",
      width: "",
    },
    {
      columnNames: "Delete",
      sortName: "",
      width: "",
    },
  ];
  const modifiedViewDatas = viewSelectedProducts
    ? viewSelectedProducts.map((item) => ({
        // id: undefined !== item?.product ? item.product.product_id : item.product_id && undefined !== item?.product ? item.product.id : item.id,
        id: item.hasOwnProperty('products') && undefined !== item?.products && undefined !==  item?.product_id
          ? item?.product_id : item?.id,
        name: item.hasOwnProperty('products') && undefined !== item?.products ? item?.products?.name : item?.name,
        categories: item.hasOwnProperty('products') && undefined !== item?.products
          ? item?.products?.categories?.map((cat) => cat.name).join(", ")
          : item.hasOwnProperty('categories') && undefined !== item?.categories && '' !== item?.categories && null !== item?.categories 
          ? item.categories?.map((cat) => cat.name).join(", "): item.hasOwnProperty('category_name') && undefined !== item?.category_name && '' !== item?.category_name && null !== item?.category_name ? item?.category_name : "",
        barcode:
        item.hasOwnProperty('products') && undefined !== item?.products ? item?.products?.barcode : item?.barcode,
        item_code:
        item.hasOwnProperty('products') &&  undefined !== item?.products
            ? item.products?.getvendors?.[0]?.pivot?.item_code
            : item.getvendors?.[0]?.pivot?.item_code,
        sale_price:
        item.hasOwnProperty('products') && undefined !== item?.products
            ? item?.products?.sale_price
            : item.sale_price,
        in_stock_qty:
        item.hasOwnProperty('products') && undefined !== item?.products
            ? item?.products?.in_stock_qty
            : 'N.A.',
        quantity: undefined !== item?.quantity ? parseInt(item.quantity) : null,
        cost: undefined !== item?.cost ? item.cost : null,
        quantityUnit:
          undefined !== item?.quantity_unit ? item.quantity_unit : null,
        unit_case:undefined !== item?.no_of_eaches ? item.no_of_eaches : null,
        total_unit:(undefined !== item?.quantity && 0<item?.quantity )&&  (undefined !== item?.no_of_eaches && 0 < item?.no_of_eaches)  ? (item.quantity * item.no_of_eaches) : 'N.A.',
      }))
    : [];
    useEffect(() => {
      if (fetchRef.current === true) {
        setAllChecked(false);
        if (!isChecked) {
          fetchData();
        } else {
          fetchDatathreshold();
        }
        fetchRef.current = false;
      }
    }, [searchQuery, paginationModel, sortModel, loading, isChecked,modalOpen, isAllProductsChecked]);

    useEffect(() => {
      let searchExist = modifiedViewDatas.findIndex((x) => x.additionalRow == true);
      let searchViewExist = viewSelectedProducts.findIndex((x) => x.additionalRow == true);
      if (modifiedViewDatas.length > 0 && effectRunData.current && (searchExist == -1 && searchViewExist == -1)) {
        let array = [];
        var addInput = {
          id: '',
          name: '',
          categories: '',
          barcode: '',
          item_code: '',
          sale_price: '',
          in_stock_qty: '',
          quantity: '',
          cost: '',
          quantityUnit: '',
          unit_case:'',
          total_unit:'',
          additionalRow:true
        };
        array = [...viewSelectedProducts,addInput];
        setViewSelectedProducts(array);
        effectRunData.current = false;
      }
    }, [viewSelectedProducts]);

  // useEffect(() => {
  //   if (addProductClicked) {
  //     if (allLocationProductFLag === 1) {
  //       setAddProductClicked(false);
  //       fetchData();
  //     } else {
  //       if (thresholdProductClicked) {
  //         fetchDatathreshold();
  //       }
  //     }
  //   }
  // }, [addProductClicked, thresholdProductClicked]);

  const handleSearchList = () => {
    if (isChecked) {
      fetchDatathreshold();
    } else {
      fetchData();
    }
  };

  const handleColumn = (coulmn) => {
    if (coulmn.sortName === columnProductViewNames) {
      if (sortType === "asc") {
        // handleSortColumn(coulmn, "desc");
      } else {
        // handleSortColumn(coulmn, "asc");
      }
    } else {
      // handleSortColumn(coulmn, "asc");
    }
  };

  const fetchData = async () => {
    setLoading(true);
    let data = {
      created_at: purchaseOrderData.created_at,
      deleted_at: purchaseOrderData.deleted_at,
      id: purchaseOrderData.id,
      location: selectedLocationData.id,
      location_id: selectedLocationData.id,
      locationCity: purchaseOrderData.locations?.city,
      locationCountry: purchaseOrderData.locations?.country,
      locationState: purchaseOrderData.locations?.state,
      vendors: {
        id: selectedVendorData.id,
      },
      locations: {
        id: selectedLocationData.id,
      },
      vendor: selectedVendorData.id,
      users: {
        id: perchesOrderUserData.id,
      },
      categories: selectedCategories,
      show_all: isAllProductsChecked == true ? true : false,
      query: {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
        allProdFlag: allLocationProductFLag === 1 ? 1 : 0,
      },
    };
    try {
      editManualProductList(data)
        .then((response) => {
          if (response.data.flag === true) {
            setProductData(response.data.data.data);
            setAllLocationProductFLag(
              response.data.data.allLocationProductFLag
            );
            setTotalCount(response.data.data.totalItems);
            const cat = response.data.data?.category;
            const categoriesDropDownData = cat?.map((cat) => ({
              label: cat.name,
              value: { name: cat.name, id: cat.id },
            }));
            setCategories(categoriesDropDownData);
            setLoading(false);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            setProductData([]);
            setTotalCount(0);
            setLoading(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setProductData([]);
          setTotalCount(0);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setProductData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  const fetchDatathreshold = async () => {
    let data = {
      created_at: purchaseOrderData.created_at,
      deleted_at: purchaseOrderData.deleted_at,
      id: purchaseOrderData.id,
      location: selectedLocationData.id,
      locationCity: purchaseOrderData.locations?.city,
      locationCountry: purchaseOrderData.locations?.country,
      locationState: purchaseOrderData.locations?.state,
      location_id: selectedLocationData.id,
      vendors: {
        id: selectedVendorData.id,
      },
      locations: {
        id: selectedLocationData.id,
      },
      vendor: selectedVendorData.id,
      users: {
        id: perchesOrderUserData.id,
      },
      query: {
        limit: paginationModel.pageSize,
        page: paginationModel.page + 1,
        order:
          sortModel.length > 0 ? sortModel[0].field : sortRef.current[0].field,
        order_type:
          sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        filter: searchQuery,
        allProdFlag: allLocationProductFLag === 1 ? 1 : 0,
      },
    };
    setLoading(true);
    try {
      editPurchaseOrderProductList(data)
        .then((response) => {
          if (response.data.flag === true) {
            // setCheckedItems(response.data.data.data);
            let array = [];
            let selected_Product = [];
            const productDetails = response.data.data.data;
            setProductData(productDetails);
            productDetails.map((product) => {
              let index = viewSelectedProducts.findIndex(x => x.id === product.id)
              if (
                !(
                  parseFloat(product.in_stock_qty) >=
                  parseFloat(product.threshold)
                )  || index > -1
              ) {
                selected_Product.push(product.id);
                selectedProductRef.current = [...selectedProductRef.current,product]
              }
            });
            setCheckedItems(selected_Product);
            selected_Product.map((item) => {
              // let obj = {
              //   id: item,
              //   quantity: "",
              //   cost: "",
              //   quantityUnit: "CAS",
              // };
              let index = selectedProdQtyRef.current.findIndex(
                (x) => x.id == item
              );
              let eaches = selectedProductRef.current.find(
                (x) => x.id == item
              );
              let obj = {
                id: item,
                quantity:
                  index > -1 ? parseInt(selectedProdQtyRef.current[index].quantity) : "",
                cost: index > -1 ? selectedProdQtyRef.current[index].cost : "",
                unit_case: index > -1 ? selectedProdQtyRef.current[index].unit_case : undefined != eaches.no_of_eaches ? eaches.no_of_eaches : "",
                quantityUnit:
                  index > -1
                    ? selectedProdQtyRef.current[index].quantityUnit
                    : "CAS",
                total_unit: index > -1 ? parseFloat(selectedProdQtyRef.current[index].quantity *  selectedProdQtyRef.current[index].unit_case).toFixed(2) : "N.A.",
                cost_per_unit: index > -1 ? parseFloat(selectedProdQtyRef.current[index].cost /  selectedProdQtyRef.current[index].unit_case).toFixed(2) : "N.A.",
              };
              // array.push(obj);
              array.splice(array.length, 0, obj);
            });
            if (array.length > 0) {
              setPerchesOrderData(array);
            }
            setTotalCount(response.data.data.totalItems);
            setLoading(false);
            setShowTable(false);
            myContext.handleLoading(false);
          } else {
            setProductData([]);
            setPerchesOrderData([]);
            setTotalCount(0);
            setLoading(false);
            setShowTable(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setProductData([]);
          setPerchesOrderData([]);
          setTotalCount(0);
          setLoading(false);
          setShowTable(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setPerchesOrderData([]);
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };
  const fetchProductData = async () => {
    const data = {
      vendor: selectedVendorData.id,
      location: selectedLocationData.id,
      vendors: selectedVendorData.id,
      locations: selectedLocationData.id,
    };
    setLoading(true);
    try {
      getProductForEdit(data)
        .then((response) => {
          if (response.data.flag === true) {
            setLocationAllProduct(response.data.data.product);
            setLoading(false);
          } else {
            setLocationAllProduct([])
            setTotalCount(0);
            setLoading(false);
            let actionData = response;
            error({ actionData });
            myContext.handleLoading(false);
          }
        })
        .catch((err) => {
          setLocationAllProduct([])
          setTotalCount(0);
          setLoading(false);
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      setLocationAllProduct([])
      setTotalCount(0);
      setLoading(false);
      myContext.handleLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedVendorData?.id &&
      selectedLocationData?.id &&
      locationRef.current
    ) {
      fetchProductData();
      locationRef.current = false;
    }
    // effectRun.current = true; // Ensures fetchProductData runs only after the initial render
  }, [selectedVendorData, selectedLocationData]);

  function reportDateFormate(dateString) {
    if (!dateString) {
      return "";
    }
    const date = moment(dateString);
    const formattedDate = date.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    return formattedDate;
  }

  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const [isVendorChanged, setIsVendorChanged] = useState(false);
  const formik = useFormik({
    initialValues: {
      vendors:
        undefined !==
          loaderResponse?.data?.data?.PurchaseOrder?.vendors?.name &&
        null != loaderResponse?.data?.data?.PurchaseOrder?.vendors?.name
          ? loaderResponse?.data?.data?.PurchaseOrder?.vendors?.name
          : "",
      locations:
        undefined !==
          loaderResponse?.data?.data?.PurchaseOrder?.locations?.name &&
        null != loaderResponse?.data?.data?.PurchaseOrder?.locations?.name
          ? loaderResponse?.data?.data?.PurchaseOrder?.locations?.name
          : "",
      vendor_invoice_number:
        undefined !==
          loaderResponse?.data?.data?.PurchaseOrder?.vendor_invoice_number &&
        null != loaderResponse?.data?.data?.PurchaseOrder?.vendor_invoice_number
          ? loaderResponse?.data?.data?.PurchaseOrder?.vendor_invoice_number
          : "",
      status:
        undefined !== loaderResponse?.data?.data?.PurchaseOrder?.status &&
        null != loaderResponse?.data?.data?.PurchaseOrder?.status
          ? loaderResponse?.data?.data?.PurchaseOrder?.status
          : "",
      payment_due_date:
        undefined !==
          loaderResponse?.data?.data?.PurchaseOrder?.payment_due_date &&
        null != loaderResponse?.data?.data?.PurchaseOrder?.payment_due_date
          ? new Date(
              loaderResponse?.data?.data?.PurchaseOrder?.payment_due_date
            )
          : "",
      received_date:
        undefined !==
          loaderResponse?.data?.data?.PurchaseOrder?.received_date &&
        null != loaderResponse?.data?.data?.PurchaseOrder?.received_date
          ? new Date(loaderResponse?.data?.data?.PurchaseOrder?.received_date)
          : "",
      received:
        undefined !== loaderResponse?.data?.data?.PurchaseOrder?.received &&
        null != loaderResponse?.data?.data?.PurchaseOrder?.received
          ? loaderResponse?.data?.data?.PurchaseOrder?.received
          : "",
      paid:
        undefined !== loaderResponse?.data?.data?.PurchaseOrder?.paid &&
        null != loaderResponse?.data?.data?.PurchaseOrder?.paid
          ? loaderResponse?.data?.data?.PurchaseOrder?.paid
          : "",
      barcode: "",
    },
    validationSchema: Yup.object({
      vendors: Yup.string().required(Constants.PURCHASING_ORDER_SELECT_VENDOR),
      locations: Yup.string().required(
        Constants.PURCHASING_ORDER_SELECT_LOCATION
      ),
    }),
    onSubmit: async (values) => {
      setVisible(false);
      myContext.handleLoading(true);
      var removeAdditionalField = viewSelectedProducts.filter((pr) => {
        return (
          (undefined == pr.additionalRow || pr.additionalRow != true)
        );
      });
      const payload = {
        id: removeAdditionalField?.[0]?.purchase_order_id,
        vendor_id: selectedVendorData.id,
        location_id: selectedLocationData.id,
        user_id: perchesOrderUserData.id,
        vendor_invoice_number: values.vendor_invoice_number,
        status: selectedQuantityUnit,
        created_at: purchaseOrderData.created_at,
        updated_at: purchaseOrderData.updated_at,
        deleted_at: purchaseOrderData.deleted_at,
        payment_due_date: reportDateFormate(values.payment_due_date),
        received_date: reportDateFormate(values.received_date),
        received: values.received ? 1 : 0,
        paid: values.paid ? 1 : 0,
        users: JSON.stringify(perchesOrderUserData),
        vendors: JSON.stringify(selectedVendorData),
        locations: JSON.stringify(selectedLocationData),
        vendor: selectedVendorData.id,
        location: selectedLocationData.id,
        purchas_order_items: JSON.stringify(viewSelectedProducts),
        // products: JSON.stringify(
        //   viewSelectedProducts.map((product,index) => {
        //     const quantity = perchesOrderData[index]?.quantity ?? modifiedViewDatas[index]?.quantity ?? viewSelectedProducts?.[0]?.quantity ;
        //     const quantity_unit = perchesOrderData[index]?.quantityUnit ?? modifiedViewDatas[index]?.quantityUnit ?? viewSelectedProducts?.[0]?.quantity_unit;
        //     const cost = perchesOrderData[index]?.cost ?? modifiedViewDatas[index]?.cost ?? viewSelectedProducts?.[0]?.cost;

        //     return {
        //       ...product.products,
        //       ...product,
        //       quantity,
        //       quantity_unit,
        //       cost,
        //       categories: viewSelectedProducts?.[0]?.products?.categories,
        //       location:  selectedLocationData,
        //       getvendors: viewSelectedProducts?.[0]?.products?.getvendors,
        //     };
        //   })
        // ),
        // products: JSON.stringify(
        //   viewSelectedProducts.map((product, index) => {
        //     const quantity =
        //       perchesOrderData[index]?.quantity ??
        //       modifiedViewDatas[index]?.quantity ??
        //       viewSelectedProducts?.[0]?.quantity;
        //     const quantity_unit =
        //       perchesOrderData[index]?.quantityUnit ??
        //       modifiedViewDatas[index]?.quantityUnit ??
        //       viewSelectedProducts?.[0]?.quantity_unit;
        //     const cost =
        //       perchesOrderData[index]?.cost ??
        //       modifiedViewDatas[index]?.cost ??
        //       viewSelectedProducts?.[0]?.cost;
        //     if (isLocationChanged || isVendorChanged) {
        //       return {
        //         ...product,
        //         quantity,
        //         quantity_unit,
        //         cost,
        //         location: selectedLocationData,
        //       };
        //     } else if (viewSelectedProducts?.[0]?.products) {
        //       return {
        //         ...product.products,
        //         quantity,
        //         quantity_unit,
        //         cost,
        //         categories: viewSelectedProducts?.[0]?.products?.categories,
        //         location: selectedLocationData,
        //         getvendors: viewSelectedProducts?.[0]?.products?.getvendors,
        //       };
        //     } else {
        //       return {
        //         ...product,
        //         quantity,
        //         quantity_unit,
        //         cost,
        //         categories: viewSelectedProducts?.[0]?.categories,
        //         location: selectedLocationData,
        //         getvendors: viewSelectedProducts?.[0]?.products?.getvendors,
        //       };
        //     }
        //   })
        // ),
        products: JSON.stringify(
          removeAdditionalField.map((product) => {
            // Find matching product in perchesOrderData by id or product_id
            const matchedProduct = perchesOrderData.find(
              (p) => p.id === product.id || p.id === product.product_id
            );

            const quantity = parseInt(matchedProduct?.quantity) ?? parseInt(product.quantity);
            const quantity_unit =
              matchedProduct?.quantityUnit ?? product.quantityUnit;
            const cost = matchedProduct?.cost ?? product.cost;
            const unit_case = matchedProduct?.unit_case ?? product.no_of_eaches;

            if (isLocationChanged || isVendorChanged) {
              return {
                ...product,
                quantity,
                quantity_unit,
                cost,
                unit_case,
                location: selectedLocationData,
              };
            } else if (product.products) {
              return {
                ...product.products,
                quantity,
                quantity_unit,
                cost,
                unit_case,
                categories: product.products.categories,
                location: selectedLocationData,
                getvendors: product.products.getvendors,
              };
            } else {
              return {
                ...product,
                quantity,
                quantity_unit,
                cost,
                unit_case,
                categories: product.categories,
                location: selectedLocationData,
                getvendors: product.getvendors,
              };
            }
          })
        ),
      };
      setButtonDisable(true);
      actionRun.current = true;
      submit(payload, {
        method: Constants.POST,
        path: Constants.PURCHASE_ORDER_EDIT_API_ROUTE,
      });
    },
  });

  const handleInputChange = (event, id, field_name) => {
    event.preventDefault();
    const { value } = event.target;
    let index = perchesOrderData.findIndex((x) => x.id === id);
    if (index > -1) {
      let obj = { ...perchesOrderData[index] };
      obj[field_name] = value;
      switch (field_name) {
        case "quantity":
          obj.quantityErrors =
            value.length === 0
              ? Constants.PURCHASING_ORDER_SELECT_QUANTITY
              : parseInt(value) < 1
              ? Constants.PURCHASING_ORDER_QUANTITY_MIN_VALUE
              : parseInt(value) > 1000000
              ? Constants.PURCHASING_ORDER_QUANTITY_MAX_VALUE
              : "";
          break;
        case "cost":
          obj.costErrors =
            value.length === 0
              ? ""
              : parseFloat(value).toFixed(2) < 0.0
              ? Constants.PURCHASING_ORDER_MIN_VALUE
              : parseFloat(value).toFixed(2) > 1000000
              ? Constants.PURCHASING_ORDER_MAX_VALUES
              : "";
          break;
        case "unit_case":
          obj.unitCaseErrors =
            value.length === 0
              ? ""
              : parseFloat(value).toFixed(2) < 0.01
              ? Constants.PURCHASING_UNIT_CASE_MIN_VALUE
              : parseFloat(value).toFixed(2) > 1000000
              ? Constants.PURCHASING_UNIT_CASE_MAX_VALUE
              : "";
          break;
        default:
          break;
      }
      let array = [
        ...perchesOrderData.slice(0, index),
        obj,
        ...perchesOrderData.slice(index + 1),
      ];
      array.map((obj,i)=>{
        if(((undefined == obj.unitCaseErrors || '' == obj.unitCaseErrors) && (undefined == obj.quantityErrors || '' == obj.quantityErrors)) && (obj.unit_case > 0 && obj.quantity > 0)){
          var total = parseFloat(obj.unit_case).toFixed(2) * parseInt(obj.quantity);
          obj.total_unit = parseFloat(total).toFixed(2);
        }
        else{
          obj.total_unit = 'N.A.';
        }
        if(((undefined == obj.unitCaseErrors || '' == obj.unitCaseErrors) && (undefined == obj.quantityErrors || '' == obj.quantityErrors)) && (obj.cost > 0 && obj.unit_case > 0)){
          var costUnit = parseFloat(obj.cost).toFixed(2) / parseFloat(obj.unit_case).toFixed(2);
          obj.cost_per_unit = parseFloat(costUnit).toFixed(2);
        }
        else{
          obj.cost_per_unit = 'N.A.';
        }
      });
      setPerchesOrderData(array);
    }
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(formik.values.vendors.length <= 0 ||
      formik.errors.vendors ||
      formik.errors.locations ||
      formik.errors.quantity ||
      formik.errors.cost ||
      formik.errors.unit_case ||
      buttonDisable
        ? true
        : false)
    ) {
      formik.handleSubmit();
    }
  };

  const handleQuantityUnitChange = (event) => {
    const value = event.target.value;
    setSelectedQuantityUnit(value);
    formik.setFieldValue("status", value);
  };

  const hadleFromDateSelect = (e) => {
    const selectedDate = e.value;
    formik.setFieldValue("payment_due_date", selectedDate);
    setFromDateView(selectedDate);
  };

  const toDateCalendarRef = useRef(null);
  const [toDateFocus, setToDateFocus] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };
  }, [fromDateFocus, toDateFocus]);
  const sortRef = useRef(sortModel);
  return (
    <Box flex="1" pb="3.31rem">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            // pass param data in tax list page
            props.navigate(Constants.PURCHASING_ORDER_PATH, {
              state: stateData.state,
            });
          }}
          breadCrumNames={["Purchasing", "Edit Purchase Order"]}
          breadCrumTitle={"Edit Purchase Order"}
        />
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              // pass param data in purchase order list page
              props.navigate(Constants.PURCHASING_ORDER_PATH, {
                state: stateData.state,
              });
            }}
            isDisabled={buttonDisable}
          />
          <PosFormButton
            isDisabled={
              !formik.values.vendors ||
              !formik.values.locations ||
              formik.values.vendors.length <= 0 ||
              formik.errors.vendors ||
              formik.errors.locations ||
              (viewSelectedProducts.length-1) <= 0 ||
              // !perchesOrderData ||
              // perchesOrderData.length === 0 ||
              perchesOrderData.some(
                (item) =>
                  !item.quantity ||
                parseInt(item.quantity) <= 0 ||
                  item.quantityErrors
              ) ||
              perchesOrderData.some((item) => item.costErrors) ||
              perchesOrderData.some((item) => item.unitCaseErrors) ||
              buttonDisable
                ? true
                : false
            }
            buttonsubmit="Submit"
            SubmitButton={true}
            onClick={formik.handleSubmit}
          />
        </Stack>
      </Flex>
      <Box
        mt={{ base: 2, md: "2rem" }}
        ml={{ base: 4, md: "3.44rem" }}
        mr={{ base: 4, md: "2.75rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
      >
        <Flex
          fontSize="1.23rem"
          color={Colors.posTextInfo}
          ml={{ base: "0", md: "1.1rem" }}
          pt={{ base: "2rem", md: "2rem", lg: "3rem" }}
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "1.13rem",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.02rem",
          }}
        >
          <Box
            backgroundColor={Colors.modifierTable}
            ml={{ base: "1rem" }}
            mt={{ base: "1rem" }}
            style={{
              width: "2.13rem",
              height: "2.13rem",
              flexShrink: 0,
              marginRight: "0.63rem",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Img
              src={invoice}
              alt="Purchase Order Details"
              style={{ width: "1.5rem", height: "1.5rem" }}
            />
          </Box>
          <Text mt={{ base: "1rem" }}>Purchase Order Details</Text>
        </Flex>
        <Divider mt={"0.56rem"} />
        <Flex
          direction={{ base: "column", md: "column", lg: "row" }}
          p={{ base: "0.3rem", md: "6", lg: "6" }}
          pl={{ base: "5.7" }}
        >
          <Box
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            justifyContent={{ base: "flex-start", md: "center" }}
          >
            <Flex
              p={2.5}
              justifyContent={{
                base: "flex-start",
                md: "flex-start",
                lg: "space-between",
              }}
            >
              <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                display="flex"
                pr={{ base: "1rem" }}
                mb={{ base: "0.5rem", md: "0" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Vendor
                <Text as="span" color={Colors.errorColor} ml="0.2rem">
                  *
                </Text>
                <Tippy
                  content="Select the Vendor for whom you want to create the Order."
                  interactive={true}
                  maxWidth={390}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  <Image
                    src={toolTip}
                    alt="Tooltip"
                    ml={{ base: "0.4rem", md: "0.3rem" }}
                  />
                </Tippy>
              </Box>
              <Box>
                <PosDropDown
                  value={selectedVendorData}
                  options={vendorData}
                  onChange={handleOptionSelect}
                  onBlur={(e) => {
                    formik.setFieldTouched("vendors", e.value);
                    formik.handleBlur(e);
                  }}
                  lableAvailable={true}
                  optionLabel="name"
                  className="w-full md:w-20rem"
                  placeholder="--Select vendor--"
                />
                <Text color={Colors.errorColor} textAlign="end">
                  {formik.touched.vendors && formik.errors.vendors ? (
                    <span>{formik.errors.vendors}</span>
                  ) : null}
                </Text>
              </Box>
            </Flex>
            <Flex
              p={2.5}
              justifyContent={{
                base: "flex-start",
                md: "flex-start",
                lg: "space-between",
              }}
            >
              <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                display="flex"
                pr={{ base: "1rem" }}
                mb={{ base: "0.5rem", md: "0" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Location
                <Text as="span" color={Colors.errorColor} ml="0.2rem">
                  *
                </Text>
              </Box>
              <Box>
                <PosDropDown
                  value={selectedLocationData}
                  onChange={onSelectLocation}
                  onBlur={(e) => {
                    formik.setFieldTouched("locations", true);
                    formik.handleBlur(e);
                  }}
                  options={locationData}
                  lableAvailable={true}
                  optionLabel="name"
                  className="w-full md:w-20rem"
                  placeholder="--Select a Location--"
                />
                <Text color={Colors.errorColor} textAlign="end">
                  {formik.touched.locations && formik.errors.locations ? (
                    <span>{formik.errors.locations}</span>
                  ) : null}
                </Text>
              </Box>
            </Flex>
            <Flex
              p={2.5}
              justifyContent={{
                base: "flex-start",
                md: "flex-start",
                lg: "space-between",
              }}
            >
              <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                flexShrink={0}
                display="flex"
                pr={{ base: "1rem" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Vendor Invoice Number
                <Tippy
                  content="This Invoice number helps in accurate tracking of the Purchase order to the Incoive."
                  interactive={true}
                  maxWidth={390}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  <Image
                    src={toolTip}
                    alt="Tooltip"
                    ml={{ base: "0.4rem", md: "0.3rem" }}
                  />
                </Tippy>
              </Box>
              <Box>
                <PosInput
                  id="vendor_invoice_number"
                  mt={{ base: "1rem" }}
                  placeholder={"Enter a Vendor Invoice Number"}
                  color={Colors.posCommon}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.vendor_invoice_number}
                  handleBlur={formik.handleBlur}
                  onKeyDown={handleKeyDown}
                  posInput={true}
                  width={{ base: "100%", md: "10rem", lg: "100%" }}
                  inputType={"text"}
                />
              </Box>
            </Flex>
            <Flex
              p={2.5}
              justifyContent={{
                base: "flex-start",
                md: "flex-start",
                lg: "space-between",
              }}
            >
              <Box
                color={Colors.posTextInfo}
                fontWeight={500}
                flexShrink={0}
                pr={{ base: "1rem" }}
                display="flex"
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Select a Status
              </Box>
              <Box position="relative" display="inline-block">
                <Select
                  backgroundColor={Colors.campCardBgColor}
                  w={{ base: "8rem", lg: "10rem" }}
                  style={{
                    padding: "0.63rem",
                    border: "0.6px solid #01004833",
                    borderRadius: "0.44rem",
                    textAlign: "start",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.63rem",
                    flexShrink: 0,
                  }}
                  appearance="none"
                  value={formik.values.status}
                  onChange={handleQuantityUnitChange}
                >
                  <option
                    style={{ backgroundColor: Colors.campCardBgColor }}
                    value="GEN"
                  >
                    Generated
                  </option>
                  <option
                    style={{ backgroundColor: Colors.campCardBgColor }}
                    value="ORD"
                  >
                    Ordered
                  </option>
                  <option
                    style={{ backgroundColor: Colors.campCardBgColor }}
                    value="SHIP"
                  >
                    Shipped
                  </option>
                  <option
                    style={{ backgroundColor: Colors.campCardBgColor }}
                    value="RECE"
                  >
                    Received
                  </option>
                </Select>
              </Box>
            </Flex>
          </Box>
          <Box
            borderRadius="0.63rem"
            bg={Colors.posPageDataBackground}
            justifyContent="flex-start"
            ml={{
              base: "0",
              sm: "0",
              md: "0",
              lg: "1.75rem",
              xl: "7.75rem",
            }}
          >
            <Flex p={2.5}>
              <Text pr={{ base: "1rem" }} fontWeight={500}>
                Order Paid
              </Text>
              <PosSwitchButton
                id="paid"
                switchValue={formik.values.paid}
                onChange={formik.handleChange}
              />
            </Flex>
            <Flex p={2.5} justifyContent="flex-start">
              <Box
                color={Colors.couponDropDownLabelColor}
                fontWeight={500}
                display="flex"
                pr={{ base: "1rem" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Payment Due Date
                <Tippy
                  content="The date when the Payment for the Order is due."
                  interactive={true}
                  maxWidth={390}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  <Image
                    src={toolTip}
                    alt="Tooltip"
                    ml={{ base: "0.4rem", md: "0.3rem" }}
                  />
                </Tippy>
              </Box>
              <Box>
                <WrapItem flexDirection={{ base: "column", md: "row" }}>
                  <PosDatePicker
                    id={"payment_due_date"}
                    ref={calendarRef}
                    value={formik.values.payment_due_date}
                    label={"Payment Due Date"}
                    readOnlyInput={true}
                    onFocus={() => {
                      if (
                        null !== toDateCalendarRef.current &&
                        toDateCalendarRef.current
                      ) {
                        toDateCalendarRef.current.hide();
                      }
                      setToDateFocus(false);
                      setTimeout(() => {
                        setFromDateFocus(true);
                      }, 1000);
                      formik.setFieldTouched("payment_due_date", true);
                    }}
                    handleSelectData={hadleFromDateSelect}
                    yearRange={"2000:2030"}
                    viewDate={fromDateView}
                    showButtonBar={true}
                  />
                </WrapItem>
              </Box>
            </Flex>
            <Flex p={2.5}>
              <Text pr={{ base: "1rem" }} fontWeight={500}>
                Order Received
              </Text>
              <PosSwitchButton
                id="received"
                switchValue={formik.values.received}
                onChange={formik.handleChange}
              />
            </Flex>
            <Flex
              p={2.5}
              justifyContent={{
                base: "flex-start",
                md: "flex-start",
                lg: "space-between",
              }}
            >
              <Box
                color={Colors.couponDropDownLabelColor}
                fontWeight={500}
                display="flex"
                pr={{ base: "1rem" }}
                style={{
                  fontSize: "0.94rem",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  alignItems: "center",
                }}
              >
                Received Date
                <Tippy
                  content="The date when the shipment for the order was received."
                  interactive={true}
                  maxWidth={390}
                  placement="top"
                  animation="fade"
                  inertia={true}
                  moveTransition="transform 0.2s ease-out"
                  theme="tomato"
                >
                  <Image
                    src={toolTip}
                    alt="Tooltip"
                    ml={{ base: "0.4rem", md: "0.3rem" }}
                  />
                </Tippy>
              </Box>
              <Box>
                <WrapItem flexDirection={{ base: "column", md: "row" }}>
                  <PosDatePicker
                    id={"received_date"}
                    ref={calendarRef1}
                    readOnlyInput={true}
                    onFocus={() => {
                      if (null !== calendarRef.current && calendarRef.current) {
                        calendarRef.current.hide();
                      }
                      setFromDateFocus(false);
                      setTimeout(() => {
                        setToDateFocus(true);
                      }, 1000);
                      formik.setFieldTouched("received_date", true);
                    }}
                    value={formik.values.received_date}
                    label={"Received Date"}
                    handleSelectData={(e) => {
                      formik.setFieldValue("received_date", e.value);
                      setToDateView(e.value);
                    }}
                    yearRange={"2000:2030"}
                    showButtonBar={true}
                  />
                </WrapItem>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Divider />
        {/* <Box
          mt={{ base: "1rem", md: "2.06rem" }}
          // mb={{ base: "0", md: "0.87rem" }}
          ml={{ base: "1.81rem", md: "1.81rem" }}
          fontSize="1.13rem"
          color={Colors.posTextInfo}
          style={{
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
        >
          Invoice Line
        </Box> */}
        <Flex
          direction={{ base: "column", md: "column", lg: "row" }}
          style={{ alignItems: "start" }}
          pl={{ base: "1.81rem" }}
        >
          {/* <Box
            color={Colors.primeposTextInfo}
            fontSize="0.94rem"
            fontStyle="normal"
            fontWeight={300}
            lineHeight="normal"
            letterSpacing="-0.02rem"
          >
          <Flex gap={2} mt={{ base: "1rem" }} mr={{ base: "1.7rem" }}>
            <Box>
              <AutoComplete
                id="selectedProducts"
                field="name"
                value={autoSelectedProducts}
                suggestions={filteredProducts}
                completeMethod={search}
                placeholder="Enter Barcode"
                onChange={(e) => {
                  setAutoSelectedProducts(e.value);
                }}
                disabled={
                  !formik.values.vendors ||
                  !formik.values.locations ||
                  formik.values.vendors.length <= 0 ||
                  formik.errors.vendors ||
                  // formik.values.locations.length <= 0 ||
                  formik.errors.locations ||
                  buttonDisable
                    ? true
                    : false
                }
                showEmptyMessage={true}
                emptyMessage="There is no product."
                onSelect={(e) => {
                  if(undefined != e.value && null != e.value && '' != e.value){
                    autoCompleteSelectedProducts(e.value);
                  }
                }}
                dropdown
              />
            </Box>
            </Flex>
          </Box> */}
          <Spacer />
          <Box
            mb={{ base: "1rem" }}
            mr={{ md: "3.56rem" }}
            mt={{ base: "1rem" }}
          >
            <PosFormButton
              buttonsubmit={"Add Multiple Products"}
              width={"15.25rem"}
              // onClick={() => {
              //   fetchRef.current = true
              //   setModal(true);
              //   selectedProdQtyRef.current = perchesOrderData;
              //   oldselectedRef.current = selectedProductRef.current
              //   // setAddProductClicked(true);
              //   // fetchData();
              // }}
              onClick={() => {
                setVisible(true);               
                openDialog();
              }}
              isDisabled={
                !formik.values.vendors ||
                !formik.values.locations ||
                formik.values.vendors.length <= 0 ||
                formik.errors.vendors ||
                // formik.values.locations.length <= 0 ||
                formik.errors.locations ||
                buttonDisable
                  ? true
                  : false
              }
            />
          </Box>
          <>
            {/* {modalOpen && ( */}
              {/* <ThemeProvider theme={Mtheme}> */}
                <Dialog
                  header={<div style={{ padding: 0, margin: 0 }}> </div>}
                  visible={visible}
                  onHide={onHideDialog}
                  style={{ width: isMobileDialog ? "80%" : "70%" }}
                  closable={false}
                  closeOnEscape={false}
                >
                  {/* <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                      fontSize: "1.5rem",
                      fontWeight: 600,
                      color: Colors.posTextInfo,
                    }}
                  > */}
                  <ChakraProvider theme={theme}>
                  <Box
                    position="sticky"
                    top="0"
                    zIndex="1000"
                    backgroundColor="white"
                    pb={2}
                  >
                  <Flex
                      minWidth="max-content"
                      gap="2"
                      mt={4}
                      mb={4}
                      alignItems="center"
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      <Flex alignSelf="flex-start" fontSize="1.5rem" fontWeight={600} color="rgb(1, 0, 72)">
                        Select Products
                      </Flex>
                      <Spacer />
                      <ButtonGroup alignSelf="flex-end">
                        <PosFormButton
                          onClick={modalClose}
                          buttonText="Cancel"
                          CancelButton={true}
                          isDisabled={buttonDisable}
                        />
                        <PosFormButton
                          // isDisabled={
                          //   checkedItems.length <= 0 ||
                          //   formik.errors.product ||
                          //   modifiedData.length === 0 ||
                          //   (isChecked && perchesOrderData.length === 0) ||
                          //   buttonDisable
                          // }
                          isDisabled={
                            checkedItems.length <= 0 ||
                            (checkedItems.length === 1 && checkedItems[0] === "") ||
                            (isChecked && perchesOrderData.length === 0) ||
                            buttonDisable
                          }
                          buttonsubmit="Add Products"
                          SubmitButton={true}
                          onClick={handleSelectedViewProduct}
                        />
                      </ButtonGroup>
                    </Flex>
                    <Flex alignItems="center">
                    </Flex>
                    <Flex
                      // minWidth="max-content"
                      gap="2"
                      mt={4}
                      mb={4}
                      // alignItems="center"
                      flexDirection={{ base: "column", sm: "column", md: "row" }}
                    >
                      {/* {modifiedData.length > 0 && (
                        <> */}
                      <PosSearch
                      value={searchQuery}
                      // onChange={(e) => {
                      //   let value = searchQuery.length > 0 ? e.target.value : e.target.value.trim();
                      //   setSearchQuery(value);
                      // }}
                      onChange={onChange}
                      onSearch={handleSearchList}
                      handleClear={handleClear}
                    />
                    {/* <Spacer /> */}
                    <PosDropDown
                      options={categories}
                      value={selectedCategories}
                      onChange={(e) => {
                        clickIfapplyCat(e.value);
                      }}
                      // onChange={(e) => setSelectedCategories(e.value)}
                      multiSelect={true}
                      optionLabel="label"
                      placeholder="Categories"
                      lableAvailable={true}
                      width="100%"
                      height="2.5rem"
                      className="w-full md:w-20rem"
                      maxSelectedLabels={2}
                    />
                    {/* <Spacer /> */}
                    {/* </>
                      )} */}
                    </Flex>
                    <Flex>
                      <Spacer />
                      <Tippy
                        content="Looks for items below the threshold and adds them to the purchase list."
                        interactive={true}
                        maxWidth={390}
                        placement="top"
                        animation="fade"
                        inertia={true}
                        moveTransition="transform 0.2s ease-out"
                        theme="tomato"
                      >
                        <Image
                          src={toolTip}
                          alt="Tooltip"
                          mr={{ base: "0.4rem", md: "0.3rem" }}
                        />
                      </Tippy>
                      <Text mt={2}>
                      Low Threshold Products:
                      </Text>
                      <ChakraProvider theme={theme}>
                        <Box
                          mt={2}
                          ml={{ base: "0.5rem", md: "0.5rem" }}
                          pr={{ base: "0.5rem", md: "0.5rem" }}
                        >
                          <PosSwitchButton
                            id="isChecked"
                            onChange={handleSwitchChange}
                            switchValue={isChecked}
                            disabled={isAllProductsChecked}
                            // onClick={() => {
                            //   setThresholdProductClicked(true);
                            // }}
                          />
                        </Box>
                      </ChakraProvider>
                      <Text mt={2}>
                        Vendor's Products:
                      </Text>
                      <ChakraProvider theme={theme}>
                        <Box
                          mt={2}
                          mb={4}
                          ml={{ base: "0.5rem", md: "0.5rem" }}
                          pr={{ base: "0.5rem", md: "0.5rem" }}
                        >
                          <PosSwitchButton
                            id="isAllProductsChecked"
                            onChange={handleAllProductSwitchChange}
                            switchValue={isAllProductsChecked}
                            disabled={isChecked}
                          />
                        </Box>
                      </ChakraProvider>
                    </Flex>
                    {/* <ChakraProvider theme={theme}> */}
                      {/* <Box mt={3.5}>
                        <PosSearch
                          // mb={3.5}
                          value={searchQuery}
                          onChange={(e) => {
                            let value =
                              searchQuery.length > 0
                                ? e.target.value
                                : e.target.value.trim();
                            setSearchQuery(value);
                          }}
                          onSearch={handleSearchList}
                          handleClear={handleClear}
                        />
                        <PosDropDown
                        options={categories}
                        value={selectedCategories}
                        onChange={(e) => {
                          clickIfapplyCat(e.value);
                        }}
                        // onChange={(e) => setSelectedCategories(e.value)}
                        multiSelect={true}
                        optionLabel="label"
                        placeholder="Categories"
                        lableAvailable={true}
                        width="100%"
                        height="2.5rem"
                        className="w-full md:w-20rem"
                        maxSelectedLabels={2}
                      />
                      </Box> */}
                    {/* </ChakraProvider> */}
                  {/* </DialogTitle> */}
                  {/* <DialogContent> */}
                    {showTable ? null : (
                      <>
                        <Box mb={{ base: "0.88rem", md: "0.88rem" }}>
                          {/* <PosSearch
                            value={searchQuery}
                            onChange={(e) => {
                              let value =
                                searchQuery.length > 0
                                  ? e.target.value
                                  : e.target.value.trim();
                              setSearchQuery(value);
                            }}
                            onSearch={handleSearchList}
                            handleClear={handleClear}
                          /> */}
                          {productData.length >= 1 ? (
                            !isChecked &&
                            checkedItems.length >= 0 &&
                            allLocationProductFLag == 1 && (modifiedData.length > 0 && !isAllProductsChecked) ? (
                              <Text
                                mt={{ base: 0, md: "0.30rem" }}
                                style={{
                                  fontSize: "1.13rem",
                                  color: "#008000",
                                  fontStyle: "normal",
                                  fontWeight: "300",
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                }}
                              >
                                We are displaying all the products of selected location.
                              </Text>
                            )
                            // (
                            // <>
                              // <Flex>
                              // <Text
                              //   mt={{ base: 0, md: "0.30rem" }}
                              //   style={{
                              //     fontSize: "1.13rem",
                              //     color: "#008000",
                              //     fontStyle: "normal",
                              //     fontWeight: "300",
                              //     lineHeight: "normal",
                              //     letterSpacing: "-0.01rem",
                              //   }}
                              // >
                              //   { isAllProductsChecked && ('Product is not associated with selected vendor. ')}
                              //   We are displaying all the products of selected
                              //   location.
                              // </Text>
                              // </Flex>
                            //   {/* <Flex
                            //   color={Colors.primeposTextInfo}
                            //   style={{
                            //     fontSize: "0.94rem",
                            //     fontStyle: "normal",
                            //     fontWeight: "300",
                            //     lineHeight: "normal",
                            //     letterSpacing: "-0.01rem",
                            //     marginRight: "1rem",
                            //   }}
                            //   pt={"0.88rem"}
                            //   mr={{ base: "18rem", md: "0.63rem" }}
                            // >
                            // </Flex> */}
                            // </>
                            // )
                             : (
                              (modifiedData.length === 0 && isAllProductsChecked) ? <PosNoDataFound />
                              :(totalcount > 0 && allLocationProductFLag == 0 && <Text
                                mt={{ base: 0, md: "0.88rem" }}
                                style={{
                                  fontSize: "1.13rem",
                                  color: "#008000",
                                  fontStyle: "normal",
                                  fontWeight: "300",
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                }}
                              >
                                We are displaying all the vendor's products.
                              </Text>)
                            )
                          ) : loading && (
                            <PosProgress />
                          )}
                        </Box>
                        {loading ? (
                          <PosProgress />
                        ) : isChecked ? (
                          allLocationProductFLag == 1 ? (
                            <PosNoDataFound title={"No more products found."}  />
                          ) : (
                            <Box
                              sx={{
                                overflowX: "auto",
                                maxHeight: "30.91rem",
                                maxWidth: "100%",
                                "& .Product Name": {
                                  backgroundColor: Colors.loginFooter,
                                },
                              }}
                            >
                              {(modifiedData.length === 0 && isAllProductsChecked)? (
                                <>
                                <PosNoDataFound
                                title={"No more products found."} 
                                />
                                {/* <Flex
                                  flexDirection={{ base: "column", sm: "column", md: "row" }}
                                >
                                <Spacer />
                                <Text>
                                  Vendor's Products:
                                </Text>
                                  <Box
                                    mb={4}
                                    ml={{ base: "0.5rem", md: "0.5rem" }}
                                    pr={{ base: "0.5rem", md: "0.5rem" }}
                                  >
                                    <PosSwitchButton
                                      id="isAllProductsChecked"
                                      onChange={handleAllProductSwitchChange}
                                      switchValue={isAllProductsChecked}
                                      disabled={isChecked}
                                    />
                                  </Box>
                                </Flex> */}
                                </>
                              ) : (
                                <PosDataGridTable
                                  columns={productColumnNames}
                                  rows={modifiedData}
                                  totalcount={totalcount}
                                  columnVisibilityModel={
                                    olColumnVisibilityModel
                                  }
                                  onColumnVisibilityModelChange={(newModel) =>
                                    setOlColumnVisibilityModel(newModel)
                                  }
                                  paginationModel={paginationModel}
                                  paginationMode="server"
                                  sortingMode="server"
                                  onPaginationModelChange={(
                                    newPaginationModel
                                  ) => {
                                    fetchRef.current = true;
                                    setPaginationModel(newPaginationModel);
                                  }}
                                  slots={{
                                    toolbar: GridToolbar,
                                  }}
                                  sortModel={sortModel}
                                  onSortModelChange={(newSortModel) => {
                                    sortRef.current = sortModel;
                                    fetchRef.current = true;
                                    setSortModel(newSortModel);
                                  }}
                                  fileName={"POLowthresholdProducts"}
                                />
                              )}
                            </Box>
                          )
                        ) : (modifiedData.length === 0 && isAllProductsChecked) ? (
                          <>
                          <PosNoDataFound title={"No more products found."}  />
                          {/* <Flex
                            // minWidth="max-content"
                            // alignItems="center"
                            flexDirection={{ base: "column", sm: "column", md: "row" }}
                          >
                          <Spacer />
                          <Text>
                            Vendor's Products:
                          </Text>
                            <Box
                              mb={4}
                              ml={{ base: "0.5rem", md: "0.5rem" }}
                              pr={{ base: "0.5rem", md: "0.5rem" }}
                            >
                              <PosSwitchButton
                                id="isAllProductsChecked"
                                onChange={handleAllProductSwitchChange}
                                switchValue={isAllProductsChecked}
                                disabled={isChecked}
                              />
                            </Box>
                          </Flex> */}
                          </>
                        ) : (
                          <>
                          {/* <Flex
                          flexDirection={{ base: "column", sm: "column", md: "row" }}
                        >
                          <Spacer />
                          <Text>
                              Vendor's Products:
                          </Text>
                            <Box
                              mb={4}
                              ml={{ base: "0.5rem", md: "0.5rem" }}
                              pr={{ base: "0.5rem", md: "0.5rem" }}
                            >
                              <PosSwitchButton
                                id="isAllProductsChecked"
                                onChange={handleAllProductSwitchChange}
                                switchValue={isAllProductsChecked}
                                disabled={isChecked}
                              />
                            </Box>
                          </Flex> */}
                          <Box
                            sx={{
                              overflowX: "auto",
                              maxHeight: "30.91rem",
                              maxWidth: "100%",
                              "& .Product Name": {
                                backgroundColor: Colors.loginFooter,
                              },
                            }}
                          >
                            <PosDataGridTable
                              columns={productColumnNames}
                              rows={modifiedData}
                              totalcount={totalcount}
                              columnVisibilityModel={olColumnVisibilityModel}
                              onColumnVisibilityModelChange={(newModel) =>
                                setOlColumnVisibilityModel(newModel)
                              }
                              slots={{
                                toolbar: GridToolbar,
                              }}
                              paginationModel={paginationModel}
                              paginationMode="server"
                              sortingMode="server"
                              onPaginationModelChange={(newPaginationModel) => {
                                fetchRef.current = true;
                                setPaginationModel(newPaginationModel);
                              }}
                              sortModel={sortModel}
                              onSortModelChange={(newSortModel) => {
                                sortRef.current = sortModel;
                                fetchRef.current = true;
                                setSortModel(newSortModel);
                              }}
                              fileName={"POProducts"}
                            />
                          </Box>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                  </ChakraProvider>
                  {/* </DialogContent> */}
                  {/* <DialogActions>
                    <ChakraProvider theme={theme}>
                      <Flex minWidth="max-content" gap="2" mt={4} mb={4}>
                        <Spacer />
                        <ButtonGroup>
                          <PosFormButton
                            onClick={modalClose}
                            buttonText="Cancel"
                            CancelButton={true}
                            isDisabled={buttonDisable}
                          />
                          <PosFormButton
                            isDisabled={
                              checkedItems.length <= 0 ||
                              formik.errors.product ||
                              modifiedData.length === 0 ||
                              (isChecked && perchesOrderData.length === 0) ||
                              buttonDisable
                            }
                            buttonsubmit={"Add Product"}
                            SubmitButton={true}
                            onClick={handleSelectedViewProduct}
                          />
                        </ButtonGroup>
                      </Flex>
                    </ChakraProvider>
                  </DialogActions> */}
                </Dialog>
              {/* </ThemeProvider> */}
            {/* // )} */}
          </>
        </Flex>
        {!formik.values.apply_on_all_prods &&
          viewSelectedProducts &&
          viewSelectedProducts.length > 0 && (
            <Box
              borderRadius="0.63rem"
              mt={3}
              bg={Colors.posPageDataBackground}
              boxShadow={"0px 0.25rem 1.25rem 0px" + Colors.createEditBoxShadow}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead
                    sx={{
                      backgroundColor: Colors.modifierTable,
                      alignItems: "start",
                    }}
                  >
                    <TableRow>
                      {columnProductViewNames.map((column, index) => (
                        <TableCell
                          key={index}
                          // color={Colors.primeposTextInfo}
                          sx={{
                            fontWeight: 500,
                            fontSize: "0.94rem",
                            lineHeight: "1.18rem",
                            letterSpacing: "-0.01rem",
                            fontStyle: "normal",
                            textAlign: column.textAlign || "center",
                            maxHeight: "42rem",
                            color:"#010048"
                          }}
                        >
                          <Flex flexDirection={"row"}>
                          {column.columnNames}
                          {column.columnNames=='Cost/Case($)' || column.columnNames=='Units/Case' ? (<Tippy
                            content={column.columnNames=='Cost/Case($)' ? 'This will your cost per case. For example, $50 for Kellogs Cornflakes 300Gm X 20':'Please enter the quantity of individual units per case. For example, for Kellogs Cornflakes 300Gm X 20, you should enter 20.'}
                            interactive={true}
                            maxWidth={300}
                            placement="top"
                            animation="fade"
                            inertia={true}
                            moveTransition="transform 0.2s ease-out"
                            theme="tomato"
                          >
                            <Image
                              src={toolTip}
                              alt="Tooltip"
                              ml={{ base: "0.3rem", md: "0.3rem" }}
                            />
                          </Tippy>): null}
                          </Flex>
                          {column.sortName.length > 0 ? (
                            <TableSortLabel
                              active={
                                column.sortName === columnProductViewNames
                              }
                              direction={
                                column.sortName === columnProductViewNames
                                  ? sortType
                                  : "asc"
                              }
                              onClick={() => handleColumn(column)}
                            />
                          ) : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {modifiedViewDatas.map((row,i) => {
                      let index = perchesOrderData.findIndex(
                        (x) => x.id === row.id
                      );
                      return (
                        <TableRow
                          key={i}
                          // sx={{
                          //   "&:last-child td, &:last-child th": { border: 0 },
                          // }}
                        >          
                        <TableCell>                 
                          {index === -1 ? (
                            <Flex flexDirection={"row"}>
                            <Search2Icon mt={3} mr={3} />
                            {/* <AutoComplete
                              id="selectedProducts"
                              field="name"
                              value={autoSelectedProducts}
                              suggestions={filteredProducts}
                              completeMethod={search}
                              placeholder="Search"
                              onChange={(e) => {
                                setAutoSelectedProducts(e.value);
                              }}
                              disabled={
                                !formik.values.vendors ||
                                !formik.values.locations ||
                                formik.values.vendors.length <= 0 ||
                                formik.errors.vendors ||
                                // formik.values.locations.length <= 0 ||
                                formik.errors.locations ||
                                buttonDisable
                                  ? true
                                  : false
                              }
                              showEmptyMessage={true}
                              emptyMessage="There is no product."
                              onSelect={(e) => {
                                if(undefined != e.value && null != e.value && '' != e.value){
                                  autoCompleteSelectedProducts(e.value);
                                }
                              }}
                            /> */}
                            <ThemeProvider theme={Mtheme}>
                            <Autocomplete
                              size="small"
                              filterOptions={(x) => x}
                              id="autoselect"
                              disabled={
                                !formik.values.vendors ||
                                !formik.values.locations ||
                                formik.values.vendors.length <= 0 ||
                                formik.errors.vendors ||
                                // formik.values.locations.length <= 0 ||
                                formik.errors.locations ||
                                buttonDisable
                                  ? true
                                  : false
                              }
                              sx={{ width: 300 }}
                              open={open}
                              onOpen={() => {
                                setOpen(true);
                              }}
                              onClose={() => {
                                setOpen(false);
                              }}
                              value={autoSelectedProducts}
                              onChange={(event, newValue) => {
                                // setAutoSelectedProducts(newValue);
                                autoCompleteSelectedProducts(newValue);
                                // setInputValueAuto(newValue);
                              }}
                              inputValue={inputValueAuto || ""}
                              onInputChange={(event, newInputValue) => {
                                setOptions([]);
                                setInputValueAuto(newInputValue);
                              }}
                              // isOptionEqualToValue={(option, value) => option.barcode === value?.barcode}
                              getOptionLabel={(option) => option?.name || ""}
                              options={options}
                              loading={loading1}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search"
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <React.Fragment>
                                        {loading1 ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                            </ThemeProvider>
                          </Flex>
                          ): (
                            <VStack align="start">
                              <Box
                                style={{
                                  fontSize: "0.94rem",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                  color:"#010048"
                                }}
                              >
                                {row.name} ({row.categories})
                              </Box>
                              <Flex>
                                <Box
                                  color={Colors.taxCreateExemptEbt}
                                  style={{
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  Item Code:
                                </Box>
                                <Box ml={2}>{row.item_code || "N.A"}</Box>
                              </Flex>
                              {/* <Flex>
                                <Box
                                  color={Colors.taxCreateExemptEbt}
                                  style={{
                                    fontSize: "0.94rem",
                                    fontStyle: "normal",
                                    fontWeight: "300",
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01rem",
                                  }}
                                >
                                  Primary Barcode:
                                </Box>
                                <Box ml={2}>{row.barcode}</Box>
                              </Flex> */}
                            </VStack>)}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {row.barcode || "N.A"}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {null != row.sale_price && '' != row.sale_price ? "$"+row.sale_price : "N.A."}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              color: "#111928",
                              fontSize: "0.94rem",
                              fontStyle: "normal",
                              fontWeight: 300,
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                          >
                            {row.in_stock_qty}
                          </TableCell>
                          <TableCell align="center">
                            <Input
                              ref={(el) => (inputRefs.current[i] = el)}
                              id="cost"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onChange={(e) =>
                                handleInputChange(e, row.id, "cost")
                              }
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "cost");
                              }}
                              value={
                                (index > -1
                                  ? perchesOrderData[index].cost
                                  : row.cost) || ""
                              }
                              type="number"
                              isInvalid={
                                perchesOrderData[index]?.costErrors?.length > 0
                              }
                            />
                            <Text color={Colors.errorColor}>
                              {perchesOrderData[index]?.costErrors?.length >
                              0 ? (
                                <span>
                                  {perchesOrderData[index]?.costErrors}
                                </span>
                              ) : null}
                            </Text>
                          </TableCell>
                          <TableCell align="center">
                            <Input
                              id="quantity"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              disabled={modifiedViewDatas.length-1 == i}
                              onChange={(e) => handleInputChange(e, row.id, "quantity")}
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseInt(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue;
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "quantity");
                              }}
                              value={
                                (index > -1
                                  ? perchesOrderData[index].quantity
                                  : row.quantity) || ""
                              }
                              onKeyPress={(e) => {
                                if (e.key === "-" || e.key === "." || e.key === "e") { // Prevent non-integer characters
                                  e.preventDefault();
                                }
                              }}
                              type="number"
                              isInvalid={
                                perchesOrderData[index]?.quantityErrors?.length > 0
                              }
                            />
                            <Text color={Colors.errorColor}>
                              {perchesOrderData[index]?.quantityErrors?.length >
                              0 ? (
                                <span>
                                  {perchesOrderData[index]?.quantityErrors}
                                </span>
                              ) : null}
                            </Text>
                          </TableCell>
                          <TableCell align="center">
                            <Input
                              id="unit_case"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "unit_case")
                              }
                              disabled={modifiedViewDatas.length-1 == i}
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "unit_case");
                              }}
                              value={
                                (index > -1
                                  ? perchesOrderData[index].unit_case
                                  : row.unit_case) || ""
                              }
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              type="number"
                              isInvalid={
                                perchesOrderData[index]?.unitCaseErrors?.length > 0
                              }
                            />
                            <Text color={Colors.errorColor}>
                              {perchesOrderData[index]?.unitCaseErrors?.length >
                              0 ? (
                                <span>
                                  {perchesOrderData[index]?.unitCaseErrors}
                                </span>
                              ) : null}
                            </Text>
                          </TableCell>
                          <TableCell align="center">
                            <Input
                              disabled={true}
                              id="total_units"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "total_units")
                              }
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "total_units");
                              }}
                              value={
                                (index > -1
                                  ? perchesOrderData[index].total_unit
                                  : 'N.A.') || "N.A."
                              }
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              type="text"
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Input
                              disabled={true}
                              id="cost_per_unit"
                              htmlSize={4}
                              variant={"posBasicInput"}
                              width="5rem"
                              onChange={(e) =>
                                handleInputChange(e, row.id, "cost_per_unit")
                              }
                              sx={{
                                color: "#111928",
                                fontSize: "0.94rem",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "normal",
                                letterSpacing: "-0.01rem",
                                border: "3px solid #e5e5e5b3" // Adjust color as needed
                              }}
                              onBlur={(e) => {
                                let originalValue = e.target.value.trim();
                                let parsedValue = parseFloat(originalValue);
                                if (!isNaN(parsedValue)) {
                                  e.target.value = parsedValue.toFixed(2);
                                } else {
                                  e.target.value = originalValue;
                                }
                                handleInputChange(e, row.id, "cost_per_unit");
                              }}
                              value={
                                (index > -1
                                  ? (perchesOrderData[index].cost_per_unit)
                                  : 'N.A.') || "N.A."
                              }
                              onKeyPress={(e) => {
                                if (e.key === "-") {
                                  e.preventDefault();
                                }
                              }}
                              type="text"
                            />
                          </TableCell>
                          {/* <TableCell align="center">
                            <Box position="relative" display="inline-block">
                              <Select
                                style={{
                                  width: "5.2rem",
                                  padding: "0.63rem",
                                  backgroundColor: "white",
                                  border: "0.6px solid #01004833",
                                  borderRadius: "0.44rem",
                                  textAlign: "start",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "0.63rem",
                                  flexShrink: 0,
                                  color:"#010048",
                                  fontSize: "0.94rem",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  lineHeight: "normal",
                                  letterSpacing: "-0.01rem",
                                }}
                                appearance="none"
                                value={
                                  index > -1
                                    ? perchesOrderData[index].quantityUnit
                                    : row.quantityUnit
                                }
                                onChange={(e) =>
                                  handleInputChange(e, row.id, "quantityUnit")
                                }
                                onBlur={(e) =>
                                  handleInputChange(e, row.id, "quantityUnit")
                                }
                              >
                                <option value="CAS">Case</option>
                                <option value="PLT">Pallet</option>
                                <option value="EAC">Each</option>
                              </Select>
                            </Box>
                          </TableCell> */}
                          <TableCell align="center">
                            {index == -1 ? (
                              null
                            ) : 
                            (<DeleteIcon
                              color={Colors.posCancleButtonMuiRed}
                              style={{
                                width: "1.13rem",
                                height: "1.13rem",
                                flexShrink: 0,
                                cursor:"pointer"
                              }}
                              onClick={() => handleSingleDelete(row.id)}
                            />)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
          <Box pb={16}></Box>
      </Box>
    </Box>
  );
};
export default WithRouter(PurchaseOrderEdit);
