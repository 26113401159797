import { EmailIcon, LockIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Text
} from "@chakra-ui/react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import {
  NavLink,
  useActionData,
  useLoaderData,
  useSubmit,
  useLocation,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import warnignIcon from "../../../assets/images/Ellipse2.svg";
import signInImage from "../../../assets/images/newcropped.png";
import {
  AppContext,
  PosAlert,
  PosMainProgress,
  PosTostMessage
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks/index";
import { RouteWithLayout } from "../../../navigators/RouteWithLayout";

const SignIn = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [displayLayout, setDisplayLayout] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formik = useFormik({
    initialValues: {
      email: null != queryParams.get("user") && '' != queryParams.get("user") && queryParams.get("user").match(/.{1,2}/g)
      .map(byte => String.fromCharCode(parseInt(byte, 16)))
      .join("") == 'super' ? queryParams.get("email").match(/.{1,2}/g)
      .map(byte => String.fromCharCode(parseInt(byte, 16)))
      .join(""):"" ,
      password: null != queryParams.get("user") && '' != queryParams.get("user") && queryParams.get("user").match(/.{1,2}/g)
      .map(byte => String.fromCharCode(parseInt(byte, 16)))
      .join("") == 'super' ? queryParams.get("password"):"",
      user: null != queryParams.get("user") && '' != queryParams.get("user") && queryParams.get("user").match(/.{1,2}/g)
      .map(byte => String.fromCharCode(parseInt(byte, 16)))
      .join("") == 'super' ? queryParams.get("user").match(/.{1,2}/g)
      .map(byte => String.fromCharCode(parseInt(byte, 16)))
      .join(""):"",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .trim()
        .email(Constants.USER_EMAIL_NOT_VALID)
        // .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, Constants.USER_EMAIL_NOT_VALID)
        .max(254, Constants.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER)
        .required(Constants.USER_EMAIL_REQUIRED),
      password: Yup.string()
        .min(8, Constants.PASSWORD_MUST_BE_8_CHARACHTER)
        .required(Constants.PASSWORD_FIELD_REQUIRED)
        .oneOf(
          [Yup.ref(Constants.PASSWORD)],
          Constants.YOU_ENTERED_THE_WRONG_PASSWORD
        ),
    }),
    onSubmit: (values) => {
      myContext.handleLoading(true);
      setButtonDisable(true);
      submit(values, {
        method: Constants.POST,
        path: Constants.SIGNIN_PATH,
      });
    },
  });
  useEffect(() => {
    if (
      undefined != loaderResponse &&
      null != loaderResponse &&
      {} != loaderResponse
    ) {
      if(null != queryParams.get("user") && '' != queryParams.get("user") && queryParams.get("user").match(/.{1,2}/g)
        .map(byte => String.fromCharCode(parseInt(byte, 16)))
        .join("") == 'super'){
        const originalEmail = queryParams.get("email").match(/.{1,2}/g)
        .map(byte => String.fromCharCode(parseInt(byte, 16)))
        .join("");
        setDisplayLayout(true);
        myContext.handleLoading(false);
        formik.setFieldValue("email", originalEmail);
        formik.setFieldValue("password", queryParams.get("password"));
        formik.handleSubmit();
      }
      else{
      if (loaderResponse?.data) {
        // if(!myContext.loading)
        props.navigate(Constants.DASHBOARD_PATH);
      } else {
        setDisplayLayout(true);
        myContext.handleLoading(false);
      }
      }
    }
  }, [loaderResponse]);
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse?.response;
        if (actionData?.status == 500) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.TOAST_HEADING_WARNING,
            alertDescription: Constants.FAILED_TO_LOGIN,
          });
        } else {
          let actionData = actionResponse;
          error({ actionData });
        }
        myContext.handleLoading(false);
        // let actionData = actionResponse;
        // error({ actionData });
      } else {
        myContext.handleLoading(false);
        localStorage.setItem("token", actionResponse.data.data.token);
        localStorage.setItem(
          "currentUser",
          JSON.stringify(actionResponse.data.data.user)
        );
        props.navigate(Constants.DASHBOARD_PATH);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: "User Loging",
          alertDescription: actionResponse.data.message,
        });
      }
    }
  }, [actionResponse]);

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values.email.length <= 0 ||
        formik.errors.email ||
        formik.values.password.length <= 0 ||
        formik.errors.password ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  if (displayLayout) {
    return (
      <Flex
        bg={Colors.loginAuthBackground}
        minHeight={"100vh"}
        flexDirection={"column"}
      >
        <Box
          w={"100%"}
          sx={{
            position: "-webkit-sticky",
            /* Safari */ position: "sticky",
            top: "0",
            zIndex: 999,
          }}
        >
          <Box
            w={"100%"}
            sx={{
              /* Safari */ position: "absolute",
              top: "0",
              zIndex: 99999999,
            }}
          >
            {myContext.loading ? <PosMainProgress /> : null}
          </Box>
          <Flex
            h={"100vh"}
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
          >
            <Box
              // mt={{ base: 10, md: 20, sm: 16, lg: 28 }}
              // mb={{ base: 10, md: 20, sm: 16, lg: 28 }}
              bg={Colors.loginAuthBackground}
              style={{
                width: "54.13rem",
                borderRadius: "0.63rem",
                border: "0.13rem solid #FFF",
                boxShadow: "0rem 1.25rem 3.13rem 0rem #59595926",
                paddingBottom: "2.81rem",
                boxSizing: "border-box",
              }}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Image
                  style={{
                    width: "15.31rem",
                    marginTop: "4.69rem",
                  }}
                  src={signInImage}
                  alt="Octopos"
                />
              </Box>
              <Box
                color={Colors.posTextInfo}
                mt={{ base: "2rem", md: "2.63rem" }}
                style={{
                  fontSize: "1.5rem",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "normal",
                  letterSpacing: "-0.02rem",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Text>Log into your Account</Text>
              </Box>
              <Box
                color={Colors.posTextInfo}
                style={{
                  marginTop: "0.31rem",
                  fontSize: "1.13rem",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "normal",
                  letterSpacing: "-0.02rem",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* <Text>
            Need to register?{" "}
            <Link color={Colors.posNavbarLink} href="">
              Sign up
            </Link>
          </Text> */}
              </Box>
              <Box mx={{ base: 4, md: 156 }} textAlign="left">
                <Stack spacing={2}>
                  <Text
                    mt={{ base: 20, md: 20, sm: 16, lg: 28 }}
                    color={Colors.posTextInfo}
                    style={{
                      fontSize: "0.88rem",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "normal",
                      letterSpacing: "-0.01rem",
                    }}
                  >
                    Please fill in your details
                  </Text>
                  <InputGroup
                    bg={Colors.loginAuthInput}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      flexShrink: 0,
                      borderRadius: "0.63rem",
                    }}
                  >
                    <InputLeftElement pointerEvents="none">
                      <EmailIcon color={Colors.posNavbarLink} />
                    </InputLeftElement>
                    <Input
                      placeholder="Email Address"
                      name="email"
                      onChange={formik.handleChange}
                      onBlur={(e) => {
                        formik.setFieldValue(
                          "email",
                          formik.values.email.trim()
                        );
                        formik.handleBlur(e);
                      }}
                      onKeyDown={handleKeyDown}
                      value={formik.values.email}
                    />
                  </InputGroup>
                  {formik.touched.email && formik.errors.email ? (
                    <PosAlert
                      alertStatus={"error"}
                      alertTitle={""}
                      image={warnignIcon}
                      bg={Colors.errorsign}
                      color={Colors.orangeButton}
                      alertDescription={formik.errors.email}
                      width={{ base: "100%" }}
                    />
                  ) : null}
                  <InputGroup
                    bg={Colors.loginAuthInput}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      flexShrink: 0,
                      borderRadius: "0.63rem",
                    }}
                  >
                    <InputLeftElement pointerEvents="none">
                      <LockIcon color={Colors.posNavbarLink} />
                    </InputLeftElement>
                    <Input
                      placeholder="Password"
                      name="password"
                      type="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyDown={handleKeyDown}
                      value={formik.values.password}
                    />
                  </InputGroup>
                  {formik.touched.password && formik.errors.password ? (
                    <PosAlert
                      alertStatus={"error"}
                      alertTitle={""}
                      image={warnignIcon}
                      bg={Colors.errorsign}
                      color={Colors.orangeButton}
                      alertDescription={formik.errors.password}
                      width={{ base: "100%" }}
                    />
                  ) : null}
                  <Text mb="2.06rem" mt={{ base: "1rem", md: "1.25rem" }}>
                    <NavLink to="/forgot-password">Forgot password?</NavLink>
                  </Text>
                  <Button
                    onClick={formik.handleSubmit}
                    bg={Colors.loginButtonBackground}
                    w={"100%"}
                    isDisabled={
                      formik.values.email.length <= 0 ||
                      formik.errors.email ||
                      formik.values.password.length <= 0 ||
                      formik.errors.password ||
                      buttonDisable
                        ? true
                        : false
                    }
                  >
                    Log In
                  </Button>
                  {/* <Checkbox defaultChecked>Keep me Logged In</Checkbox> */}
                </Stack>
              </Box>
              <Box
                color={Colors.loginFooter}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10rem",
                  fontSize: "0.75rem",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "normal",
                }}
              >
                <CopyrightIcon
                  style={{
                    width: "1.13rem",
                    height: "1.13rem",
                    flexShrink: 0,
                    marginRight: "0.25rem",
                  }}
                />
                <Text>
                  2024,
                  <Link target="_blank" href="https://www.octopos.com/">
                    Octopos.
                  </Link>
                  All rights reserved
                </Text>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  } else {
    return null;
  }
};

export default RouteWithLayout(SignIn);
