import {
  Box,
  ButtonGroup,
  Flex,
  Spacer,
  Stack,
  Text,
  useMediaQuery
} from "@chakra-ui/react";
import { GridToolbar } from "@mui/x-data-grid";
import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useLoaderData, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDataGridTable,
  PosDatePicker,
  PosDropDown,
  PosFormButton,
  PosIconButton,
  PosInput,
  PosNoDataFound,
  PosProgress,
  PosSearch,
  PosTostMessage,
} from "../../../../../components/index";
import * as Constants from "../../../../../constants/Constants";
import {
  endOfTodaysDay,
  reportDateFormate,
  startOfTodaysDay, twofix
} from "../../../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../../../hooks";
import { WithRouter } from "../../../../../navigators/WithRouter";
const DailyInventoryMovement = (props) => {
  const { addToast } = PosTostMessage();
  const myContext = useContext(AppContext);
  const submit = useSubmit();
  const { error } = PosErrorHook();
  const actionResponse = useActionData();
  const loaderResponse = useLoaderData();
  const [tableData, setTableData] = useState([]);
  const [showTable, setShowTable] = useState(true);
  const [totalcount, setTotalCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState([]);
  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [fromDateView, setFromDateView] = useState(startOfTodaysDay());
  const [toDateView, setToDateView] = useState(endOfTodaysDay());
  const [searchQuery, setSearchQuery] = useState("");
  const [isExtraLarge] = useMediaQuery("(min-width: 1580px)");
  const [isLarge] = useMediaQuery("(min-width: 1435px)");
  const [isMeduim1] = useMediaQuery("(min-width: 1267px)");
  const [isExtraSmall] = useMediaQuery("(max-width: 480px)");
  const [isSmall] = useMediaQuery("(max-width: 1032px)");
  const [isMeduim] = useMediaQuery("(max-width: 1266px)");
  const pageRef = useRef(false);
  const searchInputHideRef = useRef(false);
  const [searchButton, setSearchButton] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: "in_stock_qty",
      sort: "desc",
    },
  ]);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [olColumnVisibilityModel, setOlColumnVisibilityModel] = React.useState({
    product_gap: false,
    last_received: false,
  });
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const actionColumn = isMobile ? { width: 150 } : { flex: 1 };
  const actionColumnFlexDouble = isMobile ? { width: 250 } : { flex: 1.2 };
  const columnNames = [
    {
      field: "name",
      headerName: "Product Name",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "barcode",
      headerName: "Primary Barcode",
      sortable: true,
      ...actionColumnFlexDouble,
    },
    {
      field: "in_stock_qty",
      headerName: "In Stock Quantity",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "quantity",
      headerName: "Total Quantity",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "free_quantity",
      headerName: "Free Quantity",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "sale_price",
      headerName: "Sale Price($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "discount",
      headerName: "Total Discount($)",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "revanue",
      headerName: "Total Revenue($)",
      sortable: true,
      ...actionColumn,
    },
    {
      field: "last_received",
      headerName: "Last Received",
      sortable: false,
      ...actionColumn,
    },
    {
      field: "product_gap",
      headerName: "Product Gap",
      sortable: false,
      ...actionColumn,
    },
  ];
  const [loading, setLoading] = useState(true);
  const calendarRef = useRef(null);
  const toDateCalendarRef = useRef(null);
  const effectRun = useRef(true);
  const sortRef = useRef(sortModel);
  const formik = useFormik({
    initialValues: {
      selectedLoactions: "",
      fromDate: "",
      min_quantity: "",
      max_quantity: "",
      last_received: "",
      product_gap: "",
    },
    validateOnChange: true,
    validationSchema: Yup.object({
      selectedLoactions: Yup.string().required(Constants.LOCATION_IS_REQUIRED),
      fromDate: Yup.string().required(Constants.FORM_DATE_IS_REQUIRED),
      toDate: Yup.string().required(Constants.TO_DATE_IS_REQUIRED),
      min_quantity: Yup.number()
        .typeError(Constants.MIN_QUA_NOT_VALID)
        .integer(Constants.MIN_QUA_NOT_VALID),
      max_quantity: Yup.number()
        .typeError(Constants.MAX_QUA_NOT_VALID)
        .integer(Constants.MAX_QUA_NOT_VALID),
      last_received: Yup.number()
        .typeError(Constants.LAST_RECEIVED_NOT_VALID)
        .integer(Constants.LAST_RECEIVED_NOT_VALID)
        .min(1, Constants.LAST_RECEIVED_MUST_BE_AT_LEAST_1),
      product_gap: Yup.number()
        .typeError(Constants.PROD_GAP_NOT_VALID)
        .integer(Constants.PROD_GAP_NOT_VALID)
        .min(1, Constants.PRODUCT_GAP_MUST_BE_AT_LEAST_1),
    }),
    onSubmit: async (values) => { },
  });
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          if (
            undefined !== loaderResponse?.data?.data?.location &&
            null !== loaderResponse?.data?.data?.location &&
            loaderResponse?.data?.data?.location?.length === 1
          ) {
            setSelectedLocation(loaderResponse?.data?.data?.location[0]);
            formik.setFieldValue(
              "selectedLoactions",
              loaderResponse?.data?.data?.location[0].name
            );
          }
          setLocations(loaderResponse?.data?.data?.location);
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (pageRef.current) {
      getDailyInventoryMovementData();
    }
  }, [paginationModel, sortModel]);
  useEffect(() => {
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        if (
          actionResponse.data?.message ===
          "Inventory Report successfully exported."
        ) {
          const url = Constants.REACT_APP_API_URL + actionResponse.data.data;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            Constants.DOWNLOAD,
            Constants.DAILY_INVENTORY_MOVEMENT_EXCEL_FILENAME
          );
          document.body.appendChild(link);
          window.stop();
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
          addToast({
            alertStatus: Constants.TOAST_TYPE_SUCESS,
            alertTitle: Constants.REPORT_MODULE,
            alertDescription: actionResponse.data?.message,
          });
          myContext.handleLoading(false);
        } else {
          setTableData(actionResponse.data.data.data.data);
          setTotalCount(actionResponse.data.data.totalItems);
          setSearchButton(false);
          setLoading(false);
          setButtonDisable(false);
        }
      } else {
        let actionData = actionResponse?.data;
        error({ actionData });
        setTableData([]);
        setTotalCount(0);
        setLoading(false);
        setButtonDisable(false);
      }
    }
  }, [actionResponse]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        null !== calendarRef.current &&
        calendarRef.current &&
        fromDateFocus
      ) {
        calendarRef.current.hide();
        setFromDateFocus(false);
      }
      if (
        null !== toDateCalendarRef.current &&
        toDateCalendarRef.current &&
        toDateFocus
      ) {
        toDateCalendarRef.current.hide();
        setToDateFocus(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [fromDateFocus, toDateFocus]);
  const handleExportReport = () => {

    if (
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions &&
      null != formik.values.fromDate &&
      "" != formik.values.fromDate &&
      null != formik.values.toDate &&
      "" != formik.values.toDate
    ) {
      myContext.handleLoading(true);
      const formattedData = {
        dateFrom: reportDateFormate(formik.values.fromDate),
        dateTo: reportDateFormate(formik.values.toDate),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        location: {
          label: selectedLocation.name,
          value: selectedLocation,
        },
        max_quantity: (undefined != formik.values.max_quantity && null != formik.values.max_quantity && '' != formik.values.max_quantity) ? Number(formik.values.max_quantity) : "",
        min_quantity: (undefined != formik.values.min_quantity && null != formik.values.min_quantity && '' != formik.values.min_quantity) ? Number(formik.values.min_quantity) : "",
        last_received: formik.values.last_received,
        product_gap: formik.values.product_gap,
      };
      let data = {
        getreport: false,
        data: JSON.stringify(formattedData),
        type: "xls",
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.INVENTORY_MOVEMENT_BY_DAY_REPORT_ROUTE,
      });
    }
  };
  const clearTableData = (message) => {
    // clear table data when remove date and location
    setTableData([]);
    setTotalCount(0);
    setLoading(false);
    setButtonDisable(false);
    myContext.handleLoading(false);
    addToast({
      alertStatus: Constants.TOAST_TYPE_WARNING,
      alertTitle: Constants.REPORT_MODULE,
      alertDescription: message,
    });
  };
  const getDailyInventoryMovementData = () => {
    if (
      (formik.values.min_quantity == "" ||
        formik.values.min_quantity == null) &&
      formik.values.max_quantity != "" &&
      formik.values.max_quantity != null &&
      formik.values.max_quantity != undefined
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.MIN_QUA_EMPTY,
      });
    } else if (
      (formik.values.max_quantity === "" ||
        formik.values.max_quantity === null) &&
      (formik.values.min_quantity !== undefined &&
        formik.values.min_quantity !== "" &&
        formik.values.min_quantity !== null)
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.MAX_QUA_EMPTY,
      });
    } else if (
      Number(formik.values.min_quantity) > Number(formik.values.max_quantity)
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.MIN_QUA_GREATER_THAN_MAX_QUA,
      });
    } else {
      if (
        null != formik.values.selectedLoactions &&
        "" != formik.values.selectedLoactions
      ) {
        if (null != formik.values.fromDate && "" != formik.values.fromDate) {
          if (null != formik.values.toDate && "" != formik.values.toDate) {
            setButtonDisable(true);
            setLoading(true);
            setShowTable(false);
            const formattedData = {
              dateFrom: reportDateFormate(formik.values.fromDate),
              dateTo: reportDateFormate(formik.values.toDate),
              zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              location: {
                label: selectedLocation.name,
                value: selectedLocation,
              },
              max_quantity: (undefined != formik.values.max_quantity && null != formik.values.max_quantity && '' != formik.values.max_quantity) ? Number(formik.values.max_quantity) : "",
              min_quantity: (undefined != formik.values.min_quantity && null != formik.values.min_quantity && '' != formik.values.min_quantity) ? Number(formik.values.min_quantity) : "",
              last_received: formik.values.last_received,
              product_gap: formik.values.product_gap,
            };
            let data = {
              getreport: true,
              filter: searchQuery,
              data: JSON.stringify(formattedData),
              query: JSON.stringify({
                limit: paginationModel.pageSize,
                filter: searchQuery,
                page: paginationModel.page + 1,
                order:
                  sortModel.length > 0
                    ? sortModel[0].field
                    : sortRef.current[0].field,
                order_type:
                  sortModel.length > 0
                    ? sortModel[0].sort
                    : sortRef.current[0].sort,
              }),
            };
            submit(data, {
              method: Constants.POST,
              path: Constants.INVENTORY_MOVEMENT_BY_DAY_REPORT_ROUTE,
            });
          } else {
            clearTableData(Constants.TO_DATE_IS_REQUIRED);
          }
        } else {
          clearTableData(Constants.FORM_DATE_IS_REQUIRED);
        }
      } else {
        clearTableData(Constants.LOCATION_IS_REQUIRED);
      }
    }
  };

  const getDailyInventoryMovementDataAfterClear = () => {
    if (
      (formik.values.min_quantity == "" ||
        formik.values.min_quantity == null) &&
      formik.values.max_quantity != "" &&
      formik.values.max_quantity != null &&
      formik.values.max_quantity != undefined
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.MIN_QUA_EMPTY,
      });
    } else if (
      (formik.values.max_quantity === "" ||
        formik.values.max_quantity === null) &&
      (formik.values.min_quantity !== undefined &&
        formik.values.min_quantity !== "" &&
        formik.values.min_quantity !== null)
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.MAX_QUA_EMPTY,
      });
    } else if (
      Number(formik.values.min_quantity) > Number(formik.values.max_quantity)
    ) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.REPORT_MODULE,
        alertDescription: Constants.MIN_QUA_GREATER_THAN_MAX_QUA,
      });
    } else if (
      null != formik.values.selectedLoactions &&
      "" != formik.values.selectedLoactions &&
      null != formik.values.fromDate &&
      "" != formik.values.fromDate &&
      null != formik.values.toDate &&
      "" != formik.values.toDate
    ) {
      setButtonDisable(true);
      setLoading(true);
      setShowTable(false);
      const formattedData = {
        dateFrom: reportDateFormate(formik.values.fromDate),
        dateTo: reportDateFormate(formik.values.toDate),
        zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        location: {
          label: selectedLocation.name,
          value: selectedLocation,
        },
        max_quantity: (undefined != formik.values.max_quantity && null != formik.values.max_quantity && '' != formik.values.max_quantity) ? Number(formik.values.max_quantity) : "",
        min_quantity: (undefined != formik.values.min_quantity && null != formik.values.min_quantity && '' != formik.values.min_quantity) ? Number(formik.values.min_quantity) : "",
        last_received: formik.values.last_received,
        product_gap: formik.values.product_gap,
      };
      let data = {
        getreport: true,
        filter: "",
        data: JSON.stringify(formattedData),
        query: JSON.stringify({
          limit: paginationModel.pageSize,
          filter: "",
          page: paginationModel.page + 1,
          order:
            sortModel.length > 0
              ? sortModel[0].field
              : sortRef.current[0].field,
          order_type:
            sortModel.length > 0 ? sortModel[0].sort : sortRef.current[0].sort,
        }),
      };
      submit(data, {
        method: Constants.POST,
        path: Constants.INVENTORY_MOVEMENT_BY_DAY_REPORT_ROUTE,
      });
    }
  };
  const handleOptionSelect = (e) => {
    if (undefined !== e.value) {
      setSelectedLocation(e.value);
      formik.setFieldValue("selectedLoactions", e.value.name);
      //   formik.setFieldValue("selectedLoactions", e.value);
    }
  };
  const handleResetAllData = () => {
    setSearchQuery("");
    pageRef.current = false;
    setSortModel([
      {
        field: "in_stock_qty",
        sort: "desc",
      },
    ]);
    setPaginationModel({
      page: 0,
      pageSize: 25,
    });
    setTableData([]);
    setSelectedLocation([]);
    setShowTable(true);
    setTotalCount(0);
    setFromDateView(startOfTodaysDay());
    setToDateView(endOfTodaysDay());
    formik.resetForm();
  };
  const hadleFromDateSelect = (e) => {
    if (
      null !== formik.values.toDate &&
      null != formik.values.toDate &&
      "" !== formik.values.toDate
    ) {
      if (moment(formik.values.toDate).isBefore(e.value)) {
        formik.setFieldValue("fromDate", e.value);
        formik.setFieldValue("toDate", e.value);
        setFromDateView(e.value);
        setToDateView(e.value);
      } else {
        formik.setFieldValue("fromDate", e.value);
        setFromDateView(e.value);
      }
    } else {
      formik.setFieldValue("fromDate", e.value);
      setFromDateView(e.value);
    }
  };
  const modifiedData = tableData.map((data) => ({
    id: data.id,
    name: data.name == "" || data.name == null ? "N.A." : data.name,
    barcode: data.barcode == "" || data.barcode == "" ? "N.A." : data.barcode,
    in_stock_qty:
      data.in_stock_qty == "" || data.in_stock_qty == null
        ? "N.A."
        : data.in_stock_qty,
    quantity:
      data.quantity == "" || data.quantity == null ? "N.A." : data.quantity,
    free_quantity:
      data.free_quantity == "" || data.free_quantity == null
        ? "N.A."
        : data.free_quantity,
    sale_price:
      data.sale_price == "" || data.sale_price == null
        ? "N.A."
        : data.sale_price,
    discount:
      data.discount == "" || data.discount == null ? "N.A." : data.discount,
    revanue: data.revanue == "" || data.revanue == null ? "N.A." : data.revanue,
    last_received:
      data.product_audit_trail_cr === null || data.product_audit_trail_cr === ""
        ? "N.A."
        : data.product_audit_trail_cr.days + " Day(s) Ago",
    product_gap:
      data.product_audit_trail_dr === null || data.product_audit_trail_dr === ""
        ? "N.A."
        : data.product_audit_trail_dr.days + " Day(s) Ago",
  }));
  const handleSearchList = () => {
    getDailyInventoryMovementData();
  };
  // const handleClear = () => {
  //   setSearchQuery("");
  //   getDailyInventoryMovementData();
  // };
  const renderReportLayout = () => {
    return (
      <>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isSmall ? "column" : "row"}
          spacing={4}
          width={isSmall ? "100% !important" : "auto"}
          justifyContent={"space-between"}
        >
          <Box width={isSmall ? "100% !important" : "33% !important"}>
            <PosDropDown
              id="selectedLoactions"
              options={locations}
              value={selectedLocation}
              resetFilterOnHide={true}
              onChange={handleOptionSelect}
              onBlur={(e) => {
                formik.setFieldTouched("selectedLoactions");
                formik.handleBlur(e);
              }}
              optionLabel="name"
              placeholder="Select Locations*"
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                    ? "17rem"
                    : isMeduim1
                      ? "13.32rem"
                      : "100%"
              }
              height={"2.5rem"}
              className="w-full md:w-20rem"
            />
            <Text color={Colors.errorColor}>
              {formik.touched.selectedLoactions &&
                formik.errors.selectedLoactions ? (
                <span>{formik.errors.selectedLoactions}</span>
              ) : null}
            </Text>
            {/* <Text color={Colors.errorColor}>
              {formik.touched.selectedLoactions &&
              formik.errors.selectedLoactions ? (
                <span>{formik.errors.selectedLoactions}</span>
              ) : null}
            </Text> */}
          </Box>
          <Stack
            width={isSmall ? "100% !important" : "67% !important"}
            flexDirection={isExtraSmall ? "column" : "row"}
            justifyContent={"space-between"}
            spacing={4}
          >
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"fromDate"}
                ref={calendarRef}
                value={formik.values.fromDate}
                label={"From Date*"}
                onFocus={() => {
                  if (
                    null !== toDateCalendarRef.current &&
                    toDateCalendarRef.current
                  ) {
                    toDateCalendarRef.current.hide();
                  }
                  setToDateFocus(false);
                  setTimeout(() => {
                    setFromDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("fromDate", true);
                }}
                handleSelectData={hadleFromDateSelect}
                yearRange={"2000:2030"}
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                      ? "17rem"
                      : isMeduim1
                        ? "13.32rem"
                        : "100%"
                }
                maxDate={dateTimeValue}
                viewDate={fromDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.fromDate && formik.errors.fromDate ? (
                  <span>{formik.errors.fromDate}</span>
                ) : null}
              </Text>
            </Box>
            <Box width={"100% !important"}>
              <PosDatePicker
                id={"toDate"}
                ref={toDateCalendarRef}
                value={formik.values.toDate}
                label={"To Date*"}
                onFocus={() => {
                  if (null !== calendarRef.current && calendarRef.current) {
                    calendarRef.current.hide();
                  }
                  setFromDateFocus(false);
                  setTimeout(() => {
                    setToDateFocus(true);
                  }, 1000);
                  formik.setFieldTouched("toDate", true);
                }}
                handleSelectData={(e) => {
                  formik.setFieldValue("toDate", e.value);
                  setToDateView(e.value);
                }}
                yearRange={"2000:2030"}
                width={
                  isExtraLarge
                    ? "20rem"
                    : isLarge
                      ? "17rem"
                      : isMeduim1
                        ? "13.32rem"
                        : "100%"
                }
                minDate={
                  null !== formik.values.fromDate &&
                    "" !== formik.values.fromDate
                    ? formik.values.fromDate
                    : dateTimeValue
                }
                maxDate={dateTimeValue}
                viewDate={toDateView}
                showButtonBar={true}
                showTime={true}
              />
              <Text color={Colors.errorColor}>
                {formik.touched.toDate && formik.errors.toDate ? (
                  <span>{formik.errors.toDate}</span>
                ) : null}
              </Text>
            </Box>
          </Stack>
        </Stack>
        <Stack
          className="card flex justify-content-center"
          flexDirection={isExtraSmall ? "column" : "row"}
          spacing={4}
          width={"100% !important"}
        >
          <Box width={isMeduim ? "100% !important" : "auto"}>
            <PosInput
              id="min_quantity"
              placeholder={"Enter Minimum Quantity"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.min_quantity || ""}
              handleBlur={(e) => {
                formik.setFieldValue("min_quantity", twofix(e.target.value));
                formik.handleBlur(e);
              }}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                    ? "17rem"
                    : isMeduim1
                      ? "13.32rem"
                      : "100%"
              }
              // onKeyDown={handleKeyDown}
              posInput={true}
              darkPlaceholder={true}
              inputType={"text"}
              inputError={
                formik.touched.min_quantity && formik.errors.min_quantity
              }
            />
            <Text color={Colors.errorColor}>
              {formik.touched.min_quantity && formik.errors.min_quantity ? (
                <span>{formik.errors.min_quantity}</span>
              ) : null}
            </Text>
          </Box>
          <Box width={isMeduim ? "100% !important" : "auto"}>
            <PosInput
              id="max_quantity"
              placeholder={"Enter Maximum Quantity"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.max_quantity || ""}
              handleBlur={(e) => {
                formik.setFieldValue("max_quantity", twofix(e.target.value));
                formik.handleBlur(e);
              }}
              width={
                isExtraLarge
                  ? "20rem"
                  : isLarge
                    ? "17rem"
                    : isMeduim1
                      ? "13.32rem"
                      : "100%"
              }
              // onKeyDown={handleKeyDown}
              posInput={true}
              darkPlaceholder={true}
              inputType={"text"}
              inputError={
                formik.touched.max_quantity && formik.errors.max_quantity
              }
            />
            <Text color={Colors.errorColor}>
              {formik.touched.max_quantity && formik.errors.max_quantity ? (
                <span>{formik.errors.max_quantity}</span>
              ) : null}
            </Text>
          </Box>
        </Stack>
      </>
    );
  };
  const handleClear = () => {
    setSearchQuery("");
    setSearchButton(true);
    getDailyInventoryMovementDataAfterClear();
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.REPORT_PATH);
          }}
          breadCrumNames={["Reports", "Inventory Movement"]}
          breadCrumTitle={"Inventory Movement"}
        />
        <Spacer />
        <ButtonGroup gap="2" direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.REPORT_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
          />
          {totalcount > 0 && (
            <PosIconButton
              name={Constants.EXPORT_BUTTON}
              onClick={handleExportReport}
              exportIcon={true}
              width={"7.5rem"}
            />
          )}
        </ButtonGroup>
      </Flex>
      <Box
        borderRadius="0.63rem"
        bg="white"
        // p={2}
        boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
      >
        <Flex
          direction={isMeduim ? "column" : "row"}
          justifyContent={{ base: "center", sm: "space-between" }}
        >
          <Stack
            gap={4}
            flex={1}
            flexShrink={"0"}
            direction={"column"}
            m={4}
            alignItems={"flex-start"}
          >
            {renderReportLayout()}
          </Stack>
          <Stack
            direction={"row"}
            justifyContent={isMeduim ? "flex-start" : "flex-end"}
            alignItems={"flex-start"}
            pl={isLarge ? 4 : 4}
            pt={isMeduim ? 0 : 4}
          >
            <PosFormButton
              onClick={handleResetAllData}
              buttonText={"Reset"}
              ghostButton={true}
            />
            <PosFormButton
              buttonsubmit={"Apply"}
              SubmitButton={true}
              isDisabled={
                formik.values.selectedLoactions?.length === 0 ||
                formik.values.fromDate === "" ||
                formik.values.toDate === "" ||
                formik.errors.selectedLoactions ||
                formik.errors.fromDate ||
                formik.errors.min_quantity ||
                formik.errors.max_quantity ||
                formik.errors.last_received ||
                formik.errors.product_gap ||
                formik.errors.toDate ||
                buttonDisable
              }
              onClick={getDailyInventoryMovementData}
            />
          </Stack>
        </Flex>
        <Box p={4}>
          {showTable ? null : searchQuery.length > 0 ||
            totalcount > 0 ||
            searchButton ? (
            <Flex mb={2} mr={2} flexDirection={{ base: "column", md: "row" }}>
              <PosSearch
                value={searchQuery}
                onChange={(e) => {
                  let value =
                    searchQuery.length > 0
                      ? e.target.value
                      : e.target.value.trim();
                  setSearchQuery(value);
                  if (e.target.value == null || "" == e.target.value) {
                    getDailyInventoryMovementDataAfterClear();
                  }
                }}
                onSearch={handleSearchList}
                handleClear={handleClear}
              />
              <Spacer></Spacer>
              <Text mt={2}>Location : {formik.values.selectedLoactions}</Text>
            </Flex>
          ) : null}
          {showTable ? null : loading ? (
            <PosProgress />
          ) : totalcount > 0 ? (
            <Flex direction={"column"} gap={4}>
              <PosDataGridTable
                columns={columnNames}
                rows={modifiedData}
                totalcount={totalcount}
                columnVisibilityModel={olColumnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setOlColumnVisibilityModel(newModel)
                }
                paginationModel={paginationModel}
                paginationMode="server"
                sortingMode="server"
                onPaginationModelChange={(newPageModel) => {
                  pageRef.current = true;
                  setPaginationModel(newPageModel);
                }}
                sortModel={sortModel}
                onSortModelChange={(newSortModel) => {
                  pageRef.current = true;
                  sortRef.current = sortModel;
                  setSortModel(newSortModel);
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
                fileName={Constants.DAILY_INVENTORY_MOVEMENT_CSV}
              />
            </Flex>
          ) : (
            <PosNoDataFound title={"There are no Inventory Movement."} />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WithRouter(DailyInventoryMovement);
