import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getAllLocationsForPOS() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.POS_GET_LOCATIONS_API_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.POS_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function getAllLocationsForPOSList({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.POS_GET_LOCATIONS_API_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.POS_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function getAllPos(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.POS_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.POS_LIST_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function posCreate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    const payload = JSON.stringify({
      name: Object.fromEntries(formData).name,
      location_id: Object.fromEntries(formData).location_id,
    });
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.POS_CREATE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.POS_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function posUpdate({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = JSON.stringify({
      name: Object.fromEntries(formData).name,
      location_id: Object.fromEntries(formData).location_id,
    });
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.POS_EDIT_API_ROUTE +
          `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.POS_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function getPosStation({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.POS_EDIT_GET_DATA_API_ROUTE + "/" + id + "/edit"}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.POS_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function getPosSettings({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.POS_EDIT_API_ROUTE_SETTING + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.POS_EDIT_SETTINGS_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function updatePosSettings({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = {
      name: Object.fromEntries(formData).name,
      location_id: Object.fromEntries(formData).location_id,
      pos_station_id: id,
      // key:Object.fromEntries(formData).allValue
    };
    if (Object.fromEntries(formData).hasOwnProperty("allValue")) {
      payload.key = JSON.parse(Object.fromEntries(formData).allValue);
    }
    axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.POS_UPDATE_SETTING_API_ROUTE_SETTING +
          `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.POS_EDIT_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function getPosSettingsView({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          `${Constants.POS_SETTING_VIEW_API_ROUTE_SETTING + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.POS_EDIT_SETTINGS_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

export function posDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.POS_DEACTIVATE_API_ROUTE +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.POS_DEACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.POS_REACTIVATE_API_ROUTE +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.POS_REACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code
          };
          resolve(errorData);
        });
    }
  });
}

export function getAllDeactivePos(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.POS_DEACTIVATE_LIST_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.POS_DEACTIVATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function duplicatePosSettings({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    const id = params.id;
    let formData = await request.formData();
    const payload = {
      name: Object.fromEntries(formData).name,
      location_id: Object.fromEntries(formData).location_id,
      pos_station_id: id,
      // key:Object.fromEntries(formData).allValue
    };
    if (Object.fromEntries(formData).hasOwnProperty("allValue")) {
      payload.key = JSON.parse(Object.fromEntries(formData).allValue);
    }
    axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.POS_SETTING_DUPLICATE_API_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.POS_EDIT_API_PERMISSION, // change when new permission create POS_SETTING_DUPLICATE_PERMISSION
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code
        };
        resolve(errorData);
      });
  });
}

//TAX_EDIT: 'Tax-edit',  TAX_CREATE: 'tax-create',
