import {
  Box,
  Drawer,
  DrawerContent,
  Flex,
  Icon,
  Image,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
// import { CiSearch } from "react-icons/ci";
import { useLocation } from "react-router-dom";
import Colors from "../../assets/colors/Colors";
import logo from "../../assets/images/newcropped.png";
import NavItem from "./NavItem";
import { SidebarData } from "./sidebarData";
import * as Constants from "../../constants/Constants";

const Sidebar = (props) => {
  const [curruntRoute, setCurruntRoute] = useState("");
  const [mainRoute, setMainRoute] = useState();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const location = useLocation();
  useEffect(() => {
    // if(effectRun.current == true) {
    window.scrollTo(0, 0);
    var pathInSideBar = 0;
    SidebarData.map((item) => {
      let j = item?.CrudPath?.findIndex((x) => x === location.pathname || location.pathname.includes(x));
      if (j > -1) {
        pathInSideBar = 1;
        setMainRoute(item.title);
        setCurruntRoute("");
      } else if (item.subNav) {
        item.subNav.map((sub) => {
          let i = sub?.CrudPath?.findIndex((x) => x === location.pathname || location.pathname.includes(x));
          if (i > -1) {
            pathInSideBar = 1;
            setMainRoute(item.title);
            setCurruntRoute(sub.title);
          }
        });
      }
    });
    let index = Constants.SIDEBAR_CLOSE_ROUTE.findIndex(
      (x) => x === location.pathname
    );
    if (index > -1 || pathInSideBar === 0) {
      setMainRoute("");
      setCurruntRoute("");
    }
    //   return () => {
    //     effectRun.current = false;
    //   };
    // }
  }, [location.pathname]);

  const handleChangeRoute = (item) => {
    setCurruntRoute(item);
  };

  const handleChangeMainRoute = (item) => {
    setMainRoute(item);
  };

  const handleClose = () => {
    props.onClose();
  };

  if (isMobile) {
    return (
      <Drawer
        isOpen={props.isOpen}
        placement="left"
        onClose={props.onClose}
        // bg="yellow"
      >
        <DrawerContent bg={Colors.headerColor} w={"16.45rem"}>
          <Box h="100vh" w={"20rem"}>
            <Flex alignItems="center" justifyContent={"space-between"}>
              <Box bg={Colors.headerColor}>
                <Image
                  mt={{ base: "1.63rem", md: "1.63rem" }}
                  ml={{ base: "1rem", md: "2rem" }}
                  mb={{ base: "1.94rem", md: "1.19rem" }}
                  style={{ width: "7.81rem" }}
                  src={logo}
                  alt="sanepos"
                />
              </Box>
              <Icon
                as={IoMdClose}
                boxSize={"2rem"}
                alignItems={"flex-end"}
                mr={{ base: "1rem", md: "2rem" }}
                onClick={props.onClose}
              />
            </Flex>
            <VStack
              justifyContent="flex-start"
              spacing={4}
              p="0rem 0.63rem 0.63rem 1.94rem"
            >
              <Flex
                flexDir="column"
                w="100%"
                alignItems={"flex-start"}
                as="nav"
              >
                {SidebarData.map((item, index) => {
                  if (
                    location.pathname == item.path ||
                    item.title === mainRoute
                  ) {
                    return (
                      <NavItem
                        icon={item.icon}
                        title={item.title}
                        item={item}
                        subnav={item.subNav}
                        key={index}
                        curruntRoute={curruntRoute}
                        handleChangeRoute={handleChangeRoute}
                        handleChangeMainRoute={handleChangeMainRoute}
                        handleClose={handleClose}
                        active
                      />
                    );
                  } else {
                    return (
                      <NavItem
                        icon={item.icon}
                        title={item.title}
                        item={item}
                        subnav={false}
                        key={index}
                        curruntRoute={curruntRoute}
                        handleChangeRoute={handleChangeRoute}
                        handleChangeMainRoute={handleChangeMainRoute}
                        handleClose={handleClose}
                      />
                    );
                  }
                })}
              </Flex>
            </VStack>
          </Box>
        </DrawerContent>
      </Drawer>
    );
  } else {
    return (
      <Box
        w={{ md: "16.45rem" }}
        bg={Colors.headerColor}
        position="fixed"
        h="100vh"
      >
        <VStack
          justifyContent="flex-start"
          spacing={4}
          // p="0rem 0.63rem 0.63rem 1.94rem"
        >
          <Flex
            flexDir="column"
            w="100%"
            alignItems={"flex-start"}
            as="nav"
            mt={{ base: "1.63rem", md: "1.63rem" }}
          >
            {SidebarData.map((item, index) => {
              if (location.pathname === item.path || item.title === mainRoute) {
                return (
                  <NavItem
                    icon={item.icon}
                    title={item.title}
                    item={item}
                    subnav={item.subNav}
                    key={index}
                    curruntRoute={curruntRoute}
                    handleChangeRoute={handleChangeRoute}
                    handleChangeMainRoute={handleChangeMainRoute}
                    handleClose={handleClose}
                    active
                  />
                );
              } else {
                return (
                  <NavItem
                    icon={item.icon}
                    title={item.title}
                    item={item}
                    subnav={false}
                    key={index}
                    curruntRoute={curruntRoute}
                    handleChangeRoute={handleChangeRoute}
                    handleChangeMainRoute={handleChangeMainRoute}
                    handleClose={handleClose}
                  />
                );
              }
            })}
          </Flex>
        </VStack>
      </Box>
    );
  }
};

export default Sidebar;
