import {
  Box,
  ButtonGroup,
  Card,
  CardBody,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  VStack,
  useRadioGroup
} from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { SelectButton } from "primereact/selectbutton";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, useSubmit } from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosDatePicker,
  PosDrawer,
  PosDropDown,
  PosFormButton,
  PosImageCropper,
  PosInput,
  PosLable,
  PosOutlineButton,
  PosSwitchButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import {
  reportDateFormate,
  startOfTodaysDay,
  twofix,
} from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import {
  createAccountSettings,
  getAllLocationsAdditionalSettingData,
} from "./LocationService";
import "./locationDesigning.css";

const LocationOnlineOrderSettings = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const { id } = useParams();
  const [selectedCouponType, setSelectedCouponType] = useState("");

  const [showLogin, setShowLogin] = React.useState(false);
  const [showKey, setShowKey] = React.useState(false);
  const handleClick = () => setShowLogin(!showLogin);
  const handleClickMerchant = () => setShowKey(!showKey);
  const [additionalZipcodes, setZipCodes] = useState([]);
  const [restrictedDates, setRestrictedDates] = useState([]);

  const [dateTimeValue, setdateTimeValue] = useState(new Date());
  const [deliveryDefault, setDeliveryValue] = useState(startOfTodaysDay());
  const [pickupDefault, setPickupValue] = useState(startOfTodaysDay());

  const [onlineSettings, setOnlineOrderingSettings] = useState([]);
  const calendarRef = useRef(Calendar);

  const [selectedDays, setSelectedDays] = useState([]);
  const [online_types, setSelectedTypes] = useState([]);

  const [pickLead, setPickLead] = useState("");
  const [deliveryLead, setDeliveryLead] = useState("");

  const [HoursEditable, setHoursEditable] = useState([]);
  const [mondayHoursEditable, setMondayHoursEditable] = useState([]);
  const [tuesdayHoursEditable, setTuesdayHoursEditable] = useState([]);
  const [wednesdayHoursEditable, setWednesdayHoursEditable] = useState([]);
  const [thursdayHoursEditable, setThursdayHoursEditable] = useState([]);
  const [fridayHoursEditable, setFridayHoursEditable] = useState([]);
  const [saturdayHoursEditable, setSaturdayHoursEditable] = useState([]);

  const [operationHoursEdited, setOperationHoursEdited] = useState(0);

  const [inputs, setInputs] = useState([]);
  const [inputsMonday, setMondayInputs] = useState([]);
  const [inputsTuesday, setTuesdayInputs] = useState([]);
  const [inputsWednesday, setWednesdayInputs] = useState([]);
  const [inputsThursday, setThursdayInputs] = useState([]);
  const [inputsFriday, setFridayInputs] = useState([]);
  const [inputsSaturday, setSaturdayInputs] = useState([]);

  const [Hours, setHours] = useState([]);
  const [mondayHours, setMondayHours] = useState([]);
  const [tuesdayHours, setTuesdayHours] = useState([]);
  const [wednesdayHours, setWednesdayHours] = useState([]);
  const [thursdayHours, setThursdayHours] = useState([]);
  const [fridayHours, setFridayHours] = useState([]);
  const [saturdayHours, setSaturdayHours] = useState([]);

  const [grubUrl, setGrubUrl] = useState("");
  const [url, setUrl] = useState(window.location.origin + "/");

  const [imageUploadedFlag, setImageUploadedFlag] = useState(0);
  const [base64OfUploadedImg, setBase64OfUploadedImg] = useState("");
  const [croppedImgDetails, setCroppedImgDetails] = useState({
    filename: "",
    filetype: "",
    filesize: "",
    base64: "",
  });
  const [oldImageBase64, setOldImageBase64] = useState("");
  const [newImageUploadedFlag, setNewImageUploadedFlag] = useState(0);
  const [viewFlag, setViewFlag] = useState(false);

  const handleCallback = (base64Details, fileDetails, imgUploadStatus) => {
    if (
      (null == base64Details || "" == base64Details) &&
      (null == fileDetails || "" == fileDetails) &&
      (null == imgUploadStatus || "" == imgUploadStatus)
    ) {
      setCroppedImgDetails((previousState) => {
        return {
          ...previousState,
          filename: "",
          filetype: "",
          filesize: "",
          base64: "",
        };
      });
      setBase64OfUploadedImg("");
      setImageUploadedFlag(0);
    } else {
      if (imgUploadStatus.newImgUploadFlag == 1) {
        setCroppedImgDetails((previousState) => {
          return {
            ...previousState,
            filename: fileDetails["name"],
            filetype: fileDetails["type"],
            filesize: fileDetails["size"],
            base64: base64Details.toDataURL().split(",")[1],
          };
        });
        setBase64OfUploadedImg(base64Details.toDataURL());
        setImageUploadedFlag(1);
        setNewImageUploadedFlag(1);
      } else {
        setCroppedImgDetails((previousState) => {
          return {
            ...previousState,
            filename: null,
            filetype: null,
            filesize: null,
            base64: base64Details.toDataURL().split(",")[1],
          };
        });
        setBase64OfUploadedImg(base64Details.toDataURL());
        setImageUploadedFlag(1);
        setNewImageUploadedFlag(0);
      }
    }
  };

  const setHoursString = (array) => {
    let data = array.map((hour, i) => {
      let time = Object.assign({}, hour);
      time.open = moment(reportDateFormate(time.open)).format("hh:mm A");
      time.close = moment(reportDateFormate(time.close)).format("hh:mm A");
      return time;
    });
    return data;
  };
  const formik = useFormik({
    initialValues: {
      online_order:
        undefined != onlineSettings.is_order_online &&
        null != onlineSettings.is_order_online &&
        "" != onlineSettings.is_order_online
          ? onlineSettings.is_order_online
          : false,
      online_order_types: online_types,
      dlvr:
        undefined != onlineSettings.is_delivery &&
        null != onlineSettings.is_delivery &&
        "" != onlineSettings.is_delivery &&
        onlineSettings.is_delivery == 1
          ? true
          : false,
      pick:
        undefined != onlineSettings.is_pickup &&
        null != onlineSettings.is_pickup &&
        "" != onlineSettings.is_pickup &&
        onlineSettings.is_pickup == 1
          ? true
          : false,
      minimum_order_amount:
        undefined != onlineSettings.minimum_order_amount &&
        null != onlineSettings.minimum_order_amount &&
        "" != onlineSettings.minimum_order_amount
          ? onlineSettings.minimum_order_amount
          : "",
      delivery_lead_time:
        undefined != deliveryLead && null != deliveryLead && "" != deliveryLead
          ? deliveryLead
          : "",
      pickup_lead_time:
        undefined != pickLead && null != pickLead && "" != pickLead
          ? pickLead
          : "",
      enable_reward_card:
        undefined != onlineSettings.enable_reward_earn &&
        null != onlineSettings.enable_reward_earn &&
        "" != onlineSettings.enable_reward_earn
          ? onlineSettings.enable_reward_earn
          : false,
      is_tip_enable:
        undefined != onlineSettings.is_tip_enable &&
        null != onlineSettings.is_tip_enable &&
        "" != onlineSettings.is_tip_enable
          ? onlineSettings.is_tip_enable
          : false,
      merchant_login_id:
        undefined != onlineSettings.auth_net_mercnt_login_id &&
        null != onlineSettings.auth_net_mercnt_login_id &&
        "" != onlineSettings.auth_net_mercnt_login_id
          ? onlineSettings.auth_net_mercnt_login_id
          : "",
      merchant_transaction_key:
        undefined != onlineSettings.auth_net_mercnt_transaction_key &&
        null != onlineSettings.auth_net_mercnt_transaction_key &&
        "" != onlineSettings.auth_net_mercnt_transaction_key
          ? onlineSettings.auth_net_mercnt_transaction_key
          : "",
      slot_interval:
        undefined != onlineSettings.slot_interval_minute &&
        null != onlineSettings.slot_interval_minute &&
        "" != onlineSettings.slot_interval_minute
          ? onlineSettings.slot_interval_minute
          : "5",
      selectedDays: selectedDays,
      zipcode: "",
    },
    validationSchema: Yup.object({
      // online_order_types: Yup.array().min(1, "At least one must be checked."),
      minimum_order_amount: Yup.number().when("dlvr", {
        is: true,
        then: (schema) =>
          schema
            .typeError(Constants.MIN_DELIVERY_AMOUNT_VALID_ERROR)
            .required(Constants.MIN_DELIVERY_AMOUNT_REQUIRED)
            .min(0.01, Constants.MIN_DELIVERY_AMOUNT_MIN_ERROR)
            .max(999999.99, Constants.MIN_DELIVERY_AMOUNT_MAX_ERROR),
        otherwise: (schema) => schema.optional(),
      }),
      delivery_lead_time: Yup.string().when("dlvr", {
        is: true,
        then: (schema) => schema.required(Constants.DELIVERY_LEAD_REQUIRED),
        otherwise: (schema) => schema.optional(),
      }),
      pickup_lead_time: Yup.string().when("pick", {
        is: true,
        then: (schema) => schema.required(Constants.PICKUP_LEAD_REQUIRED),
        otherwise: (schema) => schema.optional(),
      }),
      slot_interval: Yup.string().required("select slot interval"),
      selectedDays: Yup.array().min(1, Constants.DAYS_REQUIRED),
      zipcode: Yup.number()
        .integer(Constants.LOCATION_ZIP_VALID)
        .typeError(Constants.LOCATION_ZIP_VALID)
        .min(9, Constants.LOCATION_ZIP_MUST_BE_2_DIGITS)
        .max(9999999999, Constants.LOCATION_ZIP_MUST_BE_10_DIGITS),
    }),
    enableReinitialize: true,
    onSubmit: async (values) => {
      myContext.handleLoading(true);
      let payload = {
        ...values,
        online_ordering: true,
        operationHours: [].concat(
          setHoursString(Hours),
          setHoursString(mondayHours),
          setHoursString(tuesdayHours),
          setHoursString(wednesdayHours),
          setHoursString(thursdayHours),
          setHoursString(fridayHours),
          setHoursString(saturdayHours)
        ),
        delivery_lead_time:
          undefined != formik.values.delivery_lead_time &&
          null != formik.values.delivery_lead_time &&
          "" != formik.values.delivery_lead_time
            ? `${formik.values.delivery_lead_time.getHours()}:${formik.values.delivery_lead_time.getMinutes()}`
            : "",
        pickup_lead_time:
          undefined != formik.values.pickup_lead_time &&
          null != formik.values.pickup_lead_time &&
          "" != formik.values.pickup_lead_time
            ? `${formik.values.pickup_lead_time.getHours()}:${formik.values.pickup_lead_time.getMinutes()}`
            : "",
        location_id: id,
        additionalZipcodes: additionalZipcodes,
        restrictedDates: restrictedDates,
        operationHoursEdited: operationHoursEdited,
        tenant_name: window.location.hostname.split(".")[0],
        url: url,
      };
      // image
      if (imageUploadedFlag == 1) {
        if (newImageUploadedFlag == 1) {
          var imageDetails = {
            locationpicOnline: croppedImgDetails,
          };
          payload.locationImageOnline = imageDetails;
        } else {
          payload.locationImageOnline = {};
          payload.image_base64_online = croppedImgDetails.base64;
        }
      }
        else {
        var imageDetails = {
            "locationpicOnline": {}
        }
        payload.locationImageOnline = (imageDetails);
        payload.image_base64_online = (null);
      }
      setButtonDisable(true);
      createAccountSettings(payload)
        .then((response) => {
          if (
            undefined !== response?.data?.flag &&
            null !== response?.data?.flag &&
            response?.data?.flag == true
          ) {
            addToast({
              alertStatus: Constants.TOAST_TYPE_SUCESS,
              alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
              alertDescription: response.data.message,
            });
            setButtonDisable(false);
            myContext.handleLoading(false);
            handleClose();
          } else {
            myContext.handleLoading(false);
            setButtonDisable(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          setButtonDisable(false);
          myContext.handleLoading(false);
          let actionData = err;
          error({ actionData });
        });
    },
  });
  const handleDaysChange = (value) => {
    setOperationHoursEdited(1);
    if (value.includes(Constants.SUNDAY)) {
      if (inputs.length <= 0) {
        setInputs([
          {
            open: "",
            close: "",
            openError: Constants.OPEN_HOUR_ERROR,
            closeError: Constants.CLOSE_HOUR_ERROR,
          },
        ]);
        setHours([]);
      }
    } else {
      setHours([]);
      setInputs([]);
    }
    if (value.includes(Constants.MONDAY)) {
      if (inputsMonday.length <= 0) {
        setMondayInputs([
          {
            open: "",
            close: "",
            openError: Constants.OPEN_HOUR_ERROR,
            closeError: Constants.CLOSE_HOUR_ERROR,
          },
        ]);
        setMondayHours([]);
      }
    } else {
      setMondayHours([]);
      setMondayInputs([]);
    }
    if (value.includes(Constants.TUESDAY)) {
      if (inputsTuesday.length <= 0) {
        setTuesdayInputs([
          {
            open: "",
            close: "",
            openError: Constants.OPEN_HOUR_ERROR,
            closeError: Constants.CLOSE_HOUR_ERROR,
          },
        ]);
        setTuesdayHours([]);
      }
    } else {
      setTuesdayHours([]);
      setTuesdayInputs([]);
    }
    if (value.includes(Constants.WEDNESDAY)) {
      if (inputsWednesday.length <= 0) {
        setWednesdayInputs([
          {
            open: "",
            close: "",
            openError: Constants.OPEN_HOUR_ERROR,
            closeError: Constants.CLOSE_HOUR_ERROR,
          },
        ]);
        setWednesdayHours([]);
      }
    } else {
      setWednesdayInputs([]);
      setWednesdayHours([]);
    }
    if (value.includes(Constants.THURSDAY)) {
      if (inputsThursday.length <= 0) {
        setThursdayInputs([
          {
            open: "",
            close: "",
            openError: Constants.OPEN_HOUR_ERROR,
            closeError: Constants.CLOSE_HOUR_ERROR,
          },
        ]);
        setThursdayHours([]);
      }
    } else {
      setThursdayHours([]);
      setThursdayInputs([]);
    }
    if (value.includes(Constants.FRIDAY)) {
      if (inputsFriday.length <= 0) {
        setFridayInputs([
          {
            open: "",
            close: "",
            openError: Constants.OPEN_HOUR_ERROR,
            closeError: Constants.CLOSE_HOUR_ERROR,
          },
        ]);
        setFridayHours([]);
      }
    } else {
      setFridayHours([]);
      setFridayInputs([]);
    }
    if (value.includes(Constants.SATURDAY)) {
      if (inputsSaturday.length <= 0) {
        setSaturdayInputs([
          {
            open: "",
            close: "",
            openError: Constants.OPEN_HOUR_ERROR,
            closeError: Constants.CLOSE_HOUR_ERROR,
          },
        ]);
        setSaturdayHours([]);
      }
    } else {
      setSaturdayHours([]);
      setSaturdayInputs([]);
    }
  };
  const convertTime12to24 = (time) => {
    const convert = moment(time, "hh:mm A").format("HH:mm");
    return convert;
  };
  const days = [
    {
      label: "Sunday",
      value: "SUN",
    },
    {
      label: "Monday",
      value: "MON",
    },
    {
      label: "Tuesday",
      value: "TUE",
    },
    {
      label: "Wednesday",
      value: "WED",
    },
    {
      label: "Thursday",
      value: "THU",
    },
    {
      label: "Friday",
      value: "FRI",
    },
    {
      label: "Saturday",
      value: "SAT",
    },
  ];

  // Sunday
  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        open: "",
        close: "",
        openError: Constants.OPEN_HOUR_ERROR,
        closeError: Constants.CLOSE_HOUR_ERROR,
      },
    ]);
  };
  const handleSundayBlur = (event, index) => {
    // if(Hours.length > 0) {
    let arrayTime = [];
    let obj = inputs[index];
    var flag = true;
    inputs.map((hours, i) => {
      // hours.open = moment(reportDateFormate(hours.open)).format('hh:mm A')
      // hours.close = moment(reportDateFormate(hours.close)).format('hh:mm A')
      if (
        null != obj.open &&
        "" != obj.open &&
        null != obj.close &&
        "" != obj.close
      ) {
        var opensAt = convertTime12to24(obj.open);
        var closeAt = convertTime12to24(obj.close);
        if (opensAt > closeAt || (opensAt == closeAt && flag)) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.LOCATION_MODULE,
            alertDescription: Constants.SUNDAY_MIN_ERROR,
          });
          flag = false;
          obj.closeError = Constants.CLOSE_HOUR_ERROR;
          obj.close = "";
          return false;
        } else {
          obj.closeError = "";
          obj.openError = "";
        }
        if (index !== i) {
          if (
            (flag &&
              convertTime12to24(hours.open) == convertTime12to24(obj.open)) ||
            convertTime12to24(hours.close) == convertTime12to24(obj.close) ||
            (convertTime12to24(hours.open) <= convertTime12to24(obj.open) &&
              convertTime12to24(hours.close) >= convertTime12to24(obj.close)) ||
            (convertTime12to24(obj.open) <= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) >= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.open) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.open) <= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.close) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) <= convertTime12to24(hours.close))
          ) {
            flag = false;
            obj.close = "";
            obj.open = "";
            obj.openError = Constants.OPEN_HOUR_ERROR;
            obj.closeError = Constants.CLOSE_HOUR_ERROR;
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.LOCATION_MODULE,
              alertDescription: Constants.SUNDAY_TIME_ERROR,
            });
          } else {
            obj.openError = "";
            obj.closeError = "";
            arrayTime.push(hours);
            // setHours([...onChangeValue]);
          }
        } else {
          arrayTime.push(hours);
        }
      }
    });
    setOperationHoursEdited(1);
    setHours([...arrayTime]);
  };
  const handleSundayChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = value;
    onChangeValue[index]["day"] = Constants.SUNDAY;
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "open"
    ) {
      onChangeValue[index]["openError"] = Constants.OPEN_HOUR_ERROR;
    }
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "close"
    ) {
      onChangeValue[index]["closeError"] = Constants.CLOSE_HOUR_ERROR;
    }
    setInputs(onChangeValue);
    setOperationHoursEdited(1);
  };
  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
    Hours.splice(index, 1);
    setHours(Hours);
    setOperationHoursEdited(1);
  };
  const addEachlineForHours = (hoursData) => {
    setInputs([]);
    hoursData.map((item, index) => {
      // let dateString = new Date(item.date).toLocaleDateString();
      var timeArrFrom = item.from_time.split(":");
      const open = new Date();
      open.setHours(timeArrFrom[0]);
      open.setMinutes(timeArrFrom[1]);

      var timeArrTo = item.to_time.split(":");
      const close = new Date();
      close.setHours(timeArrTo[0]);
      close.setMinutes(timeArrTo[1]);

      var obj = {
        open: open,
        close: close,
        day: item.day,
        closeError: "",
        openError: "",
        id: item.id,
      };
      inputs.push(obj);
    });
    setInputs([...inputs]);
    setHours([...inputs]);
  };
  let checkvalid = Hours.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });
  let inputvalid = inputs.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });

  // Monday
  const handleMondayAddInput = () => {
    setMondayInputs([
      ...inputsMonday,
      {
        open: "",
        close: "",
        openError: Constants.OPEN_HOUR_ERROR,
        closeError: Constants.CLOSE_HOUR_ERROR,
      },
    ]);
  };
  const handleMondayBlur = (event, index) => {
    // if(Hours.length > 0) {
    let arrayTime = [];
    let obj = inputsMonday[index];
    var flag = true;
    inputsMonday.map((hours, i) => {
      // hours.open = moment(reportDateFormate(hours.open)).format('hh:mm A')
      // hours.close = moment(reportDateFormate(hours.close)).format('hh:mm A')
      if (
        null != obj.open &&
        "" != obj.open &&
        null != obj.close &&
        "" != obj.close
      ) {
        var opensAt = convertTime12to24(obj.open);
        var closeAt = convertTime12to24(obj.close);
        if (opensAt > closeAt || (opensAt == closeAt && flag)) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.LOCATION_MODULE,
            alertDescription: Constants.MONDAY_MIN_ERROR,
          });
          flag = false;
          obj.closeError = Constants.CLOSE_HOUR_ERROR;
          obj.close = "";
          return false;
        } else {
          obj.closeError = "";
          obj.openError = "";
        }
        if (index !== i) {
          if (
            (flag &&
              convertTime12to24(hours.open) == convertTime12to24(obj.open)) ||
            convertTime12to24(hours.close) == convertTime12to24(obj.close) ||
            (convertTime12to24(hours.open) <= convertTime12to24(obj.open) &&
              convertTime12to24(hours.close) >= convertTime12to24(obj.close)) ||
            (convertTime12to24(obj.open) <= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) >= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.open) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.open) <= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.close) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) <= convertTime12to24(hours.close))
          ) {
            flag = false;
            obj.close = "";
            obj.open = "";
            obj.openError = Constants.OPEN_HOUR_ERROR;
            obj.closeError = Constants.CLOSE_HOUR_ERROR;
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.LOCATION_MODULE,
              alertDescription: Constants.SUNDAY_TIME_ERROR,
            });
          } else {
            obj.openError = "";
            obj.closeError = "";
            arrayTime.push(hours);
            // setHours([...onChangeValue]);
          }
        } else {
          arrayTime.push(hours);
        }
      }
    });
    setOperationHoursEdited(1);
    setMondayHours([...arrayTime]);
  };
  const handleMondayChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputsMonday];
    onChangeValue[index][name] = value;
    onChangeValue[index]["day"] = Constants.MONDAY;
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "open"
    ) {
      onChangeValue[index]["openError"] = Constants.OPEN_HOUR_ERROR;
    }
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "close"
    ) {
      onChangeValue[index]["closeError"] = Constants.CLOSE_HOUR_ERROR;
    }
    setMondayInputs(onChangeValue);
    setOperationHoursEdited(1);
  };
  const handleMondayDeleteInput = (index) => {
    const newArray = [...inputsMonday];
    newArray.splice(index, 1);
    setMondayInputs(newArray);
    mondayHours.splice(index, 1);
    setMondayHours(mondayHours);
    setOperationHoursEdited(1);
  };
  const addEachlineForMondayHours = (hoursData) => {
    setMondayInputs([]);
    hoursData.map((item, index) => {
      // let dateString = new Date(item.date).toLocaleDateString();
      var timeArrFrom = item.from_time.split(":");
      const open = new Date();
      open.setHours(timeArrFrom[0]);
      open.setMinutes(timeArrFrom[1]);

      var timeArrTo = item.to_time.split(":");
      const close = new Date();
      close.setHours(timeArrTo[0]);
      close.setMinutes(timeArrTo[1]);

      var obj = {
        open: open,
        close: close,
        day: item.day,
        closeError: "",
        openError: "",
        id: item.id,
      };
      inputsMonday.push(obj);
    });
    setMondayInputs([...inputsMonday]);
    setMondayHours([...inputsMonday]);
  };

  // Tuesday
  const handleTuesdayAddInput = () => {
    setTuesdayInputs([
      ...inputsTuesday,
      {
        open: "",
        close: "",
        openError: Constants.OPEN_HOUR_ERROR,
        closeError: Constants.CLOSE_HOUR_ERROR,
      },
    ]);
  };
  const handleTuesdayBlur = (event, index) => {
    // if(Hours.length > 0) {
    let arrayTime = [];
    let obj = inputsTuesday[index];
    var flag = true;
    inputsTuesday.map((hours, i) => {
      // hours.open = moment(reportDateFormate(hours.open)).format('hh:mm A')
      // hours.close = moment(reportDateFormate(hours.close)).format('hh:mm A')
      if (
        null != obj.open &&
        "" != obj.open &&
        null != obj.close &&
        "" != obj.close
      ) {
        var opensAt = convertTime12to24(obj.open);
        var closeAt = convertTime12to24(obj.close);
        if (opensAt > closeAt || (opensAt == closeAt && flag)) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.LOCATION_MODULE,
            alertDescription: Constants.TUESDAY_MIN_ERROR,
          });
          flag = false;
          obj.closeError = Constants.CLOSE_HOUR_ERROR;
          obj.close = "";
          return false;
        } else {
          obj.closeError = "";
          obj.openError = "";
        }
        if (index !== i) {
          if (
            (flag &&
              convertTime12to24(hours.open) == convertTime12to24(obj.open)) ||
            convertTime12to24(hours.close) == convertTime12to24(obj.close) ||
            (convertTime12to24(hours.open) <= convertTime12to24(obj.open) &&
              convertTime12to24(hours.close) >= convertTime12to24(obj.close)) ||
            (convertTime12to24(obj.open) <= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) >= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.open) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.open) <= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.close) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) <= convertTime12to24(hours.close))
          ) {
            flag = false;
            obj.close = "";
            obj.open = "";
            obj.openError = Constants.OPEN_HOUR_ERROR;
            obj.closeError = Constants.CLOSE_HOUR_ERROR;
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.LOCATION_MODULE,
              alertDescription: Constants.SUNDAY_TIME_ERROR,
            });
          } else {
            obj.openError = "";
            obj.closeError = "";
            arrayTime.push(hours);
            // setHours([...onChangeValue]);
          }
        } else {
          arrayTime.push(hours);
        }
      }
    });
    setOperationHoursEdited(1);
    setTuesdayHours([...arrayTime]);
  };
  const handleTuesdayChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputsTuesday];
    onChangeValue[index][name] = value;
    onChangeValue[index]["day"] = Constants.TUESDAY;
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "open"
    ) {
      onChangeValue[index]["openError"] = Constants.OPEN_HOUR_ERROR;
    }
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "close"
    ) {
      onChangeValue[index]["closeError"] = Constants.CLOSE_HOUR_ERROR;
    }
    setTuesdayInputs(onChangeValue);
    setOperationHoursEdited(1);
  };
  const handleTuesdayDeleteInput = (index) => {
    const newArray = [...inputsTuesday];
    newArray.splice(index, 1);
    setTuesdayInputs(newArray);
    tuesdayHours.splice(index, 1);
    setTuesdayHours(tuesdayHours);
    setOperationHoursEdited(1);
  };
  const addEachlineForTuesdayHours = (hoursData) => {
    setTuesdayInputs([]);
    hoursData.map((item, index) => {
      // let dateString = new Date(item.date).toLocaleDateString();
      var timeArrFrom = item.from_time.split(":");
      const open = new Date();
      open.setHours(timeArrFrom[0]);
      open.setMinutes(timeArrFrom[1]);

      var timeArrTo = item.to_time.split(":");
      const close = new Date();
      close.setHours(timeArrTo[0]);
      close.setMinutes(timeArrTo[1]);

      var obj = {
        open: open,
        close: close,
        day: item.day,
        closeError: "",
        openError: "",
        id: item.id,
      };
      inputsTuesday.push(obj);
    });
    setTuesdayInputs([...inputsTuesday]);
    setTuesdayHours([...inputsTuesday]);
  };

  // Wednesday
  const handleWednesdayAddInput = () => {
    setWednesdayInputs([
      ...inputsWednesday,
      {
        open: "",
        close: "",
        openError: Constants.OPEN_HOUR_ERROR,
        closeError: Constants.CLOSE_HOUR_ERROR,
      },
    ]);
  };
  const handleWednesdayBlur = (event, index) => {
    // if(Hours.length > 0) {
    let arrayTime = [];
    let obj = inputsWednesday[index];
    var flag = true;
    inputsWednesday.map((hours, i) => {
      // hours.open = moment(reportDateFormate(hours.open)).format('hh:mm A')
      // hours.close = moment(reportDateFormate(hours.close)).format('hh:mm A')
      if (
        null != obj.open &&
        "" != obj.open &&
        null != obj.close &&
        "" != obj.close
      ) {
        var opensAt = convertTime12to24(obj.open);
        var closeAt = convertTime12to24(obj.close);
        if (opensAt > closeAt || (opensAt == closeAt && flag)) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.LOCATION_MODULE,
            alertDescription: Constants.WEDNESDAY_MIN_ERROR,
          });
          flag = false;
          obj.closeError = Constants.CLOSE_HOUR_ERROR;
          obj.close = "";
          return false;
        } else {
          obj.closeError = "";
          obj.openError = "";
        }
        if (index !== i) {
          if (
            (flag &&
              convertTime12to24(hours.open) == convertTime12to24(obj.open)) ||
            convertTime12to24(hours.close) == convertTime12to24(obj.close) ||
            (convertTime12to24(hours.open) <= convertTime12to24(obj.open) &&
              convertTime12to24(hours.close) >= convertTime12to24(obj.close)) ||
            (convertTime12to24(obj.open) <= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) >= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.open) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.open) <= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.close) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) <= convertTime12to24(hours.close))
          ) {
            flag = false;
            obj.close = "";
            obj.open = "";
            obj.openError = Constants.OPEN_HOUR_ERROR;
            obj.closeError = Constants.CLOSE_HOUR_ERROR;
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.LOCATION_MODULE,
              alertDescription: Constants.SUNDAY_TIME_ERROR,
            });
          } else {
            obj.openError = "";
            obj.closeError = "";
            arrayTime.push(hours);
            // setHours([...onChangeValue]);
          }
        } else {
          arrayTime.push(hours);
        }
      }
    });
    setOperationHoursEdited(1);
    setWednesdayHours([...arrayTime]);
  };
  const handleWednesdayChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputsWednesday];
    onChangeValue[index][name] = value;
    onChangeValue[index]["day"] = Constants.WEDNESDAY;
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "open"
    ) {
      onChangeValue[index]["openError"] = Constants.OPEN_HOUR_ERROR;
    }
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "close"
    ) {
      onChangeValue[index]["closeError"] = Constants.CLOSE_HOUR_ERROR;
    }
    setWednesdayInputs(onChangeValue);
    setOperationHoursEdited(1);
  };
  const handleWednesdayDeleteInput = (index) => {
    const newArray = [...inputsWednesday];
    newArray.splice(index, 1);
    setWednesdayInputs(newArray);
    wednesdayHours.splice(index, 1);
    setWednesdayHours(wednesdayHours);
    setOperationHoursEdited(1);
  };
  const addEachlineForWednesdayHours = (hoursData) => {
    setWednesdayInputs([]);
    hoursData.map((item, index) => {
      // let dateString = new Date(item.date).toLocaleDateString();
      var timeArrFrom = item.from_time.split(":");
      const open = new Date();
      open.setHours(timeArrFrom[0]);
      open.setMinutes(timeArrFrom[1]);

      var timeArrTo = item.to_time.split(":");
      const close = new Date();
      close.setHours(timeArrTo[0]);
      close.setMinutes(timeArrTo[1]);

      var obj = {
        open: open,
        close: close,
        day: item.day,
        closeError: "",
        openError: "",
        id: item.id,
      };
      inputsWednesday.push(obj);
    });
    setWednesdayInputs([...inputsWednesday]);
    setWednesdayHours([...inputsWednesday]);
  };

  // Thursday
  const handleThursdayAddInput = () => {
    setThursdayInputs([
      ...inputsThursday,
      {
        open: "",
        close: "",
        openError: Constants.OPEN_HOUR_ERROR,
        closeError: Constants.CLOSE_HOUR_ERROR,
      },
    ]);
  };
  const handleThursdayBlur = (event, index) => {
    // if(Hours.length > 0) {
    let arrayTime = [];
    let obj = inputsThursday[index];
    var flag = true;
    inputsThursday.map((hours, i) => {
      // hours.open = moment(reportDateFormate(hours.open)).format('hh:mm A')
      // hours.close = moment(reportDateFormate(hours.close)).format('hh:mm A')
      if (
        null != obj.open &&
        "" != obj.open &&
        null != obj.close &&
        "" != obj.close
      ) {
        var opensAt = convertTime12to24(obj.open);
        var closeAt = convertTime12to24(obj.close);
        if (opensAt > closeAt || (opensAt == closeAt && flag)) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.LOCATION_MODULE,
            alertDescription: Constants.THURSDAY_MIN_ERROR,
          });
          flag = false;
          obj.closeError = Constants.CLOSE_HOUR_ERROR;
          obj.close = "";
          return false;
        } else {
          obj.closeError = "";
          obj.openError = "";
        }
        if (index !== i) {
          if (
            (flag &&
              convertTime12to24(hours.open) == convertTime12to24(obj.open)) ||
            convertTime12to24(hours.close) == convertTime12to24(obj.close) ||
            (convertTime12to24(hours.open) <= convertTime12to24(obj.open) &&
              convertTime12to24(hours.close) >= convertTime12to24(obj.close)) ||
            (convertTime12to24(obj.open) <= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) >= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.open) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.open) <= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.close) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) <= convertTime12to24(hours.close))
          ) {
            flag = false;
            obj.close = "";
            obj.open = "";
            obj.openError = Constants.OPEN_HOUR_ERROR;
            obj.closeError = Constants.CLOSE_HOUR_ERROR;
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.LOCATION_MODULE,
              alertDescription: Constants.SUNDAY_TIME_ERROR,
            });
          } else {
            obj.openError = "";
            obj.closeError = "";
            arrayTime.push(hours);
            // setHours([...onChangeValue]);
          }
        } else {
          arrayTime.push(hours);
        }
      }
    });
    setOperationHoursEdited(1);
    setThursdayHours([...arrayTime]);
  };
  const handleThursdayChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputsThursday];
    onChangeValue[index][name] = value;
    onChangeValue[index]["day"] = Constants.THURSDAY;
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "open"
    ) {
      onChangeValue[index]["openError"] = Constants.OPEN_HOUR_ERROR;
    }
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "close"
    ) {
      onChangeValue[index]["closeError"] = Constants.CLOSE_HOUR_ERROR;
    }
    setThursdayInputs(onChangeValue);
    setOperationHoursEdited(1);
  };
  const handleThursdayDeleteInput = (index) => {
    const newArray = [...inputsThursday];
    newArray.splice(index, 1);
    setThursdayInputs(newArray);
    thursdayHours.splice(index, 1);
    setThursdayHours(thursdayHours);
    setOperationHoursEdited(1);
  };
  const addEachlineForThursdayHours = (hoursData) => {
    setThursdayInputs([]);
    hoursData.map((item, index) => {
      // let dateString = new Date(item.date).toLocaleDateString();
      var timeArrFrom = item.from_time.split(":");
      const open = new Date();
      open.setHours(timeArrFrom[0]);
      open.setMinutes(timeArrFrom[1]);

      var timeArrTo = item.to_time.split(":");
      const close = new Date();
      close.setHours(timeArrTo[0]);
      close.setMinutes(timeArrTo[1]);

      var obj = {
        open: open,
        close: close,
        day: item.day,
        closeError: "",
        openError: "",
        id: item.id,
      };
      inputsThursday.push(obj);
    });
    setThursdayInputs([...inputsThursday]);
    setThursdayHours([...inputsThursday]);
  };

  // Friday
  const handleFridayAddInput = () => {
    setFridayInputs([
      ...inputsFriday,
      {
        open: "",
        close: "",
        openError: Constants.OPEN_HOUR_ERROR,
        closeError: Constants.CLOSE_HOUR_ERROR,
      },
    ]);
  };
  const handleFridayBlur = (event, index) => {
    // if(Hours.length > 0) {
    let arrayTime = [];
    let obj = inputsFriday[index];
    var flag = true;
    inputsFriday.map((hours, i) => {
      // hours.open = moment(reportDateFormate(hours.open)).format('hh:mm A')
      // hours.close = moment(reportDateFormate(hours.close)).format('hh:mm A')
      if (
        null != obj.open &&
        "" != obj.open &&
        null != obj.close &&
        "" != obj.close
      ) {
        var opensAt = convertTime12to24(obj.open);
        var closeAt = convertTime12to24(obj.close);
        if (opensAt > closeAt || (opensAt == closeAt && flag)) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.LOCATION_MODULE,
            alertDescription: Constants.FRIDAY_MIN_ERROR,
          });
          flag = false;
          obj.closeError = Constants.CLOSE_HOUR_ERROR;
          obj.close = "";
          return false;
        } else {
          obj.closeError = "";
          obj.openError = "";
        }
        if (index !== i) {
          if (
            (flag &&
              convertTime12to24(hours.open) == convertTime12to24(obj.open)) ||
            convertTime12to24(hours.close) == convertTime12to24(obj.close) ||
            (convertTime12to24(hours.open) <= convertTime12to24(obj.open) &&
              convertTime12to24(hours.close) >= convertTime12to24(obj.close)) ||
            (convertTime12to24(obj.open) <= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) >= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.open) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.open) <= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.close) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) <= convertTime12to24(hours.close))
          ) {
            flag = false;
            obj.close = "";
            obj.open = "";
            obj.openError = Constants.OPEN_HOUR_ERROR;
            obj.closeError = Constants.CLOSE_HOUR_ERROR;
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.LOCATION_MODULE,
              alertDescription: Constants.SUNDAY_TIME_ERROR,
            });
          } else {
            obj.openError = "";
            obj.closeError = "";
            arrayTime.push(hours);
            // setHours([...onChangeValue]);
          }
        } else {
          arrayTime.push(hours);
        }
      }
    });
    setOperationHoursEdited(1);
    setFridayHours([...arrayTime]);
  };
  const handleFridayChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputsFriday];
    onChangeValue[index][name] = value;
    onChangeValue[index]["day"] = Constants.FRIDAY;
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "open"
    ) {
      onChangeValue[index]["openError"] = Constants.OPEN_HOUR_ERROR;
    }
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "close"
    ) {
      onChangeValue[index]["closeError"] = Constants.CLOSE_HOUR_ERROR;
    }
    setFridayInputs(onChangeValue);
    setOperationHoursEdited(1);
  };
  const handleFridayDeleteInput = (index) => {
    const newArray = [...inputsFriday];
    newArray.splice(index, 1);
    setFridayInputs(newArray);
    fridayHours.splice(index, 1);
    setFridayHours(fridayHours);
    setOperationHoursEdited(1);
  };
  const addEachlineForFridayHours = (hoursData) => {
    setFridayInputs([]);
    hoursData.map((item, index) => {
      // let dateString = new Date(item.date).toLocaleDateString();
      var timeArrFrom = item.from_time.split(":");
      const open = new Date();
      open.setHours(timeArrFrom[0]);
      open.setMinutes(timeArrFrom[1]);

      var timeArrTo = item.to_time.split(":");
      const close = new Date();
      close.setHours(timeArrTo[0]);
      close.setMinutes(timeArrTo[1]);

      var obj = {
        open: open,
        close: close,
        day: item.day,
        closeError: "",
        openError: "",
        id: item.id,
      };
      inputsFriday.push(obj);
    });
    setFridayInputs([...inputsFriday]);
    setFridayHours([...inputsFriday]);
  };

  // Saturday
  const handleSaturdayAddInput = () => {
    setSaturdayInputs([
      ...inputsSaturday,
      {
        open: "",
        close: "",
        openError: Constants.OPEN_HOUR_ERROR,
        closeError: Constants.CLOSE_HOUR_ERROR,
      },
    ]);
  };
  const handleSaturdayBlur = (event, index) => {
    // if(Hours.length > 0) {
    let arrayTime = [];
    let obj = inputsSaturday[index];
    var flag = true;
    inputsSaturday.map((hours, i) => {
      // hours.open = moment(reportDateFormate(hours.open)).format('hh:mm A')
      // hours.close = moment(reportDateFormate(hours.close)).format('hh:mm A')
      if (
        null != obj.open &&
        "" != obj.open &&
        null != obj.close &&
        "" != obj.close
      ) {
        var opensAt = convertTime12to24(obj.open);
        var closeAt = convertTime12to24(obj.close);
        if (opensAt > closeAt || (opensAt == closeAt && flag)) {
          addToast({
            alertStatus: Constants.TOAST_TYPE_WARNING,
            alertTitle: Constants.LOCATION_MODULE,
            alertDescription: Constants.SATURDAY_MIN_ERROR,
          });
          flag = false;
          obj.closeError = Constants.CLOSE_HOUR_ERROR;
          obj.close = "";
          return false;
        } else {
          obj.closeError = "";
          obj.openError = "";
        }
        if (index !== i) {
          if (
            (flag &&
              convertTime12to24(hours.open) == convertTime12to24(obj.open)) ||
            convertTime12to24(hours.close) == convertTime12to24(obj.close) ||
            (convertTime12to24(hours.open) <= convertTime12to24(obj.open) &&
              convertTime12to24(hours.close) >= convertTime12to24(obj.close)) ||
            (convertTime12to24(obj.open) <= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) >= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.open) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.open) <= convertTime12to24(hours.close)) ||
            (convertTime12to24(obj.close) >= convertTime12to24(hours.open) &&
              convertTime12to24(obj.close) <= convertTime12to24(hours.close))
          ) {
            flag = false;
            obj.close = "";
            obj.open = "";
            obj.openError = Constants.OPEN_HOUR_ERROR;
            obj.closeError = Constants.CLOSE_HOUR_ERROR;
            addToast({
              alertStatus: Constants.TOAST_TYPE_WARNING,
              alertTitle: Constants.LOCATION_MODULE,
              alertDescription: Constants.SUNDAY_TIME_ERROR,
            });
          } else {
            obj.openError = "";
            obj.closeError = "";
            arrayTime.push(hours);
            // setHours([...onChangeValue]);
          }
        } else {
          arrayTime.push(hours);
        }
      }
    });
    setOperationHoursEdited(1);
    setSaturdayHours([...arrayTime]);
  };
  const handleSaturdayChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputsSaturday];
    onChangeValue[index][name] = value;
    onChangeValue[index]["day"] = Constants.SATURDAY;
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "open"
    ) {
      onChangeValue[index]["openError"] = Constants.OPEN_HOUR_ERROR;
    }
    if (
      (event.target.value == null || "" == event.target.value) &&
      name == "close"
    ) {
      onChangeValue[index]["closeError"] = Constants.CLOSE_HOUR_ERROR;
    }
    setSaturdayInputs(onChangeValue);
    setOperationHoursEdited(1);
  };
  const handleSaturdayDeleteInput = (index) => {
    const newArray = [...inputsSaturday];
    newArray.splice(index, 1);
    setSaturdayInputs(newArray);
    saturdayHours.splice(index, 1);
    setSaturdayHours(saturdayHours);
    setOperationHoursEdited(1);
  };
  const addEachlineForSaturdayHours = (hoursData) => {
    setSaturdayInputs([]);
    hoursData.map((item, index) => {
      // let dateString = new Date(item.date).toLocaleDateString();
      var timeArrFrom = item.from_time.split(":");
      const open = new Date();
      open.setHours(timeArrFrom[0]);
      open.setMinutes(timeArrFrom[1]);

      var timeArrTo = item.to_time.split(":");
      const close = new Date();
      close.setHours(timeArrTo[0]);
      close.setMinutes(timeArrTo[1]);

      var obj = {
        open: open,
        close: close,
        day: item.day,
        closeError: "",
        openError: "",
        id: item.id,
      };
      inputsSaturday.push(obj);
    });
    setSaturdayInputs([...inputsSaturday]);
    setSaturdayHours([...inputsSaturday]);
  };

  let checkvalidMonday = mondayHours.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });
  let inputvalidMonday = inputsMonday.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });

  let checkvalidTuesday = tuesdayHours.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });
  let inputvalidTuesday = inputsTuesday.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });

  let checkvalidWednesday = wednesdayHours.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });
  let inputvalidWednesday = inputsWednesday.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });

  let checkvalidThursday = thursdayHours.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });
  let inputvalidThursday = inputsThursday.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });

  let checkvalidFriday = fridayHours.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });
  let inputvalidFriday = inputsFriday.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });

  let checkvalidSaturday = saturdayHours.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });
  let inputvalidSaturday = inputsSaturday.findIndex((x) => {
    return x.openError.length > 0 || x.closeError.length > 0;
  });
  
  const submitClicked = () => {
    mondayHours.map((item, index) => {
      handleMondayBlur(item,index);
    });
    tuesdayHours.map((item, index) => {
      handleTuesdayBlur(item,index);
    });
    wednesdayHours.map((item, index) => {
      handleWednesdayBlur(item,index);
    });
    thursdayHours.map((item, index) => {
      handleThursdayBlur(item,index);
    });
    fridayHours.map((item, index) => {
      handleFridayBlur(item,index);
    });
    saturdayHours.map((item, index) => {
      handleSaturdayBlur(item,index);
    });
    Hours.map((item, index) => {
      handleSundayBlur(item,index);
    });

    let checkvalid = Hours.findIndex((x) => {
      return x.openError.length > 0 || x.closeError.length > 0;
    });
    let checkvalidMonday = mondayHours.findIndex((x) => {
      return x.openError.length > 0 || x.closeError.length > 0;
    });
  
    let checkvalidTuesday = tuesdayHours.findIndex((x) => {
      return x.openError.length > 0 || x.closeError.length > 0;
    });
  
    let checkvalidWednesday = wednesdayHours.findIndex((x) => {
      return x.openError.length > 0 || x.closeError.length > 0;
    });
  
    let checkvalidThursday = thursdayHours.findIndex((x) => {
      return x.openError.length > 0 || x.closeError.length > 0;
    });
  
    let checkvalidFriday = fridayHours.findIndex((x) => {
      return x.openError.length > 0 || x.closeError.length > 0;
    });
  
    let checkvalidSaturday = saturdayHours.findIndex((x) => {
      return x.openError.length > 0 || x.closeError.length > 0;
    });
  
    if(buttonDisable ||
      checkvalid > -1 ||
      inputvalid > -1 ||
      checkvalidMonday > -1 ||
      inputvalidMonday > -1 ||
      checkvalidTuesday > -1 ||
      inputvalidTuesday > -1 ||
      checkvalidWednesday > -1 ||
      inputvalidWednesday > -1 ||
      checkvalidThursday > -1 ||
      inputvalidThursday > -1 ||
      checkvalidFriday > -1 ||
      inputvalidFriday > -1 ||
      checkvalidSaturday > -1 ||
      inputvalidSaturday > -1 ||
      (formik.values.online_order == true &&
        formik.values.online_order_types.length <= 0) ||
      !formik.isValid){
    }
    else{
      formik.handleSubmit();
    }
  }
  const CardRadio = ({ children, isChecked, ...props }) => {
    return (
      <Box>
        {/* <input type="radio" {...props} style={{ display: "none" }} /> */}
        <Box
          cursor="pointer"
          // borderWidth="2px"
          // borderRadius="100rem"
          // h={"6.125rem"}
          // w={"19.125rem"}
          // h={{base: "7.125rem", md: "6.125rem"}}
          h={"auto"}
          w={{base: "15.125rem", md: "19.125rem"}}
          // boxShadow="md"
          // color={inherit}
          // borderColor={ "gray.300"}
          // _hover={{
          //   bg: isChecked ? "messenger" : "gray.100",
          // }}
          _checked={{
            bg: "teal.600",
            // color: "white",
            borderColor: "#5881FE",
          }}
          px={3}
          py={2}
        >
          {children}
        </Box>
      </Box>
    );
  };
  const CardRadioSlot = ({ children, isChecked, ...props }) => {
    return (
      <Box as="label">
        <input type="radio" {...props} style={{ display: "none" }} />
        <Box
          cursor="pointer"
          borderWidth="2px"
          borderRadius="0.625rem"
          h={"3.25rem"}
          w={"7rem"}
          boxShadow="md"
          // bg={isChecked ? "teal.600" : "white"}
          color={isChecked ? "white" : "black"}
          borderColor={isChecked ? "messenger.300" : "gray.300"}
          _hover={{
            bg: isChecked ? "messenger" : "gray.100",
          }}
          _checked={{
            bg: "teal.600",
            color: "white",
            borderColor: "#5881FE",
          }}
          px={5}
          py={3}
        >
          {children}
        </Box>
      </Box>
    );
  };
  const handleBarcodeClick = () => {
    const newBarcode = formik.values.zipcode;
    const foundZipcode = additionalZipcodes.find(
      (temp) => temp.zipcode == newBarcode
    );
    if (formik.values.barcode == formik.values.zipcode || foundZipcode) {
      formik.setErrors({
        ...formik.errors,
        zipcode: "Zipcode already exists in this location.",
      });
      return;
    } else if (!foundZipcode) {
      setZipCodes([...additionalZipcodes, { zipcode: newBarcode }]);
      formik.values.zipcode = "";
    }
  };

  const handleRestrictedClick = () => {
    const newDate = formik.values.rDate;
    const parsedDate = new Date(newDate); // changes here
    var year = parsedDate.getFullYear();
    var month = ("0" + (parsedDate.getMonth() + 1)).slice(-2);
    var day = ("0" + parsedDate.getDate()).slice(-2);
    const utcDateString = parsedDate.toDateString();
    const foundRDate = restrictedDates.find(
      (temp) => temp.date == year + "-" + month + "-" + day
    );
    if (foundRDate) {
      formik.setErrors({
        rDate: "Restricted date already exists.",
      });
      return;
    } else if (!foundRDate) {
      setRestrictedDates([
        ...restrictedDates,
        { date: year + "-" + month + "-" + day },
      ]);
      formik.values.rDate = "";
    }
  };
  const handleDelete = (index) => {
    const updatedZipcodes = additionalZipcodes.filter((_, i) => i != index);
    setZipCodes(updatedZipcodes);
  };

  const handleDeleteRDates = (index) => {
    const updatedRDates = restrictedDates.filter((_, i) => i != index);
    setRestrictedDates(updatedRDates);
  };
  const couponTypeOptions = [
    {
      title: "Delivery",
      body: "Option to ship products to customer after purchase",
      value: "Delivery",
      icon: (
        <EditIcon color={Colors.taxInfo} style={{ fill: Colors.taxInfo }} />
      ),
    },
    {
      title: "Pick Up",
      body: "Option for customer to collect products at store after purchase",
      value: "Pickup",
      icon: <EditIcon style={{ fill: Colors.taxInfo }} />,
    },
  ];

  const SlotIntervalTypeOptions = [
    {
      title: "5 Mins",
      value: 5,
    },
    {
      title: "15 Mins",
      value: 15,
    },
    {
      title: "30 Mins",
      value: 30,
    },
  ];

  const handleCouponTypeChange = (value) => {
    setSelectedCouponType(value);
    formik.setFieldValue("coupon_type", value);
  };
  const handleSlotTypeChange = (value) => {
    formik.setFieldValue("slot_interval", value);
    setOperationHoursEdited(1);
  };
  const {
    getRootProps: getCouponTypeRootProps,
    getRadioProps: getCouponTypeRadioProps,
  } = useRadioGroup({
    name: "value",
    defaultValue: "",
    onChange: handleCouponTypeChange,
  });

  const {
    getRootProps: getSlotTypeRootProps,
    getRadioProps: getSlotTypeRadioProps,
  } = useRadioGroup({
    name: "value",
    defaultValue: "",
    onChange: handleSlotTypeChange,
  });

  useEffect(() => {
    if (props.isOnlineOrderOpen) {
      myContext.handleLoading(true);
      fetchData(id);
    }
    return () => {};
  }, [props.isOnlineOrderOpen]);

  const couponTypeGroup = getCouponTypeRootProps();
  const slotTypeGroup = getSlotTypeRootProps();

  const fetchData = async (id) => {
    try {
      getAllLocationsAdditionalSettingData(id)
        .then((response) => {
          if (
            undefined !== response.data.flag &&
            null !== response.data.flag &&
            response.data.flag == true
          ) {
            if (
              undefined != response.data?.data &&
              null != response.data?.data &&
              "" != response.data?.data
            ) {
              if (
                undefined != response.data?.data?.locationOnlOrdSettings &&
                null != response.data?.data?.locationOnlOrdSettings &&
                "" != response.data?.data?.locationOnlOrdSettings
              ) {
                if (
                  response.data?.data?.locationOnlOrdSettings?.is_pickup == 1 &&
                  response.data?.data?.locationOnlOrdSettings?.is_delivery == 0
                ) {
                  if (!online_types.includes(Constants.PICKUP)) {
                    online_types.push(Constants.PICKUP);
                  }
                }
                if (
                  response.data?.data?.locationOnlOrdSettings.is_delivery ==
                    1 &&
                  response.data?.data?.locationOnlOrdSettings.is_pickup == 0
                ) {
                  if (!online_types.includes(Constants.DELIVERY)) {
                    online_types.push(Constants.DELIVERY);
                  }
                }
                if (
                  response.data?.data?.locationOnlOrdSettings.is_delivery ==
                    1 &&
                  response.data?.data?.locationOnlOrdSettings.is_pickup == 1
                ) {
                  if (
                    !online_types.includes(Constants.PICKUP) &&
                    !online_types.includes(Constants.DELIVERY)
                  ) {
                    online_types.push(Constants.PICKUP);
                    online_types.push(Constants.DELIVERY);
                  }
                }
                var pickup_lead =
                  response.data?.data?.locationOnlOrdSettings?.pickup_lead_time;
                var delivery_lead =
                  response.data?.data?.locationOnlOrdSettings
                    ?.delivery_lead_time;
                if (
                  undefined != pickup_lead &&
                  null != pickup_lead &&
                  "" != pickup_lead
                ) {
                  var timeArrPickup = pickup_lead.split(":");
                  const selectedDatePick = new Date();
                  selectedDatePick.setHours(timeArrPickup[0]);
                  selectedDatePick.setMinutes(timeArrPickup[1]);
                  setPickLead(selectedDatePick);
                }
                if (
                  undefined != delivery_lead &&
                  null != delivery_lead &&
                  "" != delivery_lead
                ) {
                  var timeArrPickup = delivery_lead.split(":");
                  const selectedDateDelivery = new Date();
                  selectedDateDelivery.setHours(timeArrPickup[0]);
                  selectedDateDelivery.setMinutes(timeArrPickup[1]);
                  setDeliveryLead(selectedDateDelivery);
                }
                setOnlineOrderingSettings(
                  response.data?.data?.locationOnlOrdSettings
                );
              }
              if (
                undefined != response.data?.data?.locationOnlOrdZipcodes &&
                response.data?.data?.locationOnlOrdZipcodes.length > 0
              ) {
                setZipCodes(response.data?.data?.locationOnlOrdZipcodes);
              }
              if (
                undefined != response.data?.data?.locOnlRestrictedDates &&
                response.data?.data?.locOnlRestrictedDates.length > 0
              ) {
                setRestrictedDates(response.data?.data?.locOnlRestrictedDates);
              }
              if (
                undefined !=
                  response.data?.data?.locationOnlOrdSettings
                    ?.image_base64_online &&
                null !=
                  response.data?.data?.locationOnlOrdSettings
                    ?.image_base64_online &&
                "" !=
                  response.data?.data?.locationOnlOrdSettings
                    ?.image_base64_online
              ) {
                var explodedImageName =
                  response.data?.data?.locationOnlOrdSettings?.image_online.split(
                    "."
                  );
                var imageType = explodedImageName[explodedImageName.length - 1];
                var actualBase64 =
                  "data:" +
                  imageType +
                  ";base64," +
                  response.data?.data?.locationOnlOrdSettings
                    ?.image_base64_online;
                setOldImageBase64(actualBase64);
                setBase64OfUploadedImg(actualBase64);
              }
              if (
                response.data?.data?.octogrub_url != null &&
                "" != response.data?.data?.octogrub_url &&
                undefined != response.data?.data?.octogrub_url
              ) {
                setGrubUrl(
                  response.data?.data?.octogrub_url +
                    window.location.hostname.split(".")[0]
                );
              }
              if (url.includes(":3000")) {
                const newUrl = url.replace(":3000", ":8000");
                setUrl(newUrl);
              }
              if (
                response.data?.data?.locOnlOpeHours?.length > 0 &&
                undefined != response.data?.data?.locOnlOpeHours
              ) {
                response.data?.data?.locOnlOpeHours.map((dy) => {
                  // const days = selectedDays.find((temp)=>temp===dy.day);
                  if (dy.day == Constants.SUNDAY) {
                    if (!selectedDays.includes(Constants.SUNDAY)) {
                      selectedDays.push(Constants.SUNDAY);
                    }
                    HoursEditable.push(dy);
                  }
                  if (dy.day == Constants.MONDAY) {
                    if (!selectedDays.includes(Constants.MONDAY)) {
                      selectedDays.push(Constants.MONDAY);
                    }
                    mondayHoursEditable.push(dy);
                  }
                  if (dy.day == Constants.TUESDAY) {
                    if (!selectedDays.includes(Constants.TUESDAY)) {
                      selectedDays.push(Constants.TUESDAY);
                    }
                    tuesdayHoursEditable.push(dy);
                  }
                  if (dy.day == Constants.WEDNESDAY) {
                    if (!selectedDays.includes(Constants.WEDNESDAY)) {
                      selectedDays.push(Constants.WEDNESDAY);
                    }
                    wednesdayHoursEditable.push(dy);
                  }
                  if (dy.day == Constants.THURSDAY) {
                    if (!selectedDays.includes(Constants.THURSDAY)) {
                      selectedDays.push(Constants.THURSDAY);
                    }
                    thursdayHoursEditable.push(dy);
                  }
                  if (dy.day == Constants.FRIDAY) {
                    if (!selectedDays.includes(Constants.FRIDAY)) {
                      selectedDays.push(Constants.FRIDAY);
                    }
                    fridayHoursEditable.push(dy);
                  }
                  if (dy.day == Constants.SATURDAY) {
                    if (!selectedDays.includes(Constants.SATURDAY)) {
                      selectedDays.push(Constants.SATURDAY);
                    }
                    saturdayHoursEditable.push(dy);
                  }
                });
                addEachlineForHours(HoursEditable);
                addEachlineForMondayHours(mondayHoursEditable);
                addEachlineForTuesdayHours(tuesdayHoursEditable);
                addEachlineForWednesdayHours(wednesdayHoursEditable);
                addEachlineForThursdayHours(thursdayHoursEditable);
                addEachlineForFridayHours(fridayHoursEditable);
                addEachlineForSaturdayHours(saturdayHoursEditable);
              }
              myContext.handleLoading(false);
              setViewFlag(true);
            }
          } else {
            myContext.handleLoading(false);
            let actionData = response;
            error({ actionData });
          }
        })
        .catch((err) => {
          let actionData = err;
          error({ actionData });
          myContext.handleLoading(false);
        });
    } catch (error) {
      myContext.handleLoading(false);
    }
  };

  const handleClose = () => {
    formik.resetForm();
    setViewFlag(false);
    if (props.onOnlineOrderClose) {
      props.onOnlineOrderClose();
    }
    setInputs([]);
    setHoursEditable([]);

    setMondayInputs([]);
    setMondayHoursEditable([]);

    setTuesdayInputs([]);
    setTuesdayHoursEditable([]);

    setWednesdayInputs([]);
    setWednesdayHoursEditable([]);

    setThursdayInputs([]);
    setThursdayHoursEditable([]);

    setFridayInputs([]);
    setFridayHoursEditable([]);

    setSaturdayInputs([]);
    setSaturdayHoursEditable([]);

    setSelectedDays([]);

    setSelectedTypes([]);
    setCroppedImgDetails({
      filename: "",
      filetype: "",
      filesize: "",
      base64: "",
    });
    setOldImageBase64("");
  };
  const handleCopyClick = () => {
    const textArea = document.createElement('textarea');
    textArea.value = grubUrl;
    textArea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand('copy');
      addToast({
        alertStatus: Constants.TOAST_TYPE_SUCESS,
        alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
        alertDescription: "Your Online Ordering Portal URL copied successfully.",
      });
    } catch (err) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.LOCATION_ACCOUNT_SETTINGS,
        alertDescription: 'Failed to copy text to clipboard.',
      });
    }
  };
  const itemTemplate = (option, index) => {
    const radio = getCouponTypeRadioProps({
      value: option.value,
    });
    return (
      <CardRadio
        key={index}
        {...radio}
        isChecked={formik.values.online_order_types.includes(option.value)}
      >
        <Flex
          // color={
          //   formik.values.online_order_types.includes(option.value)
          //     ? "white"
          //     : Colors.posTextInfo
          // }
          flexDirection="row"
          alignItems="left"
          gap={{ base: "1rem", md: 1 }}
        >
          {option.icon}
          <Text
            fontFamily={"Lexend, sans-serif;"}
            fontWeight="bold"
            // color={
            //   formik.values.online_order_types.includes(option.value)
            //     ? "white"
            //     : Colors.posTextInfo
            // }
          >
            {option.title}
          </Text>
        </Flex>
        <Text
          fontFamily={"Lexend, sans-serif;"}
          textAlign={"left"}
          mt={"0.63rem"}
          // color={
          //   formik.values.online_order_types.includes(option.value)
          //     ? "white"
          //     : Colors.posTextInfo
          // }
        >
          {option.body}
        </Text>
      </CardRadio>
    );
  };
  return (
    <PosDrawer
      visible={viewFlag}
      onHide={handleClose}
      position="right"
      showCloseIcon={true}
    >
      <Box padding={2}>
        <Box
          bg={Colors.loginAuthBackground}
          position="sticky"
          top="0"
          zIndex="sticky"
        >
          <Flex
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box pb={{ base: 4, md: 0 }}>
              <PosBreadCrumb
                handleClick={(i) => {
                  props.navigate(Constants.ESTABLISHMENT_LOCATION_PATH);
                }}
                breadCrumNames={["Locations", "Online Orders"]}
                breadCrumTitle={"Online Orders"}
              />
            </Box>
            {/* <Spacer /> */}
            <ButtonGroup mr={{ base: 0, md: "0.88rem" }} gap="2" alignSelf={"flex-end"} mb={2}>
              {/* <PosFormButton
                onClick={() => {
                  handleClose();
                }}
                buttonText={"Cancel"}
                CancelButton={true}
                isDisabled={buttonDisable}
              /> */}
              <PosFormButton
                isDisabled={
                  buttonDisable ||
                  checkvalid > -1 ||
                  inputvalid > -1 ||
                  checkvalidMonday > -1 ||
                  inputvalidMonday > -1 ||
                  checkvalidTuesday > -1 ||
                  inputvalidTuesday > -1 ||
                  checkvalidWednesday > -1 ||
                  inputvalidWednesday > -1 ||
                  checkvalidThursday > -1 ||
                  inputvalidThursday > -1 ||
                  checkvalidFriday > -1 ||
                  inputvalidFriday > -1 ||
                  checkvalidSaturday > -1 ||
                  inputvalidSaturday > -1 ||
                  (formik.values.online_order == true &&
                    formik.values.online_order_types.length <= 0) ||
                  !formik.isValid
                    ? true
                    : false
                }
                buttonsubmit={"Save Changes"}
                SubmitButton={true}
                onClick={() =>
                  submitClicked()
                }
              />
            </ButtonGroup>
          </Flex>
        </Box>
        <Divider
          borderWidth={"0.8px"}
          borderColor="#E6E6E6"
          mt={5}
          mb={5}
        />
        <Card
          margin={{ base: 0, sm: 0, md: "1.88rem", lg: "1.88rem" }}
          sx={{
            boxShadow: "none",
          }}
          bg={Colors.posPageDataBackground}
          // boxShadow={"0px 4px 20px 0px rgba(90, 90, 90, 0.04)"}
          borderRadius={"0.625rem"}
        >
          {/* <CardHeader alignItems={{ base: "flex-start" }} mt={10}>
        </CardHeader> */}
          {/* <Divider borderColor="#E6E6E6" mt={"2rem"} mb={"2rem"} /> */}
          <CardBody>
            <Box
              w={{ base: "100%", md: "95%" }}
              ml={{ base: "0", md: "2.5rem" }}
              mt={"2rem"}
              mb={1}
            >
              <Heading
                as="h3"
                size="xs"
                fontWeight="500"
                fontSize="1.125rem"
                lineHeight="1.40625rem"
                letterSpacing="-0.016875rem"
                color="#010048"
              >
                Basic Details
              </Heading>
              {/* <Divider
            borderWidth={"0.8px"}
            borderColor={Colors.dividerColor}
            mt={5}
            width={{ base: "100%", md: "100%" }}
          /> */}
            </Box>
            <Divider borderColor="#E6E6E6" mt={"1rem"} />
            <Stack spacing="2">
              <Box>
                <Flex
                  justifyContent={{ base: "center", md: "space-between" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Flex
                    flexDirection={"row"}
                    ml={{ base: "0", md: "2.5rem" }}
                    w={{ base: "100%", md: "80%" }}
                  >
                    <PosLable
                      label={true}
                      fontWeight={500}
                      requiredLabel={true}
                      name={"Is this Location available for online order?"}
                    />
                    {/* <Stack
                      direction={"row"}
                      mt={"0.313rem"}
                      w={"44px"}
                      height={"22px"}
                      flexShrink={"0"}
                    > */}
                      <PosSwitchButton
                        id="online_order"
                        switchValue={formik.values.online_order}
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldTouched("online_order_types", true);
                        }}
                        mt={"2rem"}
                        ml={"1.5rem"}
                      />
                    {/* </Stack> */}
                  </Flex>
                </Flex>
              </Box>
            </Stack>
            <Divider borderColor="#E6E6E6" mt={"1rem"} />
            {formik.values.online_order == true && (
              <Stack spacing="2">
                {/* <Box> */}
                {/* <Flex
                justifyContent={{ base: "center", md: "space-between" }}
                alignItems={{ base: "flex-start" }}
                flexDirection={{ base: "column", md: "row" }}
              >
                <Box
                  ml={{ base: "0", md: "2.5rem" }}
                  w={{ base: "100%", md: "95%" }}
                > */}
                <Flex
                  justifyContent={{ base: "center", md: "space-between" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    ml={{ base: "0", md: "2.5rem" }}
                    w={{ base: "100%", md: "80%" }}
                  >
                    <PosLable
                      label={true}
                      fontWeight={500}
                      requiredLabel={true}
                      name={"Online Order Types"}
                    />
                    <Stack mt={2} direction={{ base: "column", md: "row" }}>
                      <SelectButton
                        id="online_order_types"
                        name="online_order_types"
                        value={formik.values.online_order_types}
                        options={couponTypeOptions}
                        onChange={(e) => {
                          formik.handleChange(e);
                          if (
                            e.value.includes("Delivery") &&
                            e.value.includes("Pickup")
                          ) {
                            if (!formik.values.dlvr) {
                              formik.setFieldValue("dlvr", true);
                              formik.setFieldValue("delivery_lead_time", "");
                              formik.setFieldValue("minimum_order_amount", "");
                            } else if (!formik.values.pick) {
                              formik.setFieldValue("pick", true);
                              formik.setFieldValue("pickup_lead_time", "");
                            }
                          } else if (e.value.includes("Delivery")) {
                            if (!formik.values.dlvr) {
                              formik.setFieldValue("dlvr", true);
                              formik.setFieldValue("delivery_lead_time", "");
                              formik.setFieldValue("minimum_order_amount", "");
                            } else if (formik.values.pick) {
                              formik.setFieldValue("pick", false);
                            }
                          } else if (e.value.includes("Pickup")) {
                            if (formik.values.dlvr) {
                              formik.setFieldValue("dlvr", false);
                            } else if (!formik.values.pick) {
                              formik.setFieldValue("pick", true);
                              formik.setFieldValue("pickup_lead_time", "");
                            }
                          } else {
                            if (formik.values.dlvr) {
                              formik.setFieldValue("dlvr", false);
                            } else if (formik.values.pick) {
                              formik.setFieldValue("pick", false);
                            }
                          }
                          // if (e.value.includes("Delivery")) {
                          //   formik.setFieldValue("dlvr", true);
                          //   // formik.setFieldTouched("delivery_lead_time", true);
                          //   // formik.setFieldTouched("minimum_order_amount", true);
                          // } else {
                          //   formik.setFieldValue("dlvr", false);
                          //   // formik.setFieldTouched("delivery_lead_time", false);
                          //   // formik.setFieldTouched("minimum_order_amount", false);

                          //   delete formik.errors.minimum_order_amount
                          //   delete formik.errors.delivery_lead_time
                          // }
                          // if (e.value.includes("Pickup")) {
                          //   formik.setFieldValue("pick", true);
                          //   // formik.setFieldTouched("pickup_lead_time", true);
                          // } else {
                          //   formik.setFieldValue("pick", false);
                          //   // formik.setFieldTouched("pickup_lead_time", false);
                          // }
                          // formik.handleChange(e);
                        }}
                        optionLabel="title"
                        optionValue="value"
                        multiple={true}
                        itemTemplate={itemTemplate}
                      />
                    </Stack>
                    <Text color={Colors.errorColor}>
                      {formik.touched.online_order_types &&
                      formik.errors.online_order_types ? (
                        <span>{formik.errors.online_order_types}</span>
                      ) : null}
                    </Text>
                  </Box>
                </Flex>
                <Divider borderColor="#E6E6E6" mt={"1rem"} />
                <Flex
                  justifyContent={{ base: "center", md: "space-between" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    ml={{ base: "0", md: "2.5rem" }}
                    w={{ base: "100%", md: "80%" }}
                  >
                    <PosLable
                      label={true}
                      fontWeight={500}
                      name={"Online Ordering Home Page Side Cover Image"}
                    />
                    {!oldImageBase64 ? (
                      <PosImageCropper
                        parentCallback={handleCallback}
                        canvasWidth="14.75rem"
                        canvasHeight="14.75rem"
                        cropperWidth="135"
                        cropperHeight="264"
                        // cropperMinWidth="135" // Minimum width of the crop area Cropper hide na thay ena mate
                        // cropperMaxWidth="135" // Maximum width of the crop area when edit open at time full cropper display
                        // cropperMinHeight="264" // Minimum height of the crop area Cropper hide na thay ena mate
                        // cropperMaxHeight="264"
                      />
                    ) : (
                      <PosImageCropper
                        parentCallback={handleCallback}
                        oldImageBase64={oldImageBase64}
                        canvasWidth="14.75rem"
                        canvasHeight="14.75rem"
                        cropperWidth="135"
                        cropperHeight="264"
                        cropperMinWidth="135" // Minimum width of the crop area Cropper hide na thay ena mate
                        cropperMaxWidth="135" // Maximum width of the crop area when edit open at time full cropper display
                        cropperMinHeight="264" // Minimum height of the crop area Cropper hide na thay ena mate
                        cropperMaxHeight="264"
                      />
                    )}
                  </Box>
                </Flex>
                <Divider borderColor="#E6E6E6" mt={"1rem"} />
                <Flex
                  justifyContent={{ base: "center", md: "space-between" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    ml={{ base: "0", md: "2.5rem" }}
                    w={{ base: "100%", md: "80%" }}
                  >
                    <PosLable
                      label={true}
                      fontWeight={500}
                      name={"Online Ordering Portal"}
                    />
                    <Box
                      bg={Colors.posviewbgcolor}
                      w={{ base: "100%", md: "45rem" }}
                      p={4}
                      mt={2}
                      borderRadius={"0.3125rem"}
                    >
                      <Text color={Colors.posTextInfo}>
                        Your Online Ordering Portal URL for your business
                      </Text>
                      <Text
                        mr={2}
                        mt={"1.19rem"}
                        color={Colors.posNavbarLink}
                        fontSize={"0.94rem"}
                        as="u"
                      >
                        <a
                          target="_blank"
                          href={
                            undefined != grubUrl &&
                            null != grubUrl &&
                            "" != grubUrl
                              ? grubUrl
                              : "N.A."
                          }
                          style={{
                            cursor: "pointer",
                            flexShrink: "0",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "normal",
                            letterSpacing: "-0.18px",
                            color: Colors.posNavbarLink,
                          }}
                        >
                          {undefined != grubUrl &&
                          null != grubUrl &&
                          "" != grubUrl
                            ? grubUrl
                            : "N.A."}
                        </a>
                      </Text>
                      <PosOutlineButton
                        onClick={handleCopyClick}
                        name={<ContentCopyIcon />}
                      ></PosOutlineButton>
                    </Box>
                  </Box>
                </Flex>
                <Divider borderColor="#E6E6E6" mt={"1rem"} />
                {formik.values.online_order_types.includes("Delivery") && (
                  <>
                    <Stack gap={1}>
                      <Flex
                        justifyContent={{ base: "center", md: "space-between" }}
                        alignItems={{ base: "flex-start" }}
                        flexDirection={{ base: "column", md: "row" }}
                      >
                        <Box
                          ml={{ base: "0", md: "2.5rem" }}
                          w={{ base: "100%", md: "80%" }}
                        >
                          <PosLable
                            label={true}
                            requiredLabel={true}
                            fontWeight={500}
                            color={Colors.posTextInfo}
                            name={"Minimum Order Amount For Delivery"}
                          />
                          <PosInput
                            id="minimum_order_amount"
                            name="minimum_order_amount"
                            placeholder={"Enter minimum order for delivery"}
                            handleInputChange={formik.handleChange}
                            inputValue={formik.values.minimum_order_amount}
                            handleBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldValue(
                                "minimum_order_amount",
                                twofix(formik.values.minimum_order_amount)
                              );
                              // formik.handleBlur(e);
                            }}
                            // onKeyDown={handleKeyDown}
                            width={{ base: "100%", md: "46.31rem" }}
                            posNumberInput={true}
                            background={Colors.posviewbgcolor}
                            inputType={"number"}
                            inputError={
                              formik.touched.minimum_order_amount &&
                              formik.errors.minimum_order_amount
                            }
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.minimum_order_amount &&
                            formik.errors.minimum_order_amount ? (
                              <span>{formik.errors.minimum_order_amount}</span>
                            ) : null}
                          </Text>
                        </Box>
                      </Flex>
                    </Stack>
                    <Divider borderColor="#E6E6E6" mt={"1rem"} />
                  </>
                )}
                <Flex
                  justifyContent={{ base: "center", md: "space-between" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Flex
                    flexDirection={"row"}
                    ml={{ base: "0", md: "2.5rem" }}
                    w={{ base: "100%", md: "80%" }}
                  >
                    <PosLable
                      label={true}
                      fontWeight={500}
                      requiredLabel={true}
                      name={"Enable Reward Customer"}
                    />
                      <PosSwitchButton
                        id="enable_reward_card"
                        switchValue={formik.values.enable_reward_card}
                        onChange={formik.handleChange}
                        mt={"2rem"}
                        ml={"1.5rem"}
                      />
                  </Flex>
                </Flex>
                <Divider borderColor="#E6E6E6" mt={"1rem"} />
                {formik.values.online_order_types.includes("Delivery") && (
                  <>
                    <Flex
                      justifyContent={{ base: "center", md: "space-between" }}
                      alignItems={{ base: "flex-start" }}
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      <Box
                        ml={{ base: "0", md: "2.5rem" }}
                        w={{ base: "100%", md: "80%" }}
                      >
                        <PosLable
                          label={true}
                          fontWeight={500}
                          requiredLabel={true}
                          name={"Delivery Lead Time (HH:MM)"}
                        />
                        <VStack
                          justifyContent={"flex-start"}
                          alignItems={"flex-start"}
                          // direction={"row"}
                          mt={"0.313rem"}
                          // height={"22px"}
                          flexShrink={"0"}
                        >
                          <Calendar
                            id="delivery_lead_time"
                            name="delivery_lead_time"
                            value={formik.values.delivery_lead_time}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "delivery_lead_time",
                                e.value
                              )
                            }
                            onBlur={() => {
                              formik.setFieldTouched(
                                "delivery_lead_time",
                                true
                              );
                            }}
                            viewDate={deliveryDefault}
                            timeOnly
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.delivery_lead_time &&
                            formik.errors.delivery_lead_time ? (
                              <span>{formik.errors.delivery_lead_time}</span>
                            ) : null}
                          </Text>
                        </VStack>
                      </Box>
                    </Flex>
                    <Divider borderColor="#E6E6E6" mt={"1rem"} />
                  </>
                )}
                {formik.values.online_order_types.includes("Pickup") && (
                  <>
                    <Flex
                      justifyContent={{ base: "center", md: "space-between" }}
                      alignItems={{ base: "flex-start" }}
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      <Box
                        ml={{ base: "0", md: "2.5rem" }}
                        w={{ base: "100%", md: "80%" }}
                      >
                        <PosLable
                          label={true}
                          fontWeight={500}
                          requiredLabel={true}
                          name={"Pickup Lead Time (HH:MM)"}
                        />
                        <VStack
                          // direction={"row"}
                          justifyContent={"flex-start"}
                          alignItems={"flex-start"}
                          mt={"0.313rem"}
                          // height={"22px"}
                          flexShrink={"0"}
                        >
                          <Calendar
                            id="pickup_lead_time"
                            name="pickup_lead_time"
                            value={formik.values.pickup_lead_time}
                            onChange={(e) =>
                              formik.setFieldValue("pickup_lead_time", e.value)
                            }
                            onBlur={() => {
                              formik.setFieldTouched("pickup_lead_time", true);
                            }}
                            viewDate={pickupDefault}
                            timeOnly
                          />
                          <Text color={Colors.errorColor}>
                            {formik.touched.pickup_lead_time &&
                            formik.errors.pickup_lead_time ? (
                              <span>{formik.errors.pickup_lead_time}</span>
                            ) : null}
                          </Text>
                        </VStack>
                      </Box>
                    </Flex>
                    <Divider borderColor="#E6E6E6" mt={"1rem"} />
                  </>
                )}
                <Flex
                  justifyContent={{ base: "center", md: "space-between" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    ml={{ base: "0", md: "2.5rem" }}
                    w={{ base: "100%", md: "80%" }}
                  >
                    <PosLable
                      label={true}
                      fontWeight={500}
                      requiredLabel={true}
                      name={"Slot Interval"}
                    />
                    <Stack direction={{ base: "column", md: "row" }}>
                      {/* <HStack
                      mt={2}
                      spacing={{ base: "1rem", md: "1.10rem" }}
                      mb={2}
                      {...slotTypeGroup}
                      w={{ md: "14rem" }}
                    > */}
                      {SlotIntervalTypeOptions.map((option, index) => {
                        const radio = getSlotTypeRadioProps({
                          value: option.value,
                        });
                        return (
                          <CardRadioSlot
                            key={index}
                            {...radio}
                            isChecked={
                              formik.values.slot_interval == option.value
                            }
                          >
                            <Flex flexDirection={{ base: "column", md: "row" }} alignItems="center">
                              <Text
                                fontWeight="bold"
                                color={Colors.posTextInfo}
                                textAlign="center"
                              >
                                {option.title}
                              </Text>
                            </Flex>
                          </CardRadioSlot>
                        );
                      })}
                      {/* </HStack> */}
                    </Stack>
                  </Box>
                </Flex>
                <Divider borderColor="#E6E6E6" mt={"1rem"} />
                <Flex
                  justifyContent={{ base: "center", md: "space-between" }}
                  alignItems={{ base: "flex-start" }}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Flex
                    flexDirection={"row"}
                    ml={{ base: "0", md: "2.5rem" }}
                    w={{ base: "100%", md: "80%" }}
                  >
                    <PosLable
                      label={true}
                      fontWeight={500}
                      requiredLabel={true}
                      name={"Collect Tips"}
                    />
                      <PosSwitchButton
                        id="is_tip_enable"
                        switchValue={formik.values.is_tip_enable}
                        onChange={formik.handleChange}
                        mt={"2rem"}
                        ml={"1.5rem"}
                      />
                  </Flex>
                </Flex>
                <Divider borderColor="#E6E6E6" mt={"1rem"} />
                <Box display={"flex"}>
                  <Box
                    ml={{ base: "0", md: "2.5rem" }}
                    w={{ base: "100%", md: "40%" }}
                  >
                    <PosLable
                      label={true}
                      fontWeight={500}
                      name={"Delivery Zipcodes"}
                    />
                    <PosInput
                      id="zipcode"
                      placeholder={"Enter Zipcode"}
                      handleInputChange={formik.handleChange}
                      inputValue={formik.values.zipcode}
                      handleBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                      posNumberInput={true}
                      inputType={"number"}
                      inputError={
                        formik.touched.zipcode && formik.errors.zipcode
                      }
                    />
                    <Text color={Colors.errorColor}>
                      {formik.errors.zipcode ? (
                        <span>{formik.errors.zipcode}</span>
                      ) : null}
                    </Text>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={
                      formik.errors?.zipcode?.length > 0 ? "center" : "flex-end"
                    }
                    justifyContent={"center"}
                    w={"3rem"}
                    ml={"1rem"}
                  >
                    <IconButton
                      onClick={handleBarcodeClick}
                      sx={{
                        marginTop:
                          formik.errors?.zipcode?.length > 0 ? "2rem" : 0,
                      }}
                      isRound={true}
                      isDisabled={
                        formik.values?.zipcode?.length <= 0 ||
                        formik.errors?.zipcode?.length > 0
                          ? true
                          : false
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
                {additionalZipcodes.length > 0 && (
                  <Box ml={{ base: "0", md: "2.5rem" }} mt={3}>
                    <Flex
                      justifyContent={{ base: "center", md: "space-between" }}
                      alignItems={{ base: "flex-start" }}
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      <Box w={{ base: "100%", md: "45%" }}>
                        <DataTable value={additionalZipcodes}>
                          <Column
                            field="field_name"
                            header="Zipcode"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "80%",
                            }}
                            body={(rowData) =>
                              rowData.zipcode ? rowData.zipcode : "N.A"
                            }
                          />
                          <Column
                            field="delete"
                            header="Delete"
                            headerStyle={{
                              backgroundColor: "#5881fe1a",
                              fontWeight: "500",
                              color: "#38128A",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                            }}
                            style={{
                              color: "#010048",
                              fontSize: "0.93rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "normal",
                              letterSpacing: "-0.01rem",
                              width: "80%",
                            }}
                            body={(rowData) => (
                              <DeleteIcon
                                bg={Colors.posCancleButtonRed}
                                onClick={() =>
                                  handleDelete(
                                    additionalZipcodes.indexOf(rowData)
                                  )
                                }
                                sx={{ color: Colors.posCancleButtonRed }}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "0.31rem",
                                  flexShrink: 0,
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          />
                        </DataTable>
                      </Box>
                    </Flex>
                  </Box>
                )}
                <Divider borderColor="#E6E6E6" mt={"2rem"} mb={"2rem"} />
                <Box
                  w={{ base: "100%", md: "43%" }}
                  ml={{ base: "0", md: "2.5rem" }}
                  mb={1}
                >
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.40625rem"
                    letterSpacing="-0.016875rem"
                    color="#010048"
                  >
                    Hours of Operation
                  </Heading>
                  <PosDropDown
                    options={days}
                    width={"100%"}
                    lableAvailable={true}
                    // value={selectedDays}
                    value={formik.values.selectedDays}
                    // onChange={(e) => setSelectedDays(e.value)}
                    onChange={(e) => {
                      formik.setFieldValue("selectedDays", e.value);
                      handleDaysChange(e.value);
                    }}
                    onBlur={(e) => {
                      formik.setFieldTouched("selectedDays");
                      formik.handleBlur(e);
                    }}
                    filter
                    multiSelect={true}
                    optionLabel="label"
                    placeholder="Select days"
                    className="w-full md:w-20rem"
                    maxSelectedLabels={7}
                  />
                  <Text color={Colors.errorColor}>
                    {
                    formik.errors.selectedDays ? (
                      <span>{formik.errors.selectedDays}</span>
                    ) : null}
                  </Text>
                </Box>
                <Stack spacing="2">
                  <Box>
                    <Flex
                      justifyContent={{ base: "center", md: "space-between" }}
                      alignItems={{ base: "flex-start" }}
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      <Box
                        ml={{ base: "0", md: "0" }}
                        w={{ base: "100%", md: "50rem" }}
                      >
                        {formik.values.selectedDays.includes("SUN") && (
                          <Flex
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            flexDirection={{ base: "column", md: "row" }}
                          >
                            <Flex
                              pl={{ base: "0", md: "10" }}
                              pr={{ base: "0", md: "10" }}
                              w={{ base: "100%", md: "10rem" }}
                            >
                              <PosLable
                                label={true}
                                fontWeight={500}
                                name={"Sunday"}
                              />
                            </Flex>
                            <Flex
                              mt={{ base: "0", md: "2rem" }}
                              justifyContent={"flex-start"}
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                              gap={2}
                              width={{ base: "100%", md: "40rem" }}
                            >
                              {inputs.map((item, index) => (
                                <Flex key={index} gap={2}>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="open"
                                      value={item.open}
                                      onChange={(event) =>
                                        handleSundayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleSundayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Opens At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.openError &&
                                      null != item?.openError &&
                                      "" != item?.openError ? (
                                        <span>Please enter open hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="close"
                                      value={item.close}
                                      onChange={(event) =>
                                        handleSundayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleSundayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Closes At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.closeError &&
                                      null != item?.closeError &&
                                      "" != item?.closeError ? (
                                        <span>Please enter close hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <Flex
                                    flexDirection={{
                                      base: "column",
                                      md: "row",
                                    }}
                                    width={{ base: "30%", md: "10rem" }}
                                  >
                                    {index === inputs.length - 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                      >
                                        <IconButton
                                          onClick={() => handleAddInput()}
                                          isRound={true}
                                          isDisabled={
                                            item?.close == null ||
                                            "" == item?.close ||
                                            undefined == item?.close ||
                                            item?.open == null ||
                                            "" == item?.open ||
                                            undefined == item?.open ||
                                            (undefined != item?.closeError &&
                                              null != item?.closeError &&
                                              "" != item?.closeError) ||
                                            (undefined != item?.openError &&
                                              null != item?.openError &&
                                              "" != item?.openError)
                                              ? true
                                              : false
                                          }
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                    {inputs.length > 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                      >
                                        <IconButton
                                          bg={Colors.posCancleButtonRed}
                                          onClick={() =>
                                            handleDeleteInput(index)
                                          }
                                          isRound={true}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                  </Flex>
                                </Flex>
                              ))}
                            </Flex>
                          </Flex>
                        )}
                        {formik.values.selectedDays.includes("MON") && (
                          <Flex
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            flexDirection={{ base: "column", md: "row" }}
                          >
                            <Flex
                              pl={{ base: "0", md: "10" }}
                              pr={{ base: "0", md: "10" }}
                              w={{ base: "100%", md: "10rem" }}
                            >
                              <PosLable
                                label={true}
                                fontWeight={500}
                                name={"Monday"}
                              />
                            </Flex>
                            <Flex
                              mt={{ base: "0", md: "2rem" }}
                              justifyContent={"flex-start"}
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                              gap={2}
                              width={{ base: "100%", md: "40rem" }}
                            >
                              {inputsMonday.map((item, index) => (
                                <Flex key={index} gap={1}>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="open"
                                      value={item.open}
                                      onChange={(event) =>
                                        handleMondayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleMondayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Opens At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.openError &&
                                      null != item?.openError &&
                                      "" != item?.openError ? (
                                        <span>Please enter open hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="close"
                                      value={item.close}
                                      onChange={(event) =>
                                        handleMondayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleMondayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Closes At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.closeError &&
                                      null != item?.closeError &&
                                      "" != item?.closeError ? (
                                        <span>Please enter close hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <Flex
                                    flexDirection={{
                                      base: "column",
                                      md: "row",
                                    }}
                                    width={{ base: "30%", md: "10rem" }}
                                  >
                                    {index === inputsMonday.length - 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                        // ml={"1rem"}
                                      >
                                        <IconButton
                                          onClick={() => handleMondayAddInput()}
                                          isRound={true}
                                          isDisabled={
                                            item?.close == null ||
                                            "" == item?.close ||
                                            undefined == item?.close ||
                                            item?.open == null ||
                                            "" == item?.open ||
                                            undefined == item?.open ||
                                            (undefined != item?.closeError &&
                                              null != item?.closeError &&
                                              "" != item?.closeError) ||
                                            (undefined != item?.openError &&
                                              null != item?.openError &&
                                              "" != item?.openError)
                                              ? true
                                              : false
                                          }
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                    {inputsMonday.length > 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                      >
                                        <IconButton
                                          bg={Colors.posCancleButtonRed}
                                          onClick={() =>
                                            handleMondayDeleteInput(index)
                                          }
                                          isRound={true}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                  </Flex>
                                </Flex>
                              ))}
                            </Flex>
                          </Flex>
                        )}
                        {formik.values.selectedDays.includes("TUE") && (
                          <Flex
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            flexDirection={{ base: "column", md: "row" }}
                          >
                            <Flex
                              pl={{ base: "0", md: "10" }}
                              pr={{ base: "0", md: "10" }}
                              w={{ base: "100%", md: "10rem" }}
                            >
                              <PosLable
                                label={true}
                                fontWeight={500}
                                name={"Tuesday"}
                              />
                            </Flex>
                            <Flex
                              mt={{ base: "0", md: "2rem" }}
                              justifyContent={"flex-start"}
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                              gap={2}
                              width={{ base: "100%", md: "40rem" }}
                            >
                              {inputsTuesday.map((item, index) => (
                                <Flex key={index} gap={1}>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="open"
                                      value={item.open}
                                      onChange={(event) =>
                                        handleTuesdayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleTuesdayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Opens At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.openError &&
                                      null != item?.openError &&
                                      "" != item?.openError ? (
                                        <span>Please enter open hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="close"
                                      value={item.close}
                                      onChange={(event) =>
                                        handleTuesdayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleTuesdayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Closes At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.closeError &&
                                      null != item?.closeError &&
                                      "" != item?.closeError ? (
                                        <span>Please enter close hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <Flex
                                    flexDirection={{
                                      base: "column",
                                      md: "row",
                                    }}
                                    width={{ base: "30%", md: "10rem" }}
                                  >
                                    {index === inputsTuesday.length - 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            handleTuesdayAddInput()
                                          }
                                          isRound={true}
                                          isDisabled={
                                            item?.close == null ||
                                            "" == item?.close ||
                                            undefined == item?.close ||
                                            item?.open == null ||
                                            "" == item?.open ||
                                            undefined == item?.open ||
                                            (undefined != item?.closeError &&
                                              null != item?.closeError &&
                                              "" != item?.closeError) ||
                                            (undefined != item?.openError &&
                                              null != item?.openError &&
                                              "" != item?.openError)
                                              ? true
                                              : false
                                          }
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                    {inputsTuesday.length > 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                      >
                                        <IconButton
                                          bg={Colors.posCancleButtonRed}
                                          onClick={() =>
                                            handleTuesdayDeleteInput(index)
                                          }
                                          isRound={true}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                  </Flex>
                                </Flex>
                              ))}
                            </Flex>
                          </Flex>
                        )}
                        {formik.values.selectedDays.includes("WED") && (
                          <Flex
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            flexDirection={{ base: "column", md: "row" }}
                          >
                            <Flex
                              pl={{ base: "0", md: "10" }}
                              pr={{ base: "0", md: "10" }}
                              w={{ base: "100%", md: "10rem" }}
                            >
                              <PosLable
                                label={true}
                                fontWeight={500}
                                name={"Wednesday"}
                              />
                            </Flex>
                            <Flex
                              mt={{ base: "0", md: "2rem" }}
                              justifyContent={"flex-start"}
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                              gap={2}
                              width={{ base: "100%", md: "40rem" }}
                            >
                              {/* <Stack ml={{ base: "0", md: "2rem" }}> */}
                              {inputsWednesday.map((item, index) => (
                                <Flex key={index} gap={1}>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="open"
                                      value={item.open}
                                      onChange={(event) =>
                                        handleWednesdayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleWednesdayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Opens At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.openError &&
                                      null != item?.openError &&
                                      "" != item?.openError ? (
                                        <span>Please enter open hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="close"
                                      value={item.close}
                                      onChange={(event) =>
                                        handleWednesdayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleWednesdayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Closes At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.closeError &&
                                      null != item?.closeError &&
                                      "" != item?.closeError ? (
                                        <span>Please enter close hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <Flex
                                    flexDirection={{
                                      base: "column",
                                      md: "row",
                                    }}
                                    width={{ base: "30%", md: "10rem" }}
                                  >
                                    {index === inputsWednesday.length - 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                        // ml={"1rem"}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            handleWednesdayAddInput()
                                          }
                                          isRound={true}
                                          isDisabled={
                                            item?.close == null ||
                                            "" == item?.close ||
                                            undefined == item?.close ||
                                            item?.open == null ||
                                            "" == item?.open ||
                                            undefined == item?.open ||
                                            (undefined != item?.closeError &&
                                              null != item?.closeError &&
                                              "" != item?.closeError) ||
                                            (undefined != item?.openError &&
                                              null != item?.openError &&
                                              "" != item?.openError)
                                              ? true
                                              : false
                                          }
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                    {inputsWednesday.length > 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                      >
                                        <IconButton
                                          bg={Colors.posCancleButtonRed}
                                          onClick={() =>
                                            handleWednesdayDeleteInput(index)
                                          }
                                          isRound={true}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                  </Flex>
                                </Flex>
                              ))}
                              {/* </Stack> */}
                            </Flex>
                          </Flex>
                        )}
                        {formik.values.selectedDays.includes("THU") && (
                          <Flex
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            flexDirection={{ base: "column", md: "row" }}
                          >
                            <Flex
                              pl={{ base: "0", md: "10" }}
                              pr={{ base: "0", md: "10" }}
                              w={{ base: "100%", md: "10rem" }}
                            >
                              <PosLable
                                label={true}
                                fontWeight={500}
                                name={"Thursday"}
                              />
                            </Flex>
                            <Flex
                              mt={{ base: "0", md: "2rem" }}
                              justifyContent={"flex-start"}
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                              gap={2}
                              width={{ base: "100%", md: "40rem" }}
                            >
                              {inputsThursday.map((item, index) => (
                                <Flex key={index} gap={1}>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="open"
                                      value={item.open}
                                      onChange={(event) =>
                                        handleThursdayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleThursdayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Opens At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.openError &&
                                      null != item?.openError &&
                                      "" != item?.openError ? (
                                        <span>Please enter open hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="close"
                                      value={item.close}
                                      onChange={(event) =>
                                        handleThursdayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleThursdayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Closes At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.closeError &&
                                      null != item?.closeError &&
                                      "" != item?.closeError ? (
                                        <span>Please enter close hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <Flex
                                    flexDirection={{
                                      base: "column",
                                      md: "row",
                                    }}
                                    width={{ base: "30%", md: "10rem" }}
                                  >
                                    {index === inputsThursday.length - 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                        // ml={"1rem"}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            handleThursdayAddInput()
                                          }
                                          isRound={true}
                                          isDisabled={
                                            item?.close == null ||
                                            "" == item?.close ||
                                            undefined == item?.close ||
                                            item?.open == null ||
                                            "" == item?.open ||
                                            undefined == item?.open ||
                                            (undefined != item?.closeError &&
                                              null != item?.closeError &&
                                              "" != item?.closeError) ||
                                            (undefined != item?.openError &&
                                              null != item?.openError &&
                                              "" != item?.openError)
                                              ? true
                                              : false
                                          }
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                    {inputsThursday.length > 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                      >
                                        <IconButton
                                          bg={Colors.posCancleButtonRed}
                                          onClick={() =>
                                            handleThursdayDeleteInput(index)
                                          }
                                          isRound={true}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                  </Flex>
                                </Flex>
                              ))}
                            </Flex>
                          </Flex>
                        )}
                        {formik.values.selectedDays.includes("FRI") && (
                          <Flex
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            flexDirection={{ base: "column", md: "row" }}
                          >
                            <Flex
                              pl={{ base: "0", md: "10" }}
                              pr={{ base: "0", md: "10" }}
                              w={{ base: "100%", md: "10rem" }}
                            >
                              <PosLable
                                label={true}
                                fontWeight={500}
                                name={"Friday"}
                              />
                            </Flex>
                            <Flex
                              mt={{ base: "0", md: "2rem" }}
                              justifyContent={"flex-start"}
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                              gap={2}
                              width={{ base: "100%", md: "40rem" }}
                            >
                              {/* <Stack ml={{ base: "0", md: "2rem" }}> */}
                              {inputsFriday.map((item, index) => (
                                <Flex key={index} gap={1}>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="open"
                                      value={item.open}
                                      onChange={(event) =>
                                        handleFridayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleFridayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Opens At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.openError &&
                                      null != item?.openError &&
                                      "" != item?.openError ? (
                                        <span>Please enter open hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="close"
                                      value={item.close}
                                      onChange={(event) =>
                                        handleFridayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleFridayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Closes At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.closeError &&
                                      null != item?.closeError &&
                                      "" != item?.closeError ? (
                                        <span>Please enter close hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <Flex
                                    flexDirection={{
                                      base: "column",
                                      md: "row",
                                    }}
                                    width={{ base: "30%", md: "10rem" }}
                                  >
                                    {index === inputsFriday.length - 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                        // ml={"1rem"}
                                      >
                                        <IconButton
                                          onClick={() => handleFridayAddInput()}
                                          isRound={true}
                                          isDisabled={
                                            item?.close == null ||
                                            "" == item?.close ||
                                            undefined == item?.close ||
                                            item?.open == null ||
                                            "" == item?.open ||
                                            undefined == item?.open ||
                                            (undefined != item?.closeError &&
                                              null != item?.closeError &&
                                              "" != item?.closeError) ||
                                            (undefined != item?.openError &&
                                              null != item?.openError &&
                                              "" != item?.openError)
                                              ? true
                                              : false
                                          }
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                    {inputsFriday.length > 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                      >
                                        <IconButton
                                          bg={Colors.posCancleButtonRed}
                                          onClick={() =>
                                            handleFridayDeleteInput(index)
                                          }
                                          isRound={true}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                  </Flex>
                                </Flex>
                              ))}
                              {/* </Stack> */}
                            </Flex>
                          </Flex>
                        )}
                        {formik.values.selectedDays.includes("SAT") && (
                          <Flex
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                            flexDirection={{ base: "column", md: "row" }}
                          >
                            <Flex
                              pl={{ base: "0", md: "10" }}
                              pr={{ base: "0", md: "10" }}
                              w={{ base: "100%", md: "10rem" }}
                            >
                              <PosLable
                                label={true}
                                fontWeight={500}
                                name={"Saturday"}
                              />
                            </Flex>
                            <Flex
                              mt={{ base: "0", md: "2rem" }}
                              justifyContent={"flex-start"}
                              alignItems={"flex-start"}
                              flexDirection={"column"}
                              gap={2}
                              width={{ base: "100%", md: "40rem" }}
                            >
                              {/* <Stack ml={{ base: "0", md: "2rem" }}> */}
                              {inputsSaturday.map((item, index) => (
                                <Flex key={index} gap={1}>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="open"
                                      value={item.open}
                                      onChange={(event) =>
                                        handleSaturdayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleSaturdayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Opens At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.openError &&
                                      null != item?.openError &&
                                      "" != item?.openError ? (
                                        <span>Please enter open hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <VStack
                                    gap={1}
                                    width={{ base: "100%", md: "15rem" }}
                                  >
                                    <Calendar
                                      name="close"
                                      value={item.close}
                                      onChange={(event) =>
                                        handleSaturdayChange(event, index)
                                      }
                                      onBlur={(event) =>
                                        handleSaturdayBlur(event, index)
                                      }
                                      timeOnly={true} // Set timeOnly property to true
                                      hourFormat="12" // Specify hour format (12 or 24)
                                      inputId="timeonly" // Specify input ID
                                      placeholder="Closes At" // Specify placeholder text
                                    />
                                    <Text color={Colors.errorColor}>
                                      {undefined != item?.closeError &&
                                      null != item?.closeError &&
                                      "" != item?.closeError ? (
                                        <span>Please enter close hour.</span>
                                      ) : null}
                                    </Text>
                                  </VStack>
                                  <Flex
                                    flexDirection={{
                                      base: "column",
                                      md: "row",
                                    }}
                                    width={{ base: "30%", md: "10rem" }}
                                  >
                                    {index === inputsSaturday.length - 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                        // ml={"1rem"}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            handleSaturdayAddInput()
                                          }
                                          isRound={true}
                                          isDisabled={
                                            item?.close == null ||
                                            "" == item?.close ||
                                            undefined == item?.close ||
                                            item?.open == null ||
                                            "" == item?.open ||
                                            undefined == item?.open ||
                                            (undefined != item?.closeError &&
                                              null != item?.closeError &&
                                              "" != item?.closeError) ||
                                            (undefined != item?.openError &&
                                              null != item?.openError &&
                                              "" != item?.openError)
                                              ? true
                                              : false
                                          }
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                    {inputsSaturday.length > 1 && (
                                      <Box
                                        display={"flex"}
                                        justifyContent={"center"}
                                        w={"3rem"}
                                      >
                                        <IconButton
                                          bg={Colors.posCancleButtonRed}
                                          onClick={() =>
                                            handleSaturdayDeleteInput(index)
                                          }
                                          isRound={true}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    )}
                                  </Flex>
                                </Flex>
                              ))}
                              {/* </Stack> */}
                            </Flex>
                          </Flex>
                        )}
                      </Box>
                    </Flex>
                  </Box>
                </Stack>
                <Divider borderColor="#E6E6E6" mt={"2rem"} mb={"2rem"} />
                <Box
                  w={{ base: "100%", md: "95%" }}
                  ml={{ base: "0", md: "2.5rem" }}
                  mb={1}
                >
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.40625rem"
                    letterSpacing="-0.016875rem"
                    color="#010048"
                  >
                    Manage Online Ordering dates
                  </Heading>
                  <Text
                    mt={"0.1rem"}
                    h={"1.44rem"}
                    color={Colors.taxCreateExemptEbt}
                    style={{
                      flexShrink: "0",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "normal",
                      letterSpacing: "-0.18px",
                    }}
                  >
                    Please select restricted dates for online ordering
                  </Text>
                  <Box mt={2} display={"flex"}>
                    <Box w={{ base: "100%", md: "20%" }}>
                      <PosDatePicker
                        label={"Select Restricted Date"}
                        // handleSelectData={(e) => setDate(e.value)}
                        id="rDate"
                        value={formik.values.rDate}
                        ref={calendarRef}
                        onFocus={() => {
                          formik.setFieldTouched("rDate", true);
                        }}
                        handleSelectData={(e) => {
                          formik.setFieldValue("rDate", e.value);
                        }}
                        yearRange={"2000:2030"}
                        minDate={dateTimeValue}
                        showButtonBar={true}
                      />
                      <Text color={Colors.errorColor}>
                        {formik.touched.rDate && formik.errors.rDate ? (
                          <span>{formik.errors.rDate}</span>
                        ) : null}
                      </Text>
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      w={"3rem"}
                      ml={"1rem"}
                    >
                      <IconButton
                        onClick={handleRestrictedClick}
                        isRound={true}
                        isDisabled={
                          formik.values.rDate == null ||
                          "" == formik.values.rDate ||
                          formik.errors?.rDate?.length > 0
                            ? true
                            : false
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  {restrictedDates.length > 0 && (
                    <Box mt={3} w={{ base: "100%", md: "46%" }}>
                      <DataTable value={restrictedDates}>
                        <Column
                          field="field_name"
                          header="Restricted Date"
                          headerStyle={{
                            backgroundColor: "#5881fe1a",
                            fontWeight: "500",
                            color: "#38128A",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                          style={{
                            color: "#010048",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            fontWeight: "300",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                            width: "80%",
                          }}
                          body={(rowData) =>
                            rowData.date
                              ? moment(rowData.date)
                                  .format("dddd, Do MMM, YYYY")
                                  .toString()
                              : "N.A"
                          }
                        />
                        <Column
                          field="delete"
                          header="Delete"
                          headerStyle={{
                            backgroundColor: "#5881fe1a",
                            fontWeight: "500",
                            color: "#38128A",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                          }}
                          style={{
                            color: "#010048",
                            fontSize: "0.93rem",
                            fontStyle: "normal",
                            fontWeight: "300",
                            lineHeight: "normal",
                            letterSpacing: "-0.01rem",
                            width: "80%",
                          }}
                          body={(rowData) => (
                            // <Button
                            //   p={2.5}
                            //   w={20}
                            //   h={8}
                            //   bg={Colors.posCancleButtonRed}
                            //   style={{
                            //     display: "flex",
                            //     justifyContent: "center",
                            //     alignItems: "center",
                            //     gap: "0.31rem",
                            //     flexShrink: 0,
                            //   }}
                            //   onClick={() =>
                            //     handleDeleteRDates(
                            //       restrictedDates.indexOf(rowData)
                            //     )
                            //   }
                            // >
                            //   <DeleteIcon style={{ fontSize: 13 }} />
                            //   Delete
                            // </Button>
                            <DeleteIcon
                              bg={Colors.posCancleButtonRed}
                              onClick={() =>
                                handleDeleteRDates(
                                  restrictedDates.indexOf(rowData)
                                )
                              }
                              sx={{ color: Colors.posCancleButtonRed }}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "0.31rem",
                                flexShrink: 0,
                                cursor: "pointer",
                              }}
                            />
                          )}
                        />
                      </DataTable>
                    </Box>
                  )}
                </Box>
                <Stack spacing="2">
                  <Box>
                    <Flex
                      justifyContent={{ base: "center", md: "space-between" }}
                      alignItems={{ base: "flex-start" }}
                      flexDirection={{ base: "column", md: "row" }}
                    ></Flex>
                  </Box>
                </Stack>
                <Divider borderColor="#E6E6E6" mt={"2rem"} mb={"2rem"} />
                <Box
                  w={{ base: "100%", md: "95%" }}
                  ml={{ base: "0", md: "2.5rem" }}
                  mb={1}
                >
                  <Heading
                    as="h3"
                    size="xs"
                    fontWeight="500"
                    fontSize="1.125rem"
                    lineHeight="1.40625rem"
                    letterSpacing="-0.016875rem"
                    color="#010048"
                  >
                    Payment Integration
                  </Heading>
                </Box>
                <Stack spacing="2">
                  <Box>
                    <Flex
                      justifyContent={{ base: "center", md: "space-between" }}
                      alignItems={{ base: "flex-start" }}
                      flexDirection={{ base: "column", md: "row" }}
                    >
                      <Box
                        w={{ base: "100%", md: "44%" }}
                        ml={{ base: "0", md: "2.5rem" }}
                      >
                        <PosLable
                          label={true}
                          fontWeight={500}
                          name={"Login ID"}
                        />
                        <InputGroup size="md">
                          <Input
                            mt={2}
                            pr="4.5rem"
                            name="merchant_login_id"
                            id="merchant_login_id"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.merchant_login_id}
                            type={showLogin ? "text" : "password"}
                            placeholder="Enter Merchant Login Id"
                          />
                          <InputRightElement mt={2} width="4.5rem">
                            {showLogin ? (
                              <VisibilityOffIcon
                                bg={Colors.posCancleButtonRed}
                                // onClick={handleClick}
                                onClick={() => handleClick()}
                                // sx={{ color: Colors.posCancleButtonRed }}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "0.31rem",
                                  flexShrink: 0,
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <VisibilityIcon
                                bg={Colors.posCancleButtonRed}
                                // onClick={handleClick}
                                onClick={() => handleClick()}
                                // sx={{ color: Colors.posCancleButtonRed }}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "0.31rem",
                                  flexShrink: 0,
                                  cursor: "pointer",
                                }}
                              />
                            )}
                            {/* <Button
                                h="1.75rem"
                                size="sm"
                                onClick={handleClick}
                              >
                                {showLogin ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </Button> */}
                            {/* <PosOutlineButton
                                    onClick={handleClick}
                                    name={showLogin ? (
                                      <VisibilityOffIcon />
                                    ) : (
                                      <VisibilityIcon />
                                    )}
                                  >
                                  </PosOutlineButton> */}
                          </InputRightElement>
                        </InputGroup>
                        <PosLable
                          label={true}
                          fontWeight={500}
                          name={"Transaction key"}
                        />
                        <InputGroup size="md">
                          <Input
                            mt={2}
                            pr="4.5rem"
                            name="merchant_transaction_key"
                            id="merchant_transaction_key"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.merchant_transaction_key}
                            type={showKey ? "text" : "password"}
                            placeholder="Enter Merchant Transaction key"
                          />
                          <InputRightElement mt={2} width="4.5rem">
                            {showKey ? (
                              <VisibilityOffIcon
                                bg={Colors.posCancleButtonRed}
                                onClick={() => handleClickMerchant()}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "0.31rem",
                                  flexShrink: 0,
                                  cursor: "pointer",
                                }}
                              />
                            ) : (
                              <VisibilityIcon
                                bg={Colors.posCancleButtonRed}
                                onClick={() => handleClickMerchant()}
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "0.31rem",
                                  flexShrink: 0,
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </InputRightElement>
                        </InputGroup>
                      </Box>
                    </Flex>
                  </Box>
                </Stack>
                {/* </Box>
              </Flex> */}
                {/* </Box> */}
              </Stack>
            )}

            {/* <Flex
              minWidth="max-content"
              gap="2"
              mt={{ base: "4.72rem", md: "4.72rem" }}
            >
              <Spacer />
              <ButtonGroup>
                <PosFormButton
                  onClick={() => {
                    handleClose();
                  }}
                  buttonText={"Cancel"}
                  CancelButton={true}
                />
                <PosFormButton
                  isDisabled={
                    buttonDisable ||
                    checkvalid > -1 ||
                    inputvalid > -1 ||
                    checkvalidMonday > -1 ||
                    inputvalidMonday > -1 ||
                    checkvalidTuesday > -1 ||
                    inputvalidTuesday > -1 ||
                    checkvalidWednesday > -1 ||
                    inputvalidWednesday > -1 ||
                    checkvalidThursday > -1 ||
                    inputvalidThursday > -1 ||
                    checkvalidFriday > -1 ||
                    inputvalidFriday > -1 ||
                    checkvalidSaturday > -1 ||
                    inputvalidSaturday > -1 ||
                    (formik.values.online_order == true &&
                      formik.values.online_order_types.length <= 0) ||
                    !formik.isValid
                      ? true
                      : false
                  }
                  buttonsubmit={"Save Changes"}
                  SubmitButton={true}
                  onClick={formik.handleSubmit}
                />
              </ButtonGroup>
            </Flex> */}
          </CardBody>
        </Card>
      </Box>
    </PosDrawer>
  );
};
export default WithRouter(LocationOnlineOrderSettings);
