import {
  Box,
  Divider,
  Flex,
  Heading,
  Spacer,
  Stack,
  StackDivider,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocalGroceryStoreRoundedIcon from "@mui/icons-material/LocalGroceryStoreRounded";
import NavigateNextRoundedIcon from "@mui/icons-material/NavigateNextRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import SellRoundedIcon from "@mui/icons-material/SellRounded";
import StorageRoundedIcon from "@mui/icons-material/StorageRounded";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActionData, useParams, useSubmit } from "react-router-dom";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
import LocationAccountSettings from "./LocationAccountSettings";
import LocationEmailSettings from "./LocationEmailSettings";
import LocationOnlineOrderSettings from "./LocationOnlineOrderSettings";
import LocationPricing from "./LocationPricing";
import LocationReceiptSettings from "./LocationReceiptSettings";
import LocationReward from "./LocationReward";

const LocationSettingOptions = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const submit = useSubmit();
  const actionResponse = useActionData();
  const actionRun = useRef(false);
  const [state, setState] = useState({ formData: {} });
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [roles, setRoles] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPricingOpen,
    onOpen: onPricingOpen,
    onClose: onPricingClose,
  } = useDisclosure();

  const {
    isOpen: isRewardOpen,
    onOpen: onRewardOpen,
    onClose: onRewardClose,
  } = useDisclosure();

  const {
    isOpen: isReceiptOpen,
    onOpen: onReceiptOpen,
    onClose: onReceiptClose,
  } = useDisclosure();

  const {
    isOpen: isEmailOpen,
    onOpen: onEmailOpen,
    onClose: onEmailClose,
  } = useDisclosure();

  const {
    isOpen: isOnlineOrderOpen,
    onOpen: onOnlineOrderOpen,
    onClose: onOnlineOrderClose,
  } = useDisclosure();

  const btnRef = React.useRef();
  const { id } = useParams();

  useEffect(() => {
    myContext.handleLoading(false);
    // fetchRoles();
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else {
        props.navigate(Constants.HR_EMPLOYEE_PATH);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.LOCATION_MODULE,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [actionResponse]);

  return (
    <Box padding={4}>
      <LocationAccountSettings
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        onOpen={onOpen}
        finalFocusRef={btnRef}
      />
      <LocationPricing
        isPricingOpen={isPricingOpen}
        placement="right"
        onPricingClose={onPricingClose}
        onPricingOpen={onPricingOpen}
        finalFocusRef={btnRef}
      />
      <LocationReward
        isRewardOpen={isRewardOpen}
        placement="right"
        onRewardClose={onRewardClose}
        onRewardOpen={onRewardOpen}
        finalFocusRef={btnRef}
      />
      <LocationReceiptSettings
        isReceiptOpen={isReceiptOpen}
        placement="right"
        onReceiptClose={onReceiptClose}
        onReceiptOpen={onReceiptOpen}
        finalFocusRef={btnRef}
      />
      <LocationEmailSettings
        isEmailOpen={isEmailOpen}
        placement="right"
        onEmailClose={onEmailClose}
        onEmailOpen={onEmailOpen}
        finalFocusRef={btnRef}
      />
      <LocationOnlineOrderSettings
        isOnlineOrderOpen={isOnlineOrderOpen}
        placement="right"
        onOnlineOrderClose={onOnlineOrderClose}
        onOnlineOrderOpen={onOnlineOrderOpen}
        finalFocusRef={btnRef}
      />
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        /* pl={"1.88rem"} */
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        width={{ base: "100%", md: "95%" }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            props.navigate(Constants.ESTABLISHMENT_LOCATION_PATH);
          }}
          breadCrumNames={["Locations", "Location Settings"]}
          breadCrumTitle={"Location Settings"}
        />
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            onClick={() => {
              props.navigate(Constants.ESTABLISHMENT_LOCATION_PATH);
            }}
            buttonText={"Cancel"}
            CancelButton={true}
            isDisabled={buttonDisable}
          />
        </Stack>
      </Flex>
      <Divider
        borderWidth={"0.8px"}
        borderColor={Colors.dividerColor}
        mt={{ base: 0, md: 8}}
        width={{ base: "100%", md: "95%" }}
      />
      <Stack
        ml={{ base: 0, md: 21 }}
        divider={
          <StackDivider
            borderWidth={"0.8px"}
            width={{ base: "100%", md: "85%" }}
          />
        }
        spacing="2"
      >
        <Box style={{ cursor: "pointer" }} onClick={onOpen}>
          <Flex
            mt={6}
            mb={3}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={"row"}
          >
            <Box ml={3} mt={3}>
              <DriveFileRenameOutlineRoundedIcon style={{ fill: "#5881FE" }} />
            </Box>
            <Box w={{ base: "85%", md: "95%" }} ml={6}>
              <Heading
                as="h3"
                size="xs"
                fontWeight="500"
                fontSize="1.125rem"
                lineHeight="1.40625rem"
                letterSpacing="-0.016875rem"
                color="#010048"
              >
                Account Settings
              </Heading>
              <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                Customize operations and payment settings details
              </Text>
            </Box>
            {/* <Spacer /> */}
            <Box
              alignItems={{ base: "flex-end" }}
              w={{ base: "15%", md: "25%" }}
            >
              <NavigateNextRoundedIcon fontSize="large" />
            </Box>
          </Flex>
        </Box>
        <Box style={{ cursor: "pointer" }} onClick={onPricingOpen}>
          <Flex
            mt={3}
            mb={3}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            // flexDirection={{ base: "column", md: "row" }}
            flexDirection={"row"}
          >
            <Box ml={3} mt={3}>
              <SellRoundedIcon pt={3} style={{ fill: "#5881FE" }} />
            </Box>
            <Box w={{ base: "85%", md: "95%" }} ml={6}>
              <Heading
                as="h3"
                size="xs"
                fontWeight="500"
                fontSize="1.125rem"
                lineHeight="1.40625rem"
                letterSpacing="-0.016875rem"
                color="#010048"
              >
                Pricing Template
              </Heading>
              <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                Choose the appearance of your pricing cards
              </Text>
            </Box>
            {/* <Spacer /> */}
            <Box
              alignItems={{ base: "flex-end" }}
              w={{ base: "15%", md: "25%" }}
            >
              <NavigateNextRoundedIcon fontSize="large" />
            </Box>
          </Flex>
        </Box>
        <Box style={{ cursor: "pointer" }} onClick={onRewardOpen}>
          <Flex
            mt={3}
            mb={3}
            // mr={300}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            // flexDirection={{ base: "column", md: "row" }}
            flexDirection={"row"}
          >
            <Box ml={3} mt={3}>
              <StorageRoundedIcon pt={3} style={{ fill: "#5881FE" }} />
            </Box>
            <Box w={{ base: "85%", md: "95%" }} ml={6}>
              <Heading
                as="h3"
                size="xs"
                fontWeight="500"
                fontSize="1.125rem"
                lineHeight="1.40625rem"
                letterSpacing="-0.016875rem"
                color="#010048"
              >
                Rewards
              </Heading>
              <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                Manage and Customize reward settings
              </Text>
            </Box>
            {/* <Spacer /> */}
            <Box
              alignItems={{ base: "flex-end" }}
              w={{ base: "15%", md: "25%" }}
            >
              <NavigateNextRoundedIcon fontSize="large" />
            </Box>
          </Flex>
        </Box>
        <Box style={{ cursor: "pointer" }} onClick={onReceiptOpen}>
          <Flex
            mt={3}
            mb={3}
            // mr={300}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={"row"}
          >
            <Box ml={3} mt={3}>
              <ReceiptLongRoundedIcon pt={3} style={{ fill: "#5881FE" }} />
            </Box>
            <Box w={{ base: "85%", md: "95%" }} ml={6}>
              <Heading
                as="h3"
                size="xs"
                fontWeight="500"
                fontSize="1.125rem"
                lineHeight="1.40625rem"
                letterSpacing="-0.016875rem"
                color="#010048"
              >
                Receipts
              </Heading>
              <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                Manage and Customize receipt settings
              </Text>
            </Box>
            {/* <Spacer /> */}
            <Box
              alignItems={{ base: "flex-end" }}
              w={{ base: "15%", md: "25%" }}
            >
              <NavigateNextRoundedIcon fontSize="large" />
            </Box>
          </Flex>
        </Box>
        <Box style={{ cursor: "pointer" }} onClick={onOnlineOrderOpen}>
          <Flex
            mt={3}
            mb={3}
            // mr={300}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={"row"}
          >
            <Box ml={3} mt={3}>
              <LocalGroceryStoreRoundedIcon
                pt={3}
                style={{ fill: "#5881FE" }}
              />
            </Box>
            <Box w={{ base: "85%", md: "95%" }} ml={6}>
              <Heading
                as="h3"
                size="xs"
                fontWeight="500"
                fontSize="1.125rem"
                lineHeight="1.40625rem"
                letterSpacing="-0.016875rem"
                color="#010048"
              >
                Online Orders
              </Heading>
              <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                Manage and customize your online ordering settings
              </Text>
            </Box>
            <Box
              alignItems={{ base: "flex-end" }}
              w={{ base: "15%", md: "25%" }}
            >
              <NavigateNextRoundedIcon fontSize="large" />
            </Box>
          </Flex>
        </Box>
        <Box style={{ cursor: "pointer" }} onClick={onEmailOpen}>
          <Flex
            mt={3}
            mb={3}
            justifyContent={{ base: "center", md: "space-between" }}
            alignItems={{ base: "flex-start" }}
            flexDirection={"row"}
          >
            <Box ml={3} mt={3}>
              <EmailRoundedIcon pt={3} style={{ fill: "#5881FE" }} />
            </Box>
            <Box w={{ base: "85%", md: "95%" }} ml={6}>
              <Heading
                as="h3"
                size="xs"
                fontWeight="500"
                fontSize="1.125rem"
                lineHeight="1.40625rem"
                letterSpacing="-0.016875rem"
                color="#010048"
              >
                Summary Emails
              </Heading>
              <Text color="rgba(1, 0, 72, 0.6)" pt="2" fontSize="xs">
                Manage and customize your email reporting settings
              </Text>
            </Box>
            <Box
              alignItems={{ base: "flex-end" }}
              w={{ base: "15%", md: "25%" }}
            >
              <NavigateNextRoundedIcon fontSize="large" />
            </Box>
          </Flex>
        </Box>
      </Stack>
      <Flex
        minWidth="max-content"
        gap="2"
        mt={{ base: "4.72rem", md: "4.72rem" }}
      >
        <Spacer />
      </Flex>
      {/* </CardBody>
      </Card> */}
    </Box>
  );
};
export default WithRouter(LocationSettingOptions);
