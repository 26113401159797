import axios from "axios";
import * as Constants from "../../../constants/Constants";
import { refreshMasterAdminToken } from "../MasterAdminService";

export function getAllGlobalSettingMasterAdmin(payload) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    await axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GLOBAL_SETTING_LIST_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GLOBAL_SETTING_LIST_PERISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addGlobalSettingCategory(request) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    await axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.ADD_GLOBAL_SETTING_CATEGORY_API,
        request,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GLOBAL_SETTING_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function globalSettingCreateMasterAdmin({ request }) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    let formData = await request.formData();
    const payload = {
      setting_display_name: Object.fromEntries(formData).setting_display_name,
      setting_key: Object.fromEntries(formData).setting_key,
      input_type: Object.fromEntries(formData).input_type,
      field_sort: Object.fromEntries(formData).field_sort,
      required: Object.fromEntries(formData).required,
      options: Object.fromEntries(formData).hasOwnProperty("options")
        ? JSON.parse(Object.fromEntries(formData).options)
        : [],
      category: Object.fromEntries(formData).hasOwnProperty("category")
        ? JSON.parse(Object.fromEntries(formData).category)
        : [],
    };
    await axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GLOBAL_SETTING_CREATE_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GLOBAL_SETTING_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getGlobalSettingsCategory() {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    await axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GLOBAL_SETTING_CATEGORY_GET_API,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GLOBAL_SETTING_CREATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getGlobalSettingsById({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    const id = params.id;
    await axios
      .get(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GLOBAL_SETTING_EDIT_API +
          id,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GLOBAL_SETTING_EDIT_PERISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function globalSettingUpdateMasterAdmin({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    const id = params.id;
    let formData = await request.formData();
    const payload = {
      setting_display_name: Object.fromEntries(formData).setting_display_name,
      setting_key: Object.fromEntries(formData).setting_key,
      input_type: Object.fromEntries(formData).input_type,
      field_sort: Object.fromEntries(formData).field_sort,
      required: Object.fromEntries(formData).required,
      global_setting_options: Object.fromEntries(formData).hasOwnProperty(
        "options"
      )
        ? JSON.parse(Object.fromEntries(formData).options)
        : [],
      category: Object.fromEntries(formData).hasOwnProperty("category")
        ? JSON.parse(Object.fromEntries(formData).category)
        : [],
    };
    await axios
      .put(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GLOBAL_SETTING_UPDATE_API +
          `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GLOBAL_SETTING_EDIT_PERISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function globalSettingDeactiveReactiveMasterAdmin({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      await axios
        .delete(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.GLOBAL_SETTING_DEACTIVATE_API +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.GLOBAL_SETTING_DEACTIVATE_PERISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      await axios
        .get(
          Constants.REACT_APP_API_URL +
            process.env.REACT_APP_API_VERSION +
            Constants.GLOBAL_SETTING_DEACTIVATE_REACTIVE_API +
            "/" +
            id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.GLOBAL_SETTING_REACTIVATE_PERISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getAllDeactiveGlobalSettingsMasterAdmin(payload) {
  return new Promise(async (resolve, reject) => {
    let ttltimeCheck = await refreshMasterAdminToken();
    let token = localStorage.getItem("master-token");
    await axios
      .post(
        Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.GLOBAL_SETTING_DEACTIVATE_LIST_API,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.GLOBAL_SETTING_DEACTIVATE_PERISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
