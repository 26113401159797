import {
  Box,
  Button,
  Flex,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  useActionData,
  useLoaderData,
  useParams,
  useSubmit,
} from "react-router-dom";
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import {
  AppContext,
  PosInput,
  PosLable,
  PosTostMessage,
  PosListHeader
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";
const VerifyEmail = (props) => {
  const myContext = useContext(AppContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { id } = useParams();
  const [updateFlag, setUpdateFlag] = useState(0);
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const actionRun = useRef(false);
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          setUpdateFlag();
          if (loaderResponse?.data?.data?.data !== "") {
            setUpdateFlag(1);
          } else {
            setUpdateFlag(0);
          }
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.PRODUCT_TARE_PATH);
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: "Verify-Email",
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);
  const formik = useFormik({
    initialValues: {
      sender_name:
        undefined != loaderResponse?.data?.data?.data.sender_name &&
        null != loaderResponse?.data?.data?.data.sender_name
          ? loaderResponse?.data?.data?.data.sender_name
          : "",
      email:
        undefined != loaderResponse?.data?.data?.data.email &&
        null != loaderResponse?.data?.data?.data.email
          ? loaderResponse?.data?.data?.data.email
          : "",
    },
    validationSchema: Yup.object({
      sender_name: Yup.string().max(
        191,
        Constants.TAX_NAME_LENGTH_GREATE_THEN_191
      ),
      email: Yup.string()
        .trim()
        .matches(Constants.EMAIL_REGEX, Constants.USER_EMAIL_NOT_VALID)
        .required(Constants.USER_EMAIL_REQUIRED)
        .email(Constants.USER_EMAIL_NOT_VALID)
        .max(199, Constants.USER_EMAIL_LESS_THEN_OR_EQUAL_255_CHARACTER),
    }),
    onSubmit: (values) => {
      setButtonDisable(true);
      myContext.handleLoading(true);
      let path = Constants.MARKETING_VERIFY_EMAIL_PATH;
      actionRun.current = true;
      values.updateFlag = updateFlag;
      submit(values, {
        method: Constants.POST,
        path: path,
      });
    },
  });
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        // formik.values.sender_name.length <= 0 ||
        (formik.errors.sender_name || formik.errors.email || buttonDisable)
      )
    ) {
      formik.handleSubmit();
    }
  };
  return (
    <Box padding={{ base: 4, sm: 6, md: 10, lg: 14 }}>
      <Flex direction={{ base: "column", md: "row" }}
        alignItems={{ base: "flex-start", md: "center" }}
        justify="space-between"
        pb={{ base: 6, md: 16 }}>
           <PosListHeader
          header={" Verify an Email"}
          discription={"Verify your email to send an email through the OctoPos system"}
        />
      </Flex>
        <Box
           p={4}
           borderRadius="0.63rem"
           bg="white"
           boxShadow={"0px 0.25rem 0.5rem" + Colors.listShadow}
        >
          <Stack spacing={1}>
            <PosLable fontWeight={500} label={true} name={"Sender Name"} />
            <PosInput
              id="sender_name"
              placeholder={"Enter a Name"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.sender_name}
              handleBlur={(e) => {
                formik.setFieldValue(
                  "sender_name",
                  formik.values.sender_name.trim()
                );
                formik.handleBlur(e);
              }}
              onKeyDown={handleKeyDown}
              posInputGroup={true}
              inputType={"text"}
              inputError={
                formik.touched.sender_name && formik.errors.sender_name
              }
            >
              <DriveFileRenameOutlineIcon
                style={{ fill: Colors.primeTaxInfo }}
              />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.sender_name && formik.errors.sender_name ? (
                <span>{formik.errors.sender_name}</span>
              ) : null}
            </Text>
          </Stack>
          <Stack spacing={1}>
            <PosLable
              color={Colors.taxInfo}
              fontWeight={500}
              requiredLabel={true}
              name={"Email Address"}
            />
            <PosInput
              posInputGroup={true}
              id={"email"}
              inputType={"email"}
              placeholder={"Enter an Email Address"}
              handleInputChange={formik.handleChange}
              inputValue={formik.values.email}
              handleBlur={(e) => {
                formik.setFieldValue("email", formik.values.email.trim());
                formik.handleBlur(e);
              }}
              inputError={formik.touched.email && formik.errors.email}
            >
              <DriveFileRenameOutlineIcon
                style={{ fill: Colors.primeTaxInfo }}
              />
            </PosInput>
            <Text color={Colors.errorColor}>
              {formik.touched.email && formik.errors.email ? (
                <span>{formik.errors.email}</span>
              ) : null}
            </Text>
          </Stack>
          <Button
            mt={6}
            isDisabled={
              //   formik.values.sender_name.length <= 0 ||
              formik.values.email.length <= 0 ||
              formik.errors.sender_name ||
              formik.errors.email ||
              buttonDisable
                ? true
                : false
            }
            onClick={formik.handleSubmit}
          >
            {updateFlag === 1 ? "Update" : "Submit"}
          </Button>
          <UnorderedList mt={6}>
            <ListItem>
              We highly recommend that you get your own domain with an email
              address. This improves the email deliverability for your email
              campaigns. Public emails such as gmail.com will have higher
              chances of getting flagged as spam.
            </ListItem>
            <ListItem mt={1}>
              If you need help getting your own domain and email, you can see
              this video guide:{" "}
              <Link
                color={Colors.primeTaxInfo}
                href="https://www.youtube.com/watch?v=lMJOIhnvaQo"
                isExternal
              >
                https://www.youtube.com/watch?v=lMJOIhnvaQo
              </Link>
            </ListItem>
            <ListItem mt={1}>
              Once you have your business domain setup, you can contact our team
              to verify your domain. This will send your marketing emails from
              your domain and improve deliverability to your customers.
            </ListItem>
          </UnorderedList>
        </Box>
     
    </Box>
  );
};

export default WithRouter(VerifyEmail);
