import axios from "axios";
import * as Constants from "../../../constants/Constants";

export function getCampaignRelatedDetails() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.GET_CAMPAIGN_RELATED_DATE_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function sendTestEmail(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.SEND_TEST_EMAIL_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_CREATE_API_PERMISSION,
          },

        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSmsPlanData() {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.GET_SMS_PLAN_DETAILS_ROUTE,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function checkSMSAvailability(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.CHECK_CAMPAIGN_SMS_AVAILABILITY_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_CREATE_API_PERMISSION,
          },

        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateSMSPlan(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.UPDATE_SMS_PLAN_SUBSCRIPTION_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_CREATE_API_PERMISSION,
          },

        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function campaignCreate(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.CREATE_CAMPAIGN_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_CREATE_API_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getAllCampaigns(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.GET_CAMPAIGN_LIST_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_LIST_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDeactiveCampaigns(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.GET_DEACTIVE_CAMPAIGN_LIST_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_REACTIVATE_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function campaignDeactiveReactive({ request, params }) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    let formData = await request.formData();
    if (Object.fromEntries(formData).type == Constants.DEACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .delete(
          Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CAMPAIGN_DEACTIVATE_ROUTE +
          "/" +
          id,
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.CAMPAIGN_DEACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    } else if (Object.fromEntries(formData).type == Constants.REACTIVE) {
      let id = Object.fromEntries(formData).id;
      axios
        .post(
          Constants.REACT_APP_API_URL +
          process.env.REACT_APP_API_VERSION +
          Constants.CAMPAIGN_REACTIVATE_ROUTE +
          "/" +
          id,
          {},
          {
            headers: {
              "content-type": Constants.CONTANT_TYPE,
              Authorization: "Bearer" + token,
              Permission: Constants.CAMPAIGN_REACTIVATE_PERMISSION,
            },
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          let errorData = {
            error: true,
            response: error.response,
            code: error.code,
          };
          resolve(errorData);
        });
    }
  });
}

export function getCampaignEditData({ request, params }) {
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.GET_CAMPAIGN_EDIT_DATA_ROUTE + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function checkEditCampaignSMSAvailability(payload) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.CHECK_CAMPAIGN_EDIT_SMS_AVAILABILITY_ROUTE,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_EDIT_PERMISSION,
          },

        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function campaignUpdate(payload, id) {
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        Constants.CAMPAIGN_UPDATE_ROUTE +
        `/${id}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_EDIT_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function checkCampaignEdit({ request, params}){
  return new Promise(async (resolve, reject) => {
    const id = params.id;
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.CHECK_CAMAPIGN_EXIST + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_DASHBOARD_VIEW_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        let errorData = {
          error: true,
          response: error.response,
          code: error.code,
        };
        resolve(errorData);
      });
  });
}

export function getCampDbCoupRedeemDataApi(id){
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.CAMPAIGN_DASHBOARD_COUPON_REEDEM_API + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_DASHBOARD_VIEW_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCampDbTotalRevenueDataApi(id){
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.CAMPAIGN_DASHBOARD_REVENUE_API + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_DASHBOARD_VIEW_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCampDbUnsubscribedEmailSmsDataApi(id){
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.CAMPAIGN_DASHBOARD_UNSUBSCRIBED_API + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_DASHBOARD_VIEW_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCampDbEmailSentDataApi(id){
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.CAMPAIGN_DASHBOARD_EMAIL_API + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_DASHBOARD_VIEW_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCampDbSmsSentDataApi(id){
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.CAMPAIGN_DASHBOARD_SMS_API + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_DASHBOARD_VIEW_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCampaignViewDataApi(id){
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .get(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.CAMPAIGN_VIEW_API + "/" + id}`,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_DASHBOARD_VIEW_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCampaignUserDataApi(payload){
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.CAMPAIGN_VIEW_USER_API}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_DASHBOARD_VIEW_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getCampaignTimeUsedApi(payload){
  return new Promise(async (resolve, reject) => {
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.CAMPAIGN_USER_TIME_USED}`,
        payload,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.CAMPAIGN_DASHBOARD_VIEW_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDashboardTotalCouponRedeemedDataApi(payload){
  return new Promise(async (resolve, reject) => {
    let model = JSON.stringify({start_date: payload.myStartDate, end_date: payload.myEndDate, timeZone: payload.local_timezone});
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.MARK_DASHBOARD_TOTAL_COUPON_REEDEMED_API}`,
        model,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.MARK_DASHBOARD_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDashboardTotalRevenueApi(payload){
  return new Promise(async (resolve, reject) => {
    let model = JSON.stringify({start_date: payload.myStartDate, end_date: payload.myEndDate, timeZone: payload.local_timezone});
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.MARK_DASHBOARD_TOTAL_REVENUE_API}`,
        model,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.MARK_DASHBOARD_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDashboardTotalEmailSentApi(payload){
  return new Promise(async (resolve, reject) => {
    let model = JSON.stringify({start_date: payload.myStartDate, end_date: payload.myEndDate, timeZone: payload.local_timezone});
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.MARK_DASHBOARD_TOTAL_EMAIL_SENT_API}`,
        model,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.MARK_DASHBOARD_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDashboardTotalSMSSentApi(payload){
  return new Promise(async (resolve, reject) => {
    let model = JSON.stringify({start_date: payload.myStartDate, end_date: payload.myEndDate, timeZone: payload.local_timezone});
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.MARK_DASHBOARD_TOTAL_SMS_SENT_API}`,
        model,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.MARK_DASHBOARD_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDashboardTotalEmailUnsubApi(payload){
  return new Promise(async (resolve, reject) => {
    let model = JSON.stringify({start_date: payload.myStartDate, end_date: payload.myEndDate, timeZone: payload.local_timezone});
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.MARK_DASHBOARD_TOTAL_UNSUB_EMAIL_API}`,
        model,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.MARK_DASHBOARD_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDashboardTotalSMSUnsubApi(payload){
  return new Promise(async (resolve, reject) => {
    let model = JSON.stringify({start_date: payload.myStartDate, end_date: payload.myEndDate, timeZone: payload.local_timezone});
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.MARK_DASHBOARD_TOTAL_UNSUB_SMS_API}`,
        model,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.MARK_DASHBOARD_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getDashboardTopPerformingCouponApi(payload){
  return new Promise(async (resolve, reject) => {
    let model = JSON.stringify({start_date: payload.myStartDate, end_date: payload.myEndDate, timeZone: payload.local_timezone});
    let token = localStorage.getItem("token");
    axios
      .post(
        Constants.REACT_APP_API_URL +
        process.env.REACT_APP_API_VERSION +
        `${Constants.MARK_DASHBOARD_TOP_PERFORMING_COUPON_API}`,
        model,
        {
          headers: {
            "content-type": Constants.CONTANT_TYPE,
            Authorization: "Bearer" + token,
            Permission: Constants.MARK_DASHBOARD_PERMISSION,
          },
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}