import { DeleteIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Select,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import EditIcon from "@mui/icons-material/Edit";
import Tippy from "@tippyjs/react";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  useActionData,
  useLoaderData,
  useLocation,
  useParams,
  useSubmit,
} from "react-router-dom";
import "tippy.js/dist/tippy.css"; // Optional for default styling
import * as Yup from "yup";
import Colors from "../../../assets/colors/Colors";
import toolTip from "../../../assets/images/tool_tip_hover_icon.svg";
import {
  AppContext,
  PosBreadCrumb,
  PosFormButton,
  PosInput,
  PosLable,
  PosSwitchButton,
  PosTostMessage,
} from "../../../components/index";
import * as Constants from "../../../constants/Constants";
import { twofix } from "../../../helpers/utils/Utils";
import { PosErrorHook } from "../../../hooks";
import { WithRouter } from "../../../navigators/WithRouter";

const ModifierSetEdit = (props) => {
  const [modifiers, setModifiers] = useState([]);
  const stateData = useLocation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editId, setEditId] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const { id } = useParams();
  const submit = useSubmit();
  const { addToast } = PosTostMessage();
  const { error } = PosErrorHook();
  const loaderResponse = useLoaderData();
  const actionResponse = useActionData();
  const effectRun = useRef(true);
  const [deleted_at, setSelectedStatus] = useState("Yes");
  const myContext = useContext(AppContext);
  const [paramData, setParamData] = useState(stateData.state); // store in state
  const [isMobile] = useMediaQuery("(max-width: 992px)");

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    const updatedModifiers = modifiers.map((modifier, index) => {
      if (index === editIndex) {
        return {
          ...modifier,
          deleted_at: event.target.value,
          modifier_active: event.target.value,
        };
      }
      return modifier;
    });
    // setModifiers(updatedModifiers);
  };
  const [orderLine, setOrderLine] = useState([]);
  const [enableDelete, setEnableDelete] = useState(0);
  useEffect(() => {
    if (effectRun.current === true) {
      if (
        undefined != loaderResponse &&
        null != loaderResponse &&
        {} != loaderResponse
      ) {
        if (
          undefined != loaderResponse?.error &&
          null != loaderResponse?.error &&
          loaderResponse?.error
        ) {
          let actionData = loaderResponse;
          error({ actionData });
          myContext.handleLoading(false);
        } else if (
          null !== loaderResponse.data[Constants.FLAGE] &&
          true === loaderResponse.data[Constants.FLAGE]
        ) {
          const modifiers = loaderResponse?.data?.data?.modifier || [];
          let aggregatedOrderLines = [];

          modifiers.forEach((modifier) => {
            if (modifier.order_line && modifier.order_line.length > 0) {
              aggregatedOrderLines = aggregatedOrderLines.concat(
                modifier.order_line
              );
            }
          });

          setOrderLine(aggregatedOrderLines);
          // setDeactived(loaderResponse.data.data.modifier);
          setEnableDelete(setOrderLine.length > 0 ? 1 : 0);
          // setA(deactived ? 'No' : 'Yes');
          myContext.handleLoading(false);
        }
      }
      return () => {
        effectRun.current = false;
      };
    }
    if (
      undefined != actionResponse &&
      null != actionResponse &&
      {} != actionResponse
    ) {
      setButtonDisable(false);
      if (
        undefined != actionResponse?.error &&
        null != actionResponse?.error &&
        actionResponse?.error
      ) {
        let actionData = actionResponse;
        error({ actionData });
        myContext.handleLoading(false);
      } else if (
        actionResponse.data[Constants.FLAGE] !== null &&
        actionResponse.data[Constants.FLAGE] === true
      ) {
        props.navigate(Constants.MODIFIER_SET_LIST, {
          state: paramData,
        });
        addToast({
          alertStatus: Constants.TOAST_TYPE_SUCESS,
          alertTitle: Constants.MODIFIER_SET,
          alertDescription: actionResponse.data.message,
        });
        myContext.handleLoading(false);
      }
    }
  }, [loaderResponse, actionResponse]);

  const openDialog = (index) => {
    setIsDialogOpen(true);
    if (modifiers && modifiers.length > 0) {
      if (index !== undefined && modifiers[index]) {
        setEditIndex(index);
        setEditId(modifiers[index].id);
        formik.setFieldTouched("modifier_name", false);
        formik.setValues({
          ...formik.values,
          modifier_name: modifiers[index].name,
          price: modifiers[index].price,
          deleted_at: modifiers[index].deleted_at,
        });
        setSelectedStatus(modifiers[index].modifier_active);
      } else {
        setEditIndex(null);
        setEditId(null);
        formik.setFieldTouched("modifier_name", false);
        formik.setValues({
          ...formik.values,
          modifier_name: "",
          price: "",
          deleted_at: "",
        });
      }
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setEditIndex(null);
    setSelectedStatus("");
    formik.setValues({
      ...formik.values,
      modifier_name: "",
      price: "",
      deleted_at,
    });
    setSelectedStatus("Yes");
    formik.setFieldTouched("modifier_name", false);
  };
  // const handleSaveData = () => {
  //   if (editIndex !== null) {
  //     const updatedModifiers = [...modifiers];
  //     updatedModifiers[editIndex] = {
  //       ...updatedModifiers[editIndex],
  //       name: formik.values.modifier_name,
  //       price: formik.values.price,
  //       modifier_active: deleted_at,
  //     };
  //     setModifiers(updatedModifiers);
  //   } else {
  //     const newModifiers = [
  //       ...modifiers,
  //       {
  //         id: "id_1",
  //         modifier_active: deleted_at,
  //         modifier_set_id: id,
  //         name: formik.values.modifier_name,
  //         price: formik.values.price,
  //         enable: 0,
  //         editDisable: 0,
  //         hiddenId: 1,
  //         sort: modifiers.length + 1,
  //       },
  //     ];
  //     setModifiers(newModifiers);
  //   }
  //   setIsDialogOpen(false);
  // };

  // const handleSaveData = () => {
  //   if (editIndex !== null) {
  //     const foundObjects =  modifiers.find((item) => item.id != editId && item.name == formik.values.modifier_name);
  //     if (foundObjects) {
  //       addToast({
  //         alertStatus: Constants.TOAST_TYPE_WARNING,
  //         alertTitle: Constants.MODIFIER_SET,
  //         alertDescription: Constants.MODIFIER_ALREADY_EXISTS_IN_THIS_MODIFIER_SET,
  //       });
  //     } else {
  //       const updatedModifiers = [...modifiers];
  //       updatedModifiers[editIndex] = {
  //         ...updatedModifiers[editIndex],
  //         name: formik.values.modifier_name,
  //         price: formik.values.price,
  //         modifier_active: deleted_at,
  //       };
  //       setModifiers(updatedModifiers);
  //       setIsDialogOpen(false);
  //     }
  //   } else if (editId) {
  //     const foundObject = modifiers.find((item) => item.id != editIndex && item.name == formik.values.modifier_name);
  //     if (foundObject) {
  //       addToast({
  //         alertStatus: Constants.TOAST_TYPE_WARNING,
  //         alertTitle: Constants.MODIFIER_SET,
  //         alertDescription: Constants.MODIFIER_ALREADY_EXISTS_IN_THIS_MODIFIER_SET,
  //       });
  //     } else {
  //       const updatedModifiers = [...modifiers];
  //       updatedModifiers[editIndex] = {
  //         ...updatedModifiers[editIndex],
  //         name: formik.values.modifier_name,
  //         price: formik.values.price,
  //         modifier_active: deleted_at,
  //       };
  //       setModifiers(updatedModifiers);
  //       setIsDialogOpen(false);
  //     }
  //   } else {
  //     const foundObject = modifiers.find((item) => item.id !== editIndex && item.name === formik.values.modifier_name);
  //     if (foundObject) {
  //       addToast({
  //         alertStatus: Constants.TOAST_TYPE_WARNING,
  //         alertTitle: Constants.MODIFIER_SET,
  //         alertDescription: Constants.MODIFIER_ALREADY_EXISTS_IN_THIS_MODIFIER_SET,
  //       });
  //     } else {
  //       const newModifiers = [
  //         ...modifiers,
  //         {
  //           id: "id_1",
  //           modifier_active: deleted_at,
  //           modifier_set_id: id,
  //           name: formik.values.modifier_name,
  //           price: formik.values.price,
  //           enable: 0,
  //           editDisable: 0,
  //           hiddenId: 1,
  //           sort: modifiers.length + 1,
  //         },
  //       ];
  //       setModifiers(newModifiers);
  //       setIsDialogOpen(false);
  //     }
  //   }
  // };

  // const handleSaveData = () => {
  //   const modifierName = formik.values.modifier_name.trim();
  //   const foundObject =
  //     editIndex !== null
  //       ? modifiers.find(
  //           (item) => item.id !== editId && item.name === modifierName
  //         )
  //       : editId
  //       ? modifiers.find(
  //           (item) => item.id !== editIndex && item.name === modifierName
  //         )
  //       : modifiers.find(
  //           (item) => item.id !== editIndex && item.name === modifierName
  //         );

  //   if (foundObject) {
  //     addToast({
  //       alertStatus: Constants.TOAST_TYPE_WARNING,
  //       alertTitle: Constants.MODIFIER_SET,
  //       alertDescription:
  //         Constants.MODIFIER_ALREADY_EXISTS_IN_THIS_MODIFIER_SET,
  //     });
  //   } else {
  //     const updatedModifiers =
  //       editIndex !== null
  //         ? [...modifiers]
  //         : [
  //             ...modifiers,
  //             {
  //               id: "id_1",
  //               modifier_active: deleted_at,
  //               modifier_set_id: id,
  //               name: modifierName,
  //               price: formik.values.price,
  //               enable: enableDelete,
  //               editDisable: 0,
  //               hiddenId: 1,
  //               sort: modifiers.length + 1,
  //               isNew: true,
  //             },
  //           ];

  //     updatedModifiers[
  //       editIndex !== null ? editIndex : updatedModifiers.length - 1
  //     ] = {
  //       ...updatedModifiers[
  //         editIndex !== null ? editIndex : updatedModifiers.length - 1
  //       ],
  //       name: modifierName,
  //       price: formik.values.price,
  //       modifier_active: deleted_at,
  //     };
  //     setSelectedStatus(deleted_at)
  //     setModifiers(updatedModifiers);
  //     setIsDialogOpen(false);
  //   }
  // };
  const handleSaveData = () => {
    const modifierName = formik.values.modifier_name.trim();

    const foundObject = modifiers.find(
      (item, index) =>
        item.name === modifierName &&
        (editIndex !== null ? index !== editIndex : true)
    );

    if (foundObject) {
      addToast({
        alertStatus: Constants.TOAST_TYPE_WARNING,
        alertTitle: Constants.MODIFIER_SET,
        alertDescription:
          Constants.MODIFIER_ALREADY_EXISTS_IN_THIS_MODIFIER_SET,
      });
    } else {
      let updatedModifiers = [...modifiers];

      if (editIndex !== null) {
        // Update existing modifier
        updatedModifiers[editIndex] = {
          ...updatedModifiers[editIndex],
          name: modifierName,
          price: formik.values.price,
          modifier_set_sort : formik.values.modifier_set_sort,
          modifier_active: deleted_at,
        };
      } else {
        // Add new modifier
        const newModifier = {
          id: "id_1",
          modifier_active: deleted_at,
          modifier_set_id: id,
          name: modifierName,
          price: formik.values.price,
          enable: enableDelete,
          editDisable: 0,
          hiddenId: 1,
          sort: modifiers.length + 1,
          isNew: true,
        };
        updatedModifiers.push(newModifier);
      }

      setModifiers(updatedModifiers);
      setSelectedStatus("Yes");
      setIsDialogOpen(false);
    }
  };

  const [deletedModifiers, setDeletedModifiers] = useState([]);

  const handleDeleteData = (index) => {
    const deletedModifier = modifiers[index];
    const updatedModifiers = modifiers.filter((_, i) => i !== index);
    const updatedDeletedModifiers = deletedModifier
      ? [...deletedModifiers, deletedModifier]
      : deletedModifiers;
    setModifiers(updatedModifiers);
    setEditIndex(null);
    formik.setFieldValue("modifier_name","")
    formik.setFieldValue("price","")
    formik.setFieldValue("modifier_active","Yes")
    setDeletedModifiers(updatedDeletedModifiers);
  };

  // const cancelRef = React.useRef();

  useEffect(() => {
    const responseModifiers = loaderResponse.data?.data?.modifier || [];
    const modifiersWithModifierActive = responseModifiers.map((modifier) => {
      const formattedPrice =
        modifier.price === "0.00" ? modifier.price : twofix(modifier.price);
      return {
        ...modifier,
        modifier_active: modifier.deleted_at ? "No" : "Yes",
        price: formattedPrice,
      };
    });
    setModifiers(modifiersWithModifierActive);
  }, [loaderResponse]);

  const formik = useFormik({
    initialValues: {
      name: loaderResponse.data?.data?.name,
      price: "",
      modifier_name: "",
      only_one: loaderResponse.data?.data?.only_one,
      modifier_set_sort: loaderResponse.data?.data?.modifier_set_sort || "",
      modifier_active: "",
      modifier_id: "",
      enable: enableDelete,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(Constants.MODIFIER_SET_NAME_REQUIRED)
        .max(191, Constants.MODIFIER_SET_NAME_LENGTH_GREATER_THAN_191),
      modifier_name: Yup.string()
        .trim()
        .required(Constants.MODIFIER_NAME_REQUIRED)
        .max(191, Constants.MODIFIER_NAME_LENGTH_GREATER_THAN_191),
      // .test(
      //   "unique",
      //   Constants.MODIFIER_ALREADY_EXISTS_IN_THIS_MODIFIER_SET,
      //   function (value) {
      //     if (editIndex !== null) {
      //       const isUnique =
      //         modifiers.findIndex(
      //           (modifier, index) =>
      //             index !== editIndex && modifier.name === value
      //         ) === -1;
      //       return isUnique;
      //     } else {
      //       const isNameExistsInResponse =
      //         loaderResponse.data?.data?.modifier.some(
      //           (loaderModifier) => loaderModifier.name === value
      //         );
      //       if (isNameExistsInResponse) {
      //         return false;
      //       } else {
      //         const isUnique = modifiers.every(
      //           (modifier) => modifier.name !== value
      //         );
      //         return isUnique;
      //       }
      //     }
      //   }
      // ),
      modifier_set_sort: Yup.number()
        .typeError(Constants.MODIFIER_SET_SORT_MUST_INTEGER)
        .min(1, Constants.MODIFIER_SET_MIN_VALUE)
        .max(999, Constants.MODIFIER_SET_SORT_LENGTH_GREATE_THEN_999)
        .integer(Constants.MODIFIER_SET_INTEGER_VALUE),
      price: Yup.number()
        .typeError(Constants.MODIFIER_PRICE_MUST_INTEGER)
        .max(999999.99, Constants.MODIFIER_NAME_MAX_VALUE)
        .min(0, Constants.MODIFIER_VALID_MODIFIER_PRICE),
    }),
    onSubmit: (values) => {},
  });

  const handleSubmitButton = () => {
    myContext.handleLoading(true);
    const modifier_set = {
      id: id,
      name: formik.values.name,
      only_one: formik.values.only_one,
      modifier_set_sort:
      formik.values.modifier_set_sort !== undefined && formik.values.modifier_set_sort !== ""
        ? formik.values.modifier_set_sort
        : null,
    };
    const payload = {
      modifier_set: JSON.stringify(modifier_set),
      modifiers: JSON.stringify(modifiers),
      deleted_modifier: JSON.stringify(deletedModifiers),
    };
    setButtonDisable(true);
    let path = Constants.MODIFIER_SET_EDIT + id;

    submit(payload, {
      method: Constants.PUT,
      path: path,
    });
  };
  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      !(
        formik.values?.name?.length <= 0 ||
        formik.errors.name ||
        formik.errors.modifier_set_sort ||
        formik.errors.modifier_name ||
        formik.errors.price ||
        buttonDisable
      )
    ) {
      formik.handleSubmit();
    }
  };
  const onRowReorder = useCallback(
    debounce((e) => {
      const updatedModifiers = e.value.map((modifier, index) => ({
        ...modifier,
        sort: index + 1,
      }));
      setModifiers(updatedModifiers);
    }, 10),
    []
  );
  return (
    <Box flex="1" pb="3.31rem">
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems={"flex-start"}
        pt={{ base: "1.5rem", md: "3.06rem" }}
        pb={{ base: "1.5rem", md: "0" }}
        pl={{ base: "1rem", lg: "1.88rem" }}
        pr={{ base: "0.5rem", md: "1.88rem" }}
        gap={2}
      >
        <PosBreadCrumb
          handleClick={(i) => {
            // pass param data in tax list page
            props.navigate(Constants.MODIFIER_SET_LIST, {
              state: stateData.state,
            });
          }}
          breadCrumNames={["Modifiers", "Edit Modifiers"]}
          breadCrumTitle={"Edit Modifiers"}
        />
        <Spacer />
        <Stack direction={"row"} alignSelf={"flex-end"}>
          <PosFormButton
            buttonText={"Cancel"}
            CancelButton={true}
            onClick={() => {
              // pass param data in modifier set list page
              props.navigate(Constants.MODIFIER_SET_LIST, {
                state: stateData.state,
              });
            }}
            isDisabled={buttonDisable}
          />
          <PosFormButton
            isDisabled={
              formik.values?.name?.length === 0 ||
              formik.errors.name ||
              formik.errors.modifier_set_sort ||
              modifiers.length === 0 ||
              buttonDisable
                ? true
                : false
            }
            buttonsubmit={"Submit"}
            SubmitButton={true}
            onClick={handleSubmitButton}
          />
        </Stack>
      </Flex>
      <Box
        mt={{ base: 4, lg: 12 }}
        ml={{ base: 4, lg: 14 }}
        mr={{ base: 4, lg: 14 }}
        // mt={{ base: "0", md: "3.06rem" }}
        // ml={{ base: "0", md: "3.44rem" }}
        // mr={{ base: "0", md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
        p="8"
      >
        <Text
          fontSize="1.23rem"
          w="9.87rem"
          mb={{ base: "1rem" }}
          color={Colors.posTextInfo}
          style={{
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
        >
          Modifier Set
        </Text>
        <Text
          fontSize="1rem"
          // w="39.31rem"
          h="0.8rem"
          color={Colors.posTextInfo}
          style={{
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "normal",
            letterSpacing: "-0.02rem",
          }}
        >
          Edit modifier sets to add personal customizations for your customers to
          your modifier set
        </Text>
        <Stack spacing={2} pt={5}>
          <PosLable
            name={"Set Name"}
            requiredLabel={true}
            fontStyle="normal"
            fontWeight={300}
            lineHeight="normal"
            letterSpacing="-0.02"
          />
          <PosInput
            id="name"
            placeholder={"Set Name"}
            color={Colors.posTextInfo}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.name}
            handleBlur={formik.handleBlur}
            onKeyDown={handleKeyDown}
            posInput={true}
            inputType={"text"}
            inputError={formik.touched.name && formik.errors.name}
          />
          <Text color={Colors.errorColor}>
            {formik.touched.name && formik.errors.name ? (
              <span>{formik.errors.name}</span>
            ) : null}
          </Text>
        </Stack>
        {/* <Text
          mt="2rem"
          w="10.44rem"
          color={Colors.posTextInfo}
          style={{
            flexShrink: "0",
            fontSize: "0.94rem",
            fontStyle: "normal",
            fontWeight: "300",
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
        >
          Only One
        </Text> */}
        <Flex flexDirection={"row"}>
          <PosLable fontWeight={300} name={"Only One"} label={true} />
          <Tippy
            content="With this option you can decide if you want only one of the modifiers to be selected or multiple ones. For example, the choice of milk for coffee can have Only One option on."
            interactive={true}
            maxWidth={390}
            placement="top"
            animation="fade"
            inertia={true}
            moveTransition="transform 0.2s ease-out"
            theme="tomato"
          >
            <Image src={toolTip} alt="Tooltip" mt={"2rem"} ml={"0.3rem"} />
          </Tippy>
        </Flex>
        <Stack
          direction="row"
          mt="0.313rem"
          w="2.75rem"
          height="1.38rem"
          flexShrink="0"
        >
          <PosSwitchButton
            id="only_one"
            switchValue={formik.values.only_one}
            onChange={formik.handleChange}
          />
        </Stack>
        {/* <Text
          mt={"0.88rem"}
          style={{
            fontSize: "0.94rem",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
          color={Colors.posShowFields}
        >
          Turn on, to allow only one modifier option when selecting. For example
          the choice of ice cream toppings can be a single or multiple option
          selection
        </Text> */}
        <Flex>
          <Flex flexDirection={"row"}>
            <PosLable
              fontWeight={300}
              name={"Modifier Set Sort"}
              label={true}
            />
            <Tippy
              content="This determines the location of the modifier set on the modifier page. From 1 being the first one on the row and so on."
              interactive={true}
              maxWidth={390}
              placement="top"
              animation="fade"
              inertia={true}
              moveTransition="transform 0.2s ease-out"
              theme="tomato"
            >
              <Image src={toolTip} alt="Tooltip" mt={"2rem"} ml={"0.3rem"} />
            </Tippy>
          </Flex>
          {/* <Tooltip
            placement="top"
            bg={"#3758F9"}
            style={{
              borderRadius: "0.63rem",
              display: "flex",
              width: "4.94rem",
              padding: "0.31rem 0.88rem",
              justifyContent: "{center}",
              alignItems: "flex-start",
              gap: "0.63rem",
              textAlign: "center",
              fontSize: "0.38rem",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "normal",
              letterSpacing: "-0.01rem",
            }}
            hasArrow
            label="This determines the location of the modifier set on the modifier page. From 1 being the first one on the row and so on."
            mr={{ base: "0", md: "2rem" }}
          >
            <Image
              src={tool_tip_hover_icon}
              alt="Tooltip"
              mt={{ base: "0", md: "2rem" }}
              ml={{ base: "0rem", md: "0.3rem" }}
            />
          </Tooltip> */}
        </Flex>
        <Stack spacing={3} mt="0.87rem">
          <PosInput
            id={"modifier_set_sort"}
            placeholder={""}
            handleInputChange={formik.handleChange}
            inputValue={formik.values.modifier_set_sort}
            handleBlur={(e) => {
              formik.handleBlur(e);
              formik.setFieldValue("modifier_set_sort", (e.target.value));
            }}
            onKeyDown={(e) => {
              if (e.key === "." ) {
                e.preventDefault();
              }
            }}
            inputType={"number"}
            posNumberInput={true}
            inputError={
              formik.touched.modifier_set_sort &&
              formik.errors.modifier_set_sort
            }
          />
          <Text color={Colors.posCancleButtonRed}>
            {formik.touched.modifier_set_sort &&
            formik.errors.modifier_set_sort ? (
              <span>{formik.errors.modifier_set_sort}</span>
            ) : null}
          </Text>
        </Stack>
        {/* <Text
          mt="0.87rem"
          style={{
            fontSize: "0.94rem",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "normal",
            letterSpacing: "-0.01rem",
          }}
          color={Colors.posShowFields}
        >
          Determines the location of the modifier set on the page. Use ‘1’ as
          the first one on the row
        </Text> */}
      </Box>
      <Box
        mt={{ base: 2, lg: 2.5 }}
        ml={{ base: 4, lg: 14 }}
        mr={{ base: 4, lg: 14 }}
        // mt={{ base: "0", md: "0.63rem" }}
        // ml={{ base: "0", md: "3.44rem" }}
        // mr={{ base: "0", md: "3.19rem" }}
        borderRadius="0.63rem"
        bg={Colors.posPageDataBackground}
        boxShadow="0px 0.25rem 1.25rem 0px #5a5a5a0a"
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          // alignItems={{ base: "center" }}
          pt={{ base: "1.5rem", md: "2.5rem" }}
          pb={{ base: "1.5rem", md: "2rem" }}
          pl={{ base: "1rem", lg: "2.5rem" }}
          pr={{ base: "0.5rem", md: "1.56rem" }}
          gap={2}
        >
          <Box>
            <Text
              fontSize="1.13rem"
              // w="10rem"
              color={Colors.posTextInfo}
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
            >
              Modifier Option
            </Text>

            <Text
              style={{
                fontSize: "0.94rem",
                fontStyle: "normal",
                fontWeight: 300,
                lineHeight: "normal",
                letterSpacing: "-0.01rem",
              }}
            >
              Add modifier options to your modifier set
            </Text>
          </Box>
          <Spacer />
          <Button
            bg={Colors.posNavbarLink}
            style={{
              display: "flex",
              width: "5.56rem",
              padding: "0.63rem",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.63rem",
              borderRadius: "0.31rem",
              border: "1px solid #E3E3E3",
              boxShadow: "0px 0.25rem 0.25rem 0px #e0e0e01a",
            }}
            onClick={() => openDialog()}
          >
            Add
          </Button>
        </Flex>
        <>
          <AlertDialog
            isOpen={isDialogOpen}
            // onClose={handleCloseDialog}
            isCentered
            closeOnOverlayClick={false}
          >
            <AlertDialogOverlay />
            <AlertDialogContent
              style={{
                maxWidth: "35.63rem",
                backgroundColor: "#F4F4F4",
                width: isMobile ? "90%" : "50%",
              }}
            >
              <AlertDialogHeader
                mt={{ base: "0", md: "3.06rem" }}
                style={{ display: "inline-flex", justifyContent: "center" }}
              >
                {editIndex !== null
                  ? "Edit Modifier Option"
                  : "Add Modifier Option"}
              </AlertDialogHeader>
              <Divider />
              <AlertDialogBody>
                {/* <PosLable fontWeight={300} name={"Name"} label={true} /> */}
                <PosLable
                    name={"Name"}
                    requiredLabel={true}
                    fontStyle="normal"
                    fontWeight={300}
                    lineHeight="normal"
                    letterSpacing="-0.02rem"
                  />
                <PosInput
                  id="modifier_name"
                  placeholder={"Name"}
                  color={Colors.posTextInfo}
                  handleInputChange={formik.handleChange}
                  inputValue={formik.values.modifier_name}
                  handleBlur={formik.handleBlur}
                  onKeyDown={handleKeyDown}
                  posInput={true}
                  inputType={"text"}
                  inputerror={
                    formik.touched.modifier_name && formik.errors.modifier_name
                  }
                />
                <Text color={Colors.posCancleButtonRed}>
                  {formik.touched.modifier_name &&
                  formik.errors.modifier_name ? (
                    <span>{formik.errors.modifier_name}</span>
                  ) : null}
                </Text>
                <Flex
                  mt={{ base: "0.88rem", md: "1rem" }}
                  direction={{ base: "column", md: "row" }}
                >
                  <Box maxWidth="100%" overflow="hidden">
                    <Text
                      style={{
                        fontSize: "0.94rem",
                        fontStyle: "normal",
                        fontWeight: 300,
                        lineHeight: "normal",
                        letterSpacing: "-0.01rem",
                      }}
                      mb={"0.31rem"}
                    >
                      Price
                    </Text>
                    {/* <PosLable name={"Price"} mb={"0.88rem"} label={true} /> */}
                    {formik.values.price !== undefined ? (
                      <PosInput
                        id="price"
                        placeholder={"Price"}
                        // handleInputChange={formik.handleChange}
                        inputValue={formik.values.price}
                        // handleBlur={formik.handleBlur}
                        handleBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "price",
                            twofix(formik.values.price)
                          );
                        }}
                        handleInputChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*\.?\d{0,9}$/.test(value)) {
                            formik.setFieldValue("price", value);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (
                            ["Backspace", "Tab", "Delete", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(e.key) ||
                            (e.key === "." && !e.target.value.includes("."))
                          ) {
                            return;
                          }
                          if (!/^\d$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        // onKeyDown={handleKeyDown}
                        inputType={"number"}
                        posNumberInput={true}
                        inputError={formik.touched.price && formik.errors.price}
                      />
                    ) : formik.touched.price && formik.errors.price ? (
                      <PosInput
                        id="price"
                        placeholder={"Price"}
                        handleInputChange={formik.handleChange}
                        handleBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "price",
                            twofix(formik.values.price)
                          );
                        }}
                        onKeyDown={handleKeyDown}
                        inputValue={formik.values.price}
                        inputType={"number"}
                        posNumberInput={true}
                        inputError={formik.touched.price && formik.errors.price}
                      />
                    ) : (
                      <PosInput
                        id="price"
                        placeholder="Price"
                        handleInputChange={formik.handleChange}
                        inputValue={formik.values.price ? undefined : "0.00"}
                        handleBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldValue(
                            "price",
                            twofix(formik.values.price)
                          );
                        }}
                        onKeyDown={handleKeyDown}
                        inputType="number"
                        posNumberInput={true}
                        inputError={formik.touched.price && formik.errors.price}
                      />
                    )}
                    <Text color={Colors.posCancleButtonRed}>
                      {formik.touched.price && formik.errors.price ? (
                        <span>{formik.errors.price}</span>
                      ) : null}
                    </Text>
                  </Box>
                  <Box
                    ml={{ base: "0", md: "1rem" }}
                    mt={{ base: "0.88rem", md: "0" }}
                  >
                    <Text
                      style={{
                        fontSize: "0.94rem",
                        fontStyle: "normal",
                        fontWeight: 300,
                        lineHeight: "normal",
                        letterSpacing: "-0.01rem",
                      }}
                      mb={"0.31rem"}
                    >
                      Active
                    </Text>
                    {/* <PosLable name={"Active"} mb={"0.88rem"} label={true} /> */}
                    <Select
                      w={{ base: "100%", md: "16.38rem" }}
                      style={{
                        borderRadius: "0.31rem",
                        border: "0.0.38rem solid #bfbfbf38",
                        background: "#5881fe05",
                      }}
                      value={deleted_at}
                      onChange={handleStatusChange}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Select>
                  </Box>
                </Flex>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Flex mb={{ base: "1.5rem", md: "2.69rem" }} mt={"1.38rem"}>
                  <PosFormButton
                    buttonText={"Cancel"}
                    CancelButton={true}
                    // ref={cancelRef}
                    onClick={handleCloseDialog}
                  />
                  <Button
                    colorScheme="messenger"
                    ml={3}
                    onClick={handleSaveData}
                    isDisabled={
                      formik.values.modifier_name.length <= 0 ||
                      formik.errors.modifier_name ||
                      formik.values.price < 0 ||
                      formik.values.price > 999999.99 ||
                      formik.errors.price ||
                      buttonDisable
                        ? true
                        : false
                    }
                  >
                    {editIndex !== null ? "Update Option" : "Add Option"}
                  </Button>
                </Flex>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          {modifiers.length > 0 && (
            <DataTable
              value={modifiers}
              // reorderableColumns
              reorderableColumns={false}
              reorderableRows
              style={{
                overflowX: "auto",
                maxHeight: "35rem",
              }}
              tableStyle={{ minWidth: "50rem" }}
              onRowReorder={onRowReorder}
              // onRowReorder={(e) => {
              //   const updatedModifiers = e.value.map((modifier, index) => ({
              //     ...modifier,
              //     sort: index + 1,
              //   }));
              //   setModifiers(updatedModifiers);
              // }}
            >
              <Column
                rowReorder
                style={{
                  height: "3rem",
                  width: "10%",
                }}
                headerStyle={{ backgroundColor: "#5881fe1a" }}
              />
              <Column
                field="modifier_name"
                header="Name"
                headerStyle={{
                  backgroundColor: "#5881fe1a",
                  fontWeight: "300",
                  color: "#38128A",
                  fontSize: "0.93rem",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  width: "17.75%",
                  whiteSpace: "normal",
                }}
                style={{
                  color: "#010048",
                  fontSize: "0.93rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  width: "25%",
                  minWidth: "20rem",
                  maxWidth: "15rem",
                }}
                bodyStyle={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
                body={(rowData) =>
                  rowData.name ? rowData.name.toLocaleString(undefined) : "N.A"
                }
              />
              <Column
                field="price"
                header="Price"
                headerStyle={{ backgroundColor: "#5881fe1a" }}
                style={{
                  fontSize: "0.93rem",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  width: "15%",
                }}
                body={(rowData) =>
                  rowData.price
                    ? `$${rowData.price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : "$0.00"
                }
              />
              <Column
                field="status"
                header="Status"
                headerStyle={{ backgroundColor: "#5881fe1a" }}
                bodyStyle={{ padding: "1rem 1.5rem" }}
                style={{
                  fontSize: "0.93rem",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                  width: "16%",
                }}
                body={(rowData) => <Text>{rowData.modifier_active}</Text>}
              />
              <Column
                field="action"
                header="Edit"
                headerStyle={{ backgroundColor: "#5881fe1a" }}
                bodyStyle={{ padding: "1rem 0.1rem" }}
                style={{
                  fontSize: "0.93rem",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                }}
                body={(rowData) => (
                  <Button
                    p={2.5}
                    w={20}
                    h={8}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "0.31rem",
                      flexShrink: 0,
                    }}
                    onClick={() => openDialog(modifiers.indexOf(rowData))}
                  >
                    <EditIcon style={{ fontSize: 18 }} />
                    Edit
                  </Button>
                )}
              />
              <Column
                field="delete"
                header="Delete"
                headerStyle={{ backgroundColor: "#5881fe1a" }}
                bodyStyle={{ padding: "1rem 0.1rem" }}
                style={{
                  fontSize: "0.93rem",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "normal",
                  letterSpacing: "-0.01rem",
                }}
                body={(rowData) =>
                  rowData.order_line?.length === 0 || rowData.isNew ? (
                    <Button
                      p={2.5}
                      w={20}
                      h={8}
                      bg={Colors.posCancleButtonRed}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.31rem",
                        flexShrink: 0,
                      }}
                      onClick={() =>
                        handleDeleteData(modifiers.indexOf(rowData))
                      }
                    >
                      <DeleteIcon style={{ fontSize: 13 }} />
                      Delete
                    </Button>
                  ) : (
                    <Button
                      p={2.5}
                      w={20}
                      h={8}
                      bg={Colors.posCancleButtonRed}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.31rem",
                        flexShrink: 0,
                      }}
                      isDisabled
                    >
                      <DeleteIcon style={{ fontSize: 13 }} />
                      Delete
                    </Button>
                  )
                }
              />
            </DataTable>
          )}
        </>
      </Box>
    </Box>
  );
};
export default WithRouter(ModifierSetEdit);
